import React, { useState } from "react";
import style from "../../style/Allproject.module.css";

export default function TopItemsContainer(props) {
  const tabs = ["opened", "clicked", "failed", "unsubscribed", "delivered"];
  const stats = props.stats;
  return (
    <div>
      <div className={`${style.ProjectCards} mt-0 mb-5`}>
        {tabs.map((item) => (
          <div
            className={`${style.statItem} ${
              props.statTab === item ? style.active : ""
            }`}
            onClick={() => props.setStatTab(item)}
          >
            <h4>{getStatsForTab(stats, item)}</h4>
            <span style={{ textTransform: "capitalize" }}>{item}</span>
          </div>
        ))}
      </div>
      {/* )} */}
    </div>
  );
}

const getStatsForTab = (stats, type) => {
  if (!stats.length) {
    return 0;
  }

  let emailService = "mailgun";
  if (stats[0].RecordType) {
    emailService = "postmark";
  } else if (stats[0].eventType) {
    emailService = "ses";
  }

  let typeValue = type;
  if (emailService === "postmark" || emailService === "ses") {
    typeValue =
      type === "opened"
        ? "Open"
        : type === "clicked"
        ? "Click"
        : type === "failed"
        ? "Bounce"
        : type === "unsubscribed"
        ? "SubscriptionChange"
        : type === "delivered"
        ? "Delivery"
        : "";
  }

  let numberOfStats = 0;
  if (emailService === "mailgun") {
    numberOfStats = stats.filter((ite) => ite.event === typeValue).length;
  } else if (emailService === "postmark") {
    numberOfStats = stats.filter((ite) => ite.RecordType === typeValue).length;
  } else if (emailService === "ses") {
    numberOfStats = stats.filter((ite) => ite.eventType === typeValue).length;
  }

  return numberOfStats;
};
