import React, { Component, useState } from "react";
import FrontFooter from "../components/Footer/FrontFooter";
import message from "antd/lib/message";
import apiRequest from "../api/clientRequest";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import AUTHACTIONS from "../redux/actions/auth";
import NewButton from "../components/NewButton";
import { useAuth } from "../components/auth/auth";
import { useHistory } from "react-router-dom";

var mapStateToProps = (state) => {
  return {
    onboarding: state.site.onboarding,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setOnboarding: (item) => dispatch(ACTIONS.setOnboarding(item)),
  setUser: (item) => dispatch(AUTHACTIONS.setUser(item)),
});

function SignUp() {
  const [email, setEmail] = useState("");

  const auth = useAuth();
  const history = useHistory();

  const checkIfUserExists = (val) => {
    return apiRequest({
      method: "POST",
      url: `/auth/email/verify`,
      data: {
        email: val,
      },
    })
      .then((res) => true)
      .catch((err) => {
        return err.data.message;
      });
  };
  const onSubmit = async () => {
    if (!email) {
      message.error("Please enter a username or email");
    } else {
      const response = await checkIfUserExists(email);
      if (response == "Email exists") {
        message.info("An account with this email already exists.");
        return;
      }
      auth.signupwithpasswordless(email).then((user) => {
        history.push("/email-sent");
      });
    }
  };
  return (
    <div className="container ">
      <section
        className="session "
        data-action="native"
        style={{ marginTop: "9em" }}
      >
        <h1>Create Your Account</h1>

        <div className="newFormContainer mb-5">
          <div className="formField" style={{ width: "326px" }}>
            <label htmlFor="">Email Address</label>
            <input
              type="email"
              className="black-outline-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
          </div>
        </div>
        <p style={{ marginBottom: "4em" }}>
          We'll send you an email to confirm it's you. But, you can start right
          after you sign-up here and confirm later. Your choice.
        </p>

        <NewButton onClick={onSubmit} text={"Sign-up"} />
      </section>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
