import ACTIONS from "../actions/news";
import _ from "lodash";
import player from "../../data/player";

const playerInstance = player();
var defaultState = {
  tags: [],
  feed: [],
  importantFeed: [],
  playlists: [],
  readLater: [],
  boards: [],
  boardsLabels: [],
  listenLater: [],
  podcasts: [],
  feedlyUser: { token: false, userId: false },
  userFeed: { feed: [], tags: [] },
  feedlyLogChanged: false,
  radioMode: false,
  stationMode: false,
  stations: [],
  wheather: null,
  youtubeVideos: [],
  youtubeUser: null,
  todayNews: [],
  doNotPlay: [],
  selectedFeed: "",
};

var siteReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.SET_SELECTED_FEED: {
      let newState = Object.assign({}, state);
      newState.selectedFeed = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_DONOT_PLAY: {
      let newState = Object.assign({}, state);
      newState.doNotPlay = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_SUBSCRIBED_NEWS: {
      let newState = Object.assign({}, state);
      newState.todayNews = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_YOUTUBE_USER: {
      let newState = Object.assign({}, state);
      newState.youtubeUser = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_YOUTUBE_VIDEOS: {
      let newState = Object.assign({}, state);
      newState.youtubeVideos = action.payload;
      return newState;
    }

    case ACTIONS.Types.setTags: {
      let newState = Object.assign({}, state);
      newState.tags = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_LISTEN_LATER: {
      let newState = Object.assign({}, state);
      newState.listenLater = action.payload;
      return newState;
    }
    case ACTIONS.Types.setFeed: {
      let newState = Object.assign({}, state);
      newState.feed = action.payload.feed;
      newState.tags = action.payload.tags;
      return newState;
    }

    case ACTIONS.Types.setImportantFeed: {
      let newState = Object.assign({}, state);
      newState.importantFeed = action.payload;
      return newState;
    }
    case ACTIONS.Types.setReadLater: {
      let newState = Object.assign({}, state);
      newState.readLater = action.payload;
      return newState;
    }
    case ACTIONS.Types.setBoards: {
      let newState = Object.assign({}, state);
      newState.boards = action.payload;
      // newState.boardsLabels = action.payload.categories;

      return newState;
    }

    case ACTIONS.Types.setSharedNews: {
      let newState = Object.assign({}, state);
      return newState;
    }
    case ACTIONS.Types.setPlaylist: {
      let newState = Object.assign({}, state);
      newState.playlists = action.payload;
      return newState;
    }
    case ACTIONS.Types.addEpisodes: {
      let newState = Object.assign({}, state);
      const podcast = action.payload;
      newState.podcasts.map((item) => {
        let foundIndex = item.podcasts.findIndex((pod) => pod.id == podcast.id);
        if (foundIndex >= 0) {
          let foundItem = item.podcasts[foundIndex];
          foundItem.episode = podcast.episode;
          item.podcasts[foundIndex] = foundItem;
        }
        return item;
      });
    }

    case ACTIONS.Types.addToPodcast: {
      let newState = Object.assign({}, state);
      const podcast = action.payload;
      const genres = newState.podcasts.map((item) => item.genre);
      if (genres.includes(podcast.genre))
        newState.podcasts = newState.podcasts.map((item) => {
          if (item.genre == podcast.genre) item.podcasts.push(podcast);
          return item;
        });
      else {
        newState.podcasts.push({ genre: podcast.genre, podcasts: [podcast] });
      }

      return newState;
    }

    case ACTIONS.Types.setPodcast: {
      let newState = Object.assign({}, state);
      newState.podcasts = action.payload;
      return newState;
    }

    case ACTIONS.Types.setFeedlyUser: {
      let newState = Object.assign({}, state);
      newState.feedlyUser = action.payload;
      return newState;
    }

    case ACTIONS.Types.setNewsLogChange: {
      let newState = Object.assign({}, state);
      newState.feedlyLogChanged = action.payload;
      return newState;
    }

    case ACTIONS.Types.setRadioMode: {
      let newState = Object.assign({}, state);
      newState.radioMode = action.payload;

      return newState;
    }

    case ACTIONS.Types.setStations: {
      let newState = Object.assign({}, state);
      let tempStations = action.payload.map((item) => {
        const foundStation = newState.stations.find(
          (ite) => ite.station == item.station
        );
        if (foundStation) {
          item.items["news"] = [
            ...item.items["news"],
            ...foundStation.items["news"],
          ];
          item.items["news"] = item.items["news"].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );

          item.items["podcasts"] = [
            ...item.items["podcasts"],
            ...foundStation.items["podcasts"],
          ];
          item.items["podcasts"] = item.items["podcasts"].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );

          item.items["shorts"] = [
            ...item.items["shorts"],
            ...foundStation.items["shorts"],
          ];
          item.items["shorts"] = item.items["shorts"].filter(
            (v, i, a) => a.findIndex((t) => t.id === v.id) === i
          );
          return item;
        } else {
          return item;
        }
      });
      newState.stations = action.payload;
      return newState;
    }

    case ACTIONS.Types.setStationMode: {
      let newState = Object.assign({}, state);
      newState.stationMode = action.payload;

      return newState;
    }

    case ACTIONS.Types.setWheather: {
      let newState = Object.assign({}, state);
      newState.wheather = action.payload;

      return newState;
    }

    default:
      return state;
  }
};
export default siteReducer;
