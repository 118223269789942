import React from "react";
import { connect } from "react-redux";
import AllBrand from "../brand/AllBrand";
import style from "../../style/tabs.module.css";
var mapStateToProps = (state) => {
  return {
    projects: state.projects.projects.filter((item) => item.type == "brand"),
  };
};

function BrandTab(props) {
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <AllBrand projects={props.projects} type="brand" />
    </div>
  );
}

export default connect(mapStateToProps, null)(BrandTab);
