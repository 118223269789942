import React, { useState, useEffect } from "react";
import style from "../style/LatestFeed.module.css";
import PlayCard from "./PlayCard";
import InfiniteScroll from "react-infinite-scroll-component";
import apiRequest from "../api/clientRequest";
import { Button, message } from "antd";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { addItemsToCampaign } from "../redux/actions/project";
import { setAddingSource } from "../redux/actions/main";
import PodcastCard from "./PodcastCard";
import _ from "lodash";
import ShortsCard from "./ShortsCard";
import { changeToCategoriesTab } from "../redux/actions/main";
import Actions from "../redux/actions/news";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  selectedFeed: state.news.selectedFeed,
  user: state.auth.user,
  showPodcastSubscribed: state.site.showPodcastSubscribed,
  feedlyUser: state.news.feedlyUser,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
});

function FeedContainer(props) {
  const [tab, setTab] = useState("ALL");
  const [feedTracks, setFeedTracks] = useState([]);
  const [page, setPage] = useState(1);

  const tabNames = ["ALL", "News", "Podcast", "Shorts", "Video"];

  useEffect(() => {
    setTab("ALL");
  }, []);

  const renderItems = (data) => {
    let items = data;

    if (tab !== "ALL")
      if (props.selectedFeed && props.selectedFeed.label == "Shorts Categories")
        items = items;
      else items = items.filter((item) => item.type == tab.toLowerCase());

    if (props.showDrag) {
      return (
        <ReactSortable
          list={items}
          group="campaignFeed"
          setList={(list) => console.log("sorting")}
          className={`row mt-3 mx-0 ${style.dropContainer}`}
        >
          {items.map((item) => {
            return (
              <div className="mb-3 py-0 pr-0">
                {item.type == "podcast" ? (
                  <PodcastCard
                    key={item._id ? item._id : item.id}
                    data={item}
                    projectView={props.projectView}
                    onClick={() => {
                      props.onClick({ ...item, subtype: "podcast" });
                    }}
                    related={items
                      .filter((ite) => ite.id !== item.id)
                      .slice(0, 4)}
                  />
                ) : item.type == "shortscat" ? (
                  <ShortsCard
                    data={item}
                    key={item._id ? item._id : item.id}
                    projectView={props.projectView}
                    onClick={() => {
                      props.onClick({
                        ...item,
                        id: item.title,
                        subtype: "shorts",
                      });
                    }}
                    related={items
                      .filter((ite) => ite.id !== item.id)
                      .slice(0, 4)}
                  />
                ) : (
                  <PlayCard
                    key={item._id ? item._id : item.id}
                    data={{ ...item }}
                    related={items
                      .filter((ite) => ite.id !== item.id)
                      .slice(0, 4)}
                  />
                )}
              </div>
            );
          })}
        </ReactSortable>
      );
    } else
      return items.map((item) => {
        return (
          <div className="mb-3 py-0 pr-0">
            {item.type == "podcast" ? (
              <PodcastCard
                data={item}
                projectView={props.projectView}
                onClick={() => {
                  props.onClick({ ...item, subtype: "podcast" });
                }}
                related={items.filter((ite) => ite.id !== item.id).slice(0, 4)}
              />
            ) : item.type == "shortscat" ? (
              <ShortsCard
                data={item}
                projectView={props.projectView}
                onClick={() => {
                  props.onClick({
                    ...item,
                    id: item.title,
                    subtype: "shorts",
                  });
                }}
                related={items.filter((ite) => ite.id !== item.id).slice(0, 4)}
              />
            ) : (
              <PlayCard
                data={{ ...item }}
                related={items.filter((ite) => ite.id !== item.id).slice(0, 4)}
              />
            )}
          </div>
        );
      });
  };
  const fetchMoreData = () => {
    if (props.selectedFeed || props.query) return;

    if (props.tab == "Now" && tab !== "ALL")
      apiRequest({
        url: `/today/lazyload`,
        data: {
          page,
          count: 20,
          type: tab,
          feedlyUser: props.feedlyUser,
        },
        method: "POST",
      }).then((res) => {
        setPage(page + 1);

        let tempItems = [...feedTracks, ...res.data.articles];
        tempItems = tempItems.sort((a, b) => {
          return b.published - a.published;
        });
        tempItems = _.uniqBy(tempItems, "id");

        setFeedTracks(tempItems);
      });
    // lazyload for projects,brand
    else if (props.activeProject) {
      apiRequest({
        url: "/feedly/lazyload",
        data: {
          page,
          count: 20,
          type: tab,
          feedlyUser: props.feedlyUser,
          projectFeeds: props.activeProject.feeds.map((item) => item.label),
          projectId: props.activeProject._id,
          boards: props.boards,
        },
        method: "POST",
      }).then((res) => {
        console.log(res);
        setPage(page + 1);

        let tempItems = [...feedTracks, ...res.data.articles];
        console.log(feedTracks);
        tempItems = tempItems.sort((a, b) => {
          return b.published - a.published;
        });
        tempItems = _.uniqBy(tempItems, "id");
        setFeedTracks(tempItems);
      });
    } else {
      // lazy load for today screen
      apiRequest({
        url: "/today/lazyload",
        data: {
          page,
          count: 20,
          type: props.tab,
          feedlyUser: props.feedlyUser,
        },
        method: "POST",
      }).then((res) => {
        setPage(page + 1);
        let tempItems = [...feedTracks, ...res.data.articles];
        tempItems = tempItems.sort((a, b) => {
          return b.published - a.published;
        });
        tempItems = _.uniqBy(tempItems, "id");

        setFeedTracks(tempItems);
      });
    }
  };

  useEffect(() => {
    let { news } = props;
    if (
      props.selectedFeed &&
      (props.selectedFeed.type == "podcast" ||
        props.selectedFeed.type == "shorts" ||
        props.selectedFeed.type == "news")
    ) {
      setFeedTracks(news);
      return;
    }

    if (props.todayTab && !props.feedlyUser.token) setFeedTracks(news);

    if (feedTracks.length < news.length) {
      let dataItems =
        tab == "ALL"
          ? news
          : news.filter((item) => item.type.toLowerCase() == tab);
      setPage(1);
      setFeedTracks(dataItems);
    }
  }, [props.news]);

  useEffect(() => {
    let { origFeeds, news } = props;
    if (props.boards) {
      let dataItems = origFeeds;
      setPage(1);
      // dataItems = dataItems.slice(0, 20);
      setFeedTracks(dataItems);
    } else {
      setPage(1);
      setFeedTracks(news);
    }
  }, [props.boards]);

  useEffect(() => {
    if (props.tab == "Now" && props.showPodcastSubscribed)
      apiRequest({
        url: `/today/lazyload`,
        data: {
          page: 0,
          count: 20,
          type: tab,
          podcastSubscribed: props.showPodcastSubscribed,
        },
        method: "POST",
      }).then((res) => {
        let tempItems = [...res.data.articles];
        tempItems = tempItems.sort((a, b) => {
          return b.published - a.published;
        });
        tempItems = _.uniqBy(tempItems, "id");
        setFeedTracks(tempItems);
        setPage(1);
      });
  }, [props.showPodcastSubscribed]);

  useEffect(() => {
    if (props.query)
      apiRequest({
        url: "/feedly/project/search",
        data: {
          type: tab,
          feedlyUser: props.feedlyUser,
          projectFeeds: props.activeProject.feeds.map((item) => item.label),
          projectId: props.activeProject._id,
          boards: props.boards,
          query: props.query,
        },
        method: "POST",
      }).then((res) => {
        let tempItems = [...res.data.articles];
        tempItems = tempItems.sort((a, b) => {
          return b.published - a.published;
        });
        tempItems = _.uniqBy(tempItems, "id");
        setFeedTracks(tempItems);
      });
  }, [props.query]);

  let tempItems = feedTracks;

  return (
    <div className={`${style.LatestFeed} ${props.projectView ? "mt-0" : ""}`}>
      {/* {props.projectView ? (
        ""
      ) : (
        <div className="mb-4 d-flex align-items-center">
          <h5 className="mb-0">{props.tab}</h5>
        </div>
      )} */}
      <div className={style.FeedTags}>
        {tabNames.map((item) => (
          <a
            href="#"
            className={tab == item ? style.active : ""}
            onClick={() => {
              setTab(item);
              if (props.todayTab && (item == "ALL" || item == "News")) {
                props.setAddingSource("News");
                props.changeToCategoriesTab("News");
                props.setSelectedFeed(null);
              } else if (props.todayTab) {
                props.setAddingSource(item);
                props.changeToCategoriesTab(item);
                props.setSelectedFeed(null);
              }

              if (props.tab == "Now")
                apiRequest({
                  url: `/today/lazyload`,
                  data: {
                    page: 0,
                    count: 20,
                    type: item,
                    podcastSubscribed: props.showPodcastSubscribed,
                  },
                  method: "POST",
                }).then((res) => {
                  let tempItems = [...res.data.articles];
                  tempItems = tempItems.sort((a, b) => {
                    return b.published - a.published;
                  });
                  tempItems = _.uniqBy(tempItems, "id");
                  setFeedTracks(tempItems);
                  setPage(1);
                });
            }}
          >
            {item}
          </a>
        ))}
      </div>
      <InfiniteScroll
        dataLength={feedTracks.length}
        next={fetchMoreData}
        hasMore={true}
        scrollableTarget={props.container}
      >
        <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
          {renderItems(tempItems)}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default connect(mapState, dispatchState)(FeedContainer);
