import React, { useState, useEffect } from "react";
import { ProjectCard as ProjectCarstyle } from "../../style/projectCard.module.css";
import style from "../../style/createproject.module.css";
import { PlusOutlined } from "@ant-design/icons";
import apiRequest from "../../api/clientRequest";
import { message, Modal } from "antd";
import {
  addNewProject,
  setActiveColor,
  setActiveProject,
  deleteAnItem,
  editProject,
} from "../../redux/actions/project";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { addRecentItem } from "../../redux/actions/main";
import { connect } from "react-redux";
import colors from "../../data/colors.json";

function CreatePost(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState(colors[3]);

  const createProject = () => {
    if (props.activeProject) {
      apiRequest({
        method: "PUT",
        url: `/projects/${props.activeProject._id}`,
        data: {
          title,
          description,
          color: JSON.stringify(color),
          type: "post",
        },
      }).then((res) => {
        props.editProject(res.data);
        message.success("Project Updated Successfully.");
        props.setCreatingProjects(false);
      });
    } else {
      apiRequest({
        method: "POST",
        url: "/projects",
        data: {
          title,
          description,
          color: JSON.stringify(color),
          type: "post",
        },
      }).then((res) => {
        props.addNewProject(res.data);
        message.success("Project Created Successfully.");
        props.setCreatingProjects(false);
        props.setActiveProject(res.data);

        props.addRecentItem(res.data);
      });
    }
  };

  useEffect(() => {
    if (!props.createProject) setColor("");
  }, [props.createProject]);

  useEffect(() => {
    if (props.activeProject) {
      const { title, description, color } = props.activeProject;
      setTitle(title);
      setDescription(description);
      setColor(color);
    }
  }, [props.activeProject]);

  return (
    <div
      style={{ position: "relative", alignSelf: "baseline" }}
      className="d-flex"
    >
      <div
        className={`cards ${ProjectCarstyle}`}
        style={{
          backgroundColor: color ? color.primaryColor : "#fff",
          width: "148px",
        }}
      >
        <PlusOutlined
          style={{ fontSize: "2em", cursor: "pointer", color: "white" }}
          onClick={() => props.setCreatingProjects(!props.createProject)}
        />
        <p className="mb-4 mr-2" style={{ color: "black" }}>
          {props.activeProject ? "EDIT PODCAST" : "Add New Podcast Set"}
        </p>
      </div>
      {props.createProject ? (
        <div className={style.createContainer}>
          <div className={style.inputContainer}>
            <div>
              <label className="d-block" htmlFor="">
                Post Name
              </label>
              <div className={style.inputAppend}>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <label className="d-block" htmlFor="">
                Post Description
              </label>
              <div className={style.inputAppend}>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={3}
                  style={{ marginBottom: 0 }}
                  className={style.searchInput}
                ></textarea>
              </div>
            </div>

            {/* <div style={{ marginTop: "30px" }}>
              <label className="d-block" htmlFor="">
                Post Colors
              </label>
              <div className="d-flex">
                {colors.map((item) => (
                  <button
                    onClick={(e) => {
                      setColor(item);
                      props.setActiveColor(item);
                    }}
                    className={`${style.colorBtn} ${style.colorActive}`}
                    style={{ backgroundColor: `${item.primaryColor}` }}
                  ></button>
                ))}
              </div>
            </div> */}

            <div>
              <button onClick={() => createProject()} className={style.saveBtn}>
                Save
              </button>
              {props.activeProject ? (
                <button
                  onClick={() => {
                    Modal.confirm({
                      title: "Do you Want to delete this Post Set?",
                      icon: <ExclamationCircleOutlined />,
                      content: "You won't be able to recover this",
                      onOk() {
                        props.setCreatingProjects(false);
                        props.deleteAnItem();
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }}
                  style={{ width: "auto" }}
                  className={`${style.saveBtn} mr-3`}
                >
                  Delete Post Set
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
});
const mapDispatch = (dispatch) => ({
  addNewProject: (data) => dispatch(addNewProject(data)),
  setActiveColor: (data) => dispatch(setActiveColor(data)),
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  addRecentItem: (data) => dispatch(addRecentItem(data)),
  deleteAnItem: () => dispatch(deleteAnItem()),
  editProject: (data) => dispatch(editProject(data)),
});

export default connect(mapState, mapDispatch)(CreatePost);
