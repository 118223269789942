import React, { useState, useEffect } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { Input, AutoComplete } from "antd";
import RecentItem from "./RecentItem";
import { connect } from "react-redux";
import { setAddingSource } from "../../redux/actions/main";

const mapState = (state) => ({
  items: state.projects.activeProject
    ? state.projects.activeProject.addedItems.map((item) =>
        JSON.parse(item.article)
      )
    : [],
  addSource: state.main.addSource,
});

const dispatchState = (dispatch) => ({
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function AddItem(props) {
  const [selectedTab, setSelectedTab] = useState("");
  const { addSource } = props;

  return (
    <div className={style.AddItem}>
      <div
        className={`${style.topSection} topSection`}
        style={{ zIndex: "9999999" }}
      >
        <div className={style.actionBtn}>
          <button
            onClick={() => props.setAddingSource("Projects")}
            className={addSource == "Projects" ? style.active : ""}
          >
            <img src="/images/addProjectIcon.svg" alt="" />
            <span>Add from Projects</span>
          </button>

          <button
            onClick={() => props.setAddingSource("Today")}
            className={addSource == "Brands" ? style.active : ""}
          >
            <img src="/images/calendar.png" className="mb-2" alt="" />
            <span>Add from Today Screen</span>
          </button>
          <button
            onClick={() => props.setAddingSource("Posts")}
            className={addSource == "Posts" ? style.active : ""}
          >
            <img
              src="/images/projectIcon.svg"
              alt=""
              style={{ filter: "none", width: "25px" }}
            />
            <span>Add from Podcasts</span>
          </button>
          {/* <button
            onClick={() => props.setAddingSource("Collections")}
            className={addSource == "Collections" ? style.active : ""}
          >
            <img src="/images/addCollectingIcon.svg" alt="" />
            <span>Add from Collections</span>
          </button> */}
        </div>
      </div>

      <div style={{ paddingTop: "7.5em" }}>
        <RecentItem
          topPosition="12em"
          addView={true}
          data={props.items}
          showDrag={true}
        />
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(AddItem);
