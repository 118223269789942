import React, { useState, useEffect, createRef } from "react";
import style from "../../style/LatestFeed.module.css";
import sidebarStyle from "../../style/sidebarTabsContent.module.css";
import { connect, useSelector } from "react-redux";
import { addItemsToCampaign } from "../../redux/actions/project";
import {
  setAddingSource,
  setPostData,
  changeToCategoriesTab,
  createAdvertisement,
  uploadStandardPost,
  setMeter,
  setMeterProgress,
  setMeterTitle,
  setInterviewActions,
} from "../../redux/actions/main";
import createStyle from "../../style/createproject.module.css";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./constants";
import memberstyle from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import useDynamicRefs from "use-dynamic-refs";
import StrapiRequest from "../../api/strapiRequest";
import apiRequest from "../../api/clientRequest";
import player from "../../data/player";
import environment from "../../api/environment";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { message } from "antd";
import FeedTag from "../sidebarTabs/components/FeedTag";
import detailStyle from "../../style/ProjectDetail.module.css";
import { Switch } from "antd";
import InterviewBar from "./InterviewBar";
import { db } from "../../firebase";
import firebase from "../../firebase";
import LiveStreaming from "./LiveStreaming";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
const Player = player();
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  postData: state.main.postData,
  recordMode: state.main.recordMode,
  socket: state.site.socket,
  user: state.auth.user,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  createAdvertisement: (feed) => dispatch(createAdvertisement(feed)),
  uploadStandardPost: (feed, doTitle, voice, background, backgroundType) =>
    dispatch(
      uploadStandardPost(feed, doTitle, voice, background, backgroundType)
    ),
  setMeter: (feed) => dispatch(setMeter(feed)),
  setMeterProgress: (feed) => dispatch(setMeterProgress(feed)),
  setMeterTitle: (feed) => dispatch(setMeterTitle(feed)),
  setInterviewActions: (feed) => dispatch(setInterviewActions(feed)),
});

function InterviewAddPost(props) {
  const [stories, setStories] = useState(
    props.postData.members ? props.postData.members : []
  );
  const propsRef = React.useRef(props.postData);
  const editorBodyRef = React.useRef("");
  const selectedItemRef = React.useRef("");
  const [backgroundMusic, setBackgroundMusic] = React.useState(true);
  const storyCreated = React.useRef("");
  const story = React.useRef("");
  const user = useSelector((state) => state.auth.user);

  const postTypes = [
    { id: 1, name: "NOTES" },
    { id: 3, name: "TRANSCRIPT" },
  ];

  const [selectedPostType, setSelectedPostType] = useState(postTypes[0].name);

  const [selectedStoryline, setSelectedStoryLine] = useState("none");
  const [placeholder, setPlaceholder] = useState(
    "Start typing here to create your awesome podcast show.  We will transform everything into great sounding audio that you can share with your audience."
  );

  const { socket } = props;

  useEffect(() => {
    let allItems = [...stories];
    if (selectedStoryline >= 0) {
      if (
        props.postData.voice ||
        props.postData.image ||
        props.postData.background ||
        props.postData.backgroundOff ||
        props.postData.storyTitle
      ) {
        let tempItem = {};
        if (props.postData.image) tempItem["image"] = props.postData.image;
        if (props.postData.voice) tempItem["voice"] = props.postData.voice;
        if (props.postData.storyTitle)
          tempItem["storyTitle"] = props.postData.storyTitle;
        if (props.postData.backgroundOff) tempItem["background"] = "";
        if (props.postData.sub_type)
          tempItem["sub_type"] = props.postData.sub_type;
        if (props.postData.speechUrl)
          tempItem["speechUrl"] = props.postData.speechUrl;
        if (props.postData.voiceSpeech) {
          tempItem["voiceSpeech"] = props.postData.voiceSpeech;
          tempItem["description"] = props.postData.voiceSpeech;
        } else tempItem["background"] = props.postData.background;

        allItems[selectedStoryline] = {
          ...allItems[selectedStoryline],
          ...tempItem,
        };

        setStories(allItems);
      }
    } else {
      if (props.postData.image) {
        if (
          propsRef.current.interviewImage &&
          propsRef.current.interviewImage.file !== props.postData.image.file
        )
          props.setPostData({
            ...props.postData,
            interviewImage: props.postData.image,
            image: "",
          });
        else
          props.setPostData({
            ...props.postData,
            interviewImage: props.postData.image,
            image: "",
          });
      }
    }
  }, [props.postData]);

  React.useEffect(() => {
    // socket.on("completedStoryTTS", (data) => {
    //   console.log(data, "completed tts");
    //   if (data.progress == 100) {
    //     createStoryLine({ speechUrl: data.speechUrl, duration: data.duration });
    //   } else {
    //     props.setMeterTitle(data.progressTitle);
    //     props.setMeterProgress(data.progress);
    //   }
    // });
    if (props.postData.id) {
      storyCreated.current = props.postData;
      setUidOfUser(props.postData.id, user);
    }

    console.log("on mount", storyCreated);

    if (props.postData.members) {
      setStories(props.postData.members);
    }

    const autoSaveMembers = async () => {
      console.log("post updated", storyCreated.current);

      if (
        props.postData.members &&
        props.postData.members.find((item) => !item.created)
      ) {
        const members = props.postData.members.filter((item) => !item.created);

        if (!storyCreated.current.id) {
          props.setMeter(true);
          props.setMeterTitle("Processing");
          props.setMeterProgress(25);

          await createInterview();
        }
        console.log(storyCreated.current);
        for (let i = 0; i < members.length; i++) {
          await onStartCreatingPost(members[i]);
        }
      }
    };
    autoSaveMembers();
    return () => {
      socket.off("completedStoryTTS");
      setPresenceOffline();
    };
  }, []);

  const setUidOfUser = (roomId, newUser) => {
    const reference = firebase
      .database()
      .ref(`/online/${roomId}/${newUser._id}`);
    const online = {
      displayName: newUser.email,
      date: new Date().getTime(),
      key: newUser._id,
      email: newUser.email,
    };
    reference.set(online).then(() => {});

    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect configured"));
  };
  const setPresenceOffline = () => {
    firebase
      .database()
      .ref(`/online/${storyCreated.current.id}/${user._id}`)
      .remove();
  };

  React.useEffect(() => {
    propsRef.current = props.postData;
  }, [props.postData]);

  const onStartCreatingPost = async (SelectedItem) => {
    // const { body, voice, background, backgroundType, sub_type, speechUrl } =
    //   SelectedItem;
    if (!props.postData.title) {
      message.error("Please provide a title for the interview");
      return;
    }

    if (!storyCreated.current) {
      props.setMeter(true);
      props.setMeterTitle("Processing");
      props.setMeterProgress(25);

      await createInterview();
    }

    await createInterviewMember(SelectedItem);

    return;
  };

  const createInterview = async () => {
    apiRequest({
      url: "/campaigns/clearcache",
      method: "GET",
    });

    console.log("creating interview");

    props.setMeter(true);
    props.setMeterTitle("Creating Interview");
    props.setMeterProgress(25);

    let postData = props.postData;
    const newId = uuidv4();
    await db.collection("interview").doc(newId).set({
      title: postData.title,
      published: moment().format(),
      published_at: moment().format(),
      post_id: props.activeProject._id,
      source: props.user.fullname,
      user_id: props.user._id,
      user: props.user,
      draft: false,
      id: newId,
    });

    await db
      .collection("interview")
      .where("id", "==", newId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let itemData = doc.data();

          props.setMeterTitle("Interview Saved Successfully");
          props.setMeterProgress(100);
          props.setMeter(false);
          storyCreated.current = itemData;
          props.setPostData({
            ...props.postData,
            created: true,
            id: newId,
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    return;
  };

  const createInterviewMember = async (member) => {
    props.setMeter(true);
    props.setMeterTitle("Sending Invite");
    props.setMeterProgress(25);

    apiRequest({
      url: "/campaigns/clearcache",
      method: "GET",
    });

    let postData = member;

    delete member["body"];

    const newId = uuidv4();
    await db.collection("interview-members").doc(newId).set({
      published: moment().format(),
      id: newId,
      member: postData,
      role: "Co-Host",
      interview: storyCreated.current.id,
      user_id: postData.user_id,
    });

    await db
      .collection("interview-members")
      .where("id", "==", newId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let itemData = doc.data();

          props.setMeterTitle("Member addedd successfully");
          props.setMeterProgress(80);

          setStories((stories) => {
            let foundInd = stories.findIndex(
              (item) => item.email == itemData.member.email
            );
            let tempStories = [...stories];
            tempStories[foundInd]["created"] = true;
            tempStories[foundInd]["id"] = itemData.id;
            return stories;
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    props.setMeterProgress(100);
    props.fetchPosts();
    setTimeout(() => {
      props.setMeter(false);
      props.setMeterTitle("");
      props.setMeterProgress(0);
    }, 2000);
  };

  const onDeleteStoryLine = (id) => {
    let newMembers = stories.filter((item) => item.id !== id);
    setStories(newMembers);
    StrapiRequest({
      url: `interview-members/${id}`,
      method: "DELETE",
    });
    props.setPostData({
      ...props.postData,
      members: newMembers,
    });
  };

  const setBodyRef = (index, instance) => {
    let allItems = [...stories];
    allItems[index]["body"] = instance;
    setStories(allItems);
  };

  const onSave = async (index) => {
    let allItems = [...stories];
    let SelectedItem = allItems[index];
    selectedItemRef.current = { ...SelectedItem, order: index + 1 };
    onStartCreatingPost({ ...SelectedItem, order: index + 1 });
  };
  console.log(storyCreated, "story is created");
  return (
    <div
      className={`${style.LatestFeed} mt-0`}
      style={{ marginBottom: "20em" }}
    >
      {props.postData.id ? (
        <LiveStreaming
          isHost={true}
          room={{
            ...props.postData,
            title: props.postData.title.replace(/\s/g, ""),
          }}
          roomId={props.postData.id}
        />
      ) : (
        ""
      )}
      <h5
        className={`${createStyle.tabBtn} text-center mb-0 `}
        style={{
          cursor: "pointer",
          position: "fixed",
          left: "1200px",
          top: "53%",
          border: "none",
        }}
        onClick={() => {
          props.setInterviewActions("invite");
        }}
      >
        <img src="/images/plusButton.svg" alt="" />
      </h5>

      {stories &&
        stories.map((item, index) => (
          <>
            <div
              className={`row mt-5 mx-0 ${style.dropContainer} ${style.statusBar} `}
              style={{
                borderRadius: 0,
                // height: "75px",
                width: "970px",
                position: "relative",
              }}
            >
              <div className="d-flex pl-4 w-100 mb-0">
                <div
                  className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-4 d-flex align-items-center`}
                  style={{
                    marginTop: "-12px",
                  }}
                  onClick={() => {
                    // if (item.sub_type !== "REC0RD") {
                    //   props.changeToCategoriesTab("VoiceTab");
                    //   setSelectedStoryLine(index);
                    //   props.setPostData({ ...props.postData, ...item });
                    // }
                  }}
                >
                  <ImageInitial fullname={item.fullname} image={item.avatar} />
                  {/* <img
                    src={item.avatar}
                    className={style.voiceAvatar}
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                  /> */}
                  <div className={memberstyle.userDetail}>
                    <div>
                      <p style={{ color: "#38ef7d" }}>Co-Host</p>
                      <p>{item.fullname}</p>
                      <p style={{ opacity: "0.5" }}>Podcaster</p>
                    </div>
                    <div className="ml-4">
                      <h5
                        className={`${style.statusActionBtn} text-center mb-0 `}
                      >
                        Change
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className={`${memberstyle.userItem} ${memberstyle.smallUserItem} d-flex  align-items-center`}
                  style={{ marginTop: "-12px" }}
                  onClick={() => {
                    // props.changeToCategoriesTab("ImageTab");
                    setSelectedStoryLine(index);
                    // props.setPostData({
                    //   ...props.postData,
                    //   ...item,
                    //   image: item.image ? item.image : false,
                    // });
                  }}
                >
                  <div className={memberstyle.userDetail}>
                    <div style={{ marginTop: "-22px" }}>
                      <p className="" style={{ color: "#38ef7d" }}>
                        CO-HOST OPTIONS
                      </p>
                    </div>
                    <div className="d-flex ml-2" style={{ marginTop: "25px" }}>
                      <div className={"d-flex flex-column align-items-center"}>
                        <img
                          src="/images/chatIcon.svg"
                          alt=""
                          style={{ filter: "none" }}
                          className="mb-2"
                        />
                        <p
                          className="text-center"
                          style={{
                            color: "#999999",
                            fontSize: "7px",
                            width: "50px",
                          }}
                        >
                          Direct Message
                        </p>
                      </div>
                      <div
                        className={"d-flex flex-column align-items-center ml-3"}
                      >
                        <img
                          src="/images/micIcon.svg"
                          alt=""
                          style={{ filter: "none" }}
                          className="mb-2"
                        />
                        <p
                          className="text-center"
                          style={{
                            color: "#999999",
                            fontSize: "7px",
                            width: "30px",
                          }}
                        >
                          Mute Mic
                        </p>
                      </div>
                      <div
                        className={"d-flex flex-column align-items-center ml-4"}
                      >
                        <img
                          src="/images/cameraIcon.svg"
                          alt=""
                          style={{ filter: "none" }}
                          className="mb-2"
                        />
                        <p
                          className="text-center"
                          style={{
                            color: "#999999",
                            fontSize: "7px",
                            width: "40px",
                          }}
                        >
                          Hide Camera
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${memberstyle.userItem} ${memberstyle.smallUserItem} ml-5 d-flex  align-items-center`}
                  onClick={() => {
                    props.changeToCategoriesTab("BackgroundMusicTab");
                    setSelectedStoryLine(index);
                    // props.setPostData({ ...props.postData, ...item });
                  }}
                  style={{ marginTop: "-12px" }}
                >
                  <img
                    src="/images/recordButton.svg"
                    className={style.voiceAvatar}
                    alt=""
                    style={{
                      borderRadius: 0,
                      filter: "invert(1)",
                      width: "35px",
                      height: "35px",
                    }}
                  />
                  <div className={memberstyle.userDetail}>
                    <div style={{ marginTop: "-22px" }}>
                      <p style={{ color: "#38ef7d" }}>RECORD OPTIONS</p>
                    </div>
                    <div className="mt-4" style={{ marginLeft: "-5.6em" }}>
                      <div
                        style={{
                          height: "auto",
                          backgroundColor: "transparent",
                          marginBottom: 0,
                        }}
                        className={`${sidebarStyle.reverbContainer} d-flex ml-2 mt-3`}
                      >
                        <Switch
                          className={`${sidebarStyle.switch} ${
                            backgroundMusic ? sidebarStyle.activeSwitch : ""
                          }`}
                          checkedChildren={<span>On</span>}
                          unCheckedChildren={<span>Off</span>}
                          checked={backgroundMusic}
                          onChange={() => {
                            if (backgroundMusic) {
                              setBackgroundMusic(false);
                            } else {
                              setBackgroundMusic(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex align-items-center justify-content-end pr-3 "
                  style={{ marginTop: "-24px", flex: 1 }}
                >
                  <div
                    className={"d-flex flex-column align-items-center ml-4"}
                    style={{ marginTop: "2em" }}
                  >
                    <img
                      onClick={() => {}}
                      src={"/images/playIcon.svg"}
                      className="mb-2"
                      style={{
                        filter: "invert(1)",
                        cursor: "pointer",
                        width: "35px",
                        marginTop: "6px",
                      }}
                    />
                    <p
                      className="text-center"
                      style={{
                        color: "#999999",
                        fontSize: "10px",
                        width: "70px",
                      }}
                    >
                      Play Stream
                    </p>
                  </div>

                  <div
                    className={"d-flex flex-column align-items-center mx-3"}
                    style={{ marginTop: "2em" }}
                  >
                    <img
                      onClick={() => {}}
                      src={"/images/downloadIcon.svg"}
                      className="mb-2"
                      style={{
                        filter: "invert(1)",
                        cursor: "pointer",
                        width: "35px",
                        marginTop: "6px",
                      }}
                    />
                    <p
                      className="text-center"
                      style={{
                        color: "#999999",
                        fontSize: "10px",
                        width: "90px",
                      }}
                    >
                      Download Stream
                    </p>
                  </div>

                  {item.created ? (
                    <h5
                      className={`${style.statusActionBtn} ${style.redButton} text-center mb-0 `}
                      onClick={() => {
                        onDeleteStoryLine(item.id);
                      }}
                    >
                      Remove
                    </h5>
                  ) : (
                    ""
                  )}
                  <h5
                    className={`${style.statusActionBtn} ${style.greenButton} text-center mb-0 ml-3`}
                    onClick={() => onSave(index)}
                  >
                    Save
                  </h5>
                </div>
              </div>
              <div
                className={`d-flex pl-4 mt-0  w-100 ${memberstyle.userDetail} ${detailStyle.Description}`}
                style={{ justifyContent: "flex-start" }}
              >
                <div style={{ width: "217px" }}>
                  <h6
                    style={{
                      textTransform: "uppercase",
                    }}
                    className="mb-2"
                  >
                    STREAM CONTENT
                  </h6>
                  <div className="d-flex">
                    {postTypes.map((item) => (
                      <FeedTag
                        onClick={(id) => {
                          setSelectedPostType(item.name);
                          setSelectedStoryLine(index);
                          props.setPostData({
                            ...props.postData,
                            sub_type: item.name,
                          });
                        }}
                        isSelected={selectedPostType == item.name}
                        data={{ id: item.id, label: item.name }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`row  mx-0 ${style.dropContainer} flex-column`}
              style={{
                minHeight: "200px",
                paddingBottom: index == stories.length - 1 ? "20em" : "",
              }}
            >
              <div className="pr-5  w-100">
                <div
                  className={style.editorJs}
                  style={{
                    borderRadius: 0,
                    width: "970px",
                    boxShadow:
                      selectedStoryline == index
                        ? `0 24px 24px -18px rgb(69 104 129 / 33%), 0 9px 45px 0 rgb(114 119 160 / 12%)`
                        : "",
                  }}
                >
                  <EditorJs
                    tools={EDITOR_JS_TOOLS}
                    instanceRef={(instance) => {
                      setBodyRef(index, instance);
                    }}
                    // enableReInitialize={item.sub_type == "REC0RD" ? true : false}
                    data={item.description}
                    minHeight={0}
                    placeholder={placeholder}
                    style={{ height: "100%" }}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
}

export default connect(mapState, dispatchState)(InterviewAddPost);
