var axios = require("axios");
const Authorization = `Basic ${Buffer.from(
  `${process.env.REACT_APP_AGORA_KEY}:${process.env.REACT_APP_AGORA_SECRET}`
).toString("base64")}`;
export const recordAcquire = async (RecordingUID, cname) => {
  var data = JSON.stringify({
    cname: cname,
    uid: `${RecordingUID}`,
    clientRequest: {
      resourceExpiredHour: 72,
    },
  });

  var config = {
    method: "post",
    url: `https://api.agora.io/v1/apps/${process.env.REACT_APP_AGORA_APP_ID}/cloud_recording/acquire`,
    headers: {
      "Content-Type": "application/json",
      Authorization,
    },
    data: data,
  };

  return await axios(config);
};
export const recordStart = async (RecordingUID, resourceId, cname, token) => {
  var data = {
    cname: cname,
    uid: `${RecordingUID}`,
    clientRequest: {
      token: token,
      recordingConfig: {
        maxIdleTime: 120,
        streamTypes: 3,
        channelType: 0,
        subscribeUidGroup: 2,
      },
      storageConfig: {
        accessKey: "AKIATZLNVIV4YBG2LXVQ",
        region: 0,
        bucket: "agorarecordingsaudioone",
        secretKey: "XX/cIaHFvWbwYxd+W9NuJVX2oAM40DOby0Z/N+IX",
        vendor: 1,
        fileNamePrefix: [`${RecordingUID}`],
      },
    },
  };
  var config = {
    method: "post",
    url: `https://api.agora.io/v1/apps/${process.env.REACT_APP_AGORA_APP_ID}/cloud_recording/resourceid/${resourceId}/mode/individual/start`,
    headers: {
      "Content-Type": "application/json",
      Authorization,
    },
    data: data,
  };
  let f = await axios(config);
  return f.data;
};

export const recordStop = async (body, uid, cname) => {
  var config = {
    method: "post",
    url: `http://api.agora.io/v1/apps/${process.env.REACT_APP_AGORA_APP_ID}/cloud_recording/resourceid/${body.resourceId}/sid/${body.sid}/mode/individual/stop`,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization,
    },
    data: {
      cname: cname,
      uid: `${uid}`,
      clientRequest: {
        async_stop: true,
      },
    },
  };
  let f = await axios(config);
  return f.data;
};
export const recordQuery = async (body) => {
  var config = {
    method: "get",
    url: `https://api.agora.io/v1/apps/${process.env.REACT_APP_AGORA_APP_ID}/cloud_recording/resourceid/${body.resourceId}/sid/${body.sid}/mode/individual/query`,
    headers: {
      "Content-Type": "application/json",
      Authorization,
    },
  };

  return await axios(config);
};
