import React, { useState } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { connect } from "react-redux";
import { setAddingSource } from "../../redux/actions/main";
import FeedTag from "./components/FeedTag";
import Actions from "../../redux/actions/news";
import apiRequest from "../../api/clientRequest";
import PodcastTags from "./podcastags.json";

const mapState = (state) => ({
  items: state.projects.projects,
  activeProject: state.projects.activeProject,
  feedlyUser: state.news.feedlyUser,
  tags: state.news.tags,
});

const dispatch = (dispatch) => ({
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function AddItem(props) {
  const [selectedTab, setSelectedTab] = useState("Project");

  const tabs = [
    { title: "Add Projects", image: "projectIcon.svg" },
    { title: "Add Brands", image: "brandIcon.svg", link: "/dashboard/brand" },
    {
      title: "Add Collections",
      image: "collectingIcon.svg",
    },
    {
      title: "Add Campaigns",
      image: "campaignIcon.svg",
    },
    {
      title: "Add Audience",
      image: "audienceIcon.svg",
    },

    {
      title: "Add Templates",
      image: "templatesIcon.svg",
    },
    { title: "Add Teams", image: "teamsIcon.svg" },
    { title: "Add Items", image: "plusIcon.svg" },
  ];
  return (
    <div className={style.AddItem}>
      <div
        className={`${style.topSection} topSection`}
        style={{ zIndex: "9999999" }}
      >
        <div className={`${style.actionBtn} p-0`}>
          {tabs.map((item) => (
            <button
              onClick={() => {
                setSelectedTab(item.title);
                props.setAddingSource(item.title);
              }}
              className={selectedTab == item.title ? style.active : ""}
            >
              <img src={`/images/${item.image}`} alt="" />
              <span>{item.title}</span>
            </button>
          ))}
        </div>
        {selectedTab == "Add Items" ? (
          <div
            className="d-flex flex-wrap px-3"
            style={{
              overflow: "auto",
              paddingBottom: "6em",
              paddingTop: "2em",
              height: "388px",
              overflow: "auto",
            }}
          >
            {props.tags.map((item) => (
              <FeedTag
                isSelected={
                  props.activeProject
                    ? props.activeProject.feeds.find((ite) => ite.id == item.id)
                    : false
                }
                onClick={(id) => {
                  props.setSelectedFeed({ ...item, feed: [] });
                  apiRequest({
                    url: `/feedly/articles/${item.label}`,
                    method: "POST",
                    data: { feedlyUser: props.feedlyUser },
                  }).then((res) => {
                    props.setSelectedFeed({
                      ...item,
                      feed: res.data.articles,
                    });
                  });
                }}
                data={item}
              />
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatch)(AddItem);
