import React, { useState, useEffect } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import FeedTag from "./components/FeedTag";
import { connect } from "react-redux";
import {
  setAddingSource,
  setPostData,
  changeToCategoriesTab,
  setShareSidebar,
} from "../../redux/actions/main";
import apiRequest from "../../api/clientRequest";
import { Input, AutoComplete, message } from "antd";
import createStyle from "../../style/createproject.module.css";
import axios from "axios";
import environment from "../../api/environment";
import FileDropZone from "../FileDropZone";
import VoiceRecord from "../VoiceRecord";
import UploadAudio from "../UploadAudio";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import VoiceTab from "./VoiceTab";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import ReverbSettings from "./ReverbSettings";
import BackgroundSettings from "./BackgroundSettings";
import AudioTemplateSettings from "./AudioTemplateSettings";

const mapState = (state) => ({
  tags: state.news.tags,
  feedlyUser: state.news.feedlyUser,
  activeProject: state.projects.activeProject,
  addingSource: state.main.addSource,
  boards: state.news.boards,
  postData: state.main.postData,
  tabChange: state.main.tabChange,
});

const mapDispatch = (dispatch) => ({
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setShareSidebar: (feed) => dispatch(setShareSidebar(feed)),
});

function AddPostDetail(props) {
  const [tab, setTab] = React.useState("Categories");
  const [searchVal, setSearchVal] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tabType, setTabType] = useState("image");
  const [creatingCategory, setCreatingCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [backgroundMusic, setBackgroundMusic] = useState("");
  useEffect(() => {
    if (props.postData.editing == "edit") {
      setSelectedCategories(props.postData.categories);
    } else if (props.postData.editing == "none") {
      setSelectedCategories([]);
    }

    if (
      props.postData.post_type == "INTRO" ||
      props.postData.post_type == "OUTRO"
    )
      setTabType("audioTemplate");

    setBackgroundMusic(props.postData.background);
  }, [props.postData]);

  React.useEffect(() => {
    if (props.tabChange == "VoiceTab") setTabType("selectVoices");
    else if (props.tabChange == "BackgroundMusicTab")
      setTabType("backgroundMusic");
    else if (props.tabChange == "ImageTab") setTabType("image");

    props.changeToCategoriesTab(" ");
  }, [props.tabChange]);

  const intilizeData = async () => {
    const cat = await axios
      .get(`${environment.strapi_url}categories`)
      .then((res) => res.data);
    setCategories(cat);
  };

  useEffect(() => {
    intilizeData();
  }, []);

  const onSelect = (id) => {
    const foundItem = categories.find((item) => item.id == id);
    setSelectedCategories([foundItem, ...selectedCategories]);
    props.setPostData({
      ...props.postData,
      categories: [foundItem, ...selectedCategories],
      editing: false,
    });
  };

  const onBackgroundMusicSelect = (val) => {
    setBackgroundMusic(val);
    props.setPostData({
      ...props.postData,
      background: val,
    });
  };

  const createCategory = async (id) => {
    await axios
      .post(`${environment.strapi_url}categories`, {
        name: categoryName,
        value: categoryName,
      })
      .then((res) => res.data);
    message.success("Category added");
    intilizeData();
    setCategoryName("");
    setCreatingCategory(false);
  };

  let tempCats = categories;
  if (searchVal)
    tempCats = tempCats.filter((item) =>
      item.name.toLowerCase().includes(searchVal.toLowerCase())
    );

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  console.log("tab is", tabType);

  return (
    <div className={style.AddItem}>
      <div className={`${style.topSection} topSection`} style={{ top: "0" }}>
        <div className="text-right mt-2 mr-2 text-bold">
          <b>
            {props.postData.selectedStory
              ? `Chapter ${props.postData.selectedStory}`
              : props.postData.post_type}
          </b>
        </div>
        <div className={style.actionBtn}>
          <button
            className={style.active}
            onClick={() => {
              setTabType("image");
            }}
            style={{
              borderColor: tabType == "image" ? "#38ef7d" : "transparent",
            }}
          >
            <img src="/images/imageUpload.png" alt="" />
            <span>Cover Image</span>
          </button>
          <button
            className={style.active}
            onClick={() => {
              setTabType("categories");
            }}
            style={{
              borderColor: tabType == "categories" ? "#38ef7d" : "transparent",
            }}
          >
            <img src="/images/postCategories.svg" alt="" />
            <span>Post Categories</span>
          </button>{" "}
          {props.postData && props.postData.post_type == "INTERVIEW" ? (
            ""
          ) : (
            <>
              {props.postData && props.postData.post_type == "STORY" ? (
                props.postData.selectedStory !== null ? (
                  <button
                    className={style.active}
                    onClick={() => {
                      setTabType("selectVoices");
                    }}
                    style={{
                      borderColor:
                        tabType == "selectVoices" ? "#38ef7d" : "transparent",
                    }}
                  >
                    <img
                      src="/images/speaking.svg"
                      style={{ filter: "none" }}
                      alt=""
                    />
                    <span>Text to Voice</span>
                  </button>
                ) : (
                  ""
                )
              ) : (
                <button
                  className={style.active}
                  onClick={() => {
                    setTabType("selectVoices");
                  }}
                  style={{
                    borderColor:
                      tabType == "selectVoices" ? "#38ef7d" : "transparent",
                  }}
                >
                  <img
                    src="/images/speaking.svg"
                    style={{ filter: "none" }}
                    alt=""
                  />
                  <span>Text to Voice</span>
                </button>
              )}

              <button
                className={style.active}
                onClick={() => {
                  setTabType("backgroundMusic");
                }}
                style={{
                  borderColor:
                    tabType == "backgroundMusic" ? "#38ef7d" : "transparent",
                }}
              >
                <img
                  src="/images/backgroundMusic.svg"
                  alt=""
                  style={{ width: "29px", height: "22px" }}
                />
                <span>Background Music</span>
              </button>
            </>
          )}
          {props.postData &&
          props.postData.post_type == "STORY" &&
          props.postData.selectedStory !== null ? (
            <>
              <button
                className={style.active}
                onClick={() => {
                  // setTabType("TitleElements");
                  setTabType("TitleElements");
                  props.setShareSidebar({
                    visible: true,
                    type: "storyTemplate",
                  });
                }}
                style={{
                  borderColor:
                    tabType == "TitleElements" ? "#38ef7d" : "transparent",
                }}
              >
                <img
                  style={{ filter: "brightness(1)" }}
                  src="/images/titleElementsIcon.svg"
                  alt=""
                />
                <span>Title Elements</span>
              </button>
            </>
          ) : (
            ""
          )}
          {props.postData &&
          (props.postData.post_type == "REC0RD" ||
            props.postData.sub_type == "REC0RD") ? (
            <>
              <button
                className={style.active}
                onClick={() => {
                  setTabType("audio");
                }}
                style={{
                  borderColor: tabType == "audio" ? "#38ef7d" : "transparent",
                }}
              >
                <img src="/images/uploadAudio.svg" alt="" />
                <span>Upload Audio File</span>
              </button>
            </>
          ) : (
            ""
          )}
          {(props.postData && props.postData.post_type == "INTRO") ||
          props.postData.post_type == "OUTRO" ? (
            <>
              <button
                className={style.active}
                onClick={() => {
                  setTabType("audioTemplate");
                }}
                style={{
                  borderColor:
                    tabType == "audioTemplate" ? "#38ef7d" : "transparent",
                }}
              >
                <img
                  src="/images/djMixer.svg"
                  alt=""
                  style={{ width: "29px", height: "22px" }}
                />
                <span>Audio Templates</span>
              </button>
              <button
                className={style.active}
                onClick={() => {
                  setTabType("reverb");
                }}
                style={{
                  borderColor: tabType == "reverb" ? "#38ef7d" : "transparent",
                }}
              >
                <img
                  src="/images/reverbIcon.svg"
                  alt=""
                  style={{ width: "37px" }}
                />
                <span>Reverb</span>
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div
        className=""
        style={{
          overflow: "auto",
          paddingBottom: "6em",
          paddingTop: "9em",
          paddingLeft: "1em",
          paddingRight: "1em",
        }}
      >
        {tabType == "image" ? (
          <div style={{ paddingTop: "4em" }}>
            <FileDropZone
              image={
                props.postData.selectedStory >= 0 &&
                props.postData.selectedStory !== null
                  ? props.postData.image
                  : props.postData.storyImage
                  ? props.postData.storyImage
                  : ""
              }
              onSelectedFile={async (image) => {
                if (image && image.length > 0)
                  props.setPostData({
                    ...props.postData,
                    image: {
                      file: await toBase64(image[0]),
                      name: image[0].name,
                      origFile: await toBase64(image[0]),
                    },
                    editing: false,
                    clickedStory: false,
                  });
              }}
            />
          </div>
        ) : tabType == "audioTemplate" ? (
          <AudioTemplateSettings />
        ) : tabType == "reverb" ? (
          <ReverbSettings />
        ) : tabType == "selectVoices" ? (
          <VoiceTab />
        ) : tabType == "audio" ? (
          <div style={{ paddingTop: "4em" }}>
            <UploadAudio />
          </div>
        ) : tabType == "backgroundMusic" ? (
          <>
            <BackgroundSettings />
          </>
        ) : tabType == "categories" ? (
          <>
            <div className="d-flex " style={{ paddingTop: "6.5em" }}>
              <h5
                className={`text-center mb-0 mr-2 ${style.roundedBox} ${
                  tab == "Categories" ? style.boxActive : ""
                }`}
                onClick={() => setTab("Categories")}
              >
                Select Post Categories
              </h5>{" "}
              <h5
                className={`text-center mb-0 ${style.roundedBox} ${
                  tab == "Tags" ? style.boxActive : ""
                }`}
                onClick={() => setTab("Tags")}
              >
                Select Post Tags
              </h5>
            </div>
            <div className="w-100 mt-4">
              <Input
                size="large"
                placeholder={`Search Categories`}
                className={`${createStyle.searchInput} w-100`}
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                style={{
                  border: "1px solid #000000",
                  borderRadius: "10px",
                  width: "100%",
                  height: "34px",
                  backgroundColor: "transparent",
                  color: "#000000 ",
                  marginLeft: "0",
                  marginBottom: "0",
                  width: "100%",
                  fontSize: "0.9em",
                  padding: "6px 9px",
                }}
              />
            </div>
            <h5
              className={` text-center mt-4 ${style.roundedBox}`}
              onClick={() => setCreatingCategory(true)}
            >
              <img
                src="/images/plusIcon.svg"
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "10px",
                  filter: "invert(1)",
                }}
                alt=""
              />
              Add New Category
            </h5>
            <div className="d-flex flex-wrap mt-4">
              {creatingCategory ? (
                <Input
                  size="large"
                  placeholder={`Category Name`}
                  className={`${createStyle.searchInput} w-100`}
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" || e.keyCode === 13) {
                      createCategory();
                    }
                  }}
                  style={{
                    borderBottom: "1px solid #000000",
                    borderRadius: "10px",
                    width: "100%",
                    height: "34px",
                    backgroundColor: "transparent",
                    color: "#000000 ",
                    marginLeft: "0",
                    marginBottom: "0",
                    width: "100%",
                    fontSize: "0.9em",
                    padding: "6px 9px",
                  }}
                />
              ) : (
                tempCats.map((item) => (
                  <FeedTag
                    onClick={(id) => {
                      onSelect(id);
                    }}
                    isSelected={selectedCategories.find(
                      (ite) => ite.id == item._id
                    )}
                    data={{ id: item._id, label: item.name }}
                  />
                ))
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(AddPostDetail);
