import Types from "../constants/project";
import apiRequest from "../../api/clientRequest";
import { message } from "antd";
import moment from "moment";
import { db } from "../../firebase";

export const setProjects = () => async (dispatch, getState) => {
  const projects = await apiRequest({
    method: "GET",
    url: "/projects",
  }).then((res) => res.data);

  dispatch({
    type: Types.SET_PROJECTS,
    payload: projects,
  });
};

export const setCreatedAudios = () => async (dispatch, getState) => {
  const createdAudios = await apiRequest({
    method: "GET",
    url: "/projects/createdAudios",
  }).then((res) => res.data);

  dispatch({
    type: Types.SET_CREATED_AUDIOS,
    payload: createdAudios,
  });
};

export const createAudioToProject = (data) => async (dispatch, getState) => {
  const project = await apiRequest({
    method: "POST",
    url: `/projects/addVoiceItem`,
    data: data,
  }).then((res) => res.data);
  await dispatch(setCreatedAudios());
  return;
};

export const createEmbedLink = (data) => async (dispatch, getState) => {
  const project = await apiRequest({
    method: "POST",
    url: `/projects/addEmbed`,
    data: data,
  }).then((res) => res.data);
  return project;
};

export const updatedEmbed = (data) => async (dispatch, getState) => {
  const project = await apiRequest({
    method: "POST",
    url: `/projects/updateEmbed`,
    data: data,
  }).then((res) => res.data);
  return project;
};

export const addNewProject = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.ADD_PROJECTS,
    payload: data,
  });
};

export const setActiveColor = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_ACTIVE_COLOR,
    payload: data,
  });
};

export const setActiveProject = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_ACTIVE_PROJECT,
    payload: data,
  });
};

export const setActivePreview = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_ACTIVE_PREVIEW,
    payload: data,
  });
};

export const editProject = (data) => async (dispatch, getState) => {
  dispatch({
    type: Types.EDIT_PROJECT,
    payload: data,
  });
};

export const addItemsToCampaign = (data) => async (dispatch, getState) => {
  const activeProject = await getState().projects.activeProject;

  const project = await apiRequest({
    method: "POST",
    url: "/campaigns/addedItems",
    data: {
      projectId: activeProject._id,
      type: activeProject.type,
      items: data,
      date: moment().format("MM/DD/YYYY"),
    },
  }).then((res) => res.data);

  dispatch({
    type: Types.EDIT_PROJECT,
    payload: project,
  });
};

export const deleteAnItem = () => async (dispatch, getState) => {
  const activeProject = await getState().projects.activeProject;
  const user = await getState().auth.user;

  const { members } = activeProject;
  if (activeProject.invited) {
    const foundMember = members.find((item) => item.user_id == user._id);
    if (foundMember)
      switch (foundMember.role) {
        case "Member":
          message.error(`Member can not delete a ${activeProject.type}`);
          return;
        case "Editor":
          message.error(`Editor can not delete a ${activeProject.type}`);
          return;
        case "Admin":
          message.error(`Admin can not delete a ${activeProject.type}`);
          return;
      }
  }

  await apiRequest({
    method: "DELETE",
    url: "/projects/",
    data: {
      projectId: activeProject._id,
      type: activeProject.type,
    },
  }).then((res) => res.data);

  dispatch({
    type: Types.DELETE_PROJECT,
    payload: activeProject,
  });
};

export const addItemsToProject = (data) => async (dispatch, getState) => {
  const activeProject = await getState().projects.activeProject;

  const project = await apiRequest({
    method: "POST",
    url: "/projects/recentItems/multiple",
    data: {
      projectId: activeProject._id,
      items: data,
      type: activeProject.type,
      date: moment().format("MM/DD/YYYY"),
    },
  }).then((res) => res.data);
  dispatch({
    type: Types.UPDATE_PROJECT,
    payload: project,
  });
};

export const addItemToProject = (data) => async (dispatch, getState) => {
  const activeProject = data.project
    ? data.project
    : await getState().projects.activeProject;

  const project = await apiRequest({
    method: "POST",
    url: "/projects/recentItems",
    data: {
      projectId: activeProject._id,
      item: data,
      type: activeProject.type,
      date: moment().format("MM/DD/YYYY"),
    },
  }).then((res) => res.data);
  dispatch({
    type: Types.UPDATE_PROJECT,
    payload: project,
  });
};

export const removeItemFromProject =
  (data, isStory = false) =>
  async (dispatch, getState) => {
    if (isStory == "INTERVIEW") {
      db.collection("interview").doc(data).delete();
      message.success("Delete operation successful");
      const activeProject = await getState().projects.activeProject;
      dispatch({
        type: Types.UPDATE_PROJECT,
        payload: { ...activeProject },
      });
    } else {
      const activeProject = await getState().projects.activeProject;
      const project = await apiRequest({
        method: "DELETE",
        url: "/projects/recentItems",
        data: {
          projectId: activeProject._id,
          item: data,
          type: activeProject.type,
          isStory,
        },
      }).then((res) => res.data);
      message.success("Delete operation successful");
      dispatch({
        type: Types.UPDATE_PROJECT,
        payload: project,
      });
    }
  };

export const removeFromAudience = (data) => async (dispatch, getState) => {
  const activeProject = await getState().projects.activeProject;

  const project = await apiRequest({
    method: "DELETE",
    url: `/audience/contacts/${activeProject._id}`,
    data: {
      aud: data,
    },
  }).then((res) => res.data);
  dispatch({
    type: Types.UPDATE_PROJECT,
    payload: project,
  });
};

export const updateProject = (data) => async (dispatch, getState) => {
  const activeProject = await getState().projects.activeProject;

  console.log(data);
  const project = await apiRequest({
    method: "POST",
    url: "/projects/feed",
    data: {
      projectId: activeProject._id,
      feed: data,
      type: data.type,
    },
  }).then((res) => res.data);

  dispatch({
    type: Types.UPDATE_PROJECT,
    payload: project,
  });
};
