import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import { connect } from "react-redux";
import { changeToCategoriesTab, setPostData } from "../../redux/actions/main";
import voices from "./voices.json";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const mapState = (state) => ({
  postData: state.main.postData,
});

const mapDispatch = (dispatch) => ({
  setPostData: (feed) => dispatch(setPostData(feed)),
});

function ReverbSettings(props) {
  const [backgroundMusic, setBackgroundMusic] = React.useState(false);

  return (
    <div className=" px-3 mt-5" style={{ paddingTop: "4em" }}>
      <h4
        className="mb-0"
        style={{
          fontSize: 17,
          fontWeight: "bold",
          position: "absolute",
          top: "230px",
          zIndex: 999999,
        }}
      >
        Reverb
      </h4>
      <div className="pt-4">
        <div
          className={`${style.reverbContainer} d-flex align-items-center p-2`}
        >
          <p>1</p>
          <div
            className=" ml-2 d-flex justify-content-between align-items-center"
            style={{ flex: 1 }}
          >
            <div>
              <span className="d-block">Reverb Effect</span>
              <img
                src="/images/reverbIcon.svg"
                alt=""
                style={{ width: "25px", filter: "invert(1)" }}
              />{" "}
            </div>
            <Switch
              className={`${style.switch} ${
                backgroundMusic ? style.activeSwitch : ""
              }`}
              checkedChildren={<span>On</span>}
              unCheckedChildren={<span>Off</span>}
              checked={backgroundMusic}
              onChange={() => setBackgroundMusic(!backgroundMusic)}
            />
          </div>
        </div>
        {/* <div
          className={`${style.reverbContainer} d-flex align-items-center p-2`}
        >
          <p>2</p>
          <div
            className=" ml-2 d-flex justify-content-between align-items-center"
            style={{ flex: 1 }}
          >
            <div>
              <span className="d-block">Reverb Effect</span>
              <img
                src="/images/reverbIcon.svg"
                alt=""
                style={{ width: "25px", filter: "invert(1)" }}
              />{" "}
            </div>
            <Switch
              className={`${style.switch} ${
                backgroundMusic ? style.activeSwitch : ""
              }`}
              checkedChildren={<span>On</span>}
              unCheckedChildren={<span>Off</span>}
              checked={backgroundMusic}
              onChange={() => setBackgroundMusic(!backgroundMusic)}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(ReverbSettings);
