import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import { connect } from "react-redux";
import { changeToCategoriesTab, setPostData } from "../../redux/actions/main";
import voices from "./voices.json";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const mapState = (state) => ({
  postData: state.main.postData,
});

const mapDispatch = (dispatch) => ({
  setPostData: (feed) => dispatch(setPostData(feed)),
});

function AudioTemplateSettings(props) {
  const [backgroundMusic, setBackgroundMusic] = React.useState(true);

  let arr = [{ id: 1, name: "Dj Radio Station Jingle" }];
  return (
    <div className=" px-3 mt-5" style={{ paddingTop: "4em" }}>
      <h4
        className="mb-0"
        style={{
          fontSize: 17,
          fontWeight: "bold",
          position: "absolute",
          top: "230px",
          zIndex: 999999,
        }}
      >
        Audio Recipes
      </h4>
      <div className="pt-4">
        {arr.map((item, index) => (
          <div
            className={`${style.reverbContainer} d-flex align-items-center p-2`}
          >
            <p>{index + 1}</p>
            <div
              className=" ml-2 d-flex justify-content-between align-items-center"
              style={{ flex: 1 }}
            >
              <div>
                <span className="d-block">{item.name}</span>
                <img
                  src="/images/djMixer.svg"
                  alt=""
                  style={{ width: "15px", filter: "invert(1)" }}
                />{" "}
              </div>
              <Switch
                className={`${style.switch} ${
                  backgroundMusic == `${item.id}` ? style.activeSwitch : ""
                }`}
                checkedChildren={<span>On</span>}
                unCheckedChildren={<span>Off</span>}
                checked={backgroundMusic == `${item.id}`}
                onChange={() => {
                  if (backgroundMusic == `${item.id}`) {
                    setBackgroundMusic(false);
                    props.setPostData({ ...props.postData, template: false });
                  } else {
                    setBackgroundMusic(`${item.id}`);
                    props.setPostData({ ...props.postData, template: true });
                  }
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(AudioTemplateSettings);
