import React, { Component, useState } from "react";
import MusicControlComponent from "../components/MusicControlComponent";
import DashboardNavbar from "../components/Navbar/DashboardNavbar";
import LeftSidebar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import style from "../style/DashboardContainer.module.css";
import apiRequest from "../api/clientRequest";
import NEWSACTIONS from "../redux/actions/news";
import SITEACTIONS from "../redux/actions/site";
import { connect } from "react-redux";
import Environment from "../api/environment";
import socketIOClient from "socket.io-client";
import TodayTab from "../components/Tabs/TodayTab";
import ProjectTab from "../components/Tabs/ProjectTab";
import { Switch, Route } from "react-router";
import { setProjects, setCreatedAudios } from "../redux/actions/project";
import BrandTab from "../components/Tabs/BrandTab";
import CampaignTab from "../components/Tabs/CampaignTab";
import AudienceTab from "../components/Tabs/AudienceTab";
import TeamsTab from "../components/Tabs/TeamsTab";
import TemplatesTab from "../components/Tabs/TemplatesTab";
import CollectionTab from "../components/Tabs/CollectionTab";
import PostTab from "../components/Tabs/PostTab";
import SettingTab from "../components/Tabs/SettingTab";
import CommentaryCard from "../components/CommentaryCard";
import ShareCard from "../components/ShareCard";
import AddItemCard from "../components/AddItemCard";
import player from "../data/player";
import QueueContainer from "../components/QueueContainer";

var mapDispatchToProps = (dispatch) => ({
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setPlaylists: (item) => dispatch(NEWSACTIONS.setPlaylists(item)),
  setPodcast: (item) => dispatch(NEWSACTIONS.setPodcast(item)),
  setSocket: (item) => dispatch(SITEACTIONS.setSocket(item)),
  setSavedItems: (item) => dispatch(SITEACTIONS.setSavedItems(item)),
  setFeedlyFeed: (item) => dispatch(NEWSACTIONS.setFeedlyFeed(item)),
  setSubscribedItems: (items) =>
    dispatch(SITEACTIONS.setSubscribedItems(items)),
  setShorts: (items) => dispatch(SITEACTIONS.setShorts(items)),
  setSharedItems: (items) => dispatch(SITEACTIONS.setSharedItems(items)),
  setLastPlayed: (items) => dispatch(SITEACTIONS.setLastPlayed(items)),
  getTodayItems: () => dispatch(SITEACTIONS.getTodayItems()),
  setQueue: (item) => dispatch(SITEACTIONS.setQueue(item)),
  setRecentUsers: (item) => dispatch(SITEACTIONS.setRecentUsers(item)),
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setReadLater: (item) => dispatch(NEWSACTIONS.setReadLater(item)),
  setBoards: (item) => dispatch(NEWSACTIONS.setBoards(item)),
  setListenLater: (item) => dispatch(NEWSACTIONS.setListenLater(item)),
  setImportantFeed: (item) => dispatch(NEWSACTIONS.setImportantFeed(item)),
  setStations: (item) => dispatch(NEWSACTIONS.setStations(item)),
  setWheather: (item) => dispatch(NEWSACTIONS.setWheather(item)),
  setTodayNews: (item) => dispatch(NEWSACTIONS.setSubscribedNews(item)),
  setVideos: (item) => dispatch(NEWSACTIONS.setYoutubeVideos(item)),
  setDoNotPlay: (item) => dispatch(NEWSACTIONS.setDoNotPlay(item)),
  setProjects: () => dispatch(setProjects()),
  setCreatedAudios: () => dispatch(setCreatedAudios()),
});

var mapStateToProps = (state) => {
  return {
    feedlyUser: state.news.feedlyUser,
    theme: state.site.theme,
    setSubscribedPodcasts: state.news.subscribedPodcasts,
    user: state.auth.user,
    tags: state.news.tags,
    socket: state.site.socket,
    youtubeUser: state.news.youtubeUser,
    activeColor: state.projects.activeColor,
    queue: state.site.queue,
  };
};

class DashboardContainer extends Component {
  state = {
    projectVisible: false,
    pageName: "",
    tab: "",
    queueExpanded: false,
  };

  Player = player();

  

  componentWillReceiveProps(nextProp) {
    if (nextProp.feedlyUser.userId !== this.props.feedlyUser.userId)
      nextProp.socket.emit(
        "subscribeToFeedly",
        nextProp.feedlyUser,
        new Date(),
        this.props.user
      );
  }
  async playAudio(type, media, recent = false, playAction = false) {
    if (type == "news") {
      await this.player.playAudio({ ...media, recent }, playAction);
    } else if (type == "post") {
      await this.player.playAudio(
        {
          ...media,
          recent,
        },
        playAction,
        media.speechUrl
      );
    } else if (type.toLowerCase() == "story") {
      this.player.playStory({ ...media, recent });
    } else if (type == "podcast")
      this.player.playPodcast(
        {
          episode: media,
          title: media.source,
          recent,
        },
        playAction
      );
    else if (type == "music" || type == "spotify")
      this.player.playSpotifyTrack(
        { ...media, recent },
        playAction ? playAction : "spotifyplay"
      );
    else if (type == "video") this.player.playVideo(media);
    else
      this.player.playShorts(
        {
          ...media,
          title: `${media.title} : ${media.source}`,
          recent,
        },
        playAction
      );
  }

  attachSockets(socket) {
    if (
      window.localStorage.getItem("feedly_token") &&
      window.localStorage.getItem("feedly_id")
    ) {
      socket.on("userFeed", (feeds) => {
        console.log(feeds);
        this.props.setFeed({ feed: feeds.articles, tags: feeds.tags });
        this.props.setImportantFeed(feeds.importantArticles);
        this.props.setPlaylists(feeds.playlists);
        this.props.setReadLater(feeds.readLater);
        this.props.setTodayNews(feeds.todayArticles);
      });

      socket.off("newFeed");
    } else {
      socket.on("newFeed", (feeds) => {
        this.props.setFeed({ feed: feeds.articles, tags: feeds.tags });
      });
      socket.emit("subscribeToNews", this.props.tags);
      socket.off("userFeed");
    }

    socket.on("userShorts", (dat) => {
      this.props.setShorts(dat.data);
    });
    socket.on("stationFeed", (data) => {
      this.props.setStations(data.stations);
    });
    socket.emit("subscribeToStation");
    socket.emit("subscribeToShorts");

    socket.on("userPodcasts", (dat) => {
      this.props.setPodcast(dat.data);
      // this.props.setSubscribedItems(dat.subscribedPodcasts);
    });
    socket.emit("subscribeToPodcasts", this.props.user._id);
  }

  async componentWillMount() {
    const socket = socketIOClient(Environment.tts_url, {
      transports: ["websocket"],
      upgrade: false,
      pingTimeout: 60000,
      forceNew: true,
    });

    socket.on("reconnect_attempt", () => {
      socket.io.opts.transports = ["websocket"];
    });

    socket.on("connect_failed", function () {
      document.write("Sorry, there seems to be an issue with the connection!");
    });

    this.attachSockets(socket);
    this.props.setSocket(socket);

    this.props.setProjects();
    this.props.setCreatedAudios();
    this.props.getTodayItems();

    // apiRequest({
    //   url: "/stations/Sports",
    //   method: "POST",
    //   data: { feedlyUser: this.props.feedlyUser },
    // }).then((res) => {
    //   this.props.setStations(res.data);
    // });
  }

  render() {
    const match = this.props.match;
    const { pageName, projectVisible } = this.state;
    const { activeColor } = this.props;
    return (
      <div className={style.dashParent}>
        <DashboardNavbar />
        <CommentaryCard />
        <ShareCard />
        <AddItemCard />

        <div
          className={`${style.DashboardContainer}`}
          style={{
            backgroundColor: "rgb(22 22 22)",
          }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(36, 35, 38, 0) 0%, rgb(20 20 20 / 78%) 41%, rgb(27 27 28) 100%)`,
            }}
            className={style.topGradient}
          ></div>

          <div
            style={{
              backgroundImage: `linear-gradient(rgba(36, 35, 38, 0) 0%, rgb(20 20 20 / 78%) 41%, rgb(27 27 28) 100%)`,
            }}
            className={style.bottomGradient}
          ></div>

          <LeftSidebar
            router={this.props.history}
            // setPageName={(item) => this.setState({ pageName: item })}
            // setprojectVisible={(item) =>
            //   this.setState({ projectVisible: item })
            // }
          />
          <Switch>
            <Route path="/dashboard" exact component={TodayTab} />
            <Route path="/dashboard/audience" component={AudienceTab} />
            <Route path="/dashboard/brand" component={BrandTab} />
            <Route path="/dashboard/project" component={ProjectTab} />
            <Route path="/dashboard/campaign" component={CampaignTab} />
            <Route path="/dashboard/teams" component={TeamsTab} />
            <Route path="/dashboard/template" component={TemplatesTab} />
            <Route path="/dashboard/collection" component={CollectionTab} />
            <Route path="/dashboard/podcast" component={PostTab} />
            <Route path="/dashboard/setting" component={SettingTab} />
          </Switch>

          <RightSidebar pageName={pageName} />
        </div>

        <MusicControlComponent />
        <QueueContainer
          playAudio={this.playAudio.bind(this)}
          queueExpanded={this.state.queueExpanded}
          setQueueExpanded={(val) => this.setState({ queueExpanded: val })}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
