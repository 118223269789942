import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { connect } from "react-redux";
import { editProject } from "../../redux/actions/project";
import apiRequest from "../../api/clientRequest";
import { inputContainer } from "../../style/createproject.module.css";
import { FeedTags } from "../../style/LatestFeed.module.css";
import { Popover, message } from "antd";
import ImageInitial from "../ImageInitial";

const mapState = (state) => ({
  activeProject: state.projects.activeProject
    ? state.projects.activeProject
    : state.projects.activePreview,
  user: state.auth.user,
});

const mapDispatch = (dispatch) => ({
  editProject: (data) => dispatch(editProject(data)),
});

function AddTeam(props) {
  const [email, setEmail] = React.useState("");
  const { members, _id } = props.activeProject;

  const onRemoveMember = (val) => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not remove a user");
            return;
          case "Editor":
            message.error("Editor can not remove a user");
            return;
        }
    }
    apiRequest({
      url: `/teams/member`,
      method: "DELETE",
      data: {
        teamId: _id,
        email: val,
        type: "team",
      },
    }).then((res) => {
      props.editProject(res.data);
      message.success("Member removed successfully.");
      setEmail("");
    });
  };

  const assignRole = (role, member) => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not update permissions");
            return;
          case "Editor":
            message.error("Editor can not update permissions");
            return;
          case "Admin":
            if (member.user_id == user._id) {
              message.error("Admin can assign roles to himself");
              return;
            }
        }
    }

    apiRequest({
      url: `/teams/permission`,
      method: "POST",
      data: {
        teamId: _id,
        selectedUser: member,
        type: "team",
        role,
      },
    }).then((res) => {
      props.editProject(res.data);
      message.success("Member updated successfully.");
    });
  };

  return (
    <div className={style.AddItem}>
      <div className={`${style.topSection} topSection`}>
        <div className={style.actionBtn}>
          <button className={style.active}>
            <img src="/images/sendCampaignIcon.svg" alt="" />
            <span>Invite Members by Email</span>
          </button>
          <button>
            <img src="/images/audienceIcon.svg" alt="" />
            <span>Add Favorite Members</span>
          </button>
        </div>
        <div className={`${inputContainer} m-0 w-100`}>
          <div className="d-flex">
            <div style={{ flex: 1 }} className="mr-4">
              <label className="d-block" htmlFor="">
                Invite members by Email
              </label>
              <div className={style.inputAppend}>
                <input
                  type="text"
                  value={email}
                  // onChange={(e) => onSearch(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`${FeedTags} align-items-end`}
              style={{ width: "80px" }}
            >
              <a
                style={{ margin: 0, width: "100px", borderRadius: "8px" }}
                href="#"
                className={`px-2`}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="d-flex justify-content-between">
                  <img
                    src="/images/addIcon.svg"
                    style={{ width: "10px" }}
                    alt=""
                  />
                  <span>Invite</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className=" px-3"
        style={{ overflow: "auto", paddingBottom: "6em", paddingTop: "21em" }}
      >
        <h4 className="mb-0" style={{ fontSize: 17, fontWeight: "bold" }}>
          Members
        </h4>
        {members.map((item) => (
          <div className={`${style.userItem} d-flex mt-3 align-items-center`}>
            <ImageInitial fullname={item.fullname} image={item.avatar} />

            <div className={style.userDetail}>
              <div>
                <p>{item.fullname}</p>
                <p>{item.role}</p>
              </div>

              <div>
                <img src="/images/conversationIcon.png" />
                <Popover
                  content={
                    <div
                      style={{
                        backgroundColor: "#3b3b3b",
                        color: "#898484",
                        padding: "1.3em",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <p
                        style={{
                          color: "#ddd8d8",
                          fontSize: "1.3em",
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        Assign Roles
                      </p>
                      <p
                        style={{ fontSize: "1.2em", marginBottom: "0" }}
                        onClick={() => assignRole("Member", item)}
                      >
                        Member
                      </p>
                      <p
                        style={{ fontSize: "1.2em", marginBottom: "0" }}
                        onClick={() => assignRole("Editor", item)}
                      >
                        Editor
                      </p>
                      <p
                        style={{ fontSize: "1.2em", marginBottom: "0" }}
                        onClick={() => assignRole("Admin", item)}
                      >
                        Admin
                      </p>
                      <p
                        style={{ fontSize: "1.2em", marginBottom: "0" }}
                        onClick={() => assignRole("Owner", item)}
                      >
                        Owner
                      </p>
                    </div>
                  }
                  placement="top"
                  className="rolePopup"
                >
                  <img src="/images/pencilIcon.svg" className="mx-3" />
                </Popover>{" "}
                <img
                  src="/images/deleteBlackIcon.png"
                  onClick={() => {
                    onRemoveMember(item);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(AddTeam);
