import React from "react";
import style from "./Header.module.css";
import { CaretDownOutlined } from "@ant-design/icons";
import ImageInitial from "../ImageInitial";
import ACTIONS from "../../redux/actions/auth";
import SITEACTIONS from "../../redux/actions/site";
import NEWSACTIONS from "../../redux/actions/news";
import { connect } from "react-redux";
import FeedlyLogin from "../FeedlyLogin";
import { Menu, Dropdown } from "antd";
import GitInfo from "react-git-info/macro";
import { useAuth } from "../auth/auth";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    feedlyUser: state.news.feedlyUser,
    tab: state.site.tab,
    SearchToggled: state.site.SearchToggled,
    radioMode: state.news.radioMode,
    musicList: state.site.musicList,
    subscribedItems: state.site.subscribedItems,
    podcasts: state.news.podcasts,
    recentlyPlayed: state.site.recentlyPlayed,
    shorts: state.site.shorts,
    savedItems: state.site.savedItems,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
  setTab: (item) => dispatch(SITEACTIONS.setTab(item)),
  setTheme: (item) => dispatch(SITEACTIONS.setTheme(item)),
  setSearchToggle: (item) => dispatch(SITEACTIONS.setSearchToggle(item)),
  setThemeSelector: (item) => dispatch(SITEACTIONS.setThemeSelector(item)),
  setRadioMode: (item) => dispatch(NEWSACTIONS.setRadioMode(item)),
});
const gitInfo = GitInfo();
function Header(props) {
  const auth = useAuth();
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={() => props.history.push("/dashboard/profile")} href="">
          Profile
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a
          href=""
          onClick={() => {
            window.localStorage.removeItem("spotify_token");
            window.localStorage.removeItem("spotify_refresh");
            auth.signout();
            props.setUser(null);
            window.location = "/";
          }}
        >
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={style.Header}>
      <nav>
        <a href="#" className={style.logo}>
          <img
            src="/images/AudioOneLogo@2x.png"
            alt=""
            style={{ width: "150px" }}
          />
        </a>
        <div className={style.HeaderNavbar}>
          <div className={style.NavbarLinks}>
            <a href="#">Home</a>
            <a href="#">AudioOne Radio</a>
            <a href="#">Upgrade</a>
            <FeedlyLogin />
          </div>
          <div className={style.userProfile}>
            <a href="#" className="mr-3">
              <img src="/images/SettingsIcon.svg" alt="" />
            </a>

            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link d-flex align-items-center"
                onClick={(e) => e.preventDefault()}
              >
                <div className={style.userProfileDropdown}>
                  <ImageInitial
                    image={props.user.avatar}
                    fullname={props.user.fullname}
                  />

                  <div className={style.userName}>
                    <span className={` mr-3 lead d-block mb-1 `}>
                      {props.user.username}
                    </span>
                    <sub>
                      <span className="ml-2">F{gitInfo.commit.shortHash}</span>
                      <span className="ml-2">B{props.user.version}</span>
                    </sub>
                  </div>
                </div>
                <CaretDownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
