import React, { useState, useEffect } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import FeedTag from "./components/FeedTag";
import { connect } from "react-redux";
import {
  setAddingSource,
  setPostData,
  setPostCategory,
} from "../../redux/actions/main";
import apiRequest from "../../api/clientRequest";
import { Input, AutoComplete, message } from "antd";
import createStyle from "../../style/createproject.module.css";
import axios from "axios";
import environment from "../../api/environment";

const mapState = (state) => ({
  tags: state.news.tags,
  feedlyUser: state.news.feedlyUser,
  activeProject: state.projects.activeProject,
  addingSource: state.main.addSource,
  boards: state.news.boards,
  postData: state.main.postData,
});

const mapDispatch = (dispatch) => ({
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  setPostCategory: (feed) => dispatch(setPostCategory(feed)),
});

function PostCategoriesTab(props) {
  const [tab, setTab] = React.useState("Categories");
  const [searchVal, setSearchVal] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [creatingCategory, setCreatingCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const intilizeData = async () => {
    const cat = await axios
      .get(`${environment.strapi_url}categories`)
      .then((res) => res.data);
    setCategories(cat);
  };

  useEffect(() => {
    intilizeData();

    return () => {
      props.setPostCategory([]);
    };
  }, []);

  const onSelect = (id) => {
    const foundItem = categories.find((item) => item.id == id);
    let cats = [foundItem, ...selectedCategories];
    if (selectedCategories.find((item) => item.name == foundItem.name))
      cats = selectedCategories.filter((item) => item.name !== foundItem.name);
    else cats = [foundItem, ...selectedCategories];

    setSelectedCategories(cats);
    props.setPostCategory(cats);
  };

  const createCategory = async (id) => {
    await axios
      .post(`${environment.strapi_url}categories`, {
        name: categoryName,
        value: categoryName,
      })
      .then((res) => res.data);
    message.success("Category added");
    intilizeData();
    setCreatingCategory(false);
  };

  let tempCats = categories;
  if (searchVal)
    tempCats = tempCats.filter((item) =>
      item.name.toLowerCase().includes(searchVal.toLowerCase())
    );

  return (
    <div className={style.AddItem}>
      <div
        className=""
        style={{
          overflow: "auto",
          paddingBottom: "6em",
          paddingTop: "1em",
          paddingLeft: "1em",
          paddingRight: "1em",
          position: "relative",
          zIndex: "99999999",
        }}
      >
        <div className="d-flex">
          <h5
            className={`text-center mb-0 mr-2 ${style.roundedBox} ${
              tab == "Categories" ? style.boxActive : ""
            }`}
            onClick={() => setTab("Categories")}
          >
            Post Categories
          </h5>
          <h5
            className={`text-center mb-0 ${style.roundedBox} ${
              tab == "Tags" ? style.boxActive : ""
            }`}
            onClick={() => setTab("Tags")}
          >
            Select Post Tags
          </h5>
        </div>
        <div className="w-100 mt-4">
          <Input
            size="large"
            placeholder={`Search Categories`}
            className={`${createStyle.searchInput} w-100`}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            style={{
              border: "1px solid #000000",
              borderRadius: "10px",
              width: "100%",
              height: "34px",
              backgroundColor: "transparent",
              color: "#000000 ",
              marginLeft: "0",
              marginBottom: "0",
              width: "100%",
              fontSize: "0.9em",
              padding: "6px 9px",
            }}
          />
        </div>
        <h5
          className={` text-center mt-4 ${style.roundedBox}`}
          onClick={() => setCreatingCategory(true)}
        >
          <img
            src="/images/plusIcon.svg"
            style={{ width: "15px", height: "15px", marginRight: "10px" }}
            alt=""
          />
          Add New Category
        </h5>
        <div className="d-flex flex-wrap mt-4">
          {creatingCategory ? (
            <Input
              size="large"
              placeholder={`Category Name`}
              className={`${createStyle.searchInput} w-100`}
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  createCategory();
                }
              }}
              style={{
                borderBottom: "1px solid #000000",
                borderRadius: "10px",
                width: "100%",
                height: "34px",
                backgroundColor: "transparent",
                color: "#000000 ",
                marginLeft: "0",
                marginBottom: "0",
                width: "100%",
                fontSize: "0.9em",
                padding: "6px 9px",
              }}
            />
          ) : (
            tempCats.map((item) => (
              <FeedTag
                onClick={(id) => {
                  onSelect(id);
                }}
                isSelected={selectedCategories.find(
                  (ite) => ite.id == item._id
                )}
                data={{ id: item._id, label: item.name }}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(PostCategoriesTab);
