import React, { useState } from "react";
import style from "../style/PlayCard.module.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import player from "../data/player";
import moment from "moment";
import { connect } from "react-redux";
import {
  addItemToProject,
  addItemsToCampaign,
  removeItemFromProject,
} from "../redux/actions/project";
import { FeedTags, active } from "../style/LatestFeed.module.css";
import { Popover, message } from "antd";
import LinkModal from "./LinkModal";
import Actions from "../redux/actions/site";
import { setAddItem } from "../redux/actions/main";
import PlayHover from "./PlayHover";

const dispatchState = (dispatch) => ({
  addItemToProject: (data) => dispatch(addItemToProject(data)),
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  removeItemFromProject: (item) => dispatch(removeItemFromProject(item)),
  setCommentary: (item) => dispatch(Actions.setCommentary(item)),
  setShare: (item) => dispatch(Actions.setShare(item)),
  setAddItem: (item) => dispatch(setAddItem(item)),
});

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  activeColor: state.projects.activeColor,
  addSource: state.main.addSource,

  projectItems:
    state.projects.activeProject &&
    state.projects.activeProject.type !== "campaign" &&
    state.projects.activeProject.podcastfeed
      ? state.projects.activeProject.podcastfeed.map(
          (item) => JSON.parse(item.podcast).feed
        )
      : [],
});

const Player = player();
function PodcastCard({
  data,
  addItemToProject,
  activeColor,
  projectView,
  projectItems,
  onClick,
  activeProject,
  addItemsToCampaign,
  addSource,
  campaignView,
  removeItemFromProject,
  related,
  setCommentary,
  setShare,
  setAddItem,
}) {
  const [visible, setVisible] = useState(false);
  const [showPlayHover, setShowPlayHover] = useState(false);

  const {
    title,
    description,
    category,
    id,
    source,
    type,
    image,
    link,
    published,
    episode,
    show,
    draft,
  } = data;
  return (
    <div
      className={style.PlayCard}
      onMouseLeave={() => setShowPlayHover(false)}
    >
      <LinkModal
        loadedArticle={data}
        visible={visible}
        setVisibility={setVisible}
        related={related}
      />
      <PlayHover
        showPlayHover={showPlayHover}
        article={data}
        episode={episode}
      />
      <div className={style.backImage}>
        <img src={image} alt="" className="w-100 h-100" />
        <div
          className={style.imageGradient}
          style={{
            background: `linear-gradient(87deg, rgba(36, 35, 38, 0) 0%, rgba(36, 35, 38, 0.88) 55%, #242326 100%)`,
          }}
        ></div>
      </div>
      <div className={`row h-100 ${style.cardContent}`}>
        <div className="col-md-6">
          <img
            src="/images/playIcon.svg"
            alt=""
            style={{ fontSize: "2em", cursor: "pointer", marginRight: "1em" }}
            onClick={() => {
              setShowPlayHover(true);
            }}
            className="mb-2"
          />
          <div className="d-flex flex-column justify-content-between">
            <div className="mb-1">
              <h6>{show ? "Source" : "Show"}</h6>
              <p style={{ height: "35px", lineHeight: "11px" }}>
                {source.substr("0", "40")}
              </p>
            </div>
            <div className="mb-2">
              <h6>Category</h6>
              <p>{category ? category.substr(0, 15) : ""}</p>
            </div>
            <div>
              <h6>Type</h6>
              <div className="d-flex align-items-center">
                {/* <img src="/images/Radio.png" className="mr-2" alt="" /> */}
                <p className="mr-2">{type}</p>
                <p style={{ fontSize: "10px" }}>
                  {moment(published).fromNow().replace("minutes", "mins")}{" "}
                  {draft ? "DRAFT" : "PUBLISHED"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <h5>
            {title.substr(0, 100)}
            {title.length > 100 ? "..." : ""}
          </h5>
          <div className={style.ActionButton}>
            {" "}
            {projectView && addSource.includes("Podcast") ? (
              <>
                <div className={FeedTags}>
                  <a
                    style={{ margin: 0, width: "100px" }}
                    href="#"
                    className={`${
                      projectItems.find((item) => item.id == id) ? active : ""
                    } px-2`}
                    onClick={() => onClick(data)}
                  >
                    <div className="d-flex justify-content-between">
                      {projectItems.find((item) => item.id == id) ? (
                        <img
                          src="/images/minusIcon.png"
                          style={{
                            width: "4px",
                            transform: "rotate(90deg)",
                            height: "14px",
                            marginTop: "0.2em",
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src="/images/addIcon.svg"
                          alt=""
                          onClick={() => setAddItem({ ...data, visible: true })}
                        />
                      )}
                      <span>
                        {projectItems.find((item) => item.id == id)
                          ? "Subscribed"
                          : "Subscribe"}
                      </span>
                    </div>
                  </a>
                </div>
              </>
            ) : (
              <>
                <img
                  src="/images/addIcon.svg"
                  alt=""
                  onClick={() => setAddItem({ ...data, visible: true })}
                />
                <img
                  src="/images/shareIcon.svg"
                  onClick={() => setShare({ ...data, visible: true })}
                  alt=""
                />
                {/* <img src="/images/heartlike.svg" alt="" /> */}
                {campaignView ? (
                  <img
                    src="/images/delete.svg"
                    onClick={() => {
                      removeItemFromProject(data.id);
                    }}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(PodcastCard);
