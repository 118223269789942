import React, { useState, useEffect, useRef } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import {
  updateProject,
  editProject,
  setActiveProject,
  addItemToProject,
} from "../../redux/actions/project";
import _ from "lodash";
import createStyle from "../../style/createproject.module.css";
import MemberContainer from "../Teams/MembersContainer";
import MemberSearch from "../MemberSearch";
import apiRequest from "../../api/clientRequest";
import { setAddingSource } from "../../redux/actions/main";
import { useHistory } from "react-router-dom";
import FeedContainer from "../FeedContainer";
import TopItemsContainer from "../TopItemsContainer";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  user: state.auth.user,
  addSource: state.main.addSource,
  feedlyUser: state.auth.feedlyUser,
  brands: state.projects.projects.filter((item) => item.type == "brand"),
  projects: state.projects.projects.filter((item) => item.type == "project"),
  audiences: state.projects.projects.filter((item) => item.type == "audience"),
  campaigns: state.projects.projects.filter((item) => item.type == "campaign"),
  teams: state.projects.projects.filter((item) => item.type == "team"),
  collections: state.projects.projects.filter(
    (item) => item.type == "collection"
  ),
  templates: state.projects.projects.filter((item) => item.type == "template"),
  selectedFeed: state.news.selectedFeed,
});

const dispatchState = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  editProject: (data) => dispatch(editProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  addItemToProject: (data) => dispatch(addItemToProject(data)),
});

function CollectionDetailTab(props) {
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);

  const [mainTab, setMainTab] = useState("Added Items");
  const [items, setItems] = useState([]);
  const tabMains = ["Added Items"];
  const { addingContent, feedProjects, feeds, addSource, selectedFeed } = props;

  if (props.addSource !== "News")
    if (props.addSource == "Members" || props.addSource == "Teams")
      tabMains.push("Members");
    else {
      tabMains.push(props.addSource);
    }

  useEffect(() => {
    if (props.addSource !== "News") {
      if (
        props.addSource &&
        (props.addSource == "Members" || props.addSource == "Teams")
      ) {
        setMainTab("Members");
      } else {
        setMainTab(props.addSource);
      }
    } else {
      setMainTab("Added Items");
    }
  }, [props.addSource]);

  const onClickCard = (data) => {
    props.setActiveProject(data);

    if (data.type == "brand") history.push("/dashboard/brand");
    else if (data.type == "project") history.push("/dashboard/project");
    else if (data.type == "campaign") history.push("/dashboard/campaign");
    else if (data.type == "template") history.push("/dashboard/template");
    else if (data.type == "team") history.push("/dashboard/teams");
    else if (data.type == "collection") history.push("/dashboard/collection");
  };

  const onSearchChange = async (val) => {
    var results = [];

    if (addSource == "Add Projects")
      results = props.projects.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );
    else if (addSource == "Add Brands")
      results = props.brands.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );
    else if (addSource == "Add Collections")
      results = props.collections.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );
    else if (addSource == "Add Campaigns")
      results = props.campaigns.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );
    else if (addSource == "Add Audience")
      results = props.audiences.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );
    else if (addSource == "Add Templates")
      results = props.templates.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );
    else if (addSource == "Add Teams")
      results = props.teams.filter((item) =>
        item.title.toLowerCase().includes(val.toLowerCase())
      );

    setSearchResults(results);
  };

  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <div className="d-flex align-items-center">
          {tabMains.map((item) => (
            <h5
              className={`${
                mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
              } text-center mb-0 `}
              style={{
                cursor: "pointer",
                textTransform: "uppercase",
                minWidth: "150px",
                padding: "6px 11px",
                borderRadius: "10px",
                fontSize: "0.95em",
              }}
              onClick={() => setMainTab(item)}
            >
              {item}
            </h5>
          ))}

          <MemberSearch
            setItems={setItems}
            addingContent={addingContent}
            setQuery={(val) => onSearchChange(val)}
            collection={true}
            onClickFeed={(feed = false) => {
              setMainTab("CONTACTS");
            }}
          />
        </div>
      </div>
      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {mainTab == "Members" ? (
          <MemberContainer editing={true} searchResults={items} />
        ) : mainTab == "Add Items" ? (
          <div className="d-block">
            <FeedContainer
              news={selectedFeed ? selectedFeed.feed : []}
              tab={selectedFeed ? selectedFeed.label : "Now"}
              feedlyUser={props.feedlyUser}
              projectView={true}
              container={"projectDetail"}
              onClick={() => {}}
            />
          </div>
        ) : mainTab == props.addSource &&
          props.addSource !== "Teams" &&
          props.addSource !== "Members" ? (
          <div className="d-block w-100">
            <TopItemsContainer
              tab={props.addSource}
              data={searchResults}
              onSelect={onClickCard}
              onAdd={(data) => {
                props.addItemToProject({
                  ...data,
                  subtype: "object",
                });
              }}
            />
          </div>
        ) : (
          <>
            <div className="d-block w-100">
              <TopItemsContainer
                tab={"Recently Added"}
                data={feedProjects}
                onSelect={onClickCard}
                onAdd={"temp"}
              />
            </div>
            <div className="d-block">
              <FeedContainer
                news={feeds}
                tab={"Now"}
                feedlyUser={props.feedlyUser}
                projectView={true}
                container={"projectDetail"}
                onClick={() => {}}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(CollectionDetailTab);
