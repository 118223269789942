import React, { useEffect, useState } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { connect } from "react-redux";
import { editProject } from "../../redux/actions/project";
import apiRequest from "../../api/clientRequest";
import { Popover, message } from "antd";
import ImageInitial from "../ImageInitial";
import { setAddingSource } from "../../redux/actions/main";
import Actions from "../../redux/actions/news";

const mapState = (state) => ({
  activeProject: state.projects.activeProject
    ? state.projects.activeProject
    : state.projects.activePreview,
  teams: state.projects.projects.filter((item) => item.type == "team"),
  user: state.auth.user,
  addSource: state.main.addSource,
});

const mapDispatch = (dispatch) => ({
  editProject: (data) => dispatch(editProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
});

function ShareTab(props) {
  if (props.activeProject) var { _id } = props.activeProject;

  const onRemoveMember = (val) => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not remove a user");
            return;
          case "Editor":
            message.error("Editor can not remove a user");
            return;
        }
    }
    apiRequest({
      url: `/teams/member`,
      method: "DELETE",
      data: {
        teamId: _id,
        type: props.activeProject.type,
        email: val,
      },
    }).then((res) => {
      props.editProject(res.data);
      message.success("Member removed successfully.");
    });
  };

  useEffect(() => {
    props.setAddingSource("Members");
    props.setSelectedFeed(null);
  }, []);

  const assignRole = (role, member) => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not update permissions");
            return;
          case "Editor":
            message.error("Editor can not update permissions");
            return;
          case "Admin":
            if (member.user_id == user._id) {
              message.error("Admin can assign roles to himself");
              return;
            }
        }
    }
    apiRequest({
      url: `/teams/permission`,
      method: "POST",
      data: {
        teamId: _id,
        selectedUser: member,
        type: props.activeProject.type,
        role,
      },
    }).then((res) => {
      props.editProject(res.data);
      message.success("Member updated successfully.");
    });
  };

  const { addSource } = props;

  return (
    <div className={style.AddItem}>
      <div className={`${style.topSection} topSection`}>
        <div className={style.actionBtn}>
          <button
            className={addSource == "Members" ? style.active : ""}
            onClick={() => {
              props.setAddingSource("Members");
            }}
          >
            <img src="/images/audienceIcon.svg" alt="" />
            <span>Individual Members</span>
          </button>
          <button
            className={addSource == "Teams" ? style.active : ""}
            onClick={() => {
              props.setAddingSource("Teams");
            }}
          >
            <img src="/images/teamsIcon.svg" alt="" />
            <span>Team Members</span>
          </button>
          <button>
            <img src="/images/sendCampaignIcon.svg" alt="" />
            <span>Invite by Email</span>
          </button>
        </div>
      </div>

      <div
        className=" px-3"
        style={{ overflow: "auto", paddingBottom: "6em", paddingTop: "21em" }}
      >
        <h4 className="mb-0" style={{ fontSize: 17, fontWeight: "bold" }}>
          Members
        </h4>
        {props.activeProject
          ? props.activeProject.members
            ? props.activeProject.members.map((item) => (
                <div
                  className={`${style.userItem} d-flex mt-3 align-items-center`}
                >
                  <ImageInitial fullname={item.fullname} image={item.avatar} />

                  <div className={style.userDetail}>
                    <div>
                      <p>{item.fullname}</p>
                      <p>{item.role}</p>
                    </div>

                    <div>
                      <img src="/images/conversationIcon.png" />
                      <Popover
                        content={
                          <div
                            style={{
                              backgroundColor: "#3b3b3b",
                              color: "#898484",
                              padding: "1.3em",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <p
                              style={{
                                color: "#ddd8d8",
                                fontSize: "1.3em",
                                textAlign: "center",
                                marginBottom: "10px",
                              }}
                            >
                              Assign Roles
                            </p>
                            <p
                              style={{ fontSize: "1.2em", marginBottom: "0" }}
                              onClick={() => assignRole("Member", item)}
                            >
                              Member
                            </p>
                            <p
                              style={{ fontSize: "1.2em", marginBottom: "0" }}
                              onClick={() => assignRole("Editor", item)}
                            >
                              Editor
                            </p>
                            <p
                              style={{ fontSize: "1.2em", marginBottom: "0" }}
                              onClick={() => assignRole("Admin", item)}
                            >
                              Admin
                            </p>
                            <p
                              style={{ fontSize: "1.2em", marginBottom: "0" }}
                              onClick={() => assignRole("Owner", item)}
                            >
                              Owner
                            </p>
                          </div>
                        }
                        placement="top"
                        className="rolePopup"
                      >
                        <img src="/images/pencilIcon.svg" className="mx-3" />
                      </Popover>{" "}
                      <img
                        src="/images/deleteBlackIcon.png"
                        onClick={() => {
                          onRemoveMember(item);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            : ""
          : ""}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(ShareTab);
