import Types from "../constants/main";
var defaultState = {
  recents: [],
  addSource: "News Categories",
  tabChange: "",
  memberSearch: null,
  individualSearch: false,
  collectionAddItem: null,
  postData: { title: "", categories: [] },
  selectedPostCategory: [],
  selectedAudience: null,
  recordMode: false,
  meterTitle: "",
  meterProgress: "",
  meter: false,
  shareSidebar: {
    visible: false,
    voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
  },
  addItem: {
    visible: false,
  },
  audienceCard: {
    visible: false,
  },

  activeTab: "",
  interviewAction: "",
  interviewOptions: "",
  selectedTag: null,
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_SELECTED_TAG: {
      let newState = Object.assign({}, state);
      newState.selectedTag = action.payload;

      return newState;
    }

    case Types.SET_INTERVIEW_OPTIONS: {
      let newState = Object.assign({}, state);
      newState.interviewOptions = action.payload;

      return newState;
    }

    case Types.ADD_INTERVIEW_MEMBER: {
      let newState = Object.assign({}, state);
      let member = action.payload;
      if (newState.postData.members)
        if (newState.postData.members.find((item) => item._id == member._id))
          newState.postData.members = [
            ...newState.postData.members.filter(
              (item) => item._id !== member._id
            ),
          ];
        else newState.postData.members = [...newState.postData.members, member];
      else newState.postData.members = [member];

      return newState;
    }

    case Types.SET_INTERVIEW_ACTIONS: {
      let newState = Object.assign({}, state);
      newState.interviewAction = action.payload;

      return newState;
    }

    case Types.SET_ACTIVE_TAB: {
      let newState = Object.assign({}, state);
      newState.activeTab = action.payload;

      return newState;
    }
    case Types.SET_AUDIENCE_CARD: {
      let newState = Object.assign({}, state);
      newState.audienceCard = action.payload;

      return newState;
    }
    case Types.SET_ADD_ITEM: {
      let newState = Object.assign({}, state);
      newState.addItem = action.payload;

      return newState;
    }
    case Types.SET_SHARE_SIDEBAR: {
      let newState = Object.assign({}, state);
      newState.shareSidebar = action.payload;
      if (!newState.shareSidebar.voice)
        newState.shareSidebar.voice = {
          fullname: "Joanna",
          avatar: "/images/joanna1.png",
        };

      return newState;
    }

    case Types.SET_METER_PROGRESS: {
      let newState = Object.assign({}, state);
      newState.meterProgress = action.payload;
      return newState;
    }

    case Types.SET_METER_TITLE: {
      let newState = Object.assign({}, state);
      newState.meterTitle = action.payload;
      return newState;
    }

    case Types.SET_METER: {
      let newState = Object.assign({}, state);
      newState.meter = action.payload;
      return newState;
    }

    case Types.SET_RECORD: {
      let newState = Object.assign({}, state);
      newState.recordMode = action.payload;
      return newState;
    }

    case Types.SET_SELECTED_AUDIENCE: {
      let newState = Object.assign({}, state);
      newState.selectedAudience = action.payload;
      return newState;
    }

    case Types.SET_POST_CATEGORY: {
      let newState = Object.assign({}, state);
      newState.selectedPostCategory = action.payload;
      return newState;
    }

    case Types.SET_POST_DATA: {
      let newState = Object.assign({}, state);
      console.log(action.payload);
      newState.postData = JSON.parse(JSON.stringify(action.payload));
      return newState;
    }

    case Types.SET_COLLECTION_ADD_ITEM: {
      let newState = Object.assign({}, state);
      newState.collectionAddItem = action.payload;
      return newState;
    }

    case Types.SET_MEMBER_SEARCH: {
      let newState = Object.assign({}, state);
      newState.memberSearch = action.payload;
      return newState;
    }

    case Types.SET_INDIVIDUAL_SEARCH: {
      let newState = Object.assign({}, state);
      newState.individualSearch = action.payload;
      return newState;
    }

    case Types.SET_RECENT: {
      let newState = Object.assign({}, state);
      newState.recents = action.payload;
      return newState;
    }

    case Types.CHANGE_TAB: {
      let newState = Object.assign({}, state);
      newState.tabChange = action.payload;
      return newState;
    }
    case Types.ADD_RECENT: {
      let newState = Object.assign({}, state);
      newState.recents = [action.payload, ...newState.recents];
      return newState;
    }
    case Types.ADD_SOURCE: {
      let newState = Object.assign({}, state);
      newState.addSource = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
