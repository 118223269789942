import React, { useState, useEffect } from "react";
import style from "../../style/Allproject.module.css";
import ProjectCard from "../project/ProjectCard";
import AudienceDetail from "./AudienceDetail";
import CreateAudience from "./CreateAudience";
import { connect } from "react-redux";
import {
  setActiveProject,
  setActivePreview,
} from "../../redux/actions/project";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
});

const mapDispatch = (dispatch) => ({
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  setActivePreview: (data) => dispatch(setActivePreview(data)),
});

function AllProject(props) {
  const [creatingProject, setCreatingProjects] = useState(false);
  const [tab, setTab] = useState("All");

  useEffect(() => {
    if (props.activeProject) setTab("Audience");
    return () => {};
  }, [props.activeProject]);

  return (
    <div>
      <div className={style.ProjectLabel}>
        <span
          onClick={() => {
            props.setActiveProject(null);
            setCreatingProjects(false);
            setTab("All");
          }}
          className={tab == "All" ? style.active : ""}
        >
          ALL
        </span>
        <span className={tab == "Audience" ? style.active : ""}>
          {props.activeProject ? "AUDIENCE" : "AUDIENCES"}
        </span>
      </div>
      <div
        style={{
          flexWrap: "wrap",
          paddingTop: "10.5em",
          marginTop: 0,
          height: props.activeProject ? "100vh" : "auto",
        }}
        className={`${style.ProjectCards} Projects`}
        id="projectDetail"
      >
        {!props.activeProject || creatingProject ? (
          <CreateAudience
            createProject={creatingProject}
            setCreatingProjects={(cond) => {
              setCreatingProjects(cond);
              if (cond) {
                setTab("Audience");
              } else {
                setTab("All");
              }
            }}
          />
        ) : (
          ""
        )}
        {props.activeProject && !creatingProject ? (
          <AudienceDetail
            project={props.activeProject}
            onEdit={() => setCreatingProjects(true)}
          />
        ) : creatingProject ? (
          ""
        ) : (
          props.projects.map((item) => (
            <ProjectCard
              selectable={true}
              onSelect={() => props.setActiveProject(item)}
              onPreview={() => props.setActivePreview(item)}
              data={{ ...item, feeds: item.contacts }}
              showEdit={false}
            />
          ))
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default connect(mapState, mapDispatch)(AllProject);
