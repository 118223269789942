export default {
  SET_PROJECTS: "sets projects",
  ADD_PROJECTS: "adds a project",
  SET_ACTIVE_COLOR: "sets the active color",
  SET_ACTIVE_PROJECT: "sets a project as active",
  UPDATE_PROJECT: "updates a project",
  DELETE_PROJECT: "deletes a project",
  EDIT_PROJECT: "edits a project",
  SET_ACTIVE_PREVIEW: "sets an active preview",
  SET_CREATED_AUDIOS: "SET_CREATED_AUDIOS",
};
