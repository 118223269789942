import axios from "axios";
import environment from "./environment";
import store from "../redux/store";

const LogRequest = (options) => {
  const user = store.getState().auth.user;

  if (environment.logEnabled) {
    const onSuccess = (response) => {
      // log each request response
      return response;
    };
    const onError = function (error) {
      if (error.response)
        // some error happened with the server side
        console.log(error.response);
      // some error happened while processing the request
      else console.error("Error Message:", error.message);
      return Promise.reject(error.response || error.message);
    };
    return axios({
      url: "https://teamsbackenddev.audioone.cloud/api/v1/user-logs",
      method: "POST",
      data: {
        ...options,
        version: environment.version,
        app: environment.appType,
        fullName: user.fullname,
        userName: user.username,
      },
    })
      .then(onSuccess)
      .catch(onError);
  } else return true;
};
export default LogRequest;
