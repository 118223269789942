import React, { useState } from "react";
import style from "../style/PlayCard.module.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import player from "../data/player";
import moment from "moment";
import { connect } from "react-redux";
import {
  addItemToProject,
  addItemsToCampaign,
  removeItemFromProject,
} from "../redux/actions/project";
import { setAddItem } from "../redux/actions/main";
import Actions from "../redux/actions/site";
import { Popover, message } from "antd";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import LinkModal from "./LinkModal";
import PlayHover from "./PlayHover";
var fileDownload = require("js-file-download");

const dispatchState = (dispatch) => ({
  addItemProject: (data) => dispatch(addItemToProject(data)),
  addItemsCampaign: (data) => dispatch(addItemsToCampaign(data)),
  removeItemFromProject: (item) => dispatch(removeItemFromProject(item)),
  setCommentary: (item) => dispatch(Actions.setCommentary(item)),
  setShare: (item) => dispatch(Actions.setShare(item)),
  setAddItem: (item) => dispatch(setAddItem(item)),
});

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  createdAudio: state.projects.createdAudio,
});

const Player = player();
function PlayCard({
  data,
  addItemsCampaign,
  activeProject,
  addItemProject,
  campaignView,
  removeItemFromProject,
  related,
  setCommentary,
  setShare,
  setAddItem,
  createdAudio,
}) {
  const [visible, setVisible] = useState(false);
  const [showPlayHover, setShowPlayHover] = useState(false);

  const { title, description, category, source, type, image, link, published } =
    data;

  const existAudio = createdAudio.find((item) => item.item_id == data.id);
  return (
    <div
      className={style.PlayCard}
      style={{ backgroundImage: `url(${data.image})` }}
      onMouseLeave={() => setShowPlayHover(false)}
    >
      <LinkModal
        loadedArticle={data}
        related={related}
        visible={visible}
        setVisibility={setVisible}
      />
      <PlayHover
        showPlayHover={showPlayHover}
        article={data}
        existAudio={existAudio}
      />
      <div className="d-flex align-items-start justify-content-between">
        <img
          src="/images/playIcon.svg"
          alt=""
          style={{ fontSize: "2em", cursor: "pointer", marginRight: "1em" }}
          onClick={() => {
            setShowPlayHover(true);
          }}
        />
        <h5>
          {title ? title.substr(0, 100) : ""}
          {title.length > 100 ? "..." : ""}
        </h5>
      </div>
      <div className="row m-0 w-100">
        <div className="col-md-6 pl-0">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: " 72px" }}
          >
            <div>
              <h6>Source</h6>
              <p>{source ? source.substr("0", "20") : ""}</p>
            </div>
            <div>
              <h6>Type</h6>
              <div className="d-flex align-items-center">
                <img
                  src="/images/Feedly_Logo_gray.svg"
                  className="mr-2"
                  alt=""
                />
                <p className="mr-2">{type}</p>
                <p style={{ fontSize: "10px" }}>
                  {moment(published).fromNow().replace("minutes", "mins")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 pr-0 pl-0">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: " 72px" }}
          >
            <div>
              <h6>Category</h6>
              <p>{category}</p>
            </div>

            <div className={style.ActionButton}>
              {existAudio ? (
                <a download href={existAudio.speechUrl} target="_blank">
                  <img
                    src="/images/downloadIcon.svg"
                    alt=""
                    style={{
                      height: "20px",
                    }}
                    className="mx-2"
                    onClick={() => {
                      console.log(existAudio);
                      // fileDownload(existAudio.speechUrl, `${data.title}.mp3`);
                    }}
                  />
                </a>
              ) : (
                ""
              )}

              <img
                src="/images/addIcon.svg"
                alt=""
                onClick={() => setAddItem({ ...data, visible: true })}
              />

              <img
                src="/images/shareIcon.svg"
                onClick={() => setShare({ ...data, visible: true })}
                alt=""
              />
              {/* <img src="/images/heartlike.svg" alt="" /> */}
              {campaignView ? (
                <img
                  src="/images/delete.svg"
                  onClick={() => {
                    removeItemFromProject(data.id);
                  }}
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(PlayCard);
