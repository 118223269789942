import React, { useEffect } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import FeedTag from "../sidebarTabs/components/FeedTag";
import { connect } from "react-redux";
import Actions from "../../redux/actions/news";
import {
  setAddingSource,
  changeToCategoriesTab,
} from "../../redux/actions/main";
import apiRequest from "../../api/clientRequest";
import { FeedTags, active } from "../../style/LatestFeed.module.css";
import ColumnGroup from "antd/lib/table/ColumnGroup";

const mapState = (state) => ({
  tags: state.news.tags,
  feedlyUser: state.news.feedlyUser,
  activeProject: state.projects.activeProject,
});

const mapDispatch = (dispatch) => ({
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
});

function SourcesContainer(props) {
  const [tab, setTab] = React.useState("News");
  const [tabFilter, setTabFilter] = React.useState("");

  const tabNames = ["News", "Podcast", "Shorts", "Video"];

  const filters = {
    News: {
      tab: 1,
      heading: "HOW DO YOU WANT TO SEARCH FOR NEWS",
      filters: [
        {
          type: "newsCategory",
          icon: "Radio",
          heading: "News Categories",
        },
        {
          type: "newsBoards",
          icon: "Radio",
          heading: "News Boards",
        },
      ],
    },
    Podcast: {
      tab: 2,
      heading: "HOW DO YOU WANT TO SEARCH FOR PODCASTS",
      filters: [
        {
          type: "podcastCategory",
          icon: "Radio",
          heading: "Podcast Categories",
        },
        {
          type: "podcastShow",
          icon: "Radio",
          heading: "Podcast Shows",
        },
        {
          type: "podcastEpisodes",
          icon: "Radio",
          heading: "Podcast Episodes",
        },
        {
          type: "spotifyPodcast",
          icon: "spotWhite",
          heading: "Spotify Podcast Shows",
        },
      ],
    },
    Shorts: {
      tab: 1,
      heading: "HOW DO YOU WANT TO SEARCH FOR NEWS",
      filters: [
        {
          type: "shortsCategories",
          icon: "Radio",
          heading: "Shorts Categories",
        },
      ],
    },
  };

  useEffect(() => {
    props.setActiveSource("News Categories");
    setTabFilter("newsCategory");
    return () => {};
  }, []);

  return (
    <div className={style.AddItem}>
      <div className="d-flex px-3">
        {filters[tab].filters.map((item) => (
          <div
            className={`${style.podcastBox} ${
              item.type == tabFilter ? style.boxActive : ""
            }`}
            onClick={() => {
              if (
                item.type == "podcastEpisodes" ||
                item.type == "spotifyPodcast"
              )
                return;
              setTabFilter(item.type);
              props.setActiveSource(item.heading);
            }}
          >
            {item.heading}
          </div>
        ))}
      </div>

      <div className={`${FeedTags} ${FeedTags} px-3 mt-4`}>
        {tabNames.map((item) => (
          <a
            href="#"
            className={tab == item ? active : ""}
            onClick={() => {
              console.log(item);
              if (item == "Video") return;
              setTab(item);
              setTabFilter(filters[item].filters[0].type);
              props.setActiveSource(filters[item].filters[0].heading);
              // props.setActiveSource(item);

              if (item == "News") props.setAddingSource("News Categories");
              else if (item == "Podcast")
                props.setAddingSource("Podcast Categories");
              else if (item == "Shorts")
                props.setAddingSource("Shorts Categories");
              else if (item == "Video") props.setAddingSource("Video");

              props.setSelectedFeed(null);
            }}
          >
            {item}
          </a>
        ))}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(SourcesContainer);
