import React from "react";
import styles from "../../style/commentaryCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import style from "../../style/sidebarTabsContent.module.css";
import { tag } from "../sidebarTabs/components/feedtag.module.css";
import { setAudienceCard, setShareSidebar } from "../../redux/actions/main";
import { editProject } from "../../redux/actions/project";
import sidebarstyle from "../../style/sidebarTabsContent.module.css";
import { Spin } from "antd";
import apiRequest from "../../api/clientRequest";
import { message } from "antd";

export default function AddAudienceCard() {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmailAddress] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const { audienceCard } = useSelector((state) => state.main);
  const { activeProject } = useSelector((state) => state.projects);
  const { shareSidebar } = useSelector((state) => state.main);
  const { queue } = useSelector((state) => state.site);

  const dispatch = useDispatch();

  const onPerformOperation = async () => {
    if (email && firstName && lastName) {
      setLoading(true);
      await apiRequest({
        method: "POST",
        url: `/audience/contacts/single/${activeProject._id}`,
        data: { email, firstName, lastName },
      })
        .then((res) => {
          dispatch(editProject(res.data));
          message.success("Contact Added to Audience Successfully.");
          setEmailAddress("");
          setFirstName("");
          setLastName("");
        })
        .catch(() => {
          message.error("Unable to add the contact right now.");
        });

      setLoading(false);
    }
  };

  return audienceCard.visible ? (
    <div
      style={{ right: "25%", bottom: queue.items.length > 0 ? "110px" : 0 }}
      className={`${style.voiceRecord} ${styles.shareCard} ${style.commentartContainer} ${styles.container}`}
    >
      <div className="d-flex ">
        <img
          src="/images/greenEmailIcon.svg"
          style={{ filter: "none" }}
          alt=""
        />
        <p>New Contact</p>
        <img
          src="/images/images/closeCircleOutlined.svg"
          onClick={() => {
            dispatch(setAudienceCard({ visible: false }));
          }}
        />
      </div>
      <div
        className={` ${sidebarstyle.popupCard} d-flex align-items-center flex-column mt-5`}
      >
        <div className="mb-2">
          <label htmlFor="">CONTACT EMAIL ADDRESS</label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="">CONTACT FIRST NAME</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label htmlFor="">CONTACT LAST NAME</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        {loading ? (
          <Spin size="large" />
        ) : (
          <div
            className={`${tag} px-4 mx-5`}
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderColor: "white",
            }}
            onClick={() => onPerformOperation()}
          >
            Save
          </div>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}
