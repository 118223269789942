var Types = {
  setTags: "settags",
  setFeed: "setFeed",
  setSavedNews: "setSavedNews",
  setSharedNews: "setSharedNews",
  setPlaylist: "setPlaylist",
  setPodcast: "setPodcast",
  setFeedlyUser: "setFeedlyUser",
  setFeedlyFeed: "setFeedlyFeed",
  setNewsLogChange: "setNewsLogChange",
  addToPodcast: "addToPodcast",
  setRadioMode: "setRadioMode",
  addEpisodes: "addEpisodes",
  setImportantFeed: "setImportantFeed",
  setStationMode: "setStationMode",
  setStations: "setStations",
  setWheather: "setWheather",
  setReadLater: "setReadLater",
  setBoards: "setBoards",
  SET_LISTEN_LATER: "SET_LISTEN_LATER",
  SET_YOUTUBE_USER: "SET_YOUTUBE_USER",
  SET_YOUTUBE_VIDEOS: "SET_YOUTUBE_VIDEOS",
  SET_SUBSCRIBED_NEWS: "SET_SUBSCRIBED_NEWS",
  SET_DONOT_PLAY: "SET_DONOT_PLAY",
  SET_SELECTED_FEED: "SET_SELECTED_FEED",
};
// actions
var setTags = (cond) => ({
  type: Types.setTags,
  payload: cond,
});

var setSharedNews = (cond) => ({
  type: Types.setSharedNews,
  payload: cond,
});

var setSavedNews = (cond) => ({
  type: Types.setSavedNews,
  payload: cond,
});

var setFeed = (cond) => ({
  type: Types.setFeed,
  payload: cond,
});

var setPlaylists = (cond) => ({
  type: Types.setPlaylist,
  payload: cond,
});

var setPodcast = (cond) => ({
  type: Types.setPodcast,
  payload: cond,
});

var addToPodcast = (cond) => ({
  type: Types.addToPodcast,
  payload: cond,
});

var setFeedlyUser = (cond) => ({
  type: Types.setFeedlyUser,
  payload: cond,
});

var setFeedlyFeed = (cond) => ({
  type: Types.setFeedlyFeed,
  payload: cond,
});

var setNewsLogChange = (cond) => ({
  type: Types.setNewsLogChange,
  payload: cond,
});

var setRadioMode = (cond) => ({
  type: Types.setRadioMode,
  payload: cond,
});

var setStationMode = (cond) => ({
  type: Types.setStationMode,
  payload: cond,
});

var setStations = (cond) => ({
  type: Types.setStations,
  payload: cond,
});

var addPodcastEpisodes = (cond) => ({
  type: Types.addEpisodes,
  payload: cond,
});

var setImportantFeed = (cond) => ({
  type: Types.setImportantFeed,
  payload: cond,
});

var setWheather = (cond) => ({
  type: Types.setWheather,
  payload: cond,
});
var setReadLater = (cond) => ({
  type: Types.setReadLater,
  payload: cond,
});
var setBoards = (cond) => ({
  type: Types.setBoards,
  payload: cond,
});
var setListenLater = (cond) => ({
  type: Types.SET_LISTEN_LATER,
  payload: cond,
});

var setYoutubeUser = (cond) => ({
  type: Types.SET_YOUTUBE_USER,
  payload: cond,
});

var setYoutubeVideos = (cond) => ({
  type: Types.SET_YOUTUBE_VIDEOS,
  payload: cond,
});

var setSubscribedNews = (cond) => ({
  type: Types.SET_SUBSCRIBED_NEWS,
  payload: cond,
});

var setDoNotPlay = (cond) => ({
  type: Types.SET_DONOT_PLAY,
  payload: cond,
});

// new actions

var setSelectedFeed = (cond) => ({
  type: Types.SET_SELECTED_FEED,
  payload: cond,
});

export default {
  setSelectedFeed,
  setDoNotPlay,
  setSubscribedNews,
  setYoutubeVideos,
  setYoutubeUser,
  setListenLater,
  setBoards,
  setReadLater,
  setWheather,
  setImportantFeed,
  addPodcastEpisodes,
  setRadioMode,
  addToPodcast,
  setFeedlyUser,
  setFeedlyFeed,
  setTags,
  setFeed,
  setSavedNews,
  setSharedNews,
  setPlaylists,
  setPodcast,
  setStations,
  setNewsLogChange,
  setStationMode,
  Types,
};
