import React from "react";
import { connect } from "react-redux";
import AllSetting from "../settings/AllSetting";
import style from "../../style/tabs.module.css";
var mapStateToProps = (state) => {
  return {};
};

function ProjectTab(props) {
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <AllSetting />
    </div>
  );
}

export default connect(mapStateToProps, null)(ProjectTab);
