import React from "react";
import style from "../style/sidebarTabsContent.module.css";

export default function ImageInitial({ image, fullname, active }) {
  return (
    <div className={style.userImage}>
      {image ? (
        <img
          src={image}
          alt=""
          className="w-100 h-100"
          style={{
            borderRadius: "50%",
            border: active ? "1px solid rgb(56, 239, 125)" : "",
          }}
        />
      ) : (
        <span>
          {fullname ? fullname[0] : ""}
          {fullname
            ? fullname.split(" ")[1]
              ? fullname.split(" ")[1][0]
              : ""
            : ""}
        </span>
      )}
    </div>
  );
}
