import React from "react";
import { connect } from "react-redux";
import style from "../../style/tabs.module.css";
import AllTemplate from "../templates/AllTemplate";
var mapStateToProps = (state) => {
  return {
    projects: state.projects.projects.filter((item) => item.type == "template"),
  };
};

function TemplateTab(props) {
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <AllTemplate projects={props.projects} type="template" />
    </div>
  );
}

export default connect(mapStateToProps, null)(TemplateTab);
