import React, { useState } from "react";
import style from "../../style/PlayCard.module.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import player from "../../data/player";
import moment from "moment";
import { connect } from "react-redux";
import {
  addItemToProject,
  addItemsToCampaign,
  removeItemFromProject,
} from "../../redux/actions/project";
import Actions from "../../redux/actions/site";
import { Popover, message } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { invert } from "lodash";
import { setAddItem } from "../../redux/actions/main";
import PlayHover from "../PlayHover";
var fileDownload = require("js-file-download");

const dispatchState = (dispatch) => ({
  addItemProject: (data) => dispatch(addItemToProject(data)),
  addItemsCampaign: (data) => dispatch(addItemsToCampaign(data)),
  removeItemFromProject: (item, isStory) =>
    dispatch(removeItemFromProject(item, isStory)),
  setShare: (item) => dispatch(Actions.setShare(item)),
  setAddItem: (item) => dispatch(setAddItem(item)),
});

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
});

const Player = player();

function PostCard({
  data,
  addItemsCampaign,
  activeProject,
  addItemProject,
  campaignView,
  removeItemFromProject,
  onCardClick,
  setShare,
  setAddItem,
}) {
  let {
    title,
    description,
    category,
    source,
    type,
    image,
    link,
    createdAt,
    published_at,
    draft,
    post_type,
  } = data;
  const [showPlayHover, setShowPlayHover] = useState(false);

  // link = link.replace(
  //   "http://localhost:4000/",
  //   "https://devteamback.audioone.fm/"
  // );

  let backImage = image;
  if (post_type == "STORY" && !image) {
    let chapterImage = data.story_lines.find((item) => item.image);
    if (chapterImage) backImage = chapterImage.image;
  }

  return (
    <div
      onClick={() => onCardClick()}
      className={style.PlayCard}
      style={{ cursor: "pointer", backgroundImage: `url(${backImage})` }}
      onMouseLeave={() => setShowPlayHover(false)}
    >
      <PlayHover showPlayHover={showPlayHover} article={data} />
      <div className="d-flex align-items-start justify-content-between">
        <img
          src="/images/playIcon.svg"
          alt=""
          style={{ fontSize: "2em", cursor: "pointer", marginRight: "1em" }}
          onClick={(e) => {
            e.stopPropagation();
            setShowPlayHover(true);
          }}
        />

        <h5>
          {title ? title.substr(0, 100) : ""}
          {title ? (title.length > 100 ? "..." : "") : ""}
        </h5>
      </div>
      <div className="row m-0 w-100">
        <div className="col-md-7 pl-0">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: "110px" }}
          >
            <div>
              <h6>Source</h6>
              <p> {source}</p>
            </div>

            <div>
              <h6>Publish Date</h6>
              <p> {moment(published_at).format("MM/DD/YYYY")}</p>
            </div>
            <div>
              <h6>Type</h6>
              <div className="d-flex align-items-center">
                <img src="/images/postIcon.png" className="mr-2" alt="" />
                <p className="mr-2">
                  {post_type == "STORY"
                    ? `Story `
                    : post_type == "INTERVIEW"
                    ? "Interview "
                    : post_type == "INTRO"
                    ? "INTRO "
                    : "Podcast "}
                  {draft ? "Draft" : "Published"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 pr-0 pl-0">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: " 110px" }}
          >
            <div>
              <h6>Category</h6>
              <p>{category}</p>
            </div>
            <div>
              <h6>Creation Date</h6>
              <p>{moment(createdAt).format("MM/DD/YYYY")}</p>
            </div>

            <div className={style.ActionButton}>
              {/* <EyeOutlined
                style={{ fontSize: "1.5em", cursor: "pointer" }}
                onClick={() => onCardClick()}
              /> */}

              <a download href={data.speechUrl} target="_blank">
                <img
                  src="/images/downloadIcon.svg"
                  alt=""
                  style={{
                    height: "20px",
                  }}
                  className="mx-2"
                  onClick={() => {}}
                />
              </a>
              <img
                src="/images/addIcon.svg"
                alt=""
                onClick={(e) => {
                  e.stopPropagation();
                  setAddItem({ ...data, type: "post", visible: true });
                }}
              />

              <img
                src="/images/shareIcon.svg"
                alt=""
                className="mx-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setShare({ ...data, visible: true, type: "post" });
                }}
              />

              <img
                src="/images/delete.svg"
                className="m-0"
                onClick={(e) => {
                  e.stopPropagation();
                  removeItemFromProject(
                    data.id,
                    data.post_type == "STORY"
                      ? "STORY"
                      : data.post_type == "INTERVIEW"
                      ? "INTERVIEW"
                      : false
                  );
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(PostCard);
