import React from "react";
import style from "../style/projectCard.module.css";
import { PlayCircleOutlined, EyeOutlined } from "@ant-design/icons";
export default function ProjectCard(props) {
  const { title, members } = props.data;

  const icons = {
    post: "projectIcon.svg",
    project: "brandIcon.svg",
    audience: "audienceIcon.svg",
    campaign: "campaignIcon.svg",
    team: "teamsIcon.svg",
    collection: "collectingIcon.svg",
    templates: "templatesIcon.svg",
  };

  return (
    <div
      className={`cards ${style.ProjectCard}`}
      style={{
        backgroundColor: "transparent",
        border: `1px solid #12e361`,
        width: "150px",
        height: "173px",
      }}
      onClick={() => props.onSelect()}
    >
      <div className="d-flex justify-content-between w-100">
        <img
          src="/images/recordIconRed.svg"
          alt=""
          style={{ filter: "none" }}
        />
        {/* {props.todayScreen ? (
          <img
            src={`/images/${icons[type]}`}
            style={{
              filter: type == "post" ? "none" : "invert(1)",
              width: "25px",
            }}
            alt=""
          />
        ) : (
          ""
        )} */}
      </div>

      <p style={{ color: "white", textAlign: "right" }}>{title}</p>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="text-left w-100">
          <h4>{members.length}</h4>
          <span className={style.smText}>People</span>
        </div>
        <div>
          <div className="d-flex" style={{ marginBottom: "-15px" }}>
            {members.map((item) => (
              <img
                style={{
                  filter: "none",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  margin: "0 5px",
                }}
                src={item.member.avatar}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
