import React, { useState, useEffect, useRef } from "react";
import style from "../../style/editCampaign.module.css";
import { Button, message } from "antd";
import { connect } from "react-redux";
import { addItemsToCampaign, editProject } from "../../redux/actions/project";
import EmailEditor from "react-email-editor";
import sampletemplate from "../../data/sampletemplate.json";
import apiRequest from "../../api/clientRequest";
import TemplatePicker from "./TemplatePicker";
import axios from "axios";
import { sendCampaign } from "../../redux/actions/campaign";
import moment from "moment";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  activeColor: state.projects.activeColor,
  sendCampaign: state.campaign.sendCampaign,
  user: state.auth.user,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  editProject: (data) => dispatch(editProject(data)),
  setSendCampaign: (data) => dispatch(sendCampaign(data)),
});

function EditCampaign(props) {
  const emailEditorRef = useRef(null);
  const [emailEdittor, setEditor] = useState(null);
  const [templatePicker, setTemplatePicker] = useState("");

  const sendCampaign = ({
    subject,
    recipients,
    from,
    alternateTemplate,
    amelia,
  }) => {
    emailEdittor.exportHtml((data) => {
      const { design, html } = data;
      message.success("Email Sent!");
      apiRequest({
        method: "POST",
        url: "/campaigns/send",
        data: {
          htmlBody: html,
          design: design,
          projectId: props.activeProject._id,
          subject,
          recipients,
          from,
          alternateTemplate,
          amelia,
          date: moment().format("X"),
        },
      });
    });
  };

  useEffect(() => {
    if (props.sendCampaign) {
      sendCampaign(props.sendCampaign);
      props.setSendCampaign(null);
    }
  }, [props.sendCampaign]);

  useEffect(() => {
    if (document.getElementById("editor-container"))
      document.getElementById("editor-container").remove();

    var para = document.createElement("div"); // Create a <p> element
    para.style.height = "580px";
    para.setAttribute("id", "editor-container");

    document.getElementById("editorOutside").appendChild(para);

    let dropdownItems = [
      {
        value: JSON.stringify({ title: "ref" }),
        label: "Select an article, Podcast or other item.",
      },
    ];
    console.log(props.activeProject.addedItems);
    const artItems = props.activeProject.addedItems.map((item) => {
      return {
        label: JSON.parse(item.article).title,
        value: item.article,
      };
    });

    dropdownItems.push(...artItems);

    const editor = window.unlayer.createEditor({
      id: "editor-container",
      projectId: 9154,
      displayMode: "email",
      tools: {
        "custom#addItem": {
          properties: {
            articleSelector: {
              editor: {
                data: {
                  options: dropdownItems,
                },
              },
            },
            styleSelector: {
              editor: {
                data: {
                  options: [
                    { value: "1", label: "Standard Overlay" },
                    // { value: "2", label: "Overlay Style 2" },
                    { value: "3", label: "Overlay with out text" },
                    { value: "4", label: "Overlay Style 4" },
                  ],
                },
              },
            },
            backgroundPositionSelector: {
              editor: {
                data: {
                  options: [
                    { value: "center", label: "Center Center" },
                    { value: "top center", label: "Top Center" },
                    { value: "bottom center", label: "Bottom Center" },
                  ],
                },
              },
            },
          },
        },
      },
      customJS: [
        `
        unlayer.registerTool({
          name: 'addItem',
          label: 'Add Item',
          icon: 'fa-newspaper',
          supportedDisplayModes: ['web', 'email'],
          options: {
            default: {
              title: null,
            },
            articles: {
              title: 'Article',
              position: 2,
              options: {
                articleSelector: {
                  label: 'Select Article',
                  widget: 'dropdown', // built_in property editor
                },
              },
            },
            styles: {
              title: 'Overlay Style',
              position: 2,
              options: {
                styleSelector: {
                  label: 'Select Style',
                  widget: 'dropdown', // built_in property editor
                },
              },
            }, 
            backgroundPosition: {
              title: 'Background Position',
              position: 3,
              options: {
                backgroundPositionSelector: {
                  label: 'Select Style',
                  widget: 'dropdown', // built_in property editor
                },
              },
            },              
            thumbnail: {
              title: 'Thumbnail',
              position: 3,
              options: {
                thumbSelector: {
                  label: 'Select Thumbnail',
                  widget: 'image', // built_in property editor
                },
              },
            },           
            text: {
              title: 'Text',
              position: 1,
              options: {
                textColor: {
                  label: 'Color',
                  defaultValue: '#000',
                  widget: 'color_picker', // built_in property editor
                },
              },
            },
          },
          values: {},
          renderer: {
            Viewer: unlayer.createViewer({
              render(values) {

                console.log(values)

                let newArticle = {
                  image:"https://cdn.templates.unlayer.com/assets/1597218426091-xx.png",
                  title:"Article title",
                  source:"this is test source",
                  id:"123"
                }
                if(values.articleSelector){
                newArticle = JSON.parse(values.articleSelector)
                console.log(newArticle)
                newArticle.typeicon = newArticle.type == 'news' ? 'https://unroll-images-production.s3.amazonaws.com/projects/9154/1615193267475-feedlyWhite.png' : 'https://unroll-images-production.s3.amazonaws.com/projects/9154/1615193508573-Radio.png'            
                newArticle.type = newArticle.type == 'news' ? 'News Article' : newArticle.type == 'podcast' ? 'Podcast' : newArticle.type == 'shorts' ? 'Shorts' : newArticle.post_type ? newArticle.post_type == 'STORY' ? 'Story': 'Podcast': 'Podcast'               
                if(newArticle.title == "ref")
                  newArticle = {
                    image:"https://cdn.templates.unlayer.com/assets/1597218426091-xx.png",
                    title:"Article title",
                    source:"this is test source",
                    id:"123"
                  }
                
                if(values.thumbSelector)
                newArticle.image = values.thumbSelector.url
                }
                if(values.backgroundPositionSelector)
                newArticle.backgroundPosition = values.backgroundPositionSelector
                else
                newArticle.backgroundPosition = 'center'
                if(values.styleSelector == "2")
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';position:relative" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right"> <img style="width:20px;filter:brightness(2);margin-right:4px" src="'+newArticle.typeicon+'"/> '+newArticle.type+'</p> <div style="display:flex;margin-top:170px"> <div style="color:white;width:310px"> <a href="artLink'+newArticle.id +'" style="color:'+values.textColor +';display:block;text-decoration:none;text-align:left;margin-top:10px;padding:0 10px;font-size:18px;font-weight:bold;line-height:1.3em;height:50px;overflow:hidden">'+ newArticle.title +'</a><a style="text-align:left;padding:0 10px;font-size:12px;color:'+values.textColor +';display:block;text-decoration:none;margin-top:5px">'+ newArticle.source +'</a></div>  <a href="artLink'+newArticle.id +'" style="margin-left:auto;margin-right:15px"><img style="width:60px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a></div> </div><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
                else if(values.styleSelector == "3")
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';justify-content:center;align-items:center;position:relative;" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right;opacity:0">'+newArticle.type+'</p> <a href="artLink'+newArticle.id +'" style="text-align:center;margin:auto;margin-top:85px;display:block"><img style="width:80px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a> </div><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
                else
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';justify-content:center;align-items:center;position:relative;" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right"> <img style="width:20px;filter:brightness(2);margin-right:4px" src="'+newArticle.typeicon+'"/> '+newArticle.type+'</p> <a href="artLink'+newArticle.id +'" style="text-align:center;margin:auto;margin-top:85px;display:block"><img style="width:80px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a> </div> <a href="artLink'+newArticle.id +'" style="color:'+values.textColor +';display:block;text-decoration:none;text-align:left;margin-top:10px;padding:0 10px;font-size:18px;font-weight:bold;line-height:1.3em">'+ newArticle.title +'</a><a style="text-align:left;padding:0 10px;font-size:12px;color:'+values.textColor +';display:block;text-decoration:none;margin-top:5px">'+ newArticle.source +'</a><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
              },
            }),
            exporters: {
              web: function (values) {

                let newArticle = {
                  image:"https://cdn.templates.unlayer.com/assets/1597218426091-xx.png",
                  title:"Article title",
                  source:"this is test source",
                  id:"123"
                }
                if(values.articleSelector){
                newArticle = JSON.parse(values.articleSelector)
                newArticle.typeicon = newArticle.type == 'news' ? 'https://unroll-images-production.s3.amazonaws.com/projects/9154/1615193267475-feedlyWhite.png' : 'https://unroll-images-production.s3.amazonaws.com/projects/9154/1615193508573-Radio.png'            
                newArticle.type = newArticle.type == 'news' ? 'News Article' : newArticle.type == 'podcast' ? 'Podcast' : newArticle.type == 'shorts' ? 'Shorts' : newArticle.post_type ? newArticle.post_type == 'STORY' ? 'Story': 'Podcast': 'Podcast'               
                if(values.thumbSelector)
                newArticle.image = values.thumbSelector.url
                }

                if(values.backgroundPositionSelector)
                newArticle.backgroundPosition = values.backgroundPositionSelector
                else
                newArticle.backgroundPosition = 'center'
                if(values.styleSelector == "2")
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';position:relative" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right"> <img style="width:20px;filter:brightness(2);margin-right:4px" src="'+newArticle.typeicon+'"/> '+newArticle.type+'</p> <div style="display:flex;margin-top:170px"> <div style="color:white;width:310px"> <a href="artLink'+newArticle.id +'" style="color:'+values.textColor +';display:block;text-decoration:none;text-align:left;margin-top:10px;padding:0 10px;font-size:18px;font-weight:bold;line-height:1.3em;height:50px;overflow:hidden">'+ newArticle.title +'</a><a style="text-align:left;padding:0 10px;font-size:12px;color:'+values.textColor +';display:block;text-decoration:none;margin-top:5px">'+ newArticle.source +'</a></div>  <a href="artLink'+newArticle.id +'" style="margin-left:auto;margin-right:15px"><img style="width:60px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a></div> </div><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
                else if(values.styleSelector == "3")
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';justify-content:center;align-items:center;position:relative;" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right;opacity:0">'+newArticle.type+'</p> <a href="artLink'+newArticle.id +'" style="text-align:center;margin:auto;margin-top:85px;display:block"><img style="width:80px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a> </div><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
                else
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';justify-content:center;align-items:center;position:relative;" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right"> <img style="width:20px;filter:brightness(2);margin-right:4px" src="'+newArticle.typeicon+'"/> '+newArticle.type+'</p> <a href="artLink'+newArticle.id +'" style="text-align:center;margin:auto;margin-top:85px;display:block"><img style="width:80px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a> </div> <a href="artLink'+newArticle.id +'" style="color:'+values.textColor +';display:block;text-decoration:none;text-align:left;margin-top:10px;padding:0 10px;font-size:18px;font-weight:bold;line-height:1.3em">'+ newArticle.title +'</a><a style="text-align:left;padding:0 10px;font-size:12px;color:'+values.textColor +';display:block;text-decoration:none;margin-top:5px">'+ newArticle.source +'</a><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
              },
              email: function (values) {
                let newArticle = {
                  image:"https://cdn.templates.unlayer.com/assets/1597218426091-xx.png",
                  title:"Article title",
                  source:"this is test source",
                  id:"123"
                }
                if(values.articleSelector){
                newArticle = JSON.parse(values.articleSelector)
                newArticle.typeicon = newArticle.type == 'news' ? 'https://unroll-images-production.s3.amazonaws.com/projects/9154/1615193267475-feedlyWhite.png' : 'https://unroll-images-production.s3.amazonaws.com/projects/9154/1615193508573-Radio.png'            
                newArticle.type = newArticle.type == 'news' ? 'News Article' : newArticle.type == 'podcast' ? 'Podcast' : newArticle.type == 'shorts' ? 'Shorts' : newArticle.post_type ? newArticle.post_type == 'STORY' ? 'Story': 'Podcast': 'Podcast'               
                if(values.thumbSelector)
                newArticle.image = values.thumbSelector.url
                }

                if(values.backgroundPositionSelector)
                newArticle.backgroundPosition = values.backgroundPositionSelector
                else
                newArticle.backgroundPosition = 'center'
                if(values.styleSelector == "2")
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';position:relative" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right"> <img style="width:20px;filter:brightness(2);margin-right:4px" src="'+newArticle.typeicon+'"/> '+newArticle.type+'</p> <div style="display:flex;margin-top:170px"> <div style="color:white;width:310px"> <a href="artLink'+newArticle.id +'" style="color:'+values.textColor +';display:block;text-decoration:none;text-align:left;margin-top:10px;padding:0 10px;font-size:18px;font-weight:bold;line-height:1.3em;height:50px;overflow:hidden">'+ newArticle.title +'</a><a style="text-align:left;padding:0 10px;font-size:12px;color:'+values.textColor +';display:block;text-decoration:none;margin-top:5px">'+ newArticle.source +'</a></div>  <a href="artLink'+newArticle.id +'" style="margin-left:auto;margin-right:15px"><img style="width:60px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a></div> </div><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
                else if(values.styleSelector == "3")
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';justify-content:center;align-items:center;position:relative;" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right;opacity:0">'+newArticle.type+'</p> <a href="artLink'+newArticle.id +'" style="text-align:center;margin:auto;margin-top:85px;display:block"><img style="width:80px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a> </div><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
                else
                return '<div style="color:'+values.textColor +';"><div style="width:100%;"><div style="width:100%;height:300px;background:url('+newArticle.image +'),rgba(0,0,0,0.4);background-blend-mode:multiply;background-size:cover;background-position:'+newArticle.backgroundPosition +';justify-content:center;align-items:center;position:relative;" > <p style="color:white;font-size:18px;margin-right:10px;padding-top:5px;text-align:right"> <img style="width:20px;filter:brightness(2);margin-right:4px" src="'+newArticle.typeicon+'"/> '+newArticle.type+'</p> <a href="artLink'+newArticle.id +'" style="text-align:center;margin:auto;margin-top:85px;display:block"><img style="width:80px;" src="https://unroll-images-production.s3.amazonaws.com/projects/9154/1610359909772-PlayButton.png"/></a> </div> <a href="artLink'+newArticle.id +'" style="color:'+values.textColor +';display:block;text-decoration:none;text-align:left;margin-top:10px;padding:0 10px;font-size:18px;font-weight:bold;line-height:1.3em">'+ newArticle.title +'</a><a style="text-align:left;padding:0 10px;font-size:12px;color:'+values.textColor +';display:block;text-decoration:none;margin-top:5px">'+ newArticle.source +'</a><a href="artLink'+newArticle.id +'" style="display:none">artid'+ newArticle.id +'artid</a> </div>';
              },
            },
            head: {
              css: function (values) {},
              js: function (values) {},
            },
          },
        });       
      `,
      ],
      customCSS: [
        "https://use.fontawesome.com/releases/v5.1.1/css/all.css",
        `
      .blockbuilder-preview {
        background-color:rgb(36,35,38);
        background-image: none;
      }

      .blockbuilder-preferences{
        border-left:none !important
      }
        .blockbuilder-preferences   div{
          background-color: rgb(36,35,38) !important;
          background-image: none !important;
          color:#999 !important
        }
    
        .blockbuilder-preferences .nav-tabs .nav-item .nav-link.active{
          background-color: rgb(36,35,38) !important;
          background-image: none !important;
        }

        .blockbuilder-preferences .nav-tabs .nav-item .nav-link:hover{
          background-color: rgb(36,35,38) !important;
          background-image: none !important;
        }

        .blockbuilder-preview.design-web.editor-desktop{
          overflow-x:hidden
        }

        .blockbuilder-preview.design-web.editor-desktop::-webkit-scrollbar-track,.col-sm-12::-webkit-scrollbar-track,.icsSuz .tab-content::-webkit-scrollbar-track,.blockbuilder-options-content::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px #999;
          background-color: #999
        }
      
        .blockbuilder-preview.design-web.editor-desktop::-webkit-scrollbar,.col-sm-12::-webkit-scrollbar,.icsSuz .tab-content::-webkit-scrollbar ,.blockbuilder-options-content::-webkit-scrollbar{
            width: 6px;
            background-color: #999;
        }
        
        .blockbuilder-preview.design-web.editor-desktop::-webkit-scrollbar-thumb,.col-sm-12::-webkit-scrollbar-thumb,.icsSuz .tab-content::-webkit-scrollbar-thumb ,.blockbuilder-options-content::-webkit-scrollbar-thumb{
            background-color: white;
            border-radius:15px;
        }

        .ldxcDQ .blockbuilder-content-tool, .ldxcDQ .blockbuilder-classic-tool{
          border:1px solid #999 !important; 
        }
        .jYMMZB .blockbuilder-column::after{
          outline:#999 solid 1px
        }

        .fXGmdA{
          color:#999 !important
        }

        .bootstrap input{
          background:transparent !important;
          border:1px solid #999 !important;
        }

        .bootstrap svg,.ldxcDQ .nav-tabs .nav-item .nav-link .nav-name{
          color:#999 !important
        }

        .eNpfYQ{
          background-color:transparent;
          color:#999
        }
        .bZHhHK > .card .card-header{
          background:transparent !important;
          border:1px solid #999 !important;
          color:#999 !important;
        }

        .card .card-header{
          background:transparent !important;
          color:#999 !important;
          border:1px solid #999;
        }

        .blockbuilder-options-content{
          padding:0 8px
        }

        .gLAFCy .blockbuilder-widget-label label.blockbuilder-label-primary,.fQGYsH .blockbuilder-widget-label .blockbuilder-label-primary{
          color:#999 !important;
        }

        .gLAFCy{
          border-bottom:1px solid #999 !important;
        }

        .kVnMcU .form-control,.kVnMcU .input-group-text{
          background-color:rgba(36,35,38) !important;
          border:1px solid #999 !important;
          color:#999 !important
        }
        .gLAFCy .blockbuilder-widget-hint{
          color:#999 !important
        }
        .cFvDFf .blockbuilder-counter-control-value{
          background-color:rgb(36,35,38) !important;
          border-color:#999 !important
        }

        .cGavax .nav-tabs{
          background-color:transparent !important;
          border-color:rgb(36,35,38)!important
        }

        .cGavax .nav-tabs .nav-item .nav-link.active,.cGavax .blockbuilder-options-header,.cGavax .blockbuilder-options-header .icon,.cylmga{
          border-color:rgb(36,35,38) !important
        }
        

        .gLAFCy .blockbuilder-widget-label label,.bZHhHK > .card .card-header .group-toggle{
          color:#999 !important
        }

        .hCRjOw .blockbuilder-dropzone,.kdRQfw{
          border-color:#999 !important;
          color:#999 !important
        }

        .cFvDFf{
          border-color:#999 !important
        }

        .icsSuz .nav-tabs .nav-item .nav-link .nav-icon,.icsSuz .nav-tabs .nav-item .nav-link .nav-name{
          color:#999 !important;
          opacity:1 !important
        }
        .eTOHUT{
          color:#999
        }
      `,
      ],
    });

    editor.addEventListener("design:updated", function (updates) {
      // Design is updated by the user

      const { members } = props.activeProject;
      const { user } = props;
      if (props.activeProject.invited) {
        const foundMember = members.find((item) => item.user_id == user._id);
        if (foundMember)
          switch (foundMember.role) {
            case "Member":
              return;
          }
      }
      editor.saveDesign(function (design) {
        apiRequest({
          url: "/campaigns/emails",
          method: "POST",
          data: {
            projectId: props.activeProject._id,
            item: JSON.stringify(design),
          },
        }).then((res) => {
          // message.success("Template saved");
        });
      });
    });

    // editor.addEventListener("onLoad", function (updates) {
    //   // Design is updated by the user
    //   console.log("loaded");
    // });

    if (
      props.activeProject.email &&
      Object.keys(props.activeProject.email).length !== 0
    )
      editor.loadDesign(JSON.parse(props.activeProject.email));
    setEditor(editor);
  }, [props.activeProject]);

  const saveCampaign = () => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not edit a campaign");
            return;
        }
    }

    emailEdittor.saveDesign(function (design) {
      apiRequest({
        url: "/campaigns/emails",
        method: "POST",
        data: {
          projectId: props.activeProject._id,
          item: JSON.stringify(design),
        },
      }).then((res) => {
        message.success("email saved");
        props.editProject(res.data);
      });
    });
  };

  return (
    <div className={`${style.editCampaign} mt-0`}>
      <div className="mb-4 d-flex align-items-center">
        {/* <h5 className="mb-0">{props.tab}</h5> */}
        <Button
          style={{
            borderRadius: "15px",
            backgroundColor: templatePicker
              ? "rgba(255, 255, 255, 0.43)"
              : "transparent",
            borderColor: "white",
            color: "white",
          }}
          className="ml-4"
          onClick={() => setTemplatePicker(!templatePicker)}
        >
          {templatePicker ? "Close Template Browser" : "View Template Browser"}
        </Button>
        <Button
          style={{
            borderRadius: "15px",
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
          }}
          className="ml-4"
          ref={props.btnRef}
          onClick={() => saveCampaign()}
        >
          Save Email
        </Button>
      </div>

      {templatePicker ? (
        <TemplatePicker
          onClick={(item) => emailEdittor.loadDesign(item.design)}
          color={props.activeColor.primaryColor}
        />
      ) : (
        ""
      )}

      <div
        className="row mt-3 mx-0"
        id="editorOutside"
        style={{ paddingBottom: "10em", width: "90%" }}
      ></div>
    </div>
  );
}

export default connect(mapState, dispatchState)(EditCampaign);
