import ACTIONS from "../actions/auth";
import _ from "lodash";
import axios from "axios";
import storage from "store2";

var defaultState = {
  user: null,
  linkAccount: {
    id: "",
    token: "",
  },
  isAuthenticated: false,
  authToken: storage.has("auth_token") ? storage.get("auth_token") : "",
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.setUser: {
      let newState = Object.assign({}, state);
      newState.user = action.payload;
      return newState;
    }
    case ACTIONS.Types.AUTHENTICATE_USER:
      storage.set("auth_token", action.payload);
      return { ...state, isAuthenticated: true, authToken: action.payload };
    case ACTIONS.Types.linkAccount: {
      let newState = Object.assign({}, state);
      newState.linkAccount = action.payload;
      return newState;
    }
    case ACTIONS.Types.RESET_AUTH: {
      storage.clearAll();
      return {
        ...state,
        isAuthenticated: false,
        authToken: undefined,
        user: undefined,
      };
    }

    default:
      return state;
  }
};
export default authReducer;
