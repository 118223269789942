import React, { useState, useRef, useEffect } from "react";
import style from "../styles/form.module.css";
import { Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";

export default function SelectedField(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputVisibility, setInputVisibility] = useState(false);
  const [activeItem, setActiveItem] = useState(props.defaultValue);
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    if (e.which == 13) {
      if (e.target.value.length == 0) {
        setActiveItem(props.defaultValue);
        setInputVisibility(false);
      } else {
        setActiveItem(e.target.value);
        props.setValue(e.target.value);
        props.setModalData(e.target.value);
        setInputVisibility(false);
      }
    }
    e.target.setAttribute("size", e.target.value.length || 1);
  };
  const handleRemove = (e) => {
    if (e.keyCode == 8) {
      e.target.setAttribute("size", e.target.value.length || 1);
    }
  };
  const handleBlur = (e) => {
    console.log(e.target.value.length);
    if (e.target.value.length == 0) {
      setActiveItem(props.defaultValue);
      props.setValue(props.defaultValue);
      setInputVisibility(false);
    } else {
      setActiveItem(e.target.value);
      props.setValue(e.target.value);
      props.setModalData(e.target.value);
      setInputVisibility(false);
    }
  };

  useEffect(() => {
    if (inputVisibility) {
      inputRef.current.focus();
    }
  }, [inputVisibility]);
  useEffect(() => {
    setActiveItem(props.defaultValue);
  }, [props.defaultValue]);
  return (
    <>
      {inputVisibility ? (
        <input
          type="text"
          className={style.addNewInput}
          onKeyPress={handleSubmit}
          onKeyDown={handleRemove}
          ref={inputRef}
          size={1}
          onBlur={handleBlur}
        />
      ) : (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsModalVisible(true);
          }}
        >
          {activeItem}
        </a>
      )}
      <Modal
        centered
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={300}
      >
        <div className={style.selectContainer}>
          {props.modalData.map((item) => (
            <a
              href="#"
              className={activeItem == item ? style.active : ""}
              onClick={(e) => {
                e.preventDefault();
                if (item == "Add New") {
                  setInputVisibility(true);
                  setIsModalVisible(false);
                } else {
                  setActiveItem(item);
                  props.setValue(item);
                  setIsModalVisible(false);
                }
              }}
            >
              {item} {activeItem == item ? <CheckOutlined /> : ""}
            </a>
          ))}
        </div>
      </Modal>
    </>
  );
}
