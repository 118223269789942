import React, { useState, useEffect } from "react";
import { ProjectCard as ProjectCarstyle } from "../../style/projectCard.module.css";
import style from "../../style/createproject.module.css";
import { PlusOutlined } from "@ant-design/icons";
import apiRequest from "../../api/clientRequest";
import { message, Modal } from "antd";
import {
  addNewProject,
  setActiveColor,
  setActiveProject,
  deleteAnItem,
  editProject,
} from "../../redux/actions/project";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { setProfileSettings } from "../../redux/actions/main";
import { useDispatch } from "react-redux";
import { addRecentItem } from "../../redux/actions/main";
import { connect, useSelector } from "react-redux";
import colors from "../../data/colors.json";

function CreateSetting(props) {
  const { user } = useSelector((state) => state.auth);
  const [theme, setTheme] = useState(user.theme);
  const [detailTheme, setDetailTheme] = useState(user.detailTheme);
  const [wordpressUrl, setWordpressUrl] = useState(user.wordpressUrl);
  const [wordpressUsername, setWordpressUsername] = useState(
    user.wordpressUsername
  );
  const [wordpressPassword, setWordpressPassword] = useState(
    user.wordpressPassword
  );
  const [emailService, setEmailService] = useState(user.emailService);

  const dispatch = useDispatch();
  const saveSettings = async () => {
    let response = await dispatch(
      setProfileSettings({
        theme,
        detailTheme,
        wordpressUrl,
        wordpressUsername,
        wordpressPassword,
        emailService,
      })
    );
    if (response) message.success("Settings saved");
    else message.error("Unable to save settings right now");
  };

  return (
    <div style={{ position: "relative" }} className="d-flex">
      <div
        className={`cards ${ProjectCarstyle}`}
        style={{
          backgroundColor: "black",
          width: "148px",
        }}
      >
        <p className="mb-4 mr-2" style={{ color: "white" }}>
          Update Settings
        </p>
      </div>

      <div className={style.createContainer}>
        <div className={style.inputContainer}>
          <div>
            <label className="d-block" htmlFor="">
              Password
            </label>
            <div className={style.inputAppend}>
              <input type="text" />
            </div>
          </div>
          <div className="mt-4">
            <label className="d-block" htmlFor="">
              Confirm Password
            </label>
            <div className={style.inputAppend}>
              <input type="text" />
            </div>
          </div>

          <div className="mt-4">
            <label className="d-block" htmlFor="">
              Themes
            </label>
            <div className={style.inputAppend}>
              <select onChange={(e) => setTheme(e.target.value)}>
                <option value="lightMode">Light Mode</option>
                <option value="darkMode">Dark Mode</option>
              </select>
            </div>
          </div>

          <div className="mt-4">
            <label className="d-block" htmlFor="">
              Detail Page
            </label>
            <div className={style.inputAppend}>
              <select
                value={detailTheme}
                onChange={(e) => setDetailTheme(e.target.value)}
              >
                <option value="fullScreen">Full Screen Layout</option>
                <option value="articleLayout">Article Layout</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label className="d-block" htmlFor="">
              Wordpress Domain
            </label>
            <div className={style.inputAppend}>
              <input
                type="text"
                value={wordpressUrl}
                onChange={(e) => setWordpressUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="d-block" htmlFor="">
              Wordpress Username
            </label>
            <div className={style.inputAppend}>
              <input
                type="text"
                value={wordpressUsername}
                onChange={(e) => setWordpressUsername(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="d-block" htmlFor="">
              Wordpress Password
            </label>
            <div className={style.inputAppend}>
              <input
                type="text"
                value={wordpressPassword}
                onChange={(e) => setWordpressPassword(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="mt-4">
            <label className="d-block" htmlFor="">
              Email Service
            </label>
            <div className={style.inputAppend}>
              <select
                value={emailService}
                onChange={(e) => setEmailService(e.target.value)}
              >
                <option value="mailgun">Mailgun</option>
                <option value="postmark">Postmark</option>
                <option value="ses">Amazon SES</option>
              </select>
            </div>
          </div> */}

          <div>
            <button onClick={() => saveSettings()} className={style.saveBtn}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
});
const mapDispatch = (dispatch) => ({
  addNewProject: (data) => dispatch(addNewProject(data)),
  setActiveColor: (data) => dispatch(setActiveColor(data)),
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  addRecentItem: (data) => dispatch(addRecentItem(data)),
  deleteAnItem: () => dispatch(deleteAnItem()),
  editProject: (data) => dispatch(editProject(data)),
});

export default connect(mapState, mapDispatch)(CreateSetting);
