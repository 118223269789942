import React from "react";
import style from "../../style/PlayCard.module.css";
import player from "../../data/player";
import { connect } from "react-redux";
import moment from "moment";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  activeColor: state.projects.activeColor,
  projectItems:
    state.projects.activeProject &&
    state.projects.activeProject.type !== "campaign"
      ? state.projects.activeProject.podcastfeed.map(
          (item) => JSON.parse(item.podcast).feed
        )
      : [],
});

const Player = player();
function PlayCard({
  data,
  addItemToProject,
  clicked,
  activeColor,
  projectItems,
  activeProject,
}) {
  const { title, category, source, type, image, published } = data;

  return type == "podcast" ? (
    <div className={style.PlayCard}>
      <div className={style.backImage}>
        <img src={image} alt="" className="w-100 h-100" />
        <div
          className={style.imageGradient}
          style={{
            background: activeColor
              ? activeColor.podcard
              : "linear-gradient(87deg, rgba(244, 244, 244, 0) 0%, rgb(244 244 244 / 18%) 55%, rgb(244 244 244) 100%) ",
          }}
        ></div>
      </div>
      <div className={`row h-100 ${style.cardContent}`}>
        <div className="col-md-6">
          <img
            src="/images/playIcon.svg"
            alt=""
            style={{ fontSize: "2em", cursor: "pointer", marginRight: "1em" }}
            onClick={() => {
              return;
            }}
            className="mb-3"
          />
          <div className="d-flex flex-column justify-content-between">
            <div className="mb-3">
              <h6>Source</h6>
              <p>{source.substr("0", "15")}</p>
            </div>
            <div className="mb-3">
              <h6>Category</h6>
              <p>{category.substr(0, 15)}</p>
            </div>
            <div>
              <h6>Type</h6>
              <div className="d-flex align-items-center">
                {/* <img src="/images/Radio.png" className="mr-2" alt="" /> */}
                <p className="mr-2">{type}</p>
                <p style={{ fontSize: "10px" }}>
                  {moment(published).fromNow()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <h5>
            {title.substr(0, 100)}
            {title.length > 100 ? "..." : ""}
          </h5>
          <div
            className="text-right"
            style={{ position: "absolute", right: 0 }}
          >
            <p
              style={{
                fontSize: "1.4em",
                margin: "-10px 0px -13px",
                fontWeight: "bold",
              }}
            >
              {clicked}
            </p>
            <span style={{ fontSize: "0.6em" }}>Clicked</span>
          </div>
          <div className={style.ActionButton}>
            <img src="/images/shareIcon.svg" alt="" />
            <img src="/images/heartlike.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={style.PlayCard}>
      <div className="d-flex align-items-start justify-content-between">
        <img
          src="/images/playIcon.svg"
          alt=""
          style={{ fontSize: "2em", cursor: "pointer", marginRight: "1em" }}
          onClick={() =>
            type == "shorts" ? Player.playShorts(data) : Player.playAudio(data)
          }
        />
        <h5>
          {title ? title.substr(0, 100) : ""}
          {title.length > 100 ? "..." : ""}
        </h5>
        <div className="text-right">
          <p
            style={{
              fontSize: "1.4em",
              margin: "-10px 0px -13px",
              fontWeight: "bold",
            }}
          >
            {clicked}
          </p>
          <span style={{ fontSize: "0.6em" }}>Clicked</span>
        </div>
      </div>
      <div className="row m-0 w-100">
        <div className="col-md-6 pl-0">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: " 72px" }}
          >
            <div>
              <h6>Source</h6>
              <p>{source ? source.substr("0", "20") : ""}</p>
            </div>
            <div>
              <h6>Type</h6>
              <div className="d-flex align-items-center">
                <img
                  src="/images/Feedly_Logo_gray.svg"
                  className="mr-2"
                  alt=""
                />
                <p className="mr-2">{type}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 pr-0">
          <div
            className="d-flex flex-column justify-content-between"
            style={{ height: " 72px" }}
          >
            <div>
              <h6>Category</h6>
              <p>{category}</p>
            </div>

            <div className={style.ActionButton}>
              <img
                onClick={() => addItemToProject(data)}
                src="/images/addIcon.svg"
                alt=""
              />
              <img src="/images/shareIcon.svg" alt="" />
              <img src="/images/heartlike.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, null)(PlayCard);
