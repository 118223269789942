import React from "react";
import styles from "../../style/interviewBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setInterviewActions } from "../../redux/actions/main";
import ImageInitial from "../ImageInitial";
import { setInterviewOptions } from "../../redux/actions/main";
import firebase from "../../firebase";

export default function InterviewBar() {
  const dispatch = useDispatch();
  const { interviewOptions } = useSelector((state) => state.main);
  const { postData } = useSelector((state) => state.main);
  const { user } = useSelector((state) => state.auth);
  const [recording, setRecording] = React.useState(false);

  const [onlineUsers, setOnlineUsers] = React.useState([]);
  React.useEffect(() => {
    setUidOfUser(postData.id, user);

    const onlineRef = firebase.database().ref(`/online/${postData.id}`);
    onlineRef.on("value", (snapshot) => {
      const onlineUsers = snapshot.val();
      const onlineUsersList = [];
      for (let id in onlineUsers) {
        onlineUsersList.push({ ...onlineUsers[id], id: id });
      }
      setOnlineUsers(onlineUsersList.map((item) => item.key));
    });
    return () => {
      setPresenceOffline();
    };
  }, []);
  const setUidOfUser = (roomId, newUser) => {
    const reference = firebase
      .database()
      .ref(`/online/${roomId}/${newUser._id}`);
    const online = {
      displayName: newUser.email,
      date: new Date().getTime(),
      key: newUser._id,
      email: newUser.email,
    };
    reference.set(online).then(() => {});

    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect configured"));
  };
  const setPresenceOffline = () => {
    firebase.database().ref(`/online/${postData.id}/${user._id}`).remove();
  };
  return (
    <div className={styles.barContainer}>
      <div className="d-flex ">
        <div
          className="ml-3 d-flex align-items-center"
          style={{ marginTop: "-20px", cursor: "pointer" }}
        >
          <img src="/images/dummyImage.png" alt="" />
          <div className="ml-2" style={{ width: "200px" }}>
            <p
              style={{
                color: "#38ef7d",
                fontSize: "10px",
                marginBottom: 0,
              }}
            >
              NFL 2022 Draft Prospects
            </p>
            <p
              style={{
                color: "#fff",
                fontSize: "10px",
                marginTop: "3px",
              }}
            >
              Macquarie Sports Radio
            </p>
          </div>
        </div>
        <div
          className={"d-flex flex-column align-items-center ml-5 pb-2"}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/images/micIcon.svg"
            alt=""
            style={{ filter: "none", width: "35px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "50px",
              marginTop: "-4px",
            }}
          >
            Mute Mic
          </p>
        </div>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ margin: "0 80px", cursor: "pointer" }}
        >
          <img
            src="/images/cameraIcon.svg"
            alt=""
            style={{ filter: "none", width: "50px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Hide Camera
          </p>
        </div>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/images/shareScreen.svg"
            alt=""
            style={{ filter: "none", width: "45px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Share Screen
          </p>
        </div>
      </div>
      <div className="d-flex" style={{ magrinTop: "-12px" }}>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (interviewOptions.globalRecording)
              dispatch(
                setInterviewOptions({
                  ...interviewOptions,
                  globalRecording: "stop",
                })
              );
            else
              dispatch(
                setInterviewOptions({
                  ...interviewOptions,
                  globalRecording: "start",
                })
              );
          }}
        >
          {interviewOptions.globalRecording == "start" ? (
            <>
              {" "}
              <img
                src="/images/stopRecordingButton.svg"
                alt=""
                style={{ width: "45px" }}
                className="mb-2"
              />
              <p
                className="text-center"
                style={{
                  color: "#38ef7d",
                  fontSize: "10px",
                  width: "100px",
                }}
              >
                Stop Recording
              </p>
            </>
          ) : (
            <>
              <img
                src="/images/recordButton.svg"
                alt=""
                style={{ filter: "invert(1)", width: "45px" }}
                className="mb-2"
              />
              <p
                className="text-center"
                style={{
                  color: "#38ef7d",
                  fontSize: "10px",
                  width: "100px",
                }}
              >
                Record Interview
              </p>
            </>
          )}
        </div>
      </div>
      <div className="d-flex ">
        <div
          className={"d-flex flex-column align-items-center ml-5 pb-2"}
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(setInterviewActions("invite"))}
        >
          <img
            src="/images/inviteIcon.svg"
            alt=""
            style={{ filter: "none", width: "35px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "50px",
            }}
          >
            Invite
          </p>
        </div>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ margin: "0 80px", cursor: "pointer" }}
        >
          <img
            src="/images/wavesIcon.svg"
            alt=""
            style={{ filter: "none", width: "50px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Recordings
          </p>
        </div>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/images/chatIcon.svg"
            alt=""
            style={{ filter: "none", width: "45px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Chat
          </p>
        </div>
        <div
          className={"d-flex align-items-center mx-5"}
          style={{ marginTop: "-22px" }}
        >
          {postData.members
            ? postData.members.map((item) => (
                <div className="mx-1">
                  <ImageInitial
                    fullname={item.fullname}
                    image={item.avatar}
                    active={onlineUsers.find(
                      (ite) => ite == item.member.user_id
                    )}
                  />
                </div>
              ))
            : ""}

          <img
            src="/images/moreIcon.svg"
            alt=""
            style={{
              borderRadius: "50%",
              filter: "none",
              width: "40px",
              border: "2px solid transparent",
              cursor: "pointer",
            }}
            className=" mx-1"
          />
        </div>
      </div>{" "}
    </div>
  );
}
