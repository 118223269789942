import React, { useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Button, message } from "antd";
import { Input, AutoComplete } from "antd";
import _ from "lodash";
import apiRequest from "../api/clientRequest";
import createStyle from "../style/createproject.module.css";
import {
  searchPodcastEpisodes,
  searchPodcastShows,
  setAddingSource,
  setInterviewActions,
} from "../redux/actions/main";
import Actions from "../redux/actions/news";
import { useEffect } from "react";

const mapState = (state) => ({
  addingSource: state.main.addSource,
  selectedFeed: state.news.selectedFeed,
  activeProject: state.projects.activeProject,
  teams: state.projects.projects.filter((item) => item.type == "team"),
  postData: state.main.postData,
  interviewAction: state.main.interviewAction,
});

const mapDispatch = (dispatch) => ({
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  setInterviewActions: (feed) => dispatch(setInterviewActions(feed)),
});
function MemberSearch(props) {
  const [searchVal, setSearchVal] = useState("");
  const [options, setOptions] = useState([]);
  let propsRef = useRef();

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const { setItems, selectedFeed } = props;

  const onSearchChange = async (val) => {
    let subProps = propsRef.current;

    if (subProps.setQuery) subProps.setQuery(val);
    if (
      subProps.addingSource == "Members" ||
      subProps.interviewAction == "invite"
    ) {
      const results = await apiRequest({
        url: `/users/search/${val}`,
        method: "GET",
      }).then((res) => res.data);
      setItems(results);
    } else if (
      subProps.addingContent == "Podcast Shows" ||
      (subProps.selectedFeed &&
        subProps.selectedFeed.label == "Select Podcast Shows")
    ) {
      if (!val) {
        subProps.setSelectedFeed({
          label: "Select Podcast Shows",
          feed: [],
          id: 1,
          type: "podcast",
        });
        return;
      }
      // props.setAddingSource("Podcast");
      subProps.setSelectedFeed({
        label: "Select Podcast Shows",
        feed: [],
        id: 1,
        type: "podcast",
      });
      let results = await searchPodcastShows(val);
      subProps.setSelectedFeed({
        label: "Select Podcast Shows",
        feed: results,
        id: 1,
        type: "podcast",
      });
    } else if (subProps.addingContent == "Podcast Episodes") {
      // props.setAddingSource("Podcast");
      subProps.setSelectedFeed({
        label: "Podcast Episodes",
        feed: [],
        id: 1,
        type: "podcast",
      });
      let results = await searchPodcastEpisodes(val);
      subProps.setSelectedFeed({
        label: "Podcast Episodes",
        feed: results,
        id: 1,
        type: "podcast",
      });
    }
  };

  const handleSearch = (value) => {
    let items = props.teams.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    items = items.map((item) => {
      return {
        value: item.title,
      };
    });
    setOptions(items);
  };

  const onSelect = (value) => {
    const foundItem = props.teams.find((item) => item.title == value);
    setItems(foundItem.members);
  };

  const onChangeDebounced = useCallback(_.debounce(onSearchChange, 3000), []);

  return (
    <div className="d-flex">
      {props.individualSearch == "Teams" ? (
        <Button
          style={{
            borderRadius: "15px",
            backgroundColor: "rgba(255, 255, 255, 0.43)",
          }}
          className="ml-4"
        >
          Add Entire Team
        </Button>
      ) : (
        ""
      )}

      {(props.selectedFeed &&
        props.selectedFeed.type !== "podcast" &&
        !props.collection) ||
      props.selectedAudience ? (
        <Button
          style={{
            borderRadius: "15px",
            backgroundColor: "rgba(255, 255, 255, 0.43)",
            textTransform: "capitalize",
          }}
          className="ml-4"
          onClick={() => props.onClickFeed()}
        >
          {props.activeProject.feeds.find(
            (item) => item.label == selectedFeed.label
          )
            ? `Remove from ${props.activeProject.type}`
            : `Add to ${props.activeProject.type}`}
        </Button>
      ) : (
        ""
      )}

      {props.showPublishBtn && !props.interviewAction ? (
        ""
      ) : (
        <>
          {props.interviewAction == "invite" ||
          (props.addingSource !== "Teams" &&
            props.addingSource !== "Upload Cover Image" &&
            props.activeProject.type !== "post") ? (
            <input
              type="text"
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
                onChangeDebounced(e.target.value);
              }}
              placeholder={`Search for ${
                props.interviewAction == "invite"
                  ? "Audio One Members to interview"
                  : props.addingContent
              }`}
              className={`${createStyle.searchInput}`}
              style={{
                border: "1px solid #fff",
                borderRadius: "10px",
                backgroundColor: "transparent",
                color: "#fff ",
                marginLeft: "3em",
                marginBottom: "0",
                width: "300px",
              }}
            />
          ) : props.addingSource == "Teams" ? (
            <AutoComplete
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
            >
              <Input
                size="large"
                placeholder={`Search for ${props.addingSource}`}
                className={`${createStyle.searchInput} w-100`}
                style={{
                  border: "1px solid #fff",
                  borderRadius: "10px",
                  width: "100%",
                  height: "34px",
                  backgroundColor: "transparent",
                  color: "#fff ",
                  marginLeft: "3em",
                  marginBottom: "0",
                  width: "412px",
                  fontSize: "0.9em",
                  padding: "6px 9px",
                }}
              />
            </AutoComplete>
          ) : (
            ""
          )}
        </>
      )}

      {props.interviewAction == "invite" ? (
        <h5
          className={`${createStyle.pubBtn} ${createStyle.activeTabBtn} text-center mb-0 d-flex justify-content-between align-items-center ml-3`}
          onClick={() => props.setInterviewActions("")}
          style={{
            minWidth: "80px",
            borderColor: "#38ef7d",
            color: "#38ef7d",
            textAlign: "center",
          }}
        >
          Finished
        </h5>
      ) : props.activeProject.type == "post" && props.showPublishBtn ? (
        <div
          className="d-flex"
          style={{
            position: "absolute",
            top: "0",
            right: "189px",
          }}
        >
          {props.postData.post_type == "STORY" ? (
            ""
          ) : (
            <h5
              className={`${createStyle.pubBtn} ${createStyle.activeTabBtn} text-center mb-0 d-flex justify-content-between align-items-center `}
              onClick={(event) => {
                if (!event.detail || event.detail == 1)
                  if (props.editing) {
                    if (props.postData.draft) props.createDraft();
                  } else props.createDraft();
              }}
              style={{
                minWidth: "150px",
                borderColor: "white",
                color: "white",
                opacity: props.editing ? (props.postData.draft ? 1 : 0.5) : 1,
              }}
            >
              <img
                src="/images/saveIcon.png"
                alt=""
                style={{ width: "15px", height: "15px" }}
              />
              SAVE DRAFT
            </h5>
          )}

          {props.postData.post_type === "INTERVIEW" ||
          props.postData.post_type === "STORY" ? (
            ""
          ) : (
            <h5
              className={`${createStyle.pubBtn} ${createStyle.activeTabBtn} text-center mb-0 d-flex justify-content-between align-items-center `}
              onClick={(event) => {
                if (!event.detail || event.detail == 1)
                  if (props.postData.post_type == "STORY")
                    console.log("is a story");
                  else props.onPreview();
              }}
              style={{
                minWidth: "120px",
                borderColor: "white",
                color: "white",
                opacity: props.postData.post_type == "STORY" ? 0.5 : 1,
              }}
            >
              <img
                src="/images/saveIcon.png"
                alt=""
                style={{ width: "15px", height: "15px" }}
              />
              PREVIEW
            </h5>
          )}
          <h5
            className={`${createStyle.pubBtn} ${createStyle.activeTabBtn} text-center mb-0 d-flex  justify-content-between align-items-center`}
            onClick={(event) => {
              if (!event.detail || event.detail == 1) {
                if (
                  props.postData.post_type == "STORY" &&
                  !props.postData.draft
                )
                  console.log("already published");
                else props.createPost();
              }
            }}
            style={{
              minWidth: "120px",
              borderColor: "white",
              color: "white",
              opacity:
                props.editing &&
                props.postData.post_type == "STORY" &&
                !props.postData.draft
                  ? 0.5
                  : 1,
            }}
          >
            <img
              src="/images/uploadIcon.png"
              alt=""
              className="mr-2"
              style={{ width: "15px", height: "15px" }}
            />
            {props.editing &&
            props.postData.post_type == "STORY" &&
            !props.postData.draft
              ? " PUBLISHED"
              : " PUBLISH"}
          </h5>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default connect(mapState, mapDispatch)(MemberSearch);
