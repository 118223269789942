import React, { Component } from "react";
import styles from "../style/loadingMeter.module.css";
import { connect } from "react-redux";
import {
  setMeter,
  setMeterProgress,
  setMeterTitle,
} from "../redux/actions/main";
var mapStateToProps = (state) => {
  return {
    meterVisible: state.main.meter,
    meterProgress: state.main.meterProgress,
    meterTitle: state.main.meterTitle,
    queue: state.site.queue,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setMeter: (items) => dispatch(setMeter(items)),
  setMeterProgress: (items) => dispatch(setMeterProgress(items)),
  setMeterTitle: (items) => dispatch(setMeterTitle(items)),
});

class LoadingMeter extends Component {
  render() {
    const { queue } = this.props;
    return (
      <div
        className={styles.container}
        style={{
          display: this.props.meterVisible ? "block" : "none",
          bottom: queue.items.length > 0 ? "170px" : "50px",
        }}
      >
        <div
          className="d-flex"
          style={{ padding: "2.6em", paddingTop: "2em", paddingBottom: "0" }}
        >
          <img
            src="/images/lockIcon.png"
            alt=""
            style={{ width: "35px", height: "15px" }}
          />
          <p className={styles.title}>{this.props.meterTitle}</p>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ padding: "0 2.6em" }}
        >
          <div className={styles.sliderBackground}>
            <span
              className={styles.slider}
              style={{ width: `${this.props.meterProgress}%` }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingMeter);
