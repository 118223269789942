import React, { Component } from "react";
import Navbar from "../components/Navbar/FrontNavbar";
import Home from "../pages/home";
import { Switch, Route } from "react-router-dom";
import signIn from "../pages/signIn";
import signUp from "../pages/signUp";
import emailSent from "../pages/emailSent";
import Onboarding from "../components/onboarding";

// test
export default class FrontLayout extends Component {
  render() {
    const match = this.props.match;
    return (
      <div className="new-body-wrapper">
        {/* <Navbar /> */}

        <div className="page-content">
          <img
            className="d-flex mx-auto"
            src="/images/Audio_One_Logo.svg"
            alt=""
          />

          <Switch>
            <Route path={`${match.url}email-sent`} component={emailSent} />
            <Route path={`${match.url}signin`} component={signIn} />
            <Route path={`${match.url}signup/:teamid?`} component={signUp} />
            <Route
              path={`${match.url}onboarding`}
              exact
              component={Onboarding}
            />
            <Route path={`${match.url}`} exact component={Home} />
          </Switch>
        </div>
      </div>
    );
  }
}
