import React, { useEffect, useState } from "react";
import style from "../../style/templatePicker.module.css";
import { Tabs } from "antd";
import { connect } from "react-redux";
import axios from "axios";
const { TabPane } = Tabs;

const mapState = (state) => ({
  mytemplates: state.projects.projects.filter(
    (item) => item.type == "template"
  ),
});

function TemplatePicker(props) {
  const [templates, setTemplates] = useState([]);
  const [tab, setTab] = useState("1");

  useEffect(() => {
    axios({
      url: "https://api.unlayer.com/v1/templates",
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(
          "JsTLM308D5i0Fyex8VRq3f8l8lyLq1xPRAXQqUFeG2rYfGQqv8bdT7t2X9feqacg:"
        )}`,
      },
    }).then(async (res) => {
      let temps = res.data.data;

      for (let i = 0; i < temps.length; i++) {
        await axios({
          url: "https://api.unlayer.com/v2/export/image",
          method: "POST",
          data: {
            displayMode: "email",
            design: temps[i].design,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa(
              "JsTLM308D5i0Fyex8VRq3f8l8lyLq1xPRAXQqUFeG2rYfGQqv8bdT7t2X9feqacg:"
            )}`,
          },
        }).then((response) => {
          temps[i]["image"] = response.data.data.url;
        });
      }
      setTemplates(temps);
    });
    return () => {};
  }, []);

  return (
    <div
      className={`${style.container} px-5 pb-5 pt-4`}
      style={{ backgroundColor: props.color }}
    >
      <div className="d-flex">
        <div
          onClick={() => setTab(1)}
          style={{ cursor: "pointer", fontWeight: tab == 1 ? "700" : "500" }}
        >
          <p>Audio One Templates</p>
        </div>
        <div
          onClick={() => setTab(2)}
          style={{ cursor: "pointer", fontWeight: tab == 2 ? "700" : "500" }}
          className={` ml-4`}
        >
          <p>My Templates</p>
        </div>
      </div>
      <div className={`${tab == 1 ? "d-flex" : "d-none"}`}>
        {templates.map((item) => (
          <div
            className={style.templateItem}
            onClick={() => props.onClick(item)}
          >
            <img src={item.image} className="h-100 w-100" alt="" />
          </div>
        ))}
      </div>
      <div className={`${tab == 2 ? "d-flex" : "d-none"}`}>
        {props.mytemplates.map((item) => (
          <div
            className={style.templateItem}
            onClick={() =>
              props.onClick({ ...item, design: JSON.parse(item.design) })
            }
          >
            <img src={item.image} className="h-100 w-100" alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default connect(mapState, null)(TemplatePicker);
