import env from "../api/environment";
import $ from "jquery";
import axios from "axios";
const player = function () {
  var obj = {
    player: document.getElementById("receiver").contentWindow,
    playCount: 0,
    clicked: false,
    postMessage(obj) {
      if (window.location.pathname.includes("campaign"))
        $("#receiver").addClass("popup");
      console.log(env.PLAYER_URL);
      this.player.postMessage(obj, env.PLAYER_URL);
    },
    toggleStation(mode, playType, data) {
      var obj = { type: "stationToggle", data, mode, playType };
      this.postMessage(obj);
    },
    updateRecentPlayed(recentPlayed) {
      var obj = {};
      obj.type = "updateRecentPlayed";
      obj.tracks = recentPlayed;
      this.player.postMessage(obj, env.PLAYER_URL);
    },
    async playNext(item, type, altPage = false) {
      if (!this.clicked) {
        this.clicked = true;
        if (type.toLowerCase() == "story") item = this.createStory(item);
        else if (type == "news") item = await this.createNewsAudio(item);
        else if (type == "shorts") item = await this.createShort(item);
        else if (type == "podcast") item = await this.createPodcast(item);

        var obj = { ...item, type: "playNext", altPage: true };
        this.clicked = false;

        this.postMessage(obj);
      }
    },
    updateRecentPlayed(recentPlayed) {
      var obj = {};
      obj.type = "updateRecentPlayed";
      obj.tracks = recentPlayed;
      this.player.postMessage(obj, env.PLAYER_URL);
    },
    changeTheme(theme) {
      var obj = {};
      obj.type = "changeTheme";
      obj.theme = theme;

      this.postMessage(obj);
    },
    async playQueue(item) {
      if (!this.clicked) {
        this.clicked = true;
        var obj = {};
        obj.data = item;
        obj.type = "playQueue";
        this.postMessage(obj);

        this.clicked = false;
      }
    },
    async playSpotifyList(item, action = false) {
      if (!this.clicked) {
        this.clicked = true;
        var obj = {};
        obj.tracks = item.tracks;
        obj.type = "spotifylist";
        obj.subtype = action;
        obj.token = window.localStorage.getItem("spotify_token");
        obj.refreshToken = window.localStorage.getItem("spotify_refresh");
        this.postMessage(obj);
        this.clicked = false;
      }
    },
    async playSpotifyTrack(item, type = "spotifyplay") {
      if (!this.clicked) {
        this.clicked = true;
        var obj = {};
        obj.link = item.url ? item.url : item.link;
        obj.sid = item.id;
        obj.image = item.image;
        obj.title = item.title;
        obj.duration = item.duration ? item.duration : item.length;
        obj.type = type;
        obj.subtype = "music";
        obj.tracktype = "spotify";
        obj.token = window.localStorage.getItem("spotify_token");
        obj.recent = item.recent ? item.recent : false;
        obj.refreshToken = window.localStorage.getItem("spotify_refresh");
        this.postMessage(obj);
        this.clicked = false;
      }
    },
    async addSpotifyTrack(item) {
      var obj = {};
      obj.link = item.url;
      obj.sid = item.id;
      obj.image = item.image;
      obj.title = item.title;
      obj.duration = item.duration;
      obj.type = "spotifyaddplay";
      obj.tracktype = "spotify";
      obj.token = window.localStorage.getItem("spotify_token");
      obj.recent = item.recent ? item.recent : false;
      this.postMessage(obj);
    },
    createPodcast(item) {
      var obj = {};
      obj.link = item.episode.link;
      obj.sid = item.episode.id;
      obj.title = `${item.episode.title.replace(/[^\w\s]/gi, "")} :  ${
        item.title
      }`;
      obj.image = item.episode.image;
      obj.length = item.episode.audioLength;
      obj.description = item.episode.description;
      obj.type = "newaudio";
      obj.tracktype = "podcast";
      obj.podcast = true;
      obj.recent = item.recent ? item.recent : false;
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false;
      obj.summary = item["summary"];
      obj.commentary = item.episode.commentary
        ? item.episode.commentary
        : false;
      return obj;
    },
    async playPodcast(item, playAction = false) {
      if (!this.clicked) {
        this.clicked = true;
        var obj = this.createPodcast(item);
        if (playAction == "playNow") {
          obj["type"] = "playNow";
          obj["altPage"] = true;
        }

        this.postMessage(obj);
        this.clicked = false;
      }
    },
    createShort(item) {
      var obj = {};
      obj.link = item.link;
      obj.sid = item.id;
      obj.title = `${item.title.replace(/[^\w\s]/gi, "")} : ${item.source}`;
      obj.image = item.image;
      obj.length = item.length;
      obj.description = item.description;
      obj.type = "newaudio";
      obj.tracktype = "shorts";
      obj.shorts = true;
      obj.recent = item.recent ? item.recent : false;
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false;
      obj.commentary = item.commentary ? item.commentary : false;
      return obj;
    },
    async playShorts(item, playAction = false) {
      if (!this.clicked) {
        this.clicked = true;
        var obj = this.createShort(item);
        if (playAction == "playNow") {
          obj["type"] = "playNow";
          obj["altPage"] = true;
        }

        this.postMessage(obj);
        this.clicked = false;
      }
    },
    async playRecentShorts(item) {
      var obj = {};
      obj.link = item.link;
      obj.sid = item.id;
      obj.title = `${item.title.replace(/[^\w\s]/gi, "")} : ${
        item.title.split(":")[1]
      }`;

      obj.image = item.image;
      obj.length = item.length;
      obj.description = item.description;
      obj.type = "newaudio";
      obj.tracktype = "shorts";
      obj.shorts = true;
      obj.recent = true;
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false;
      obj.commentary = item.commentary ? item.commentary : false;
      this.postMessage(obj);
    },
    async playRecentPodcast(item) {
      var obj = item;
      obj.sid = item.id;
      obj.type = "newaudio";
      obj.tracktype = "podcast";
      obj.recent = true;
      obj.podcast = true;
      obj.isCampaignPage = item.isCampaign ? item.isCampaign : false;
      obj.commentary = item.commentary ? item.commentary : false;
      this.postMessage(obj);
    },
    async updateOrder(order) {
      var obj = {
        type: "updateQueue",
        order: order,
      };
      this.player.postMessage(obj, env.PLAYER_URL);
    },
    async removeItem(item, podcastremove) {
      var obj = {
        type: "removeItem",
        track: item,
        podcastremove,
      };
      this.player.postMessage(obj, env.PLAYER_URL);
    },
    async appLoaded() {
      var obj = {
        type: "appLoaded",
      };
      this.player.postMessage(obj, env.PLAYER_URL);
    },
    async createNewsAudio(item, isPlaylist = false, isPost) {
      this.playCount += 1;
      var musicParam = "";
      if (this.playCount == 6) {
        this.playCount = 1;
        musicParam = "music";
      }

      const response = await axios
        .post(`${env.tts_url}/getArticle`, {
          article: { ...item, isPost },
          isPlaylist: isPlaylist,
          fyi: true,
          campaign: true,
        })
        .then((res) => {
          if (res.data.error) {
            return;
          }
          var data = res.data;
          var obj = {};

          obj.title = `${item.title} : ${item.source}`;
          obj.link = isPost
            ? isPost
            : `${env.tts_url}/getAudio/${data.request_id}/${musicParam}`;
          obj.sid = item.id;
          obj.image = data.image;
          obj.description = data.description;
          obj.recent = item.recent ? item.recent : false;
          obj.isCampaignPage = item.isCampaign ? item.isCampaign : false;
          obj.commentary = item.commentary ? item.commentary : false;
          obj.type = "newaudio";
          return obj;
        })
        .catch((err) => false);

      return response;
    },
    async playAudio(item, isPlaylist = false, isPost = false) {
      console.log("inside here play");

      // if (!this.clicked) {
      console.log("inside here after");

      this.clicked = true;
      var obj = await this.createNewsAudio(item, isPlaylist, isPost).catch(
        () => {
          this.clicked = false;
        }
      );
      if (isPlaylist == "playNow") {
        obj["type"] = "playNow";
      }
      this.postMessage({
        ...obj,
        isPost: isPost,
      });
      this.clicked = false;
      return true;
      // }
    },
    async playExtraAud(item, playNow = false) {
      if (!this.clicked) {
        this.clicked = true;
        var obj = item;
        obj["type"] = playNow ? playNow : "newaudio";

        console.log(obj, "play console");
        this.postMessage({
          ...obj,
        });
        this.clicked = false;

        return true;
      }
    },
    createStory(item) {
      var obj = {};
      let storyLines = item.story_lines
        ? item.story_lines
        : item.isPost && item.isPost.type
        ? item.isPost.storyLines
        : [];
      obj.title = item.title;
      obj.storyLines = storyLines;
      obj.sid = item.id;
      obj.image =
        storyLines.length > 0
          ? storyLines[0].image
            ? storyLines[0].image.url
              ? storyLines[0].image.url
              : storyLines[0].image
            : ""
          : "";
      obj.commentary = item.commentary ? item.commentary : false;
      obj.type = "newaudio";
      obj.subtype = "story";
      obj.recent = item.recent ? item.recent : false;

      return {
        ...obj,
        isPost: {
          type: "STORY",
          storyLines: storyLines.map((item) => {
            return {
              ...item,
              image: item.image
                ? item.image.url
                  ? item.image.url
                  : item.image
                : "",
            };
          }),
        },
      };
    },
    async playStory(item) {
      // if (!this.clicked) {
      this.clicked = true;
      var obj = this.createStory(item);
      console.log("inside here");
      this.postMessage(obj);
      this.clicked = false;

      return true;
      // }
    },
    async videoEnded(item) {
      var obj = {};
      obj.title = `${item.title} : ${item.source}`;
      obj.sid = item.id;
      obj.link = "";
      obj.image = item.image;
      obj.type = "videoEnded";
      obj.tracktype = "youtube";
      this.postMessage(obj);
      return true;
    },
    async setVideoTimer(remTime, duration) {
      var obj = {};
      obj.duration = duration;
      obj.remTime = remTime;
      obj.type = "videoTimer";
      obj.tracktype = "youtube";
      this.postMessage(obj);
      return true;
    },

    async playVideo(item, action) {
      var obj = {};
      obj.title = `${item.title} : ${item.source}`;
      obj.sid = item.id;
      obj.link = "";
      obj.image = item.image;
      obj.length = item.length;
      obj.type = action ? action : "playVideo";
      obj.tracktype = "youtube";
      obj.subtype = "video";
      this.postMessage(obj);

      return true;
    },
    async setVideoControl(item) {
      var obj = {};
      obj.videoControl = JSON.parse(JSON.stringify(item));
      obj.type = "videoControl";
      this.postMessage(obj);

      return true;
    },
    async setPlayerProgress(item) {
      var obj = {};
      obj.data = item;
      obj.type = "play_progress";

      console.log(obj);
      this.postMessage(obj);
      return true;
    },
    async playTeamBrand(item) {
      const announcer = `You're listening to AudioOne Radio. The following is brought to you by ${item.name}. ${item.description}.`;

      var obj = {
        title: `${item.name} : ${announcer}`,
        image: item.logo,
        description: item.description,
        announcer: announcer,
      };

      obj["type"] = "teamBrand";

      this.postMessage(obj);

      return true;
    },
  };

  return obj;
};

export default player;
