import React from "react";
import styles from "../style/commentaryCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import style from "../style/sidebarTabsContent.module.css";
import { tag } from "./sidebarTabs/components/feedtag.module.css";
import { uploadCommentary } from "../redux/actions/main";
import SITEACTIONS from "../redux/actions/site";
import apiRequest from "../api/clientRequest";
import { message } from "antd";

export default function CommentaryCard() {
  const [recording, setRecording] = React.useState(false);
  const [audioPlayer, setAudioPlayer] = React.useState(false);
  const [recordedData, setRecordedData] = React.useState(null);
  const [recorder, setRecorder] = React.useState(null);
  const [stream, setStream] = React.useState(null);
  const [audioPlaying, setAudioPlaying] = React.useState(false);
  const [commentaryExist, setCommentaryExist] = React.useState({
    exist: false,
  });
  const { queue } = useSelector((state) => state.site);

  const { commentary } = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (commentary.id) {
      setAudioPlaying(false);
      setRecordedData(false);
      setRecording(false);
      setCommentaryExist({ exist: false });

      apiRequest({
        url: `/campaigns/commentary/exist`,
        method: "POST",
        data: { commentaryId: commentary.id },
      })
        .then((res) => {
          if (res.data.exist) {
            setCommentaryExist(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [commentary]);
  const addToAmelia = async () => {
    const { description, type } = commentary;

    await apiRequest({
      url: "/campaigns/addamelia",
      method: "POST",
      data: {
        article: {
          ...commentary,
          description:
            commentary["description"] !== undefined ? description : "",
        },
      },
    })
      .then((res) => {
        message.success("Added to your Amelia Page");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const startRecorder = () => {
    setRecording(true);
    // startRecording();

    var AudioContext = window.AudioContext || window.webkitAudioContext;
    var audioContext = new AudioContext();

    var constraints = {
      audio: { echoCancellation: false },
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (str) {
        console.log("starting recording");

        var rec;
        var input;
        var gumStream;

        gumStream = str;
        input = audioContext.createMediaStreamSource(str);

        setStream(str);

        rec = new window.WebAudioRecorder(input, {
          workerDir: "/js/", // must end with slash
          encoding: "wav",
          numChannels: 1, //2 is the default, mp3 encoding supports only 2
          onEncoderLoading: function (rec, encoding) {
            // show "loading encoder..." display
            console.log("Loading " + encoding + " encoder...");
          },
          onEncoderLoaded: function (rec, encoding) {
            // hide "loading encoder..." display
          },
        });

        setRecorder(rec);

        rec.onComplete = function (rec, blob) {
          const fileName = `${uuidv4()}recording.wav`;

          const reader = new FileReader();
          reader.onload = async function (e) {
            const srcUrl = e.target.result;
            startRecordPlay(srcUrl);
            setRecording(false);
            setRecordedData({ commentary: srcUrl });

            await addToAmelia();
            await dispatch(
              uploadCommentary({
                content: blob,
                name: fileName,
                id: commentary.id,
                userId: user._id,
                email: user.email,
              })
            );
          };
          reader.readAsDataURL(blob);
        };

        rec.setOptions({
          timeLimit: 120,
          encodeAfterRecord: true,
          bufferSize: 16384,
        });

        //start the recording process
        rec.startRecording();
      })
      .catch(function (err) {});
  };

  const startRecordPlay = (srcUrl) => {
    setAudioPlaying(true);
    let audioNode = new Audio();
    // setAudioPlayer(audioNode);
    if (commentaryExist.exist) audioNode.src = commentaryExist.commentary;
    else audioNode.src = srcUrl ? srcUrl : recordedData.commentary;
    audioNode.load();
    audioNode.play();
    audioNode.addEventListener("ended", function () {
      setAudioPlayer(false);
    });
    setAudioPlayer(audioNode);
  };

  const stopRecorder = async () => {
    setRecording(false);
    stream.getAudioTracks()[0].stop();

    recorder.finishRecording();
  };

  const onPlayPlayer = () => {
    setAudioPlaying(true);
    if (recordedData) startRecordPlay();
  };

  const onPausePlayer = () => {
    if (audioPlayer) {
      setAudioPlaying(false);
      audioPlayer.pause();
    }
  };
  const onForward = () => {
    if (audioPlaying && audioPlayer) {
      audioPlayer.currentTime -= 10;
    }
  };
  const onBackword = () => {
    if (audioPlaying && audioPlayer) {
      audioPlayer.currentTime += 10;
    }
  };
  const onRemove = () => {
    if (recordedData) {
      setAudioPlaying(false);
      setRecordedData(null);
    } else if (commentaryExist) {
      console.log(commentary);
      apiRequest({
        url: `/campaigns/commentary/delete`,
        method: "post",
        data: { commentaryId: commentaryExist.id },
      })
        .then((res) => {
          message.success("Commentary Removed");
          setCommentaryExist({ exist: false });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return commentary.visible ? (
    <div
      style={{ bottom: queue.items.length > 0 ? "110px" : 0 }}
      className={`${style.voiceRecord} ${styles.shareCard} ${style.commentartContainer} ${styles.container}`}
    >
      <div className="d-flex ">
        <img src="/images/chatting.svg" alt="" />
        <p>Add Commentary</p>
        <img
          src="/images/images/closeCircleOutlined.svg"
          onClick={() =>
            dispatch(SITEACTIONS.setCommentary({ visible: false }))
          }
        />
      </div>
      <p>{commentary.title}</p>
      <div className="d-flex justify-content-center">
        <img src="/images/playerPrevious.svg" onClick={onBackword} />
        {audioPlaying ? (
          <img
            src="/images/pauseStraight.svg"
            className="mx-5"
            style={{ width: "40px" }}
            onClick={onPausePlayer}
          />
        ) : (
          <img
            src="/images/playIcon.svg"
            className="mx-5"
            style={{ width: "40px" }}
            onClick={onPlayPlayer}
          />
        )}
        <img src="/images/playerForward.svg" onClick={onForward} />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div
          className={`${tag} px-4 mx-5`}
          style={{
            backgroundColor: "transparent",
            opacity: recordedData || commentaryExist.exist ? 1 : 0,
            color: "white",
            borderColor: "white",
          }}
          onClick={onRemove}
        >
          Remove
        </div>
      </div>

      <div className="d-flex justify-content-center mx-5 mt-3">
        {/* {processing ? (
          <Spin size="large" />
        ) : ( */}
        <img
          onClick={() => {
            if (recording) {
              stopRecorder();
            } else {
              startRecorder();
            }
          }}
          src="/images/recordButton.svg"
          className={style.voiceRecordIcon}
          alt=""
        />
        {/* )} */}
      </div>
    </div>
  ) : (
    ""
  );
}
