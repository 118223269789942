import React, { useState } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { Input, AutoComplete } from "antd";
import RecentItem from "./RecentItem";
import { connect } from "react-redux";

const mapState = (state) => ({
  items: state.projects.projects,
});

function ToolsTab(props) {
  const [selectedTab, setSelectedTab] = useState("Project");
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const tabs = [
    {
      title: "Edit",
      subtabs: [
        { title: "Add Template", icon: "addTemplateIcon.svg", id: 1 },
        { title: "Add Text", icon: "addTextIcon.svg", id: 2 },
        { title: "Add Image", icon: "addImageIcon.svg", id: 3 },
        { title: "Add Divider", icon: "addDividerIcon.svg", id: 4 },
        { title: "Add Link", icon: "addLinkIcon.svg", id: 5 },
        { title: "Add Section", icon: "addSectionIcon.svg", id: 6 },
        { title: "Add Button", icon: "addButtonIcon.svg", id: 7 },
        { title: "Add Video", icon: "addVideoIcon.svg", id: 8 },
      ],
    },
    {
      title: "Extras",
      subtabs: [
        { title: "Text to Speech", icon: "addttsIcon.svg", id: 1 },
        { title: "Text to Speech", icon: "addttsIcon.svg", id: 2 },
      ],
    },
    {
      title: "Action",
      subtabs: [
        { title: "Send Campaign", icon: "sendCampaignIcon.svg", id: 1 },
        { title: "Preview Campaign", icon: "previewCampaignIcon.svg", id: 2 },
      ],
    },
  ];

  return (
    <div className={style.AddItem}>
      <div
        className={`${style.topSection} topSection`}
        style={{ zIndex: "9999999" }}
      >
        {tabs.map((item) => {
          return (
            <div style={{ marginBottom: "50px" }}>
              <h6 className="mb-0">{item.title}</h6>

              <div className="d-flex flex-wrap align-items-center">
                {item.subtabs.map((tab) => (
                  <div
                    className={style.actionBtn}
                    style={{ padding: 0, marginBottom: 0 }}
                  >
                    <button
                      onClick={() => setSelectedTab(tab.title)}
                      className={selectedTab == tab.title ? style.active : ""}
                      style={{ marginRight: 0, marginBottom: 0 }}
                    >
                      <img src={`/images/${tab.icon}`} alt="" />
                      <span style={{ fontSize: "10px", marginTop: "5px" }}>
                        {" "}
                        {tab.title}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapState, null)(ToolsTab);
