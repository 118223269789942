import React, { Component, useEffect } from "react";
import apiRequest from "./api/clientRequest";
import FrontLayout from "./containers/FrontLayout";
import { withRouter, Route, Switch } from "react-router-dom";
import DashboardContainer from "./containers/DashboardContainer";

import ACTIONS from "./redux/actions/auth";
import NEWSACTIONS from "./redux/actions/news";
import SITEACTIONS from "./redux/actions/site";
import { connect } from "react-redux";
import RestrictedRoute from "./components/auth/RestrictedRoute";
import LoadingMeter from "./components/LoadingMeter";
import { useSelector, useDispatch } from "react-redux";
import auth0 from "./components/auth/auth0";
import { useAuth } from "./components/auth/auth";
import { useHistory } from "react-router-dom";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    queue: state.site.queue,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
  setTags: (item) => dispatch(NEWSACTIONS.setTags(item)),
  setFeed: (item) => dispatch(NEWSACTIONS.setFeed(item)),
  setFeedlyUser: (item) => dispatch(NEWSACTIONS.setFeedlyUser(item)),
  setQueue: (item) => dispatch(SITEACTIONS.setQueue(item)),
  setPodcast: (item) => dispatch(NEWSACTIONS.setPodcast(item)),
  setTheme: (item) => dispatch(SITEACTIONS.setTheme(item)),
  setSubscribedItems: (items) =>
    dispatch(SITEACTIONS.setSubscribedItems(items)),
});

function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const getData = async (usr) => {
    const response = await apiRequest({
      url: `/users/me`,
      method: "get",
    });
    let newUser = {
      ...usr,
      ...response.data,
    };
    dispatch(ACTIONS.setUser(newUser));
  };

  useEffect(() => {
    if (auth.user) {
      getData(auth.user);
    }
  }, [auth]);

  useEffect(() => {
    console.log(user, "user is");
    if (user && user.email_verified)
      if (!user.onboarded) {
        dispatch(SITEACTIONS.setOnboarding(true));
        history.push("/onboarding");
      } else {
        console.log("inside here");
        history.push("/dashboard");
      }
  }, [user]);

  return (
    <div>
      <Switch>
        <RestrictedRoute path="/dashboard" component={DashboardContainer} />
        <Route path="/" component={FrontLayout} />
      </Switch>
      <LoadingMeter />
    </div>
  );
}

const withRoute = withRouter(App);
export default connect(mapStateToProps, mapDispatchToProps)(withRoute);
