import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import { connect } from "react-redux";
import { changeToCategoriesTab, setPostData } from "../../redux/actions/main";
import voices from "./voices.json";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import player from "../../data/player";

const mapState = (state) => ({
  postData: state.main.postData,
  queue: state.site.queue,
});

const mapDispatch = (dispatch) => ({
  setPostData: (feed) => dispatch(setPostData(feed)),
});
const Player = player();

function BackgroundSettings(props) {
  const [backgroundMusic, setBackgroundMusic] = React.useState(false);
  const [audioPlayer, setAudioPlayer] = React.useState(false);
  const { currentPlayed } = props.queue;

  React.useEffect(() => {
    if (!props.postData.selectedStory && props.postData.storyBackground)
      setBackgroundMusic(props.postData.storyBackground);
    else if (props.postData.background)
      setBackgroundMusic(props.postData.background);
    else setBackgroundMusic(false);
    let aud = new Audio();
    setAudioPlayer(aud);
  }, [props.postData]);

  let arr = [
    {
      name: "Funked Up",
      url: "https://storage.googleapis.com/audio_one_background_music/190_full_funk-d-up_0175.mp3",
      id: "funked1",
    },
    {
      name: "OMG!",
      url: "https://storage.googleapis.com/audio_one_background_music/190_instrumental1_omg_0219.mp3",
      id: "omg1",
    },
    {
      name: "Trends & Styles",
      url: "https://storage.googleapis.com/audio_one_background_music/216_full_trends-styles_0136.mp3",
      id: "trends1",
    },
    {
      name: "Shut Up and Take My Money",
      url: "https://storage.googleapis.com/audio_one_background_music/232_full_shutup-and-take-my-money_0151.mp3",
      id: "shut1",
    },
    {
      name: "Glitter Bomb",
      url: "https://storage.googleapis.com/audio_one_background_music/258_full_glitter-bomb_0142.mp3",
      id: "glitter1",
    },
    {
      name: "Party Vibes",
      url: "https://storage.googleapis.com/audio_one_background_music/364_full_party-vibes_0148.mp3",
      id: "party1",
    },
    {
      name: "Just Rolling",
      url: "https://storage.googleapis.com/audio_one_background_music/422_full_just-rolling_0138.mp3",
      id: "just1",
    },
    {
      name: "Out Of Control",
      url: "https://storage.googleapis.com/audio_one_background_music/530_full_out-of-control_0156.mp3",
      id: "out1",
    },
  ];

  return (
    <div className=" px-3 mt-5" style={{ paddingTop: "4em" }}>
      <h4
        className="mb-0"
        style={{
          fontSize: 17,
          fontWeight: "bold",
          position: "absolute",
          top: "230px",
          zIndex: 999999,
        }}
      >
        {props.postData.post_type == "STORY"
          ? props.postData.selectedStory == null
            ? "Continuous Background Music"
            : "Chapter Background Music"
          : "Background Music"}
      </h4>
      <div className="pt-4">
        {arr.map((item, index) => (
          <div
            className={`${style.reverbContainer} d-flex align-items-center p-2`}
          >
            <p>{index + 1}</p>
            <div
              className=" ml-2 d-flex justify-content-between align-items-center"
              style={{ flex: 1 }}
            >
              <div>
                <span className="d-block">Sound {item.name}</span>
                <img
                  src="/images/backgroundMusic.svg"
                  alt=""
                  style={{ width: "15px", filter: "invert(1)" }}
                />{" "}
              </div>
              <div className="text-center">
                {" "}
                <div>
                  {currentPlayed.id == item.id && currentPlayed.playing ? (
                    <img
                      src={"/images/images/pause.png"}
                      className="mb-2"
                      style={{ width: "25px", cursor: "pointer" }}
                      onClick={() => {
                        Player.playQueue(item);
                      }}
                    />
                  ) : (
                    <img
                      src={"/images/playIcon.svg"}
                      className="mb-2"
                      style={{ filter: "invert(1)", cursor: "pointer" }}
                      onClick={() => {
                        if (
                          currentPlayed.id == item.id &&
                          !currentPlayed.playing
                        )
                          Player.playQueue(item);
                        else
                          Player.playExtraAud({
                            title: item.name,
                            link: item.url,
                            source: "",
                            subtype: "extra",
                            sid: item.id,
                            preview: true,
                            recent: true,
                            image:
                              "https://storage.googleapis.com/audio_one_background_music/music.png",
                          });
                      }}
                    />
                  )}
                </div>
                <Switch
                  className={`${style.switch} ${
                    backgroundMusic == `${item.name}` ? style.activeSwitch : ""
                  }`}
                  checkedChildren={<span>On</span>}
                  unCheckedChildren={<span>Off</span>}
                  checked={backgroundMusic == `${item.name}`}
                  onChange={() => {
                    if (
                      props.postData.selectedStory &&
                      !props.postData.storyBackground
                    ) {
                      if (backgroundMusic == `${item.name}`) {
                        props.setPostData({
                          ...props.postData,
                          background: false,
                          backgroundOff:
                            props.postData.post_type == "STORY" ? true : false,
                          clickedStory: false,
                        });
                        // if (props.postData.post_type !== "STORY")
                        setBackgroundMusic(false);
                      } else {
                        setBackgroundMusic(`${item.name}`);
                        props.setPostData({
                          ...props.postData,
                          background: item.name,
                          backgroundOff: false,
                          clickedStory: false,
                        });
                      }
                    } else if (!props.postData.selectedStory) {
                      console.log("inside here");
                      console.log(backgroundMusic == `${item.name}`);
                      if (backgroundMusic == `${item.name}`) {
                        if (props.postData.post_type !== "STORY")
                          props.setPostData({
                            ...props.postData,
                            storyBackground: "",
                            background: "",
                          });
                        else
                          props.setPostData({
                            ...props.postData,
                            storyBackground: "",
                          });
                        setBackgroundMusic(false);
                      } else {
                        setBackgroundMusic(`${item.name}`);

                        if (props.postData.post_type !== "STORY")
                          props.setPostData({
                            ...props.postData,
                            background: item.name,
                          });
                        else
                          props.setPostData({
                            ...props.postData,
                            storyBackground: item.name,
                          });
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(BackgroundSettings);
