import React, { useState } from "react";
import style from "../style/PlayCard.module.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import player from "../data/player";
import moment from "moment";
import { connect } from "react-redux";
import { addItemToProject } from "../redux/actions/project";
import { FeedTags, active } from "../style/LatestFeed.module.css";
import { Popover, message } from "antd";
import LinkModal from "./LinkModal";
import Actions from "../redux/actions/site";
import { setAddItem } from "../redux/actions/main";

const dispatchState = (dispatch) => ({
  addItemToProject: (data) => dispatch(addItemToProject(data)),
  setCommentary: (item) => dispatch(Actions.setCommentary(item)),
  setShare: (item) => dispatch(Actions.setShare(item)),
  setAddItem: (item) => dispatch(setAddItem(item)),
});

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  activeColor: state.projects.activeColor,
  projectItems:
    state.projects.activeProject &&
    state.projects.activeProject.type !== "campaign"
      ? state.projects.activeProject.shortsfeed.map((item) =>
          JSON.parse(item.short)
        )
      : [],
});

const Player = player();
function PodcastCard({
  data,
  addItemToProject,
  activeColor,
  projectView,
  projectItems,
  onClick,
  related,
  setCommentary,
  setShare,
}) {
  const [visible, setVisible] = useState(false);

  const {
    title,
    description,
    category,
    id,
    source,
    type,
    image,
    link,
    published,
    episode,
  } = data;
  return (
    <div className={style.PlayCard}>
      <LinkModal
        loadedArticle={data}
        visible={visible}
        setVisibility={setVisible}
        related={related}
      />
      <div className={style.backImage}>
        <img src={image} alt="" className="w-100 h-100" />
        <div
          className={style.imageGradient}
          style={{
            background: activeColor
              ? activeColor.podcard
              : "linear-gradient(87deg, rgba(244, 244, 244, 0) 0%, rgb(244 244 244 / 18%) 55%, rgb(244 244 244) 100%) ",
          }}
        ></div>
      </div>
      <div className={`row h-100 ${style.cardContent}`}>
        <div className="col-md-6">
          <div className="d-flex flex-column justify-content-between">
            <div>
              <h6>Type</h6>
              <div className="d-flex align-items-center ">
                <img
                  src="/images/Radio.png"
                  className="mr-2"
                  alt=""
                  style={{ width: "15px", height: "10px" }}
                />
                <p className="mr-2">Shorts</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <h5>
            {title.substr(0, 100)}
            {title.length > 100 ? "..." : ""}
          </h5>
          <div className={style.ActionButton}>
            {" "}
            <img
              src="/images/chatting.svg"
              onClick={() => setCommentary({ ...data, visible: true })}
              alt=""
            />
            {/* {projectView ? (
              <div className={FeedTags}>
                <a
                  style={{ margin: 0, width: "100px" }}
                  href="#"
                  className={`${
                    projectItems.find((item) => item.id == title) ? active : ""
                  } px-2`}
                  onClick={() => onClick(data)}
                >
                  <div className="d-flex justify-content-between">
                    {projectItems.find((item) => item.id == title) ? (
                      <img
                        src="/images/minusIcon.png"
                        style={{
                          width: "4px",
                          transform: "rotate(90deg)",
                          height: "14px",
                          marginTop: "0.2em",
                        }}
                        alt=""
                      />
                    ) : (
                      <img
                        src="/images/addIcon.svg"
                        style={{ width: "10px" }}
                        alt=""
                      />
                    )}
                    <span>
                      {projectItems.find((item) => item.id == title)
                        ? "Subscribed"
                        : "Subscribe"}
                    </span>
                  </div>
                </a>
              </div>
            ) : (
              <> */}
            <img
              src="/images/addIcon.svg"
              alt=""
              onClick={() => setAddItem({ ...data, visible: true })}
            />
            <img
              src="/images/shareIcon.svg"
              onClick={() => setShare({ ...data, visible: true })}
              alt=""
            />
            {/* <img src="/images/heartlike.svg" alt="" /> */}
            {/* </> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(PodcastCard);
