import ACTIONS from "../actions/site";
import _ from "lodash";
import player from "../../data/player";
import apiRequest from "../../api/clientRequest";
// const uuid = require("uuid/v1");

const playerInstance = player();
var defaultState = {
  onboarding: null,
  musicList: [],
  socket: null,
  tab: 0,
  queue: { items: [], currentPlayed: { id: "" } },
  audioHistory: [],
  spotifyLog: { fetch: false, token: "" },
  theme: "color-theme",
  queueTab: 1,
  savedItems: [],
  sharedItems: [],
  subscribedItems: [],
  themeSelector: false,
  currentlyPlaying: { id: false },
  recentlyPlayed: [],
  recentTrigger: false,
  completedPodcast: [],
  shorts: [],
  meterVisible: false,
  meterTitle: "",
  meterProgress: "0",
  recentUsers: [],
  commentary: { visible: false },
  share: { visible: false },
  showPodcastSubscribed: false,
  todayItems: [],
  playerProgress: { waveposition: 0 },
};

var siteReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.SET_PLAYER_PROGRESS: {
      let newState = Object.assign({}, state);
      newState.playerProgress = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_TODAY_ITEMS: {
      let newState = Object.assign({}, state);
      newState.todayItems = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_SHOW_SUBSCRIBED: {
      let newState = Object.assign({}, state);
      newState.showPodcastSubscribed = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_SHARE: {
      let newState = Object.assign({}, state);
      newState.share = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_COMMENTARY: {
      let newState = Object.assign({}, state);
      newState.commentary = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_AUDIO_HISTORY: {
      let newState = Object.assign({}, state);
      newState.audioHistory = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_RECENT_USERS: {
      let newState = Object.assign({}, state);
      newState.recentUsers = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_METER_TITLE: {
      let newState = Object.assign({}, state);
      newState.meterTitle = action.payload;
      return newState;
    }
    case ACTIONS.Types.SET_METER_VISIBLE: {
      let newState = Object.assign({}, state);
      newState.meterVisible = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_METER_PROGRESS: {
      let newState = Object.assign({}, state);
      newState.meterProgress = action.payload;
      return newState;
    }
    case ACTIONS.Types.setOnboarding: {
      let newState = Object.assign({}, state);
      newState.onboarding = action.payload;
      return newState;
    }
    case ACTIONS.Types.setMusicList: {
      let newState = Object.assign({}, state);
      newState.musicList = action.payload;
      return newState;
    }
    case ACTIONS.Types.setSocket: {
      let newState = Object.assign({}, state);
      newState.socket = action.payload;
      return newState;
    }

    case ACTIONS.Types.setTab: {
      let newState = Object.assign({}, state);
      newState.tab = action.payload;
      newState.searchVal = "";
      return newState;
    }

    case ACTIONS.Types.setQueue: {
      let newState = Object.assign({}, state);
      newState.queue = action.payload;
      if (
        newState.recentlyPlayed.length !== action.payload.recentPlayed.length &&
        action.payload.recentPlayed.length > 0 &&
        newState.recentTrigger
      )
        apiRequest({
          url: "/news/recentPlayed",
          method: "POST",
          data: { tracks: action.payload.recentPlayed },
        });

      newState.recentlyPlayed = action.payload.recentPlayed;
      return newState;
    }

    case ACTIONS.Types.spotifyFetchList: {
      let newState = Object.assign({}, state);
      newState.spotifyLog = action.payload;
      return newState;
    }

    case ACTIONS.Types.setInstantSearch: {
      let newState = Object.assign({}, state);
      newState.instantSearch = action.payload;
      return newState;
    }

    case ACTIONS.Types.setTheme: {
      let newState = Object.assign({}, state);
      newState.theme = action.payload;
      window.localStorage.setItem("theme", action.payload);
      playerInstance.changeTheme(action.payload);
      return newState;
    }

    case ACTIONS.Types.setQueueTab: {
      let newState = Object.assign({}, state);
      newState.queueTab = action.payload;
      return newState;
    }
    case ACTIONS.Types.setSavedItems: {
      let newState = Object.assign({}, state);
      newState.savedItems = action.payload;
      return newState;
    }

    case ACTIONS.Types.setSharedItems: {
      let newState = Object.assign({}, state);
      newState.sharedItems = action.payload;
      return newState;
    }

    case ACTIONS.Types.setSubscribedItems: {
      let newState = Object.assign({}, state);
      newState.subscribedItems = action.payload;
      return newState;
    }

    case ACTIONS.Types.setThemeSelector: {
      let newState = Object.assign({}, state);
      newState.themeSelector = action.payload;
      return newState;
    }

    case ACTIONS.Types.SET_RECENT_PLAYED: {
      let newState = Object.assign({}, state);
      newState.recentlyPlayed = action.payload;
      newState.recentTrigger = true;
      playerInstance.updateRecentPlayed(action.payload);
      return newState;
    }

    case ACTIONS.Types.UPDATE_RECENT_PLAYED: {
      let newState = Object.assign({}, state);
      const item = action.payload;
      const newRecent = newState.recentlyPlayed.map((ite) => {
        if (ite.id == item.id) item.completed = false;
        return ite;
      });
      playerInstance.updateRecentPlayed(newRecent);
      newState.recentlyPlayed = newRecent;
      apiRequest({
        url: "/news/recentPlayed",
        method: "POST",
        data: { tracks: newRecent },
      });

      return newState;
    }

    case ACTIONS.Types.SET_SHORTS: {
      let newState = Object.assign({}, state);
      newState.shorts = action.payload.filter((item) => {
        if (item.shorts.length > 0) return true;
        else return false;
      });
      return newState;
    }

    default:
      return state;
  }
};
export default siteReducer;
