import Types from "../constants/campaign";
import apiRequest from "../../api/clientRequest";

export const addEmailToCampaign = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.ADD_CAMPAIGN,
    payload: data,
  });
};

export const sendCampaign = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SEND_CAMPAIGN,
    payload: data,
  });
};

// export const buildCampaign = (data) => (dispatch, getState) => {
//   dispatch({
//     type: Types.BUILD_CAMPAIGN,
//     payload: data,
//   });
// };
