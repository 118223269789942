import React from "react";
import style from "../../style/PlayCard.module.css";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";

export default function MemberCard({ data, tab, clicked, opened }) {
  const { timestamp, recipient, name } = data;

  return (
    <div className={`${style.PlayCard} ${style.contactCard}`}>
      <div className="d-flex w-100  justify-content-between">
        <h5 className={style.contactName}>{name}</h5>
        <div className="text-right">
          <p
            style={{
              fontSize: "1.4em",
              margin: "-10px 0px -13px",
              fontWeight: "bold",
            }}
          >
            {clicked}
          </p>
          <span style={{ fontSize: "0.6em", textTransform: "uppercase" }}>
            Clicked
          </span>
        </div>
      </div>
      <div>
        <h6>Email Address</h6>
        <p>{recipient}</p>
      </div>
      <div>
        <h6>Company Name</h6>
        <p>Audio One</p>
      </div>

      <div className="d-flex flex-row justify-content-between w-100 mt-3">
        <div className={style.ActionButton}>
          <EyeOutlined style={{ fontSize: "1.5em", cursor: "pointer" }} />
          <img src="/images/heartlike.svg" alt="" className="ml-3" />
        </div>
        <div>
          <p
            style={{
              fontSize: "1.4em",
              margin: "-10px 0px -4px",
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            {opened}
          </p>
          <h6 style={{ fontSize: "0.6em", textTransform: "uppercase" }}>
            Opened
          </h6>
        </div>
      </div>
    </div>
  );
}
