import React, { useState, useEffect } from "react";
import createStyle from "../../style/createproject.module.css";
import stylestab from "../../style/sidebarTabsContent.module.css";
import ProjectCard from "../project/ProjectCard";
import style from "../../style/ProjectDetail.module.css";
import { connect } from "react-redux";
import { updateProject } from "../../redux/actions/project";
import Actions from "../../redux/actions/news";
import {
  changeToCategoriesTab,
  setAddingSource,
  setPostData,
  setInterviewActions,
} from "../../redux/actions/main";
import _ from "lodash";
import PostDetailTab from "./PostDetailTab";
import axios from "axios";
import environment from "../../api/environment";
import StrapiRequest from "../../api/strapiRequest";
import FeedTag from "../sidebarTabs/components/FeedTag";
import moment from "moment";
import { db } from "../../firebase";

var mapStateToProps = (state) => {
  return {
    todayNews: state.news.todayNews,
    selectedFeed: state.news.selectedFeed,
    feedlyUser: state.news.feedlyUser,
    teamSearch: state.main.memberSearch,
    individualSearch: state.main.individualSearch,
    teams: state.projects.projects.filter((item) => item.type == "team"),
    user: state.auth.user,
    activeProject: state.projects.activeProject,
    addSource: state.main.addSource,
    postData: state.main.postData,
  };
};

const mapDispatch = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  setInterviewActions: (feed) => dispatch(setInterviewActions(feed)),
});

function ProjectDetail(props) {
  const postTypes = [
    { id: 1, name: "STANDARD" },
    { id: 3, name: "REC0RD" },
    // { id: 2, name: "STORY" },
    // { id: 4, name: "INTERVIEW" },
    // { id: 5, name: "LIVE" },
  ];

  const specialPostTypes = [
    { id: 1, name: "INTRO" },
    { id: 2, name: "OUTRO" },
    { id: 3, name: "AD" },
    { id: 4, name: "ANNOUNCEMENT" },
    { id: 5, name: "EVENT" },
    { id: 6, name: "PRODUCT" },
    { id: 7, name: "QOUTE" },
  ];

  const [addSources, setAddSources] = useState(false);
  const [posts, setPosts] = useState([]);
  const [showTitle, setShowTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedPostType, setSelectedPostType] = useState(postTypes[0].name);

  useEffect(() => {
    props.changeToCategoriesTab("Recent");
    fetchPosts();
  }, [props.activeProject]);

  useEffect(() => {
    props.setAddingSource("News");
    props.setInterviewActions(null);
  }, []);

  const fetchPosts = async () => {
    setPosts([]);

    let posts = await StrapiRequest({
      url: `posts?post_id=${props.activeProject._id}&_sort=_id:DESC`,
      method: "get",
    })
      .then((res) => {
        let temp = res.data.map((item) => {
          return {
            ...item,
            link: `${environment.api.URL}/post/postBody/${item._id}`,
            type: "news",
            image: item.image ? item.image.url : "",
          };
        });
        // setPosts(temp);

        return temp;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });

    let stories = await StrapiRequest({
      url: `stories?post_id=${props.activeProject._id}&_sort=_id:DESC`,
      method: "get",
    })
      .then((res) => {
        let temp = res.data.map((item) => {
          return {
            ...item,
            link: `${environment.api.URL}/post/postBody/${item._id}`,
            type: "news",
            image: item.image ? item.image.url : "",
            post_type: "STORY",
            story_lines: item.story_lines
              .sort((a, b) => a.order - b.order)
              .map((item) => {
                return { ...item, image: item.image ? item.image.url : "" };
              }),
          };
        });
        return temp;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });

    let interviews = [];

    await db
      .collection("interview")
      .where("user_id", "==", props.user._id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let itemData = doc.data();
          interviews.push({
            ...itemData,
            type: "news",
            post_type: "INTERVIEW",
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    for (let i = 0; i < interviews.length; i++) {
      let members = [];
      await db
        .collection("interview-members")
        .where("interview", "==", interviews[i].id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let itemData = doc.data();
            members.push(itemData);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      interviews[i]["members"] = members;
    }

    let tempPosts = [...stories, ...posts, ...interviews];
    tempPosts = tempPosts.sort((a, b) => {
      return (
        moment(b.published_at).format("X") - moment(a.published_at).format("X")
      );
    });

    setPosts(tempPosts);
  };
  useEffect(() => {
    if (props.postData.post_type) setSelectedPostType(props.postData.post_type);
    console.log(props.postData.editing);
    if (props.postData.editing == "edit") {
      setShowTitle(true);
      setTitle(props.postData.title);
    } else if (props.postData.editing == "none") {
      setShowTitle(false);
      setTitle("");
    }
  }, [props.postData]);

  return (
    <div className={createStyle.maninContainer}>
      <div style={{ position: "relative" }} className="d-flex">
        <ProjectCard
          data={props.project}
          onEdit={props.onEdit}
          onSelect={() => {
            props.setAddingSource("News");
            setAddSources(false);
          }}
          onPreview={() => {
            props.setAddingSource("News");
            setAddSources(false);
          }}
          count={posts.length}
        />
        <div
          className={createStyle.createContainer}
          style={{ paddingTop: "2em" }}
        >
          <div
            style={{ marginTop: "4em" }}
            className="d-flex flex-column align-items-center"
          >
            <button
              className={`${createStyle.addSourcesBtn} mb-2`}
              onClick={() => {
                props.setAddingSource("ADD PODCAST");
                props.changeToCategoriesTab("EDIT PODCAST");
                props.setPostData({
                  title: "",
                  categories: [],
                  editing: "none",
                  voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
                  post_stype: "STANDARD",
                });
                setSelectedPostType(postTypes[0].name);
                props.setPostData({
                  title: "",
                  categories: [],
                  editing: "none",
                  voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
                });
              }}
            >
              <img src="/images/plusIcon.svg" alt="" />
            </button>
            <span>Add New Podcast</span>
          </div>

          <div
            className={createStyle.inputContainer}
            style={{ width: "460px" }}
          >
            <div className={style.Description}>
              {props.addSource == "ADD PODCAST" ||
              props.addSource == "EDIT PODCAST" ? (
                <div className="px-2">
                  {showTitle ? (
                    <div>
                      <h6 style={{ fontSize: "0.6em", color: "white" }}>
                        PODCAST TITLE
                      </h6>
                      <h1
                        style={{
                          fontSize: "27px",
                          lineHeight: "32px",
                          maxHeight: "92px",
                          overflow: "hidden",
                        }}
                      >
                        {title.substr(0, 90)}
                        {title.length > 90 ? "..." : ""}
                      </h1>
                    </div>
                  ) : (
                    <div>
                      <small style={{ fontSize: "0.6em", color: "white" }}>
                        PODCAST TITLE
                      </small>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className={`${stylestab.searchInput} w-100 mb-0 mr-3`}
                          placeholder={`Add Podcast Title`}
                          value={title}
                          onKeyUp={(e) => {
                            if (e.key == "Enter") setShowTitle(true);
                          }}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            props.setPostData({
                              ...props.postData,
                              title: e.target.value,
                              editing: false,
                            });
                          }}
                        />
                        {/* <img
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowTitle(true)}
                          src="/images/disketteIcon.png"
                          alt=""
                        /> */}
                      </div>
                    </div>
                  )}
                  <div className="d-flex w-100 justify-content-between">
                    {showTitle ? (
                      <img
                        style={{ cursor: "pointer", filter: "invert(1)" }}
                        src="/images/pencilIcon.svg"
                        alt=""
                        onClick={() => setShowTitle(false)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-5">
                    <h6
                      style={{ textTransform: "uppercase", color: "white" }}
                      className="mb-2"
                    >
                      PODCAST Type
                    </h6>

                    <div className="d-flex">
                      {postTypes.map((item) => (
                        <FeedTag
                          onClick={(id) => {
                            setSelectedPostType(item.name);
                            props.setPostData({
                              ...props.postData,
                              post_type: item.name,
                            });
                          }}
                          isSelected={selectedPostType == item.name}
                          data={{ id: item.id, label: item.name }}
                        />
                      ))}
                    </div>
                  </div>
                  {/* <div className="mt-3">
                    <h6
                      style={{ textTransform: "uppercase", color: "white" }}
                      className="mb-2"
                    >
                      PODCAST SPECIAL
                    </h6>

                    <div className="d-flex">
                      {specialPostTypes.map((item) => (
                        <FeedTag
                          onClick={(id) => {
                            setSelectedPostType(item.name);
                            props.setPostData({
                              ...props.postData,
                              post_type: item.name,
                            });
                          }}
                          isSelected={selectedPostType == item.name}
                          data={{ id: item.id, label: item.name }}
                        />
                      ))}
                    </div>
                  </div> */}
                </div>
              ) : (
                <>
                  <h6 style={{ textTransform: "uppercase" }}>
                    PODCAST SET DESCRIPTION
                  </h6>
                  <p>{props.project.description}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <PostDetailTab
          posts={posts}
          fetchPosts={fetchPosts}
          selectedPostType={selectedPostType}
          setTitle={setTitle}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(ProjectDetail);
