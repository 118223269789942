import React, { Component } from "react";
import NewButton from "../components/NewButton";

import { connect } from "react-redux";
import ACTIONS from "../redux/actions/site";
import AUTHACTIONS from "../redux/actions/auth";

var mapStateToProps = (state) => {
  return {
    onboarding: state.site.onboarding,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setOnboarding: (item) => dispatch(ACTIONS.setOnboarding(item)),
  setUser: (item) => dispatch(AUTHACTIONS.setUser(item)),
  setLinkAccountCreds: (item) =>
    dispatch(AUTHACTIONS.setLinkAccountCreds(item)),
});
class home extends Component {
  render() {
    return (
      <div className="container ch-pad text-center">
        <section className="session d-flex align-items-center justify-content-center">
          <NewButton
            onClick={() => this.props.history.push("/signup")}
            className={"mb-3"}
            text={"Sign Up"}
          />
          <NewButton
            onClick={() => this.props.history.push("/signin")}
            text={"Sign In"}
          />
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(home);
