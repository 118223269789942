import React, { Component } from "react";
import Upload from "antd/lib/upload";
import Icon from "antd/lib/icon";
import message from "antd/lib/message";
import apiRequest from "../../api/clientRequest";
import styles from "../../style/onboarding.module.css";
import NewButton from "../NewButton";

export default class Step3 extends Component {
  state = { name: "", bio: "", avatar: "", showedImage: "" };

  onInputChange(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  showImage(file) {
    var fr = new FileReader();
    var self = this;
    // when image is loaded, set the src of the image where you want to display it
    fr.onload = function (e) {
      self.setState({
        showedImage: this.result,
      });
    };
    fr.readAsDataURL(file);
  }

  render() {
    const { bindSubmitForm, submitHandle } = this.props;

    return (
      <section className="session " style={{ marginTop: "6em" }}>
        <h1>Setup Your Profile</h1>

        <div className="newFormContainer mb-5" style={{ cursor: "pointer" }}>
          <img
            src={
              this.state.showedImage
                ? this.state.showedImage
                : "/images/userImage.png"
            }
            style={{ borderRadius: "50%", width: "60px", height: "60px" }}
            alt=""
            className="mb-3"
          />
          <div
            className="formField"
            style={{
              width: "326px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <label
              className="text-center"
              htmlFor=""
              style={{ cursor: "pointer" }}
            >
              Change Avatar
            </label>
            <input
              style={{
                opacity: 0,
                position: "absolute",
                left: 0,
                height: "130px",
                top: "-90px",
              }}
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  this.showImage(e.target.files[0]);
                  this.setState({ avatar: e.target.files[0] });
                }
              }}
            />
          </div>
        </div>

        <div className="newFormContainer mb-5">
          <div className="formField" style={{ width: "326px" }}>
            <label htmlFor="">Your Name</label>
            <input
              type="text"
              className="black-outline-input"
              value={this.state.name}
              onChange={(e) => this.onInputChange("name", e)}
              placeholder="Type your full name"
            />
          </div>
          <div className="formField mt-4" style={{ width: "326px" }}>
            <label htmlFor="">Bio</label>
            <textarea
              type="text"
              className="black-outline-input"
              value={this.state.bio}
              onChange={(e) => this.onInputChange("bio", e)}
              placeholder="Some Tagline"
              rows={7}
              style={{ height: "auto" }}
            />
          </div>
        </div>

        <NewButton text={"Continue"} onClick={() => submitHandle(this.state)} />
      </section>
    );
  }
}
