import React, { useState, useEffect, useRef } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { updateProject, editProject } from "../../redux/actions/project";
import _ from "lodash";
import createStyle from "../../style/createproject.module.css";
import MemberContainer from "../Teams/MembersContainer";
import MemberSearch from "../MemberSearch";
import { setAddingSource } from "../../redux/actions/main";
import EditTemplate from "./EditTemplate";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  user: state.auth.user,
  addSource: state.main.addSource,
});

const dispatchState = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  editProject: (data) => dispatch(editProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function TemplateDetailTab(props) {
  const [mainTab, setMainTab] = useState("TEMPLATE");
  const [items, setItems] = useState([]);
  const tabMains = ["TEMPLATE"];
  const { addingContent } = props;
  const emailEditor = useRef(null);

  if (props.addSource == "Members" || props.addSource == "Teams")
    tabMains.push("Members");

  // useEffect(() => {
  //   if (!buildCampaign) {
  //     //   if (emailEditor.current) emailEditor.current.click();
  //   } else {
  //     setMainTab("TEMPLATE");
  //   }
  // }, [buildCampaign]);

  useEffect(() => {
    if (props.addSource !== "News") {
      if (props.addSource && props.addSource == "Members") {
        setMainTab("Members");
      }
    } else {
      setMainTab("TEMPLATE");
    }
  }, [props.addSource]);

  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <div className="d-flex align-items-center">
          {tabMains.map((item) => (
            <h5
              className={`${
                mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
              } text-center mb-0 `}
              style={{
                cursor: "pointer",
                textTransform: "uppercase",
                minWidth: "150px",
                padding: "6px 11px",
                borderRadius: "10px",
                fontSize: "0.95em",
              }}
              onClick={() => setMainTab(item)}
            >
              {item}
            </h5>
          ))}

          <MemberSearch
            setItems={setItems}
            addingContent={addingContent}
            onClickFeed={(feed = false) => {
              //   const tempFeed = feed ? feed : selectedFeed;
              //   props.updateProject({ ...tempFeed, type: activeProject.type });
              setMainTab("CONTACTS");
            }}
          />
        </div>
      </div>
      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {mainTab == "Members" ? (
          <MemberContainer editing={true} searchResults={items} />
        ) : (
          <EditTemplate btnRef={emailEditor} />
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(TemplateDetailTab);
