import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { Input } from "antd";
import RecentItem from "./RecentItem";
import FeedTag from "./components/FeedTag";
import { connect } from "react-redux";
import Actions from "../../redux/actions/news";
import SITEACTIONS from "../../redux/actions/site";
import apiRequest from "../../api/clientRequest";
import PodcastTags from "./podcastags.json";
import ShortsTags from "./shortstags.json";
import moment from "moment";

const mapState = (state) => ({
  tags: state.news.tags,
  feedlyUser: state.news.feedlyUser,
  activeProject: state.projects.activeProject,
  addingSource: state.main.addSource,
  boards: state.news.boards,
  selectedFeed: state.news.selectedFeed,
  showPodcastSubscribed: state.site.showPodcastSubscribed,
});

const mapDispatch = (dispatch) => ({
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  setShowPodcastSubscribed: (feed) =>
    dispatch(SITEACTIONS.setShowPodcastSubscribed(feed)),
});

function FeedTab(props) {
  const [tab, setTab] = React.useState("Categories");
  const [search, setSearch] = React.useState("");

  let tempItems = [];
  if (props.addingSource == "News Categories" || props.addingSource == "News")
    tempItems = props.tags;
  else if (props.addingSource == "News Boards") tempItems = props.boards;
  else if (props.addingSource == "Shorts")
    tempItems = ShortsTags.map((item, index) => {
      return { label: item, id: index };
    });
  else tempItems = PodcastTags;
  if (search) {
    tempItems = tempItems.filter((item) =>
      item.label.toLowerCase().includes(search)
    );
  }

  return (
    <div className={style.AddItem}>
      <div className={`${style.topSection} topSection`}>
        <div className="px-3" style={{ height: "40px" }}>
          <input
            type="text"
            className={`${style.searchInput} w-100`}
            placeholder={`Search for ${props.addingSource}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className={style.actionBtn}>
          <button
            className={!props.showPodcastSubscribed ? style.active : ""}
            onClick={() => props.setShowPodcastSubscribed(false)}
          >
            <img src="/images/blockIcon.png" alt="" />
            <span>Search for Categories</span>
          </button>
          {props.addingSource == "Podcast" ? (
            <>
              <button
                className={
                  props.showPodcastSubscribed == "subscribed"
                    ? style.active
                    : ""
                }
                onClick={() => {
                  if (props.showPodcastSubscribed == "subscribed")
                    props.setShowPodcastSubscribed(false);
                  else props.setShowPodcastSubscribed("subscribed");
                }}
              >
                <img
                  src="/images/subscribe.svg"
                  style={{ filter: "none", width: "28px" }}
                  alt=""
                />
                <span>Show Subscribed</span>
              </button>
              <button
                className={
                  props.showPodcastSubscribed == "preferred" ? style.active : ""
                }
                onClick={() => {
                  if (props.showPodcastSubscribed == "preferred")
                    props.setShowPodcastSubscribed(false);
                  else props.setShowPodcastSubscribed("preferred");
                }}
              >
                <img
                  src="/images/check.svg"
                  style={{ filter: "none", width: "28px" }}
                  alt=""
                />
                <span>Show Preferred</span>
              </button>
            </>
          ) : (
            <>
              <button>
                <img src="/images/newsIcon.png" alt="" />
                <span>Search News in Categories</span>
              </button>
              <button>
                <img src="/images/googleIcon.png" alt="" />
                <span>Search News in Google</span>
              </button>
            </>
          )}
        </div>
      </div>

      <div
        className=" px-3"
        style={{ overflow: "auto", paddingBottom: "6em", paddingTop: "16em" }}
      >
        <h4
          className="mb-4"
          style={{
            fontSize: 17,
            fontWeight: "bold",
            position: "absolute",
            top: "200px",
            zIndex: 999999,
          }}
        >
          Categories
        </h4>
        <div className="d-flex flex-wrap">
          {props.addingSource == "News Categories" ||
          props.addingSource == "News"
            ? tempItems.map((item) => (
                <FeedTag
                  isSelected={
                    props.activeProject
                      ? props.activeProject.feeds.find(
                          (ite) => ite.id == item.id
                        )
                      : props.selectedFeed
                      ? props.selectedFeed.label == item.label
                        ? true
                        : false
                      : false
                  }
                  onClick={(id) => {
                    console.log(props.feedlyUser.token);
                    console.log(item);
                    if (props.feedlyUser.token) {
                      props.setSelectedFeed({
                        ...item,
                        feed: [],
                        type: "news",
                      });
                      apiRequest({
                        url: `/feedly/articles/${item.label}`,
                        method: "POST",
                        data: { feedlyUser: props.feedlyUser },
                      }).then((res) => {
                        props.setSelectedFeed({
                          ...item,
                          feed: res.data.articles,
                          type: "news",
                        });
                      });
                    } else
                      props.setSelectedFeed({
                        ...item,
                      });
                  }}
                  data={item}
                />
              ))
            : props.addingSource == "News Boards"
            ? tempItems.map((item) => (
                <FeedTag
                  isSelected={
                    props.activeProject
                      ? props.activeProject.feeds.find(
                          (ite) => ite.id == item.id
                        )
                      : props.selectedFeed
                      ? props.selectedFeed.label == item.label
                        ? true
                        : false
                      : false
                  }
                  onClick={(id) => {
                    props.setSelectedFeed({
                      ...item,
                      feed: [],
                      subtype: "board",
                      type: "news",
                    });
                    apiRequest({
                      url: `/feedly/board`,
                      method: "POST",
                      data: { feedlyUser: props.feedlyUser, board: item },
                    }).then((res) => {
                      props.setSelectedFeed({
                        ...item,
                        feed: res.data.articles,
                        subtype: "board",
                        type: "news",
                      });
                    });
                  }}
                  data={item}
                />
              ))
            : props.addingSource == "Shorts"
            ? tempItems.map((item) => (
                <FeedTag
                  isSelected={
                    props.activeProject
                      ? props.activeProject.feeds.find(
                          (ite) => ite.id == item.id
                        )
                      : props.selectedFeed
                      ? props.selectedFeed.label == item.label
                        ? true
                        : false
                      : false
                  }
                  onClick={(id) => {
                    props.setSelectedFeed({
                      ...item,
                      label: `${item.label}`,
                      type: "shorts",
                      feed: [],
                    });
                    apiRequest({
                      url: `/today/shorts`,
                      method: "POST",
                      data: {
                        shortsCategory: item.label,
                      },
                    }).then((res) => {
                      props.setSelectedFeed({
                        ...item,
                        label: `${item.label}`,
                        type: "shorts",
                        feed: res.data,
                      });
                    });
                  }}
                  data={item}
                />
              ))
            : tempItems.map((item) => (
                <FeedTag
                  isSelected={
                    props.activeProject
                      ? props.activeProject.feeds.find(
                          (ite) => ite.id == item.id
                        )
                      : props.selectedFeed
                      ? props.selectedFeed.label.includes(item.label)
                        ? true
                        : false
                      : false
                  }
                  onClick={(id) => {
                    props.setSelectedFeed({
                      ...item,
                      label: `${item.label} Podcast Shows`,
                      type: "podcast",
                      feed: [],
                    });
                    apiRequest({
                      url: `/podcast/genre`,
                      method: "POST",
                      data: {
                        podcastCategory: item,
                      },
                    }).then((res) => {
                      if (window.location.pathname.includes("/project"))
                        var podcasts = res.data;
                      else
                        var podcasts = res.data
                          .filter((item) => {
                            if (
                              moment().diff(moment(item.published), "hours") <=
                              24
                            )
                              return true;
                          })
                          .sort((a, b) => b.published - a.published);

                      props.setSelectedFeed({
                        ...item,
                        label: `${item.label} Podcast Shows`,
                        type: "podcast",
                        feed: podcasts,
                      });
                    });
                  }}
                  data={item}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(FeedTab);
