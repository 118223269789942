import React from "react";
import { connect } from "react-redux";
import style from "../../style/tabs.module.css";
import AllCampaign from "../campaign/AllCampaign";
var mapStateToProps = (state) => {
  return {
    projects: state.projects.projects.filter((item) => item.type == "campaign"),
  };
};

function CampaignTab(props) {
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <AllCampaign projects={props.projects} type="brand" />
    </div>
  );
}

export default connect(mapStateToProps, null)(CampaignTab);
