import React, { useState } from "react";
import createStyle from "../../style/createproject.module.css";
import ProjectCard from "../project/ProjectCard";
import style from "../../style/ProjectDetail.module.css";
import { connect } from "react-redux";
import { updateProject, editProject } from "../../redux/actions/project";
import AudienceDetailTab from "./AudienceDetailTab";
import AddAudienceCard from "./AddAudienceCard";
import { setAddingSource } from "../../redux/actions/main";
var mapStateToProps = (state) => {
  return {
    activeProject: state.projects.activeProject,
    teamSearch: state.main.memberSearch,
    teams: state.projects.projects.filter((item) => item.type == "team"),
    individualSearch: state.main.individualSearch,
    user: state.auth.user,
  };
};

const mapDispatch = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function AudienceDetail(props) {
  const [editing, setEditing] = useState(false);
  const { project } = props;
  return (
    <div>
      <AddAudienceCard />
      <div style={{ position: "relative" }} className="d-flex">
        <ProjectCard
          data={{ ...project, feeds: project.contacts }}
          onEdit={props.onEdit}
          onSelect={() => {
            props.setAddingSource("News");
          }}
          onPreview={() => {
            props.setAddingSource("News");
          }}
        />
        <div className="d-flex flex-column">
          <div
            className={createStyle.createContainer}
            style={{ paddingTop: "2em", paddingLeft: "2em" }}
          >
            <div
              style={{ marginTop: "3.5em", width: "160px" }}
              className="d-flex flex-column align-items-center"
            >
              <button
                className={createStyle.addSourcesBtn}
                onClick={() => {
                  // props.setAudienceCard({ visible: true });
                  props.setAddingSource("addAudience");
                }}
              >
                <img src="/images/plusIcon.svg" alt="" />
              </button>
              <span className="mt-2">Add Contacts</span>
            </div>

            <div
              className={createStyle.inputContainer}
              style={{ marginLeft: "5em", marginTop: "-2em" }}
            >
              <div className={style.Description}>
                <h6>AUDIENCE DESCRIPTION</h6>
                <p>{props.project.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AudienceDetailTab
        addingContent={editing}
        setAddingContent={setEditing}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(AudienceDetail);
