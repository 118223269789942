import React from "react";
import styles from "../style/commentaryCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import style from "../style/sidebarTabsContent.module.css";
import { tag } from "./sidebarTabs/components/feedtag.module.css";
import {
  setShareSidebar,
  setMeter,
  setMeterProgress,
  setMeterTitle,
} from "../redux/actions/main";
import { addItemToProject } from "../redux/actions/project";

import SITEACTIONS from "../redux/actions/site";
import apiRequest from "../api/clientRequest";
import { message, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import sidebarstyle from "../style/sidebarTabsContent.module.css";
import LinkModal from "./LinkModal";

export default function ShareCard() {
  const [loading, setLoading] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState("");
  const [modalVisible, setModalVisible] = React.useState(false);
  const [resultLink, setResultLink] = React.useState(false);
  const { commentary } = useSelector((state) => state.site);
  const { share } = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);
  const { feedlyUser } = useSelector((state) => state.news);
  const { shareSidebar } = useSelector((state) => state.main);
  const { socket } = useSelector((state) => state.site);
  const { activeProject } = useSelector((state) => state.projects);
  const { queue } = useSelector((state) => state.site);
  const { createdAudio } = useSelector((state) => state.projects);

  const activeProjectRef = React.useRef(activeProject);
  const selectedSource = React.useRef(selectedType);
  const sidebarVoiceRef = React.useRef(shareSidebar);
  const shareArticleRef = React.useRef(share);
  const relatedArticles = React.useRef([]);

  React.useEffect(() => {
    activeProjectRef.current = activeProject;
  }, [activeProject]);

  React.useEffect(() => {
    sidebarVoiceRef.current = shareSidebar;
  }, [shareSidebar]);

  React.useEffect(() => {
    shareArticleRef.current = share;
  }, [share]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    socket.on("completedTTS", (data) => {
      console.log(data, "completed tts");
      if (data.progress == 100) {
        if (selectedSource.current == "amelia")
          addToAmelia(data.speechUrl, true);
        else if (selectedSource.current == "wordpress")
          addToWordpress(data.speechUrl, true);
        else shareByEmail(data.results, true);
      } else {
        dispatch(setMeterTitle(data.progressTitle));
        dispatch(setMeterProgress(data.progress));
      }
    });

    return () => {
      socket.off("completedTTS");
    };
  }, []);

  const shareByEmail = async (articles = [], completed = false) => {
    selectedSource.current = "link";

    if (completed) {
      if (articles.length > 0) {
        dispatch(setMeterTitle("Link Being Created"));
        let newArticles = relatedArticles.current.map((item) => {
          let foundArticle = articles.find((ite) => ite.id == item.id);
          if (foundArticle)
            return {
              ...item,
              sourceUrl: foundArticle.speechUrl,
              proccessed: true,
            };
          else {
            return {};
          }
        });

        console.log(newArticles);

        await apiRequest({
          url: "/email/share/article/link",
          method: "POST",
          data: {
            related: newArticles,
            userId: user._id,
            userName: user.username,
            firstArticleId: shareArticleRef.current.id,
          },
        }).then(async (res) => {
          if (res.data.success) {
            await axios
              .post(
                "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDeuM4cwVCPAzurlaz_8avDSJ77IY-6pls",
                {
                  dynamicLinkInfo: {
                    domainUriPrefix: "https://audioone.link",
                    link: res.data.link,
                  },
                }
              )
              .then((response) => {
                dispatch(setMeterTitle("Link Creation Completed"));
                dispatch(setMeterProgress(90));
                setLoading(false);
                setResultLink(response.data.shortLink);
                setModalVisible(true);
              })
              .catch((err) => {
                console.log(err.response);
              });
          } else {
            message.error(res.data.error);
          }
        });

        setTimeout(() => {
          dispatch(setMeter(false));
          dispatch(setMeterTitle(""));
          dispatch(setMeterProgress(0));
        }, 1500);
      }
    } else {
      const articles = await apiRequest({
        url: "/email/related-media",
        method: "POST",
        data: {
          related: false,
          type: share.type,
          userId: user._id,
          userName: user.username,
          feedlyUser: feedlyUser,
          date: moment().format("X"),
          ...share,
          description: share["description"],
        },
      })
        .then((res) => {
          return res.data.related;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });

      if (articles.length > 0) {
        let tempArticles = articles.map((item) => {
          const existAudio = createdAudio.find((ite) => ite.item_id == item.id);

          return {
            ...item,
            title: item.title,
            voice: sidebarVoiceRef.current.visible
              ? sidebarVoiceRef.current.voice.fullname
              : "",
            html: item["description"],
            background: sidebarVoiceRef.current.background,
            speechUrl: existAudio ? existAudio.speechUrl : false,
          };
        });
        relatedArticles.current = articles;
        socket.emit("createPodcastMultiple", tempArticles);
      }
    }
  };

  const addToWordpress = async (speechUrl = false, completed = false) => {
    selectedSource.current = "wordpress";
    if (completed) {
      dispatch(setMeterTitle("Being Added To Wordpress Site"));
      await apiRequest({
        url: "/projects/sendToWordpress",
        method: "POST",
        data: {
          article: {
            ...shareArticleRef.current,
            description: shareArticleRef.current["description"],
            sourceUrl: speechUrl,

            proccessed: true,
            published: moment().format("X"),
          },
          voice: sidebarVoiceRef.current.visible
            ? sidebarVoiceRef.current.voice.fullname
            : "",
          date: moment().format("X"),
        },
      })
        .then((res) => {
          dispatch(setMeterTitle("Added To Wordpress Site"));
          dispatch(setMeterProgress(90));
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        setLoading(false);
        dispatch(setMeter(false));
        dispatch(setMeterTitle(""));
        dispatch(setMeterProgress(0));
      }, 1500);
    } else {
      socket.emit("createNews", {
        background: sidebarVoiceRef.current.background,
        article: shareArticleRef.current,
        voice: sidebarVoiceRef.current.visible
          ? sidebarVoiceRef.current.voice.fullname
          : "",
      });
    }
  };

  const addToAmelia = async (speechUrl = false, completed = false) => {
    selectedSource.current = "amelia";
    if (completed) {
      dispatch(setMeterTitle("Being Added To Profile"));
      await apiRequest({
        url: "/campaigns/addamelia",
        method: "POST",
        data: {
          article: {
            ...shareArticleRef.current,
            description: shareArticleRef.current["description"],
            sourceUrl: speechUrl,
            proccessed: true,
          },
          voice: sidebarVoiceRef.current.visible
            ? sidebarVoiceRef.current.voice.fullname
            : "",
          date: moment().format("X"),
        },
      })
        .then((res) => {
          dispatch(setMeterTitle("Added To Profile Page"));
          dispatch(setMeterProgress(90));
        })
        .catch((err) => {
          console.log(err);
        });

      if (activeProjectRef.current)
        await dispatch(
          addItemToProject({
            ...shareArticleRef.current,
            project: activeProjectRef.current,
          })
        );

      setTimeout(() => {
        setLoading(false);
        dispatch(setMeter(false));
        dispatch(setMeterTitle(""));
        dispatch(setMeterProgress(0));
      }, 1500);
    } else {
      console.log(shareArticleRef.current["description"]);
      socket.emit("createNews", {
        background: sidebarVoiceRef.current.background,
        article: shareArticleRef.current,
        voice: sidebarVoiceRef.current.visible
          ? sidebarVoiceRef.current.voice.fullname
          : "",
      });
    }
  };

  const onPerformOperation = () => {
    dispatch(setMeter(true));
    dispatch(setMeterTitle("Processing"));
    dispatch(setMeterProgress(10));
    setLoading(true);

    if (selectedType == "link") {
      if (shareArticleRef.current.type == "post") {
        relatedArticles.current = [shareArticleRef.current];
        shareByEmail([shareArticleRef.current], true);
      } else shareByEmail();
    } else if (selectedType == "amelia")
      addToAmelia(
        shareArticleRef.current.type == "podcast"
          ? shareArticleRef.current.link
          : shareArticleRef.current.type == "post"
          ? shareArticleRef.current.speechUrl
          : false,
        shareArticleRef.current.type == "podcast" ||
          shareArticleRef.current.type == "post"
          ? true
          : false
      );
  };

  console.log(share);

  return share.visible ? (
    <div
      style={{ right: "45.5%", bottom: queue.items.length > 0 ? "110px" : 0 }}
      className={`${style.voiceRecord} ${styles.shareCard} ${style.commentartContainer} ${styles.container}`}
    >
      <LinkModal
        loadedArticle={share}
        visible={modalVisible}
        setVisibility={setModalVisible}
        loadedLink={resultLink}
      />
      <div className="d-flex ">
        <img
          src="/images/shareGreenIcon.svg"
          style={{ filter: "none" }}
          alt=""
        />
        <p className="mb-0">Share</p>
        <img
          src="/images/images/closeCircleOutlined.svg"
          onClick={() => {
            dispatch(SITEACTIONS.setShare({ visible: false }));
            dispatch(setShareSidebar({ visible: false }));
            setSelectedType("");
          }}
        />
      </div>
      <p>{share.title}</p>
      <div
        className={` ${sidebarstyle.popupCard} d-flex justify-content-center`}
      >
        <div className={`${sidebarstyle.actionBtn} px-0`}>
          <button
            className={sidebarstyle.active}
            onClick={() => {
              setSelectedType("link");
              dispatch(
                setShareSidebar({
                  visible: true,
                  type: shareArticleRef.current.type,
                })
              );
            }}
            style={{
              borderColor: selectedType == "link" ? "#38ef7d" : "transparent",
            }}
          >
            <img
              src="/images/createLinkIcon.svg"
              alt=""
              style={{ filter: "none" }}
            />
            <span>Create Link</span>
          </button>

          {share.post_type ? (
            ""
          ) : (
            <button
              className={sidebarstyle.active}
              onClick={() => {
                setSelectedType("amelia");
                dispatch(setShareSidebar({ visible: true }));
              }}
              style={{
                borderColor:
                  selectedType == "amelia" ? "#38ef7d" : "transparent",
              }}
            >
              <img
                src="/images/ameliaIcon.svg"
                alt=""
                style={{ filter: "brightness(2)" }}
              />
              <span>Add To Profile</span>
            </button>
          )}

          <button
            className={sidebarstyle.active}
            onClick={() => {
              // setSelectedType("campaign");
            }}
            style={{
              borderColor:
                selectedType == "campaign" ? "#38ef7d" : "transparent",
              opacity: "0.4",
              cursor: "disabled",
            }}
          >
            <img
              src="/images/campaignIcon.svg"
              style={{ filter: "invert(1)" }}
              alt=""
            />
            <span>Add To Campaign</span>
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-0">
        {loading ? (
          <Spin size="large" />
        ) : (
          <div
            className={`${tag} px-4 mx-5`}
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderColor: "white",
            }}
            onClick={() => onPerformOperation()}
          >
            Share
          </div>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}
