import React from "react";
import style from "../../style/LatestFeed.module.css";
import OpenedAud from "./OpenedAud";
import _ from "lodash";

function AudienceStat(props) {
  const { stats } = props.data;
  const renderItems = (data) => {
    let items = _.uniqBy(data, "recipient");
    return items.map((item) => {
      return (
        <div className="mb-3 py-0 pr-0">
          <OpenedAud
            data={item}
            tab={props.tab}
            clicked={
              data.filter(
                (ite) =>
                  ite.recipient == item.recipient && ite.event == "clicked"
              ).length
            }
            opened={
              data.filter(
                (ite) =>
                  ite.recipient == item.recipient && ite.event == "opened"
              ).length
            }
          />
        </div>
      );
    });
  };

  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <h5
          className="mb-0"
          style={{ textTransform: "uppercase", fontSize: "1.05em" }}
        >
          AUDIENCE WHO {props.tab}
        </h5>
      </div>

      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {props.data.stats ? renderItems(props.data.stats) : ""}
      </div>
    </div>
  );
}

export default AudienceStat;
