import React, { useState, useEffect, useRef } from "react";
import createStyle from "../../style/createproject.module.css";
import ProjectCard from "../project/ProjectCard";
import style from "../../style/ProjectDetail.module.css";
import { connect } from "react-redux";
import _ from "lodash";
import { setActiveProject } from "../../redux/actions/project";
import CollectionDetailTab from "./CollectionDetailTab";
import {
  setAddingSource,
  changeToCategoriesTab,
} from "../../redux/actions/main";
var mapStateToProps = (state) => {
  return {
    selectedFeed: state.news.selectedFeed,
    teamSearch: state.main.memberSearch,
    teams: state.projects.projects.filter((item) => item.type == "team"),
    individualSearch: state.main.individualSearch,
    user: state.auth.user,
    collectionAddItem: state.main.collectionAddItem,
  };
};

const mapDispatch = (dispatch) => ({
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function CollectionDetail(props) {
  const [feeds, setFeeds] = useState([]);
  const [feedProjects, setFeedProjects] = useState([]);

  const { project } = props;

  const setInitialFeed = async () => {
    setFeeds(
      project.addedItems.map((item) => {
        return JSON.parse(item.article);
      })
    );
    const {
      projects,
      brands,
      campaigns,
      teams,
      collections,
      templates,
      audiences,
    } = project;
    setFeedProjects([
      ...projects.map((item) => {
        return { ...item, type: "project", color: JSON.parse(item.color) };
      }),
      ...brands.map((item) => {
        return { ...item, type: "brand", color: JSON.parse(item.color) };
      }),
      ...campaigns.map((item) => {
        return { ...item, type: "campaign", color: JSON.parse(item.color) };
      }),
      ...teams.map((item) => {
        return { ...item, type: "team", color: JSON.parse(item.color) };
      }),
      ...collections.map((item) => {
        return { ...item, type: "collection", color: JSON.parse(item.color) };
      }),
      ...templates.map((item) => {
        return { ...item, type: "template", color: JSON.parse(item.color) };
      }),
      ...audiences.map((item) => {
        return { ...item, type: "audience", color: JSON.parse(item.color) };
      }),
    ]);
  };

  useEffect(() => {
    setInitialFeed();
  }, [project]);

  // const { selectedFeed } = props;
  return (
    <div>
      <div style={{ position: "relative" }} className="d-flex">
        <ProjectCard
          data={props.project}
          onEdit={props.onEdit}
          onSelect={() => {
            props.setAddingSource("News");
          }}
          onPreview={() => {
            props.setAddingSource("News");
          }}
        />
        <div className="d-flex flex-column">
          <div
            className={createStyle.createContainer}
            style={{ paddingTop: "2em", paddingLeft: "2em" }}
          >
            <div
              style={{ marginTop: "4em", width: "160px" }}
              className="d-flex flex-column align-items-center"
            >
              <button
                className={createStyle.addSourcesBtn}
                onClick={() => {
                  props.setAddingSource("Members");
                }}
              >
                <img src="/images/plusIcon.svg" alt="" />
              </button>
              <span className="mt-2">Collaborate</span>
            </div>

            <div
              className={createStyle.inputContainer}
              style={{ marginLeft: "5em", marginTop: "-2em" }}
            >
              <div className={style.Description}>
                <h6>COLLECTION DESCRIPTION</h6>
                <p>{props.project.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CollectionDetailTab feedProjects={feedProjects} feeds={feeds} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(CollectionDetail);
