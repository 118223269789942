import React from "react";
import contentstyle from "../style/contentpage.module.css";
import { useSelector } from "react-redux";
import NewQueueCard from "./NewQueueCard";
import Player from "../data/player";
import Slider from "@material-ui/core/Slider";
import style from "../style/progressbar.module.css";

export default function QueueContainer({ queueExpanded, setQueueExpanded }) {
  const queue = useSelector((state) => state.site.queue);
  const fullScreen = useSelector((state) => state.site.fullScreen);
  let player = Player();
  const playerProgress = useSelector((state) => state.site.playerProgress);

  const seekPlayer = (ev, value) => {
    player.setPlayerProgress({ ev: 1, value });
  };

  const playAudio = (type, article, recent) => {
    console.log(type);
    switch (type.toLowerCase()) {
      case "shorts":
        player.playShorts({ ...article, recent });
        break;
      case "story":
        player.playStory({ ...article, recent });
        break;
      case "news":
        if (article.post_type)
          player.playAudio(
            { ...article, link: article.link, recent },
            false,
            article.speechUrl
          );
        else
          player.playAudio({
            ...article,
            title: article.title.split(":")[0],
            source: article.title.split(":")[1],
            proccessed: true,
            sourceUrl: article.link,
            recent,
          });
        break;
      case "normal":
        if (article.post_type)
          player.playAudio(
            { ...article, link: article.link, recent },
            false,
            article.speechUrl
          );
        else
          player.playAudio({
            ...article,
            title: article.title.split(":")[0],
            source: article.title.split(":")[1],
            proccessed: true,
            sourceUrl: article.link,
            recent,
          });
        break;
      case "podcast":
        player.playPodcast({
          title: article.title,
          episode: article,
          recent,
        });
        break;

      default:
        console.log("none matched");
    }
  };
  return (
    <div
      className={`${contentstyle.bottomQueue} bottomQueue`}
      style={{
        height: queue.items.length > 0 ? "auto" : "0",
        opacity: fullScreen ? 0 : 1,
      }}
    >
      <div
        style={{
          height: queue.items.length > 0 ? "110px" : "0",
        }}
      >
        {queue &&
          queue.items.map((ite, index) => (
            <NewQueueCard
              data={{ ...ite, type: ite.type }}
              type={ite.type}
              currentPlayed={queue.currentPlayed}
              playing={queue.currentPlayed.playing}
              currentIndex={queue.items.findIndex(
                (item) => item.id == queue.currentPlayed.id
              )}
              expanded={queueExpanded}
              onExpand={(exp) => setQueueExpanded(exp)}
              queue={queue}
              onPlay={(recent = false) => {
                playAudio(
                  ite.isPost && ite.isPost.type ? ite.isPost.type : ite.type,
                  ite,
                  recent
                );
              }}
              playPrevious={() => {
                if (queue.items[index - 1]) {
                  playAudio(
                    queue.items[index - 1].isPost &&
                      queue.items[index - 1].isPost.type
                      ? queue.items[index - 1].isPost.type
                      : queue.items[index - 1].type,
                    queue.items[index - 1],
                    true
                  );
                }
              }}
              playForward={() => {
                if (queue.items[index + 1]) {
                  playAudio(
                    queue.items[index + 1].isPost &&
                      queue.items[index + 1].isPost.type
                      ? queue.items[index + 1].isPost.type
                      : queue.items[index + 1].type,
                    queue.items[index + 1],
                    true
                  );
                }
              }}
            />
          ))}
        <div style={{ minWidth: "3000px", height: "100px" }}></div>
      </div>
      {queue.currentPlayed?.id ? (
        <section className={`${style.progressContainer} progressbarContainer`}>
          {queue.currentPlayed?.isPost &&
          queue.currentPlayed?.isPost.type == "STORY" ? (
            ""
          ) : (
            <Slider
              value={playerProgress.waveposition}
              onChange={(ev, val) => seekPlayer(ev, val)}
              step={0.00000001}
              min={0}
              max={1}
              aria-labelledby="continuous-slider"
            />
          )}
        </section>
      ) : (
        ""
      )}
    </div>
  );
}
