import React from "react";
import styles from "../style/playHover.module.css";
import Player from "../data/player";
import { CLOSING } from "ws";

export default function PlayHover({
  showPlayHover,
  article,
  existAudio,
  episode,
}) {
  let player = Player();
  return (
    <div
      className={styles.playOver}
      style={{ display: showPlayHover == 1 ? "flex" : "none" }}
    >
      <div>
        Play Now{" "}
        <img
          src="/images/playIconWhite.svg"
          className={`${styles.playIcon} `}
          onClick={(e) => {
            e.stopPropagation();
            switch (article.type.toLowerCase()) {
              case "shorts":
                player.playShorts({ ...article, recent: true });
                break;

              case "story":
              case "post":
              case "news":
                if (article.post_type)
                  if (article.post_type == "STORY")
                    player.playStory({ ...article, recent: true });
                  else
                    player.playAudio(
                      { ...article, link: article.link, recent: true },
                      false,
                      article.speechUrl
                    );
                else
                  player.playAudio({
                    ...article,
                    proccessed: existAudio ? true : false,
                    sourceUrl: existAudio ? existAudio.speechUrl : "",
                    recent: true,
                  });
                break;
              case "podcast":
                if (episode)
                  player.playPodcast({
                    title: article.title,
                    episode: episode[0],
                    recent: true,
                  });
                else
                  player.playPodcast({
                    title: article.title,
                    episode: article,
                    recent: true,
                  });
                break;

              default:
                console.log("none matched");
            }
          }}
          alt=""
        />
      </div>
      <div>
        Play Next{" "}
        <img
          src="/images/playNext.png"
          className={`${styles.playIcon} `}
          onClick={(e) => {
            e.stopPropagation();
            if (article.type == "podcast")
              player.playNext(
                {
                  episode: article,
                  title: article.source,
                },
                article.type
              );
            else {
              player.playNext(article, article.type);
            }
          }}
          alt=""
        />
      </div>
      {article.post_type ||
      article.type == "podcast" ||
      article.type == "shorts" ? (
        ""
      ) : (
        <div>
          {article["leoSummary"] ? "Play Leo Summary" : "Play Summary"}
          <img
            src="/images/playSummary.png"
            className={`${styles.playIcon} `}
            //   onClick={() =>
            //     this.props.playArticle({ ...article, summary: true }, "playNow")
            //   }
            alt=""
          />
        </div>
      )}
      <div>
        Play In-Queue{" "}
        <img
          src="/images/playInQueue.png"
          className={`${styles.playIcon} `}
          onClick={(e) => {
            e.stopPropagation();
            switch (article.type) {
              case "shorts":
                player.playShorts({ ...article, recent: false });
                break;
              case "news":
                if (article.post_type)
                  if (article.post_type == "STORY")
                    player.playStory({ ...article, recent: false });
                  else
                    player.playAudio(
                      { ...article, link: article.link, recent: false },
                      false,
                      article.speechUrl
                    );
                else
                  player.playAudio({
                    ...article,
                    proccessed: existAudio ? true : false,
                    sourceUrl: existAudio ? existAudio.speechUrl : "",
                    recent: false,
                  });
                break;
              case "podcast":
                if (episode)
                  player.playPodcast({
                    title: article.title,
                    episode: episode[0],
                    recent: false,
                  });
                else
                  player.playPodcast({
                    title: article.title,
                    episode: article,
                    recent: false,
                  });
                break;

              default:
                console.log("none matched");
            }
          }}
          alt=""
        />
      </div>
    </div>
  );
}
