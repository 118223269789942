import React from "react";
import styles from "../../style/shareLinkTab.module.css";
import sidebarstyle from "../../style/sidebarTabsContent.module.css";
import style from "../../style/sidebarTabsContent.module.css";
import { useDispatch } from "react-redux";
import { setShareSidebar, setPostData } from "../../redux/actions/main";
import { connect } from "react-redux";
import { Spin, message } from "antd";
import player from "../../data/player";
import FeedTag from "./components/FeedTag";
import axios from "axios";
import _ from "lodash";
import FileDropZone from "../FileDropZone";
import $ from "jquery";
import StrapiRequest from "../../api/strapiRequest";
import { Input } from "antd";
import createStyle from "../../style/createproject.module.css";

const mapState = (state) => ({
  postData: state.main.postData,
  selectedTag: state.main.selectedTag,
  queue: state.site.queue,
  tabChange: state.main.tabChange,
});

const Player = player();

function StoryTemplates(props) {
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [backgroundOption, setBackgroundOption] = React.useState("image");
  const [searchVal, setSearchVal] = React.useState("");

  React.useEffect(() => {
    axios
      .get("https://editorbackend.audioone.cloud/api/v1/templates")
      .then((res) => {
        setTemplates(res.data.data);
      });
    if (props.postData.template) {
      setSelectedTemplate(props.postData.template);
    }
    $(".gjs-frame").on("click", "div", function (e) {
      console.log(e, "div clicked");
    });
  }, []);

  const dispatch = useDispatch();

  const templateClick = (item) => {
    const editor = window.editor;
    const { template } = item;
    let { html, css } = template;
    setSelectedTemplate(template);
    css = css.replaceAll('"', "");
    // css = `${css}body{background-color:yellow !important;background-position:center;background-size:cover;}`;
    // editor.setComponents(html);
    // editor.setStyle(css);
    dispatch(
      setPostData({
        ...props.postData,
        clickedStory: false,

        template: {
          html,
          css: `${css}body{zoom:0.75;padding:0 !important}body > div{background:transparent !important}html,body{height:100%}body>div>div{position:relative !important;z-index:10 !important;left:0 !important;top:0 !important;bottom:0 !important;right:0 !important}`,
          origCss: `${css}body{zoom:0.75;padding:0 !important}body > div{background:transparent !important}html,body{height:100%}body>div>div{position:relative !important;z-index:10 !important;left:0 !important;top:0 !important;bottom:0 !important;right:0 !important}`,
        },
      })
    );
  };

  const animations = [
    {
      name: "Zoom In",
      subName: "zoomIn",
      class: `@keyframes zoomIn {
        0% {
            opacity: 0;
            -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
        }
        50% {
            opacity: 1;
        }
    }`,
    },
    {
      name: "Fade In",
      subName: "fadeIn",
      class: `@keyframes fadeIn {
        0% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }


    }`,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={"d-flex justify-content-end"}>
        <span
          style={{ color: "white" }}
          className={`${styles.heading} mb-4 mr-3`}
        >
          STORY TEMPLATES
        </span>
        <img
          src="/images/images/closeCircleOutlined.svg"
          className={styles.closeBtn}
          onClick={() => dispatch(setShareSidebar({ visible: false }))}
        />
      </div>

      <div className=" px-3" style={{ paddingTop: "2em" }}>
        <p
          className="mb-0"
          style={{
            fontSize: 17,
            fontWeight: "bold",
            position: "absolute",
            top: "-10px",
            zIndex: 999999,
            color: "#38ef7d",
          }}
        >
          {props.tabChange == "storyImage"
            ? "BACKGROUND IMAGE"
            : props.tabChange == "storyAnimation"
            ? "ANIMATION"
            : props.tabChange == "storyTransition"
            ? "TRANSITION"
            : "STYLE"}
        </p>
        <div className=" d-flex flex-wrap">
          {props.tabChange == "storyImage" ? (
            <>
              <h5
                className={`text-center mb-0 mr-2 ${sidebarstyle.roundedBox} ${
                  backgroundOption == "image" ? sidebarstyle.activeBox : ""
                }`}
                style={{
                  width: "130px",
                }}
                onClick={() => setBackgroundOption("image")}
              >
                Background Image
              </h5>
              <h5
                className={`text-center mb-0 ${sidebarstyle.roundedBox} ${
                  backgroundOption == "video" ? sidebarstyle.activeBox : ""
                }`}
                style={{ width: "130px" }}
                onClick={() => setBackgroundOption("video")}
              >
                Background Video
              </h5>
              {backgroundOption == "video" ? (
                <div className="w-100 mt-4">
                  <Input
                    size="large"
                    placeholder={`Paste Video Url here`}
                    className={`${createStyle.searchInput} w-100`}
                    onChange={(e) => {
                      if (e.target.value) {
                        let imgUrl = e.target.value;

                        dispatch(
                          setPostData({
                            ...props.postData,
                            clickedStory: false,

                            template: {
                              ...props.postData.template,
                              background: imgUrl,
                              backgroundcss: `#videoTag > video{object-fit: cover;pointer-events:unset !important} #videoTag {object-fit: cover;min-width:100%;min-height:100%;margin: 0 auto;overflow: hidden;position: absolute !important;z-index:9 !important;height: 186px;margin-bottom: 20px;cursor: pointer;width: 100%;height: 100%;}`,
                              backgroundhtml: `<video  loop src="${imgUrl}" id="videoTag" autoplay loop muted>
                                      Your browser does not support the video tag.
                                  </video>`,
                            },
                          })
                        );
                      }
                    }}
                    style={{
                      border: "1px solid #000000",
                      borderRadius: "10px",
                      width: "100%",
                      height: "34px",
                      backgroundColor: "transparent",
                      color: "white",
                      marginLeft: "0",
                      marginBottom: "0",
                      width: "100%",
                      fontSize: "0.9em",
                      padding: "6px 9px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ) : props.tabChange == "storyAnimation" ? (
            ""
          ) : props.tabChange == "storyTransition" ? (
            ""
          ) : (
            _.uniqBy(templates, "category").map((item) => (
              <FeedTag
                isSelected={false}
                onClick={(id) => {}}
                data={{ label: item.category, id: item._id }}
              />
            ))
          )}
        </div>
      </div>
      {props.tabChange == "storyImage" ? (
        <div style={{ paddingTop: "6em" }}>
          <FileDropZone
            image={
              props.postData.template ? props.postData.template.image : null
            }
            onSelectedFile={async (image) => {
              if (image && image.length > 0 && props.postData.template) {
                var file = image[0];
                const data = new FormData();
                data.append("files", file);
                data.append("field", "image");

                let response = await StrapiRequest({
                  url: "upload",
                  data,
                  method: "post",
                });

                if (response && response.data && response.data.length > 0) {
                  let imgUrl = response.data[0].url;

                  if (backgroundOption == "image") {
                    dispatch(
                      setPostData({
                        ...props.postData,
                        clickedStory: false,

                        template: {
                          ...props.postData.template,
                          background: imgUrl,
                          backgroundcss: `body{background-image:url(${imgUrl}) !important;background-position:center;background-size:cover;background-repeat:no-repeat;}#oldvideo{display:none}.oldburn{display:none}`,
                        },
                      })
                    );
                  } else if (backgroundOption == "video") {
                    dispatch(
                      setPostData({
                        ...props.postData,
                        clickedStory: false,

                        template: {
                          ...props.postData.template,
                          background: imgUrl,
                          backgroundcss: `#videoTag > video{object-fit: cover;pointer-events:unset !important} #videoTag {object-fit: cover;min-width:100%;min-height:100%;margin: 0 auto;overflow: hidden;position: absolute !important;z-index:9;height: 186px;margin-bottom: 20px;cursor: pointer;width: 100%;height: 100%;}`,
                          backgroundhtml: `<video  loop src="${imgUrl}" id="videoTag" autoplay loop muted>
                                      Your browser does not support the video tag.
                                  </video>`,
                        },
                      })
                    );
                  }
                }
              }
            }}
          />
        </div>
      ) : props.tabChange == "storyTransition" ? (
        <div className={style.storyTemplateList}>
          <div
            className={sidebarstyle.kenBurns}
            onClick={() => {
              dispatch(
                setPostData({
                  ...props.postData,
                  clickedStory: false,
                  template: {
                    ...props.postData.template,
                    backgroundhtml: `<div class="kenBurns"><img src="${
                      props.postData.template.background
                        ? props.postData.template.background
                        : "https://unsplash.it/900/700"
                    }" /></div>`,
                    backgroundcss: `.kenBurns {margin: 0 auto;overflow: hidden;position: absolute !important;height: 186px;margin-bottom: 20px;cursor: pointer;width: 100%;height: 100%;}.kenBurns img {object-fit:cover;height: 100%;width: 100%;animation: move 30s ease infinite alternate;position: absolute;}@keyframes move {0% {-webkit-transform-origin: bottom left;-moz-transform-origin: bottom left;-ms-transform-origin: bottom left;-o-transform-origin: bottom left;transform-origin: bottom left;transform: scale(1);-ms-transform: scale(1);-webkit-transform: scale(1);-o-transform: scale(1);-moz-transform: scale(1);}100% {transform: scale(1.2);-ms-transform: scale(1.2);-webkit-transform: scale(1.2);-o-transform: scale(1.2);-moz-transform: scale(1.2);}}`,
                  },
                })
              );
            }}
          >
            <img
              src={
                props.postData.template && props.postData.template.background
                  ? props.postData.template.background
                  : "https://unsplash.it/900/700"
              }
            />
            <p
              className={"text-center mb-0"}
              style={{ color: "#38ef7d", marginTop: "160px" }}
            >
              Ken Burns
            </p>
          </div>
        </div>
      ) : props.tabChange == "storyAnimation" ? (
        <div className={style.storyTemplateList}>
          {animations.map((item) => (
            <div
              className={sidebarstyle.kenBurns}
              style={{ height: "65px" }}
              onClick={() => {
                if (props.selectedTag)
                  dispatch(
                    setPostData({
                      ...props.postData,
                      clickedStory: false,

                      template: {
                        ...props.postData.template,
                        css: `${props.postData.template.css}${item.class}#${props.selectedTag}{animation-name:${item.subName};animation-fill-mode: both;animation-duration:2s;animation-iteration-count:1;animation-delay:3s}`,
                      },
                      clickedStory: false,
                    })
                  );
              }}
            >
              <p className={"text-center mb-0"} style={{ color: "#38ef7d" }}>
                {item.name}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className={style.storyTemplateList}>
          {templates.map((item) => (
            <div
              onClick={() => templateClick(item)}
              style={{
                backgroundImage: `url(${item.image})`,
                width: "100%",
                height: "156px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginBottom: "20px",
                cursor: "pointer",
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
}
export default connect(mapState, null)(StoryTemplates);
