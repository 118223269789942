import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import { connect } from "react-redux";
import { changeToCategoriesTab, setPostData } from "../../redux/actions/main";
import voices from "./voices.json";

const mapState = (state) => ({
  postData: state.main.postData,
});

const mapDispatch = (dispatch) => ({
  setPostData: (feed) => dispatch(setPostData(feed)),
});

function VoiceTab(props) {
  const [selectedVoice, setSelectedVoice] = React.useState("");

  React.useEffect(() => {
    setSelectedVoice(props.postData.voice);
  }, [props.postData]);

  return (
    <div className=" px-3 mt-5" style={{ paddingTop: "4em" }}>
      <h4
        className="mb-0"
        style={{
          fontSize: 17,
          fontWeight: "bold",
          position: "absolute",
          top: "230px",
          zIndex: 999999,
        }}
      >
        Voices
      </h4>
      {voices.map((item) => (
        <div
          className={`${style.userItem} d-flex mt-3 align-items-center`}
          onClick={() => {
            setSelectedVoice(item);
            props.setPostData({
              ...props.postData,
              voice: item,
              clickedStory: false,
            });
          }}
        >
          <ImageInitial fullname={item.fullname} image={item.avatar} />

          <div className={style.userDetail}>
            <div
              style={{
                color:
                  selectedVoice.fullname == item.fullname ? "#38ef7d" : "white",
              }}
            >
              <p>{item.fullname}</p>
              <p>
                {" "}
                <span style={{ color: "white" }}>
                  {" "}
                  {item.premium ? "Premium" : ""}
                </span>{" "}
                Narration
              </p>
            </div>
            <div>
              <img src="/images/playIcon.svg" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default connect(mapState, mapDispatch)(VoiceTab);
