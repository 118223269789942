import React, { useCallback, useState, useEffect } from "react";
import style from "../style/dropzone.module.css";
import { useDropzone } from "react-dropzone";
import environment from "../api/environment";

export default function FileDropZone(props) {
  const [selectedFile, setSelectedFile] = useState(false);

  const onDrop = async (acceptedFiles) => {
    const file = await toBase64(acceptedFiles[0]);
    setSelectedFile(file);
    props.onSelectedFile(acceptedFiles);
  };

  useEffect(() => {
    if (props.image) {
      if (props.image.origFile) setSelectedFile(props.image.origFile);
      else setSelectedFile(`${props.image}`);
    } else setSelectedFile(``);
    return () => {};
  }, [props.image]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={style.container}
      {...getRootProps()}
      style={{ position: "relative" }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <div>
          <img
            src="/images/dropIcon.png"
            style={{ filter: "invert(1)" }}
            alt=""
          />
          <p>Drag and Drop File</p>
        </div>
      ) : (
        <div>
          {selectedFile ? (
            <div
              style={{
                position: "absolute",
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: 0,
                width: "100%",
                height: "100%",
                borderRadius: "11px",
                backgroundImage: `url(${selectedFile})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={style.dropZoneContainer}
            >
              <div
                className={style.dropOverlay}
                onClick={() => setSelectedFile(null)}
              >
                <div>
                  <img
                    style={{ width: "30px" }}
                    src="/images/pencilIconWhite.svg"
                    alt=""
                  />
                  <p style={{ color: "white" }}>Click to change picture</p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: 9,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="/images/dropIcon.png"
                alt=""
                style={{ filter: "invert(1)" }}
                className="mb-3"
              />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
