import React, { Component, useState } from "react";
import ACTIONS from "../redux/actions/auth";
import { connect } from "react-redux";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
});

function EmailSent(props) {
  return (
    <div className="container ">
      <section
        className="session "
        data-action="native"
        style={{ marginTop: "9em" }}
      >
        <h1>We need to verify that it is you.</h1>

        <div>
          <img src="/images/emailSent.svg" alt="" />
        </div>
        <p style={{ marginTop: "4.5em" }}>
          We just sent you an email confirmation. Please check your inbox and
          confirm your account. You will be redirected back here to continue.
          Thanks. See you back here soon.
        </p>
      </section>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSent);
