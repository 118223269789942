import React from "react";
import styles from "../style/commentaryCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import style from "../style/sidebarTabsContent.module.css";
import { tag } from "./sidebarTabs/components/feedtag.module.css";
import {
  setAddItem,
  setMeter,
  setMeterProgress,
  setMeterTitle,
  setShareSidebar,
} from "../redux/actions/main";
import {
  addItemToProject,
  createAudioToProject,
  addItemsToCampaign,
  createEmbedLink,
} from "../redux/actions/project";
import SITEACTIONS from "../redux/actions/site";
import apiRequest from "../api/clientRequest";
import { message, Spin } from "antd";
import sidebarstyle from "../style/sidebarTabsContent.module.css";
import moment from "moment";
import axios from "axios";
import LinkModal from "./LinkModal";
import EmbedModal from "./EmbedModal";
import environment from "../api/environment";

export default function ShareCard() {
  const [loading, setLoading] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState("");
  const { addItem } = useSelector((state) => state.main);
  const { activeTab } = useSelector((state) => state.main);
  const { activeProject } = useSelector((state) => state.projects);
  const { shareSidebar } = useSelector((state) => state.main);
  const { socket } = useSelector((state) => state.site);
  const { queue } = useSelector((state) => state.site);
  const { user } = useSelector((state) => state.auth);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [embedModal, setEmbedModal] = React.useState(false);
  const [resultLink, setResultLink] = React.useState(false);
  const [createdEmbed, setCreatedEmbed] = React.useState(false);

  const selectedSource = React.useRef(selectedType);
  const shareArticleRef = React.useRef(addItem);
  const sidebarVoiceRef = React.useRef(shareSidebar);
  const activeProjectRef = React.useRef(activeProject);

  const dispatch = useDispatch();

  React.useEffect(() => {
    shareArticleRef.current = addItem;
  }, [addItem]);

  React.useEffect(() => {
    sidebarVoiceRef.current = shareSidebar;
  }, [shareSidebar]);
  React.useEffect(() => {
    activeProjectRef.current = activeProject;
  }, [activeProject]);
  React.useEffect(() => {
    socket.on("completedTTS", (data) => {
      console.log(data, "completed tts");

      if (data.progress == 100) {
        if (selectedSource.current == "addVoice")
          addVoice(data.speechUrl, true);
        else if (selectedSource.current == "wordpress") {
          addToWordpress(data.speechUrl, true);
        } else if (selectedSource.current == "addEmbed") {
          addEmbed(data.speechUrl, true);
        }
      } else {
        dispatch(setMeterTitle(data.progressTitle));
        dispatch(setMeterProgress(data.progress));
      }
    });

    return () => {
      socket.off("completedTTS");
    };
  }, []);

  const onPerformOperation = async () => {
    dispatch(setMeter(true));
    dispatch(setMeterTitle("Processing"));
    dispatch(setMeterProgress(10));
    setLoading(true);

    if (selectedType == "addProject") {
      if (window.location.pathname == "/dashboard") {
        dispatch(setMeterTitle("Being added to Today Screen"));

        await dispatch(SITEACTIONS.addTodayItem(addItem));
        dispatch(setMeterTitle("Item added to Today Screen"));
      } else if (activeProject.type == "campaign") {
        let newArts = activeProject.addedItems.map((item) => {
          if (item.article) return JSON.parse(item.article);
          return item;
        });

        newArts.unshift(addItem);
        dispatch(setMeterTitle("Being added to Campaign"));

        await dispatch(addItemsToCampaign(newArts));
        dispatch(setMeterTitle("Item added to Campaign"));
      } else {
        dispatch(setMeterTitle("Being added to Project"));

        await dispatch(addItemToProject(addItem));
        dispatch(setMeterTitle("Item added to Project"));
      }
      dispatch(setMeterProgress(100));

      setTimeout(() => {
        setLoading(false);
        dispatch(setMeter(false));
        dispatch(setMeterTitle(""));
        dispatch(setMeterProgress(0));
      }, 1500);
    } else if (selectedType == "voice") {
      addVoice();
    } else if (selectedType == "embed") {
      if (addItem.type == "post" && addItem.post_type == "STORY") {
        dispatch(setMeterTitle("Embed link created"));
        dispatch(setMeterProgress(100));
        dispatch(setMeter(true));

        const response = await dispatch(
          createEmbedLink({
            speechUrl: addItem.speechUrl,
            title: shareArticleRef.current.title,
          })
        );

        setCreatedEmbed(response.newAud);
        setResultLink(
          `<iframe src="${environment.amelia_url}${user.username}/embed/${
            response.newAud._id
          }/${
            addItem.slug
              ? `${addItem.slug}__**__`
              : `${addItem.title.replaceAll(" ", "-").replaceAll("/", "==")}`
          }" style="width:400px;height:800px" allow="autoplay; encrypted-media" id="ameliaPlayer" frameborder="0" ></iframe>`
        );

        setEmbedModal(true);
        setTimeout(() => {
          setLoading(false);
          dispatch(setMeter(false));
          dispatch(setMeterTitle(""));
          dispatch(setMeterProgress(0));
        }, 1500);
      } else if (addItem.type == "post")
        addEmbed(addItem.speechUrl, true, shareArticleRef.current.image);
      else addEmbed(false, false, shareArticleRef.current.image);
    } else if (selectedType == "wordpress") {
      if (shareArticleRef.current.type == "news")
        addToWordpress(false, false, shareArticleRef.current.image);
      else
        addToWordpress(
          shareArticleRef.current.link,
          true,
          shareArticleRef.current.image
        );
    }
  };

  const addToWordpress = async (
    speechUrl = false,
    completed = false,
    image = false
  ) => {
    selectedSource.current = "wordpress";
    console.log(speechUrl, completed);
    if (completed) {
      dispatch(setMeterTitle("Being Added To Wordpress Site"));

      const response = await dispatch(
        createEmbedLink({
          speechUrl,
          title: shareArticleRef.current.title,
        })
      );

      await apiRequest({
        url: "/projects/sendToWordpress",
        method: "POST",
        data: {
          article: {
            ...shareArticleRef.current,
            description: shareArticleRef.current["description"],
            sourceUrl: speechUrl,
            proccessed: true,
            published: moment(shareArticleRef.current.published),
          },
          voice: sidebarVoiceRef.current.visible
            ? sidebarVoiceRef.current.voice.fullname
            : "",
          date: moment(shareArticleRef.current.published),
          embedUrl: `<iframe src="https://embed.audioone.cloud/songId=${response.newAud._id}" style="width:500px;height:80px" allow="autoplay; encrypted-media" frameborder="0" id="embedPlayer"></iframe>`,
        },
      })
        .then((res) => {
          dispatch(setMeterTitle("Added To Wordpress Site"));
          dispatch(setMeterProgress(90));
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        setLoading(false);
        dispatch(setMeter(false));
        dispatch(setMeterTitle(""));
        dispatch(setMeterProgress(0));
      }, 1500);
    } else {
      socket.emit("createNews", {
        background: sidebarVoiceRef.current.background,
        article: shareArticleRef.current,
        wordpress: true,
        image,
        voice: sidebarVoiceRef.current.visible
          ? sidebarVoiceRef.current.voice.fullname
          : "",
      });
    }
  };

  const addEmbed = async (
    speechUrl = false,
    completed = false,
    image = false
  ) => {
    selectedSource.current = "addEmbed";
    if (completed) {
      dispatch(setMeterTitle("Embed link being created"));

      const response = await dispatch(
        createEmbedLink({
          speechUrl,
          title: shareArticleRef.current.title,
        })
      );

      dispatch(setMeterTitle("Embed link created"));
      dispatch(setMeterProgress(100));
      setCreatedEmbed(response.newAud);
      setResultLink(
        `<iframe src="${environment.embedPlayer}/songId=${response.newAud._id}" style="width:500px;height:110px" allow="autoplay; encrypted-media" frameborder="0" id="embedPlayer" ></iframe>`
      );
      setEmbedModal(true);
      setTimeout(() => {
        setLoading(false);
        dispatch(setMeter(false));
        dispatch(setMeterTitle(""));
        dispatch(setMeterProgress(0));
      }, 1500);
    } else {
      socket.emit("createNews", {
        background: sidebarVoiceRef.current.background,
        article: addItem,
        wordpress: true,
        image,
        voice: sidebarVoiceRef.current.visible
          ? sidebarVoiceRef.current.voice.fullname
          : "",
      });
    }
  };

  const addVoice = async (speechUrl = false, completed = false) => {
    selectedSource.current = "addVoice";
    if (completed) {
      if (window.location.pathname == "/dashboard")
        dispatch(setMeterTitle("Being Added To Today Screen"));
      else if (activeProjectRef.current.type == "project")
        dispatch(setMeterTitle("Being Added To Project"));

      await dispatch(
        createAudioToProject({
          speechUrl,
          voice: shareSidebar.voice.fullname,
          article: shareArticleRef.current,
        })
      );
      if (window.location.pathname == "/dashboard") {
        await dispatch(SITEACTIONS.addTodayItem(shareArticleRef.current));
        dispatch(setMeterTitle("Added To Today Screen"));
        dispatch(setMeterProgress(100));
      } else if (activeProjectRef.current.type == "project") {
        await dispatch(addItemToProject(shareArticleRef.current));
        dispatch(setMeterTitle("Added To Project"));
        dispatch(setMeterProgress(100));
      } else {
        dispatch(setMeterTitle("Completed Processing"));
        dispatch(setMeterProgress(100));
      }
      setTimeout(() => {
        setLoading(false);
        dispatch(setMeter(false));
        dispatch(setMeterTitle(""));
        dispatch(setMeterProgress(0));
      }, 1500);
    } else {
      socket.emit("createNews", {
        background: sidebarVoiceRef.current.background,
        image: sidebarVoiceRef.current.image,
        title: sidebarVoiceRef.current.title,
        article: addItem,
        voice: sidebarVoiceRef.current.visible
          ? sidebarVoiceRef.current.voice.fullname
          : "",
      });
    }
  };

  return addItem.visible ? (
    <div
      style={{ right: "65%", bottom: queue.items.length > 0 ? "110px" : 0 }}
      className={`${style.voiceRecord} ${styles.shareCard} ${style.commentartContainer} ${styles.container}`}
    >
      <LinkModal
        loadedArticle={addItem}
        visible={modalVisible}
        setVisibility={setModalVisible}
        loadedLink={resultLink}
      />

      <EmbedModal
        loadedArticle={addItem}
        visible={embedModal}
        setVisibility={setEmbedModal}
        loadedLink={resultLink}
        createdEmbed={createdEmbed}
      />

      <div className="d-flex ">
        <img
          src="/images/shareGreenIcon.svg"
          style={{ filter: "none" }}
          alt=""
        />
        <p>Add Item</p>
        <img
          src="/images/images/closeCircleOutlined.svg"
          onClick={() => {
            dispatch(setAddItem({ visible: false }));
            dispatch(setShareSidebar({ visible: false }));
            setSelectedType("");
          }}
        />
      </div>
      <p>{addItem.title}</p>
      <div
        className={` ${sidebarstyle.popupCard} d-flex justify-content-center`}
      >
        <div className={`${sidebarstyle.actionBtn} px-0 mb-2`}>
          {window.location.pathname == "/dashboard" &&
          activeTab !== "TODAY ITEMS" ? (
            <button
              className={sidebarstyle.active}
              onClick={() => {
                setSelectedType("addProject");
              }}
              style={{
                borderColor:
                  selectedType == "addProject" ? "#38ef7d" : "transparent",
              }}
            >
              <img
                src="/images/plusIcon.svg"
                alt=""
                style={{ filter: "invert(1)" }}
              />
              <span>Add Item To Project</span>
            </button>
          ) : (
            ""
          )}
          {(activeTab == "PROJECT ITEMS" &&
            activeProject &&
            activeProject.type == "project") ||
          !activeProject ? (
            ""
          ) : (
            <button
              className={sidebarstyle.active}
              onClick={() => {
                setSelectedType("addProject");
              }}
              style={{
                borderColor:
                  selectedType == "addProject" ? "#38ef7d" : "transparent",
              }}
            >
              <img
                src="/images/plusIcon.svg"
                alt=""
                style={{ filter: "invert(1)" }}
              />
              <span>Add Item To Project</span>
            </button>
          )}
          {addItem.type == "news" ? (
            <>
              <button
                className={sidebarstyle.active}
                onClick={() => {
                  setSelectedType("voice");
                  dispatch(setShareSidebar({ visible: true }));
                }}
                style={{
                  borderColor:
                    selectedType == "voice" ? "#38ef7d" : "transparent",
                }}
              >
                <img
                  src="/images/audioOptionIcon.svg"
                  alt=""
                  style={{ filter: "brightness(2)" }}
                />
                <span>Add Audio Options</span>
              </button>
            </>
          ) : (
            ""
          )}

          <button
            className={sidebarstyle.active}
            onClick={() => {
              setSelectedType("commentary");
              // dispatch(setShareSidebar({ visible: true }));
              dispatch(
                SITEACTIONS.setCommentary({ ...addItem, visible: true })
              );
            }}
            style={{
              borderColor:
                selectedType == "commentary" ? "#38ef7d" : "transparent",
            }}
          >
            <img src="/images/chatting.svg" alt="" />
            <span>Add Commentary</span>
          </button>
          {addItem.type == "news" ||
          (addItem.type == "post" &&
            (addItem.post_type == "STANDARD" ||
              addItem.post_type == "REC0RD")) ? (
            <button
              className={sidebarstyle.active}
              onClick={() => {
                setSelectedType("wordpress");
                dispatch(setShareSidebar({ visible: true }));
              }}
              style={{
                borderColor:
                  selectedType == "wordpress" ? "#38ef7d" : "transparent",
              }}
            >
              <img
                src="/images/wordpressIcon.svg"
                alt=""
                style={{ filter: "brightness(2)" }}
              />
              <span>Send To WordPress</span>
            </button>
          ) : (
            ""
          )}
          {addItem.type == "news" || addItem.type == "post" ? (
            <button
              className={sidebarstyle.active}
              onClick={() => {
                setSelectedType("embed");
                dispatch(setShareSidebar({ visible: true }));
              }}
              style={{
                borderColor:
                  selectedType == "embed" ? "#38ef7d" : "transparent",
              }}
            >
              <img
                src="/images/embedIcon.svg"
                alt=""
                style={{ filter: "brightness(2)", width: "28px" }}
              />
              <span>Create Embed</span>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-0">
        {loading ? (
          <Spin size="large" />
        ) : (
          <div
            className={`${tag} px-4 mx-5`}
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderColor: "white",
            }}
            onClick={() => onPerformOperation()}
          >
            Save
          </div>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}
