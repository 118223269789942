import React, { Component } from "react";
import message from "antd/lib/message";
import styles from "../../style/onboarding.module.css";

var newstags = require("../../data/playtag.json");

export default class step2 extends Component {
  state = {
    selectedTags: [],
    searchQuery: "",
  };

  componentWillMount() {
    newstags = this.shuffleTags(newstags);
    this.articleTags = {
      children: newstags,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.submitHandle({ playlisttags: this.state.selectedTags });
  }

  tagClicked(item) {
    if (this.state.selectedTags.length == 5) {
      message.warning("Sorry you can only select upto five tags");
      return false;
    } else if (!this.state.selectedTags.find((ite) => ite.id == item)) {
      const tempItem = newstags.find((ite) => ite.id == item);
      this.setState((prev) => ({
        selectedTags: [...prev.selectedTags, tempItem],
      }));
      return true;
    } else {
      this.setState((prev) => ({
        selectedTags: prev.selectedTags.filter((ite) => ite.id !== item),
      }));
      return false;
    }
  }

  shuffleTags(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  componentDidMount() {
    var diameter = 1100;
    const self = this;
    var bubble = window.d3
      .pack(this.articleTags)
      .size([diameter, diameter])
      .padding(function (d) {
        return d.height > 1 ? 5 / 0.35 : 20;
      });
    var svg = window.d3
      .select("#chart")
      .append("svg")
      .attr("width", diameter)
      .attr("height", diameter)
      .attr("class", "bubble")
      .style("transform", "rotate(-90deg)");

    var nodes = window.d3.hierarchy(this.articleTags).sum(function (d) {
      return Math.floor(Math.random() * 400) + 200;
    });

    var node = svg
      .selectAll(".node")
      .data(bubble(nodes).descendants())
      .enter()
      .filter(function (d) {
        return !d.children;
      })
      .append("g")
      .attr("class", function (d) {
        if (self.props.data.playlisttags.includes(d.data.name))
          return "node active";
        else return "node";
      })
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ") ";
      })
      .on("mouseover", function (d, i) {
        window.d3.select(this).style("cursor", "pointer");
      })
      .on("click", function (d) {
        if (self.tagClicked(d.data.id)) {
          window.d3.select(this).classed("active", true);
        } else window.d3.select(this).classed("active", false);
      });

    node.append("title").text(function (d) {
      return d.name + ": " + d.Count;
    });

    node
      .append("circle")
      .attr("r", function (d) {
        return d.r;
      })
      .style("fill", "#1C1C1C");

    node
      .append("text")
      .attr("dy", ".2em")
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .style("transform", "rotate(90deg)")
      .text(function (d) {
        console.log(d);
        return d.data.name.substring(0, d.r / 3);
      })
      .attr("font-family", "sans-serif")
      .attr("fill", "white");

    node
      .append("svg:foreignObject")
      .attr("height", "30px")
      .attr("width", "30px")

      .attr("margin-top", "10px")
      .html('<i class="fas fa-check"></i>');
  }
  render() {
    const { bindSubmitForm, submitHandle } = this.props;
    let tags = newstags;

    if (this.state.selectedTags.length > 0) {
      tags = newstags.filter(
        (ite) => !this.state.selectedTags.some((item) => item.id === ite.id)
      );
    }

    if (this.state.searchQuery) {
      const query = this.state.searchQuery;
      const rege = `${query.toLowerCase()}.*`;

      const re = new RegExp(rege);
      tags = tags.filter((ite) => ite.name.toLowerCase().match(re));
    }

    return (
      <div className={styles.stepContainer}>
        <div className="row">
          <div className={`col-md-3 ${styles.leftSide}`}>
            <h1 className={styles.heading}>PODCAST</h1>
            <img
              src="/images/podImage.png"
              style={{
                width: "180px",
                margin: "auto",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              className={styles.stepImage}
            />
            <h2 className={styles.secondHeading}>Add to the Mix</h2>
            <p className={styles.paraContent}>
              Podcasts are a great way to extend your listening experience.
              Select from categories, explore and search for your favorites. You
              can subscribe to podcast series and Audio One will automatically
              add them to your personal queue when a new episode arrives.
            </p>
            <p className={styles.paraContent}>
              Get started by selecting up to 5 topics on your free Audio One
              account. Podcasting is becoming more popular than ever, so their
              new shows coming out everyday, including exclusive offerings from
              services like Spotify, Stitcher and many others. You want more
              options, Audio One will be delivering them real soon with
              integrations.
            </p>
          </div>
          <div className={`col-md-9 ${styles.rightContainer}`}>
            <div id="chart"></div>
          </div>

          <button
            ref={(fr) => {
              bindSubmitForm(fr);
            }}
            onClick={this.handleSubmit.bind(this)}
            type="submit"
            className="d-none btn"
          >
            click me
          </button>
        </div>
      </div>
    );
  }
}
