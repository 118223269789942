import React from "react";
import style from "../../style/PlayCard.module.css";
import { connect } from "react-redux";
import { addItemToProject } from "../../redux/actions/project";
import { EyeOutlined } from "@ant-design/icons";
import { FeedTags, active } from "../../style/LatestFeed.module.css";
import ImageInitial from "../ImageInitial";

export default function MemberCard({ data, isInvited, onClick }) {
  const { fullname, email, avatar } = data;

  return (
    <div className={`${style.PlayCard} ${style.contactCard}`}>
      <div className="d-flex w-100  justify-content-between">
        <h5 className={style.contactName}>{fullname}</h5>
        <div style={{ marginTop: "-5px" }}>
          <ImageInitial fullname={fullname} image={avatar} />
        </div>
      </div>
      <div>
        <h6>Email Address</h6>
        <p>{email}</p>
      </div>
      <div>
        <h6>Company Name</h6>
        <p>Audio One</p>
      </div>

      <div className="d-flex flex-row justify-content-between w-100 mt-3">
        <div className={FeedTags}>
          <a
            style={{ margin: 0, width: "100px" }}
            href="#"
            className={`${active} px-3`}
            onClick={() => onClick()}
          >
            <div className="d-flex justify-content-between">
              {isInvited ? (
                <>
                  <img
                    src="/images/minusIcon.png"
                    style={{
                      width: "4px",
                      transform: "rotate(90deg)",
                      height: "14px",
                      marginTop: "0.3em",
                    }}
                    alt=""
                  />
                  <span style={{ color: "#38ef7d" }}>Invited</span>
                </>
              ) : (
                <>
                  <img
                    src="/images/addIcon.svg"
                    style={{ width: "10px" }}
                    alt=""
                  />
                  <span style={{ color: "#38ef7d" }}>Invite</span>
                </>
              )}
            </div>
          </a>
        </div>
        <div className={style.ActionButton}>
          <EyeOutlined style={{ fontSize: "1.5em", cursor: "pointer" }} />
          <img src="/images/heartlike.svg" alt="" className="ml-3" />
        </div>
      </div>
    </div>
  );
}
