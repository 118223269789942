import React, { useEffect, useRef, useState } from "react";
import memberstyle from "../../style/sidebarTabsContent.module.css";
import style from "../../style/LatestFeed.module.css";
import grapesjs from "grapesjs";
import { HexColorPicker } from "react-colorful";
import { Popover, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "react-color-picker/index.css";
import {
  setShareSidebar,
  setPostData,
  changeToCategoriesTab,
  setTitleSelectedTag,
} from "../../redux/actions/main";
import $ from "jquery";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js";

export default function StoryTemplate(props) {
  const editor = useRef(null);
  const [color, setColor] = useState("#aabbcc");
  const [image, setImage] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTagOriginal, setSelectedTagOriginal] = useState("");
  const originalFontSize = useRef(null);
  const originalLineHeight = useRef(null);
  const baseHeight = useRef(0);
  const postDataRef = useRef(null);
  const { postData } = useSelector((state) => state.main);
  const { tabChange } = useSelector((state) => state.main);

  useEffect(() => {
    postDataRef.current = postData;
  }, [postData]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editor.current) {
      return;
    }
    editor.current = grapesjs.init({
      container: "#gjs",
      fromElement: true,
      draggable: false,
      dropable: false,
      editable: false,
      storageManager: false,
      panels: { defaults: [] },
      plugins: ["gjs-preset-webpage"],
    });

    editor.current.on("load", () => {
      editor.current.DomComponents.getWrapper().setStyle("background: #141414");

      const domComponents = editor.current.DomComponents;
      //New Toolbar on every Component

      domComponents.getTypes().map((type) => {
        domComponents.addType(type.id, {
          model: {
            initToolbar() {
              var model = this;

              if (!model.get("toolbar")) {
                var tb = [
                  {
                    attributes: { class: "fa fa-trash-o" },
                    command: "tlb-delete",
                  },
                ];
                model.set("toolbar", tb);
              }
            },
          },
        });
      });
    });

    editor.current.on("component:selected", (component) => {
      const domElement = component.getEl();
      // const style = window.getComputedStyle(domElement);
      if ($(domElement).attr("data-gjs-type") === "wrapper") setSelectedTag("");
      else {
        let selectedId = $(domElement).attr("id");
        setSelectedTag(selectedId);
        dispatch(setTitleSelectedTag($(domElement).attr("id")));
        // setSelectedTagOriginal(domElement);
      }

      onComponentSelection(component);
    });

    window.editor = editor.current;
  }, []);

  const onComponentSelection = (model) => {
    if (
      model &&
      model.parent() &&
      model.parent().attributes.type === "text" &&
      model.attributes.type === "text"
    ) {
      return;
    }

    const elementRef = model.getEl();
    baseHeight.current = 0;
    originalFontSize.current = window.getComputedStyle(elementRef).fontSize;
    originalLineHeight.current = window.getComputedStyle(elementRef).lineHeight;
    elementRef.addEventListener("input", onTextElementKeyDown);
  };

  const onTextElementKeyDown = (event) => {
    resize_to_fit(event);
  };

  const resize_to_fit = (event) => {
    const element = event.target;
    if (!element) {
      return;
    }
    const maxHeight = window.getComputedStyle(element).maxHeight;

    if (!baseHeight.current) {
      baseHeight.current = element.scrollHeight;
    }
    let scrollHeight = element.scrollHeight;
    if (!maxHeight || maxHeight === "inital" || maxHeight === "none") {
      return;
    }

    let parsedMaxHeight;
    try {
      parsedMaxHeight = parseFloat(maxHeight);
    } catch (e) {
      parsedMaxHeight = 0;
    }
    if (!parsedMaxHeight) {
      return;
    }

    if (
      scrollHeight < baseHeight.current &&
      parsedMaxHeight > baseHeight.current &&
      event &&
      event.inputType == "deleteContentBackward"
    ) {
      element.style.fontSize = originalFontSize.current;
      element.style.lineHeight = originalLineHeight.current;
      return;
    }

    while (scrollHeight > parsedMaxHeight) {
      let fontSize = window.getComputedStyle(element).fontSize;
      element.style.fontSize = parseFloat(fontSize) - 1 + "px";
      try {
        const lineHeight = window.getComputedStyle(element).lineHeight;
        element.style.lineHeight = parseFloat(lineHeight) - 1 + "px";
      } catch (e) {}
      scrollHeight = element.scrollHeight;
    }

    // while (
    //   scrollHeight < maxHeight &&
    //   outerFont !== originalFontSize.current &&
    //   parseFloat(outerFont) < parseFloat(originalFontSize.current) &&
    //   event &&
    //   event.inputType == 'deleteContentBackward'
    // ) {
    //   let fontSize = window.getComputedStyle(element).fontSize;
    //   element.style.fontSize = parseFloat(fontSize) + 1 + 'px';
    //   scrollHeight = element.scrollHeight;
    // }
  };

  React.useEffect(() => {
    if (props.data && props.data.template) {
      const { html, css, backgroundcss, backgroundhtml, selectedAnimation } =
        props.data.template;
      window.editor.setComponents(
        `${backgroundhtml ? backgroundhtml : ""} ${html}`
      );
      window.editor.setStyle(`${css}${backgroundcss ? backgroundcss : ""}`);
      if (selectedTagOriginal) {
        $(selectedTagOriginal).addClass(selectedAnimation);
      }
    }
  }, [props.data]);
  const content = (
    <div>
      <HexColorPicker
        color={color}
        onChange={(color) => {
          setColor(color);
          if (props.data && props.data.template) {
            if (selectedTag)
              dispatch(
                setPostData({
                  ...postData,
                  clickedStory: false,

                  template: {
                    ...props.data.template,
                    css: `${props.data.template.css}#${selectedTag}{color:${color} !important}`,
                  },
                })
              );
            else
              dispatch(
                setPostData({
                  ...postData,
                  clickedStory: false,

                  template: {
                    ...props.data.template,
                    backgroundcss: `body{background-color:${color} !important;background-position:center;background-size:cover;background-image:none !important}`,
                  },
                })
              );
          }
        }}
      />
    </div>
  );

  return (
    <div id="storyContainer">
      <div
        style={{
          height: "80px",
          borderRadius: "10px",
          backgroundColor: "#141414",
          marginTop: "2em",
        }}
        className="d-flex pl-4 w-100 mb-4"
      >
        {selectedTag ? (
          ""
        ) : (
          <div
            className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-5 d-flex  align-items-center`}
          >
            <img
              src={"/images/transitionIcon.svg"}
              className={style.voiceAvatar}
              alt=""
              style={{ width: "40px", height: "40px", borderRadius: 0 }}
            />
            <div
              className={memberstyle.userDetail}
              onClick={() => {
                dispatch(changeToCategoriesTab("storyTransition"));
              }}
            >
              <div>
                <p style={{ color: "#38ef7d" }}>Transitions</p>
                <p style={{ color: "#38ef7d" }}>Ken Burns</p>
                <p style={{ opacity: "0.5" }}>Effect</p>
              </div>
            </div>
          </div>
        )}

        {selectedTag ? (
          <>
            <div
              className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-5 d-flex  align-items-center`}
            >
              <img
                src={"/images/animationIcon.svg"}
                className={style.voiceAvatar}
                alt=""
                style={{ width: "40px", height: "40px", borderRadius: 0 }}
              />
              <div
                className={memberstyle.userDetail}
                onClick={() => {
                  dispatch(changeToCategoriesTab("storyAnimation"));
                }}
              >
                <div>
                  <p style={{ color: "#38ef7d" }}>Animation</p>
                  <p style={{ color: "#38ef7d" }}>Design</p>
                  <p style={{ opacity: "0" }}>Effect</p>
                </div>
              </div>
            </div>
            <div
              className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-5 d-flex  align-items-center`}
            >
              <img
                src={"/images/textColorImage.svg"}
                className={style.voiceAvatar}
                alt=""
                style={{ width: "40px", height: "40px", borderRadius: 0 }}
              />
              <Popover content={content} trigger="click">
                <div className={memberstyle.userDetail}>
                  <div>
                    <p style={{ color: "#38ef7d" }}>Text Color</p>
                    <p style={{ opacity: "0" }}>Fade</p>
                    <p style={{ opacity: "0" }}>Effect</p>
                  </div>
                </div>
              </Popover>
            </div>
          </>
        ) : (
          <>
            <div
              className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-5 d-flex  align-items-center`}
            >
              <img
                src={"/images/backgroundColorImage.svg"}
                className={style.voiceAvatar}
                alt=""
                style={{ width: "40px", height: "40px", borderRadius: 0 }}
              />
              <Popover content={content} trigger="click">
                <div className={memberstyle.userDetail}>
                  <div>
                    <p style={{ color: "#38ef7d" }}>Background </p>
                    <p style={{ color: "#38ef7d", opacity: "1" }}>Color</p>
                    <p style={{ opacity: "0" }}>Effect</p>
                  </div>
                </div>
              </Popover>
            </div>
            <div
              className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-5 d-flex  align-items-center`}
            >
              <img
                src={"/images/titleBackgroundImage.svg"}
                className={style.voiceAvatar}
                alt=""
                style={{ width: "40px", height: "40px", borderRadius: 0 }}
              />
              <div
                className={memberstyle.userDetail}
                onClick={() => {
                  dispatch(changeToCategoriesTab("storyImage"));
                }}
              >
                <div>
                  <p style={{ color: "#38ef7d" }}>Background</p>
                  <p style={{ color: "#38ef7d", opacity: "1" }}>Image</p>
                  <p style={{ opacity: "0" }}>Effect</p>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-5 d-flex  align-items-center`}
        >
          <img
            src={"/images/animationIcon.svg"}
            className={style.voiceAvatar}
            alt=""
            style={{ width: "40px", height: "40px", borderRadius: 0 }}
          />
          <div
            className={memberstyle.userDetail}
            onClick={() => {
              dispatch(changeToCategoriesTab("storyTemplate"));
            }}
          >
            <div>
              <p style={{ color: "#38ef7d" }}>Template</p>
              <p style={{ color: "#38ef7d" }}>Design</p>
              <p style={{ opacity: "0" }}>Effect</p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="gjs"
        style={{
          borderRadius: "10px",
          backgroundColor: "#141414",
          marginTop: "1em",
        }}
      ></div>
    </div>
  );
}
