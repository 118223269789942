import React from "react";
import style from "../../style/LatestFeed.module.css";
import MemberCard from "../Teams/MemberCard";
import InterviewBar from "./InterviewBar";
import LiveStreaming from "./LiveStreaming";
// import Masonry from "react-masonry-css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import firebase from "../../firebase";
import { useSelector, useDispatch } from "react-redux";

export default function InterviewDetail({ interview }) {
  const user = useSelector((state) => state.auth.user);
  const [onlineUsers, setOnlineUsers] = React.useState([]);

  React.useEffect(() => {
    setUidOfUser(interview.id, user);

    const onlineRef = firebase.database().ref(`/online/${interview.id}`);
    onlineRef.on("value", (snapshot) => {
      const onlineUsers = snapshot.val();
      const onlineUsersList = [];
      for (let id in onlineUsers) {
        onlineUsersList.push({ ...onlineUsers[id], id: id });
      }
      console.log(onlineUsersList, "update");
      setOnlineUsers(onlineUsersList.map((item) => item.key));
    });
    return () => {
      setPresenceOffline();
    };
  }, []);

  const renderItems = (data) =>
    data.map((item, index) => (
      <div id={`audioPlayer${index}`} className={style.memberCard}>
        <div>
          {" "}
          <div className={style.name}>{item.member.fullname}</div>
          <div className={style.role}>
            {item.member.role ? item.member.role : "Co Host"}
          </div>
          <div className={style.role}>
            {onlineUsers.find((ite) => ite == item.member.user_id)
              ? "Online"
              : "Offline"}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end", flex: 1 }}>
          <img className={style.image} src={item.member.avatar} alt="" />
        </div>
        <div className="d-flex flex-column">
          <img
            className={style.actionImage}
            src="/images/chatIcon.svg"
            alt=""
            o
          />
          <img
            className={style.actionImage}
            src="/images/shareIcon.svg"
            alt=""
            style={{ filter: "invert(1)" }}
          />
        </div>
      </div>
    ));

  const setUidOfUser = (roomId, newUser) => {
    const reference = firebase
      .database()
      .ref(`/online/${roomId}/${newUser._id}`);
    const online = {
      displayName: newUser.email,
      date: new Date().getTime(),
      key: newUser._id,
      email: newUser.email,
    };
    reference.set(online).then(() => {});

    reference
      .onDisconnect()
      .remove()
      .then(() => console.log("On disconnect configured"));
  };
  const setPresenceOffline = () => {
    firebase.database().ref(`/online/${interview.id}/${user._id}`).remove();
  };

  console.log(onlineUsers);

  const activeUser = {
    ...interview.user,
    role: "host",
  };
  return (
    <div className={`${style.LatestFeed} mt-0`}>
      <div
        className={`row mt-3 mx-0 ${style.dropContainer}`}
        style={{
          paddingBottom: "10em",
          flexDirection: "column",
          position: "absolute",
          top: "20.5%",
          left: "53%",
          flexDirection: "column",
        }}
      >
        {renderItems([
          {
            member: {
              ...interview.user,
              role: "host",
            },
          },
          ...interview.members,
        ])}
      </div>
      <div
        id={`audioPlayer`}
        className={style.memberCard}
        style={{
          width: "593px",
          maxHeight: "550px",
          position: "absolute",
          left: "21%",
          top: "22%",
        }}
      >
        <div>
          {" "}
          <div className={style.name}>{activeUser.fullname}</div>
          <div className={style.role}>
            {activeUser.role ? activeUser.role : "Co Host"}
          </div>
          <div className={style.role}>
            {onlineUsers.find((ite) => ite == activeUser.user_id)
              ? "Online"
              : "Offline"}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            marginLeft: "-5.5em",
          }}
        >
          <img
            className={style.image}
            src={activeUser.avatar}
            alt=""
            style={{
              width: "190px",
              height: "190px",
            }}
          />
        </div>
        <div className="d-flex flex-column">
          <img
            className={style.actionImage}
            src="/images/chatIcon.svg"
            alt=""
            o
          />
          <img
            className={style.actionImage}
            src="/images/shareIcon.svg"
            alt=""
            style={{ filter: "invert(1)" }}
          />
        </div>
      </div>
      <LiveStreaming
        roomId={interview.id}
        room={{ ...interview, title: interview.title.replace(/\s/g, "") }}
        isHost={false}
      />

      <InterviewBar />
    </div>
  );
}
