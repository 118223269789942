import axios from "axios";
import environment from "./environment";
import auth0 from "../components/auth/auth0";

const apiRequest = function (options) {
  const accessToken = auth0.extended.getAccessToken();
  const uid = auth0.extended.getUid();

  const onSuccess = (response) => {
    console.log(response);
    if (response.status !== 200) {
      //continue without any action if no data found
      if (response.data.data === "auth/empty-uid-and-token") {
        if (auth0.extended.getUid().split("|")[0] != "email")
          auth0.extended.logout();
      }

      // Automatically signout user if accessToken is no longer valid
      if (response.dataresponse.data.data === "auth/invalid-user-token") {
        //don't log out if passwordless user
        if (
          auth0.extended.getUid() &&
          auth0.extended.getUid().split("|")[0] != "email"
        )
          auth0.extended.logout();
      }
      console.log(response.code, response.message);
    } else {
      return response;
    }
  };
  const onError = function (error) {
    if (error.response)
      // some error happened with the server side
      console.log(error.response);
    // some error happened while processing the request
    else console.error("Error Message:", error.message);
    return Promise.reject(error.response || error.message);
  };
  return axios({
    baseURL: environment.api.URL,
    ...options,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      uid: uid,
    },
  })
    .then(onSuccess)
    .catch(onError);
};

export default apiRequest;
