import React from "react";
import style from "../../style/projectCard.module.css";
import { PlayCircleOutlined, EyeOutlined } from "@ant-design/icons";
export default function ProjectCard(props) {
  const { title, color, feeds, type } = props.data;

  const icons = {
    post: "projectIcon.svg",
    project: "brandIcon.svg",
    audience: "audienceIcon.svg",
    campaign: "campaignIcon.svg",
    team: "teamsIcon.svg",
    collection: "collectingIcon.svg",
    templates: "templatesIcon.svg",
  };

  const typeWord = type == "post" ? (props.count ? "Podcasts" : "") : "Items";
  const countWord = type == "post" ? props.count : feeds ? feeds.length : 0;
  return (
    <div
      className={`cards ${style.ProjectCard}`}
      style={{
        backgroundColor: "rgb(30 30 30)",
      }}
      onClick={() =>
        props.onAdd
          ? ""
          : props.campaignSelect
          ? props.onPreview(props.data)
          : props.onSelect(true)
      }
    >
      <div className="d-flex justify-content-between w-100">
        <PlayCircleOutlined style={{ fontSize: "2em", cursor: "pointer" }} />
        {props.todayScreen ? (
          <img
            src={`/images/${icons[type]}`}
            style={{
              filter: type == "post" ? "none" : "invert(1)",
              width: "25px",
            }}
            alt=""
          />
        ) : (
          ""
        )}
      </div>

      <p>{title}</p>
      <div className="d-flex align-items-center justify-content-between w-100">
        {props.onAdd && props.onAdd != "temp" ? (
          <div>
            <img
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.onAdd(props.data);
              }}
              className="mr-2 mb-2"
              style={{ cursor: "pointer", width: "20px" }}
              src="/images/plusIcon.svg"
              alt=""
            />
          </div>
        ) : props.selectable ? (
          ""
        ) : (
          ""
        )}
        {props.showEdit == false ? (
          ""
        ) : (
          <div>
            <img
              onClick={(e) => {
                e.stopPropagation();

                props.onEdit();
                // e.stopPropagation();
              }}
              className="mr-2 mb-2"
              style={{ cursor: "pointer" }}
              src="/images/pencilIcon.svg"
              alt=""
            />
          </div>
        )}
        <div className="text-right w-100">
          <h4>{countWord}</h4>
          <span className={style.smText}>{typeWord}</span>
        </div>
      </div>
    </div>
  );
}
