import React from "react";
import FileDropZone from "./FileDropZone";

export default function UploadAudio() {
  const [selectedFile, setSelectedFile] = React.useState("");

  return (
    <div>
      <FileDropZone
        onSelectedFile={(image) => {
          console.log(image[0]);
          if (image && image.length > 0) setSelectedFile(image);
        }}
      />
    </div>
  );
}
