import React, { useState } from "react";
import style from "./styles/form.module.css";
import { Modal } from "antd";
import SelectCountry from "./components/SelectCountry";
import SelectedField from "./components/SelectedField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddingSource,
  setPostData,
  changeToCategoriesTab,
  createAdvertisement,
  uploadStandardPost,
  setMeter,
  setMeterProgress,
  setMeterTitle,
  uploadIntroOutro,
} from "../../redux/actions/main";
export default function Form() {
  const dispatch = useDispatch();
  const [djName, setDjName] = useState(["Add New", "Hameed", "Tony"]);
  const [djNameVal, setDjNameVal] = useState("");
  const { postData } = useSelector((state) => state.main);
  const [djNameSecond, setDjNameSecond] = useState(["Add New"]);
  const [djNameSecondVal, setDjNameSecondVal] = useState("");

  const [channelFrequency, setChannelFrequency] = useState(["Add New"]);
  const [channelFrequencyVal, setChannelFrequencyVal] = useState("");

  const [channelName, setChannelName] = useState(["Add New"]);
  const [channelNameVal, setChannelNameVal] = useState("");

  React.useEffect(() => {
    if (postData.voice) {
      console.log(postData, "voice is");
      try {
        let vars = JSON.parse(postData.voice);
        console.log(vars);
        setDjNameVal(vars.name);
        setDjNameSecondVal(vars.name2);
        setChannelFrequencyVal(vars.channel);
        setChannelNameVal(vars.channelName);
      } catch (e) {}
    }
  }, []);

  React.useEffect(() => {
    dispatch(
      setPostData({
        ...postData,
        variables: {
          name2: djNameSecondVal,
          name: djNameVal,
          channel: channelFrequencyVal,
          channelName: channelNameVal,
        },
        recipe: "Dj Radio Station Jingle",
      })
    );
  }, [djNameSecondVal, djNameVal, channelFrequencyVal, channelNameVal]);
  return (
    <>
      <div className={style.formWrapper}>
        <div className={style.FormContainer}>
          <span>You are now listening to </span>
          <SelectedField
            modalData={djName}
            defaultValue={djNameVal ? djNameVal : "name"}
            value={djNameVal}
            setValue={setDjNameVal}
            setModalData={(item) => setDjName([...djName, item])}
          />
          <span> in the mix. Ladies and Gentlemen, give way to </span>
          <SelectedField
            modalData={djNameSecond}
            defaultValue={djNameSecondVal ? djNameSecondVal : "name"}
            value={djNameSecondVal}
            setValue={setDjNameSecondVal}
            setModalData={(item) => setDjNameSecond([...djNameSecond, item])}
          />

          <span> on </span>
          <SelectedField
            modalData={channelFrequency}
            defaultValue={
              channelFrequencyVal ? channelFrequencyVal : "Station ID"
            }
            value={channelFrequencyVal}
            setValue={setChannelFrequencyVal}
            setModalData={(item) =>
              setChannelFrequency([...channelFrequency, item])
            }
          />
          <span>,</span>

          <SelectedField
            modalData={channelName}
            defaultValue={channelNameVal ? channelNameVal : "Station Name"}
            value={channelNameVal}
            setValue={setChannelNameVal}
            setModalData={(item) => setChannelName([...channelName, item])}
          />
        </div>
      </div>
    </>
  );
}
