import { promisify } from "es6-promisify";
import Auth0 from "auth0-js";
import { getUser } from "./db.js";

// Initialize Auth0
const auth0Realm = "Username-Password-Authentication";
const auth0 = new Auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
  responseType: "token id_token",
  scope: "openid profile email",
  redirectUri: "https://app.audioone.fyi/",
  // redirectUri: "http://localhost:3000/",
});

// First let's create promisified versions of the Auth0 methods we need
// so that we can use then().catch() instead of dealing with callback hell.
// We use bind so that internally "this" still has the correct scope.

const signupAndAuthorize = promisify(auth0.signupAndAuthorize.bind(auth0));
const login = promisify(auth0.client.login.bind(auth0.client));
const popupAuthorize = promisify(auth0.popup.authorize.bind(auth0.popup));
const userInfo = promisify(auth0.client.userInfo.bind(auth0.client));
const changePassword = promisify(auth0.changePassword.bind(auth0));
const signupwithpasswordless = promisify(auth0.passwordlessStart.bind(auth0));

// Now lets wrap our methods with extra logic, such as including a "connection" value
// and ensuring human readable errors are thrown for our UI to catch and display.
// We make these custom methods available within an auth0.extended object.

let onChangeCallback = () => null;

auth0.extended = {
  getCurrentUser: () => {
    const accessToken = getAccessToken();
    return userInfo(accessToken).catch(handleError);
  },

  signupAndAuthorize: (options) => {
    return signupAndAuthorize({
      connection: auth0Realm,
      ...options,
    })
      .then(handleAuth)
      .catch(handleError);
  },

  signupwithpasswordless: (options) => {
    return signupwithpasswordless({
      connection: "email",
      send: "link",
      ...options,
    })
      .then(handlePasswordlessUser)
      .catch(handleError);
  },

  login: (options) => {
    return login({
      realm: auth0Realm,
      ...options,
    })
      .then(handleAuth)
      .catch(handleError);
  },

  popupAuthorize: (options) => {
    console.log("popup authorize ", options);
    return popupAuthorize(options).then(handleAuth).catch(handleError);
  },

  // Send email so user can reset password
  changePassword: (options) => {
    return changePassword({
      connection: auth0Realm,
      ...options,
    }).catch((error) => handleError(error, true));
  },

  logout: () => {
    handleLogout();
  },

  parseHashFromUrl: () => {
    // console.log("authresult",authResult)
    auth0.parseHash((err, authResult) => {
      console.log(err);
      console.log("authresult", authResult);

      if (authResult && authResult.accessToken && authResult.idToken) {
        setUid(authResult.idTokenPayload.sub);
        setAccessToken(authResult.accessToken);
        window.location = "/";
      } else if (err) {
        console.error(err);
      }
    });
    return true;
  },

  // A method for listening to to auth changes and receiving user data in passed callback
  onChange: function (cb) {
    // Store passed callback function
    onChangeCallback = cb;

    const handleOnChange = (data, key) => {
      console.log("inside storage", key, data);
      if (key === TOKEN_STORAGE_KEY && data) {
        userInfo(data)
          .then((user) => {
            console.log(user);
            onChangeCallback(user);
          })
          .catch((error) => handleError(error, true));
      }
      //using uid if no access token
      // else if (key === UID_STORAGE_KEY && data) {
      //   getUser(data)
      //     .then((user) => {
      //       user.sub = user.uid;
      //       onChangeCallback(user);
      //     })
      //     .catch((error) => handleError(error, true));
      // }
      else {
        onChangeCallback(false);
      }
    };

    // Local Storage listener
    // This is ONLY called when storage is changed by another tab so we
    // must manually call onChangeCallback after any user triggered changes.
    const listener = window.addEventListener(
      "storage",
      ({ key, newValue }) => {
        if (key === TOKEN_STORAGE_KEY) {
          handleOnChange(newValue, key);
        }
        // if token not present, check for uid for possibility of passwordless user
        else if (key === UID_STORAGE_KEY) {
          handleOnChange(newValue, key);
        }
      },
      false
    );

    // Get accessToken from storage and call handleOnChange.
    const accessToken = getAccessToken();
    if (accessToken == null) {
      const uid = getUid();
      handleOnChange(uid, UID_STORAGE_KEY);
    } else {
      handleOnChange(accessToken, TOKEN_STORAGE_KEY);
    }

    // Return an unsubscribe function so calling function can
    // call unsubscribe when needed (such as when a component unmounts).
    return () => {
      window.removeEventListener("storage", listener);
    };
  },

  getAccessToken: () => getAccessToken(),
  getUid: () => getUid(),
};

// Gets passed auth response, stores accessToken, returns user data.
const handleAuth = (response) => {
  setAccessToken(response.accessToken);
  return userInfo(response.accessToken).then((user) => {
    console.log(user);
    setUid(user.sub);
    onChangeCallback(user);
    return user;
  });
};

const handlePasswordlessUser = (response) => {
  const user = {
    email: response.email,
    email_verified: response.emailVerified,
    sub: "email|" + response.Id,
    uid: "email|" + response.Id,
  };
  setUid(user.uid);
  onChangeCallback(user);
  return user;
};

const handleLogout = () => {
  removeAccessToken();
  removeUid();
  onChangeCallback(false);
};

const handleError = (error, autoLogout = false) => {
  console.log("popo up authorize error", error, autoLogout);

  // If error code indicates user is unauthorized then log them out.
  // We only do this if autoLogout is enabled so we can skip in instances
  // where it's not possible its due to token expiration (such as right after login)
  // and we'd rather throw an error that can be displayed by the UI.
  if (error.code === 401 && autoLogout) {
    handleLogout();
  }

  // Find a human readable error message in an Auth0 error object and throw.
  // Unfortunately, it's not always in the same location :/
  let message;
  if (error.code === "invalid_password") {
    message = `Your password must be: ${error.policy}`;
  } else if (typeof error.message === "string") {
    message = error.message;
  } else if (typeof error.description === "string") {
    message = error.description;
  } else if (typeof error.original === "string") {
    message = error.original;
  } else if (error.original && typeof error.original.message === "string") {
    message = error.original.message;
  } else {
    message = error.code; // Use error.code if no better option
  }
  console.log(error.code, message);
};

// Local Storage methods
const TOKEN_STORAGE_KEY = "auth0_access_token";
const UID_STORAGE_KEY = "auth0_uid";
const getAccessToken = () => localStorage.getItem(TOKEN_STORAGE_KEY);
const setAccessToken = (accessToken) =>
  localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
const getUid = () => localStorage.getItem(UID_STORAGE_KEY);
const setUid = (uid) => localStorage.setItem(UID_STORAGE_KEY, uid);
const removeAccessToken = () => localStorage.removeItem(TOKEN_STORAGE_KEY);
const removeUid = () => localStorage.removeItem(UID_STORAGE_KEY);

export default auth0;
