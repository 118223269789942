import React from "react";
import { Modal, Button, message } from "antd";
import styles from "../style/sharemodal.module.css";
import _ from "lodash";
import { connect } from "react-redux";
import { Switch } from "antd";
import { updatedEmbed } from "../redux/actions/project";
import { useDispatch } from "react-redux";

function EmbedModal(props) {
  const dispatch = useDispatch();
  const [showControls, setShowControls] = React.useState(true);

  React.useEffect(() => {
    setShowControls(true);
  }, [props.visible]);

  const copyText = (text) => {
    const self = this;
    navigator.clipboard.writeText(text).then(
      function () {
        message.success("Link Copied To Clipboard", 7);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const updatedControls = (showControlValue) => {
    dispatch(
      updatedEmbed({
        embedId: props.createdEmbed._id,
        showControls: showControlValue,
      })
    );
  };

  return (
    <Modal
      visible={props.visible}
      title="Embed Options"
      footer={[]}
      className={`${styles.shareEmail} ${styles.embedLink} shareEmail share-modal shareLinkModal `}
      onCancel={() => {
        props.setVisibility(false);
      }}
    >
      <div className="p-3">
        <div className="row">
          <div
            dangerouslySetInnerHTML={{ __html: props.loadedLink }}
            className="col-md-6 py-4 iframeContainer"
          ></div>
          <div className="col-md-6 p-5 ">
            <div
              style={{
                background: "gainsboro",
                color: "black",
                padding: "0.6em",
                borderRadius: "8px",
                marginBottom: "33px",
                cursor: "pointer",
              }}
              onClick={() => copyText(props.loadedLink)}
            >
              {props.loadedLink}
            </div>
            <div className="d-flex">
              <img
                className={styles.shareArticleImage}
                src={props.loadedArticle ? props.loadedArticle.image : ""}
              />
              <h4 className={styles.shareArticleTitle}>
                {props.loadedArticle ? props.loadedArticle.title : ""}
              </h4>
            </div>

            <div className="mt-4">
              <p htmlFor="">Show Controls</p>
              <Switch
                checkedChildren={<span>On</span>}
                unCheckedChildren={<span>Off</span>}
                checked={showControls}
                onChange={() => {
                  updatedControls(!showControls);
                  setShowControls(!showControls);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

var mapState = (state) => {
  return {
    user: state.auth.user,
    feedlyUser: state.news.feedlyUser,
  };
};

export default connect(mapState, null)(EmbedModal);
