import { combineReducers } from "redux";
import Auth from "./auth";
import Site from "./site";
import News from "./news";
import Search from "./search";
import Teams from "./team";
import Projects from "./projects";
import Main from "./main";
import Campaign from "./campaign";

var reducers = {
  auth: Auth,
  site: Site,
  search: Search,
  team: Teams,
  news: News,
  projects: Projects,
  main: Main,
  campaign: Campaign,
};

export default reducers;
