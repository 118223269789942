export default {
  SET_RECENT: "sets recent items",
  ADD_RECENT: "adds a recent item",
  ADD_SOURCE: "adding source",
  CHANGE_TAB: "changing the tab",
  SET_MEMBER_SEARCH: "sets the member search",
  SET_INDIVIDUAL_SEARCH: "toggle individual search",
  SET_COLLECTION_ADD_ITEM: "set collections add item",
  SET_POST_DATA: "sets post data",
  SET_POST_CATEGORY: "sets post category",
  SET_SELECTED_AUDIENCE: "sets selected audience",
  SET_RECORD: "SET_RECORD",
  SET_METER_TITLE: "sets a meter title",
  SET_METER_PROGRESS: "sets a meter progress",
  SET_METER: "set meter display",
  SET_SHARE_SIDEBAR: "SET_SHARE_SIDEBAR",
  SET_ADD_ITEM: "SET_ADD_ITEM",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_AUDIENCE_CARD: "SET_AUDIENCE_CARD",
  SET_INTERVIEW_ACTIONS: "SET_INTERVIEW_ACTIONS",
  ADD_INTERVIEW_MEMBER: "ADD_INTERVIEW_MEMBER",
  SET_INTERVIEW_OPTIONS: "SET_INTERVIEW_OPTIONS",
  SET_SELECTED_TAG: "SET_SELECTED_TAG",
};
