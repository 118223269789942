import React, { useState, useEffect } from "react";
import createStyle from "../../style/createproject.module.css";
import ProjectCard from "../project/ProjectCard";
import style from "../../style/ProjectDetail.module.css";
import { connect } from "react-redux";
import { updateProject, editProject } from "../../redux/actions/project";
import apiRequest from "../../api/clientRequest";
import { message } from "antd";
import MemberContainer from "./MembersContainer";

var mapStateToProps = (state) => {
  return {
    activeProject: state.projects.activeProject,
  };
};

const mapDispatch = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  editProject: (data) => dispatch(editProject(data)),
});

function AudienceDetail(props) {
  const [editing, setEditing] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { project } = props;

  const onSearch = async (val) => {
    setSearchVal(val);
    const results = await apiRequest({
      url: `/users/search/${val}`,
      method: "GET",
    }).then((res) => res.data);
    setSearchResults(results);
  };

  return (
    <div>
      <div style={{ position: "relative" }} className="d-flex">
        <ProjectCard
          data={{ ...project, feeds: [] }}
          onEdit={props.onEdit}
          onPreview={() => console.log("preview")}
        />
        <div
          className={createStyle.createContainer}
          style={{ paddingTop: "2em", paddingLeft: "2em" }}
        >
          <div
            style={{ marginTop: "4em", width: "160px" }}
            className="d-flex flex-column align-items-center"
          >
            <button
              className={createStyle.addSourcesBtn}
              onClick={() => setEditing(!editing)}
            >
              <img src="/images/plusIcon.svg" alt="" />
            </button>
            <span className="mt-2">Add Members</span>
          </div>

          <div
            className={createStyle.inputContainer}
            style={{ marginLeft: "5em", marginTop: "-2em" }}
          >
            {editing ? (
              <input
                type="text"
                className={`${createStyle.searchInput} w-100`}
                placeholder={`Search for members`}
                value={searchVal}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
            ) : (
              <div className={style.Description}>
                <h6>TEAM DESCRIPTION</h6>
                <p>{props.project.description}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <MemberContainer editing={editing} searchResults={searchResults} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(AudienceDetail);
