import React, { useState, useEffect, useRef } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { updateProject, editProject } from "../../redux/actions/project";
import _ from "lodash";
import createStyle from "../../style/createproject.module.css";
import MemberContainer from "../Teams/MembersContainer";
import MemberSearch from "../MemberSearch";
import {
  setAddingSource,
  setPostData,
  changeToCategoriesTab,
  createAdvertisement,
  uploadStandardPost,
  setMeter,
  setMeterProgress,
  setMeterTitle,
  uploadIntroOutro,
} from "../../redux/actions/main";
import AddPost from "./AddPost";
import PostCard from "./PostCard";
import { message } from "antd";
import StrapiRequest from "../../api/strapiRequest";
import apiRequest from "../../api/clientRequest";
import logRequest from "../../api/logRequest";
import moment from "moment";
import environment from "../../api/environment";
import { SettingFilled } from "@ant-design/icons";
import InterviewBar from "./InterviewBar";
import RecordBar from "./RecordBar";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  user: state.auth.user,
  addSource: state.main.addSource,
  postData: state.main.postData,
  selectedPostCategory: state.main.selectedPostCategory,
  recordMode: state.main.recordMode,
  socket: state.site.socket,
  interviewAction: state.main.interviewAction,
});

const dispatchState = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  editProject: (data) => dispatch(editProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  createAdvertisement: (feed) => dispatch(createAdvertisement(feed)),
  uploadStandardPost: (feed, doTitle) =>
    dispatch(uploadStandardPost(feed, doTitle)),
  setMeter: (feed) => dispatch(setMeter(feed)),
  setMeterProgress: (feed) => dispatch(setMeterProgress(feed)),
  setMeterTitle: (feed) => dispatch(setMeterTitle(feed)),
  uploadIntroOutro: (feed) => dispatch(uploadIntroOutro(feed)),
});

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function ProjectDetailTab(props) {
  const [mainTab, setMainTab] = useState("PODCASTS");
  const [items, setItems] = useState([]);
  const [body, setBody] = useState({});
  const [createType, setCreateType] = useState(false);

  const [listenerAttached, setListenerAttached] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editorContainer, setEditorContainer] = useState(null);
  const [groupView, setGroupView] = useState(true);
  const propsRef = React.useRef(props.postData);
  const editorBodyRef = React.useRef("");
  const previewRef = React.useRef("");
  const editingRef = React.useRef("");

  const tabMains = ["PODCASTS"];
  const { addingContent } = props;
  const { socket } = props;
  if (props.addSource !== "News")
    if (props.addSource == "Members" || props.addSource == "Teams")
      tabMains.push("Members");
    else {
      tabMains.push(props.addSource);
    }

  React.useEffect(() => {
    attachSockets();
    return () => {
      detachSockets();
    };
  }, []);

  React.useEffect(() => {
    propsRef.current = props.postData;
  }, [props.postData]);

  const attachSockets = () => {
    if (!listenerAttached) {
      setListenerAttached(true);
      socket.on("completedTTS", (data) => {
        console.log(data, "completed tts");
        if (data.progress == 100) {
          createPost(data.speechUrl);
        } else {
          props.setMeterTitle(data.progressTitle);
          props.setMeterProgress(data.progress);
        }
      });
    }
  };

  const detachSockets = () => {
    setListenerAttached(false);
    socket.off("completedTTS");
  };

  const onStartCreatingPost = async (preview = false) => {
    const postType = props.selectedPostType
      ? props.selectedPostType
      : "STANDARD";

    if (!props.postData.title) {
      message.error("Please provide a title");
      return;
    }

    if (
      postType !== "STORY" &&
      postType !== "INTERVIEW" &&
      postType !== "INTRO" &&
      postType !== "OUTRO"
    ) {
      var tempBody = await editorContainer.save();
      editorBodyRef.current = tempBody;
    }
    editingRef.current = editing;
    previewRef.current = preview;

    props.setMeter(true);
    props.setMeterTitle("Processing");
    props.setMeterProgress(25);

    setCreateType(preview);

    if (postType == "STANDARD") {
      props.uploadStandardPost(tempBody, postType == "STANDARD" ? true : false);
    } else if (postType == "INTRO" || postType == "OUTRO") {
      props.uploadIntroOutro();
    } else if (postType == "STORY") {
      createStory();
    } else if (postType == "INTERVIEW") {
      createInterview();
    } else if (postType == "AD") {
      props.createAdvertisement(tempBody);
    } else if (postType == "REC0RD") {
      createPost(props.postData.speechUrl);
    }
  };

  const createStory = async () => {
    props.setMeter(true);
    props.setMeterTitle("Creating Story");
    props.setMeterProgress(25);
    const editingPost = editingRef.current;

    let postData = props.postData;

    if (postData.created || editingPost) {
      logRequest({ action: `Edited story with name ${postData.title}` });
      await StrapiRequest({
        url: `stories/${postData.id}`,
        method: "PUT",
        data: {
          title: postData.title,
          published: new Date(),
          categories: postData.categories
            ? postData.categories.map((item) => item.id)
            : [],
          post_id: props.activeProject._id,
          source: props.user.fullname,
          user_id: props.user.strapiUser.id,
          draft: false,
        },
      })
        .then(async (res) => {
          if (postData.storyImage && postData.storyImage.file) {
            props.setMeterTitle("Uploading Thumbnail");
            props.setMeterProgress(90);
            var file = dataURLtoFile(
              postData.storyImage.file,
              postData.storyImage.name
            );

            const data = new FormData();
            data.append("files", file);
            data.append("refId", res.data._id);
            data.append("ref", "story");
            data.append("field", "image");
            await StrapiRequest({
              url: "upload",
              data,
              method: "post",
            });
          }
          props.setMeterTitle("Story Saved Successfully");
          props.setMeterProgress(100);
          setMainTab("PODCASTS");
          setBody({});
          props.setAddingSource("News");
          props.changeToCategoriesTab("EDIT PODCAST");
          props.setTitle("");
          setEditing(false);

          props.setPostData({
            title: "",
            categories: [],
            editing: "none",
            voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
          });
          props.fetchPosts();
          message.success("Story Published");
        })
        .catch((err) => {
          console.log(err);
          logRequest({
            action: `Failed while editing story with name ${postData.title}`,
          });

          props.setMeter(false);
        });
    } else {
      logRequest({ action: `Created story with name ${postData.title}` });

      await StrapiRequest({
        url: `stories`,
        method: "POST",
        data: {
          title: postData.title,
          published: new Date(),
          categories: postData.categories
            ? postData.categories.map((item) => item.id)
            : [],
          post_id: props.activeProject._id,
          source: props.user.fullname,
          user_id: props.user.strapiUser.id,
          draft: false,
        },
      })
        .then(async (res) => {
          if (postData.storyImage && postData.storyImage.file) {
            props.setMeterTitle("Uploading Thumbnail");
            props.setMeterProgress(90);
            var file = dataURLtoFile(
              postData.storyImage.file,
              postData.storyImage.name
            );
            const data = new FormData();
            data.append("files", file);
            data.append("refId", res.data._id);
            data.append("ref", "story");
            data.append("field", "image");
            await StrapiRequest({
              url: "upload",
              data,
              method: "post",
            });
          }
          props.setMeterTitle("Story Saved Successfully");
          props.setMeterProgress(100);
          setMainTab("PODCASTS");
          setBody({});
          props.setAddingSource("News");
          props.changeToCategoriesTab("EDIT PODCAST");
          props.setTitle("");
          setEditing(false);
          props.fetchPosts();
          message.success("Story Published");
        })
        .catch((err) => {
          console.log(err);
          logRequest({
            action: `Failed while creating story with name ${postData.title}`,
          });

          props.setMeter(false);
        });
    }
    setCreateType(false);

    setTimeout(() => {
      props.setMeter(false);
      props.setMeterTitle("");
      props.setMeterProgress(0);
    }, 2000);
  };

  const createInterview = async () => {
    props.setMeter(true);
    props.setMeterTitle("Creating Interview");
    props.setMeterProgress(25);
    const editingPost = editingRef.current;

    let postData = props.postData;
    if (postData.created || editingPost) {
      // await StrapiRequest({
      //   url: `stories/${postData.id}`,
      //   method: "PUT",
      //   data: {
      //     title: postData.title,
      //     published: new Date(),
      //     categories: postData.categories
      //       ? postData.categories.map((item) => item.id)
      //       : [],
      //     post_id: props.activeProject._id,
      //     source: props.user.fullname,
      //     user_id: props.user.strapiUser.id,
      //     draft: false,
      //   },
      // })
      //   .then(async (res) => {
      //     if (postData.storyImage && postData.storyImage.file) {
      //       props.setMeterTitle("Uploading Thumbnail");
      //       props.setMeterProgress(90);
      //       var file = dataURLtoFile(
      //         postData.storyImage.file,
      //         postData.storyImage.name
      //       );
      //       const data = new FormData();
      //       data.append("files", file);
      //       data.append("refId", res.data._id);
      //       data.append("ref", "story");
      //       data.append("field", "image");
      //       await StrapiRequest({
      //         url: "upload",
      //         data,
      //         method: "post",
      //       });
      //     }
      //     props.setMeterTitle("Story Saved Successfully");
      //     props.setMeterProgress(100);
      //     setMainTab("PODCASTS");
      //     setBody({});
      //     props.setAddingSource("News");
      //     props.changeToCategoriesTab("EDIT PODCAST");
      //     props.setTitle("");
      //     setEditing(false);
      //     props.setPostData({
      //       title: "",
      //       categories: [],
      //       editing: "none",
      //       voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
      //     });
      //     props.fetchPosts();
      //     message.success("Story Published");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     props.setMeter(false);
      //   });
    } else {
      logRequest({ action: `Created Interview with name ${postData.title}` });

      await db.collection("interview").doc(uuidv4()).set({
        title: postData.title,
        published: moment().format(),
        published_at: moment().format(),
        post_id: props.activeProject._id,
        source: props.user.fullname,
        user_id: props.user._id,
        user: props.user,
        draft: false,
        id: uuidv4(),
      });
      props.setMeterTitle("Interview Saved Successfully");
      props.setMeterProgress(100);
      setMainTab("PODCASTS");
      setBody({});
      props.setAddingSource("News");
      props.changeToCategoriesTab("EDIT PODCAST");
      props.setTitle("");
      setEditing(false);
      props.fetchPosts();
      message.success("Interview Published");
    }
    setCreateType(false);

    setTimeout(() => {
      props.setMeter(false);
      props.setMeterTitle("");
      props.setMeterProgress(0);
    }, 2000);
  };

  const createPost = async (speechUrl) => {
    const tempBody = editorBodyRef.current;
    const postData = propsRef.current;
    const preview = previewRef.current;
    const editingPost = editingRef.current;
    var speechUrl = speechUrl
      ? speechUrl
      : postData.speechUrl
      ? postData.speechUrl
      : "";
    const postType = postData.post_type
      ? postData.post_type
      : props.selectedPostType
      ? props.selectedPostType
      : "STANDARD";

    props.setMeterTitle("Saving Podcast");
    props.setMeterProgress(80);

    apiRequest({
      url: "/campaigns/clearcache",
      method: "GET",
    });

    console.log(postType, "post type this");
    console.log(postData);

    if (editingPost) {
      logRequest({
        action: `Edited podcast ${postType}  with name ${postData.title}`,
      });

      StrapiRequest({
        url: `posts/${editingPost._id}`,
        method: "PUT",
        data: {
          title: postData.title,
          body: JSON.stringify(tempBody),
          published: new Date(),
          categories: postData.categories
            ? postData.categories.map((item) => item.id)
            : [],
          post_id: props.activeProject._id,
          source: props.user.fullname,
          user_id: props.user.strapiUser.id,
          post_type: postType,
          voice:
            postType == "INTRO"
              ? JSON.stringify(postData.variables)
              : JSON.stringify(postData.voice),
          background: postData.background ? postData.background : "0",
          speechUrl: speechUrl,
          originalUrl: postData.originalUrl ? postData.originalUrl : "",
          draft: preview
            ? preview == "draft"
              ? true
              : postData.draft
              ? true
              : false
            : false,
        },
      })
        .then(async (res) => {
          const data = new FormData();
          if (postData.image && postData.image.file) {
            props.setMeterTitle("Uploading Thumbnail");
            props.setMeterProgress(80);

            var file = dataURLtoFile(postData.image.file, postData.image.name);
            data.append("files", file);
            data.append("refId", res.data._id);
            data.append("ref", "post");
            data.append("field", "image");
            await StrapiRequest({
              url: "upload",
              data,
              method: "post",
            });
          }
          props.setMeterTitle("Podcast Saved Successfully");
          props.setMeterProgress(100);

          if (preview == "preview") {
            const previewUrl = `${
              environment.amelia_url
            }${props.user.username.toLowerCase()}/preview/${res.data.id}`;
            window.open(previewUrl, "_blank");
            setEditing(res.data);
          } else {
            if (preview == "draft")
              message.success("Post Saved as a Draft Successfully.");
            else message.success("Post Updated Successfully.");

            setMainTab("PODCASTS");
            setBody({});
            props.setAddingSource("News");
            props.changeToCategoriesTab("EDIT PODCAST");
            props.setTitle("");
            setEditing(false);

            props.setPostData({
              title: "",
              categories: [],
              editing: "none",
              voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
              post_type: "STANDARD",
            });
            props.fetchPosts();
          }
        })
        .catch((err) => {
          logRequest({
            action: `Failed while editing podcast with name ${postData.title}`,
          });
          console.log(err);
        });
    } else {
      logRequest({
        action: `Created podcast ${postType}  with name ${postData.title}`,
      });
      const result = await apiRequest({
        method: "POST",
        url: "/post/createActivity",
        data: { activityId: uuidv4(), userId: props.user._id },
      });
      StrapiRequest({
        url: "posts",
        method: "POST",
        data: {
          activityId: result.data.id,
          title: postData.title,
          body: JSON.stringify(tempBody),
          published: new Date(),
          categories: postData.categories
            ? postData.categories.map((item) => item.id)
            : [],
          post_id: props.activeProject._id,
          source: props.user.fullname,
          user_id: props.user.strapiUser.id,
          voice:
            postType == "INTRO"
              ? JSON.stringify(postData.variables)
              : JSON.stringify(postData.voice),
          post_type: postType,
          speechUrl: speechUrl,
          originalUrl: postData.originalUrl ? postData.originalUrl : "",
          draft: preview ? true : false,
          background: postData.background ? postData.background : "",
        },
      })
        .then(async (res) => {
          if (postData.image && postData.image.file) {
            props.setMeterTitle("Uploading Thumbnail");
            props.setMeterProgress(80);
            var file = dataURLtoFile(postData.image.file, postData.image.name);
            const data = new FormData();
            data.append("files", file);
            data.append("refId", res.data._id);
            data.append("ref", "post");
            data.append("field", "image");
            await StrapiRequest({
              url: "upload",
              data,
              method: "post",
            });
          }

          props.setMeterTitle("Podcast Saved Successfully");
          props.setMeterProgress(100);

          if (preview == "preview") {
            const previewUrl = `${
              environment.amelia_url
            }${props.user.username.toLowerCase()}/preview/${res.data.id}`;
            window.open(previewUrl, "_blank");
            setEditing(res.data);
          } else {
            if (preview == "draft")
              message.success("Post Saved as a Draft Successfully.");
            else message.success("Post Created Successfully.");
            setMainTab("PODCASTS");
            setBody({});
            props.setAddingSource("News");
            props.changeToCategoriesTab("EDIT PODCAST");
            props.setTitle("");
            setEditing(false);
          }
          props.fetchPosts();
        })
        .catch((err) => {
          logRequest({
            action: `Failed while creating podcast with name ${postData.title}`,
          });
          console.log(err);
        });
    }

    setCreateType(false);

    setTimeout(() => {
      props.setMeter(false);
      props.setMeterTitle("");
      props.setMeterProgress(0);
    }, 2000);
  };

  useEffect(() => {
    if (props.addSource !== "News")
      if (props.addSource == "Members" || props.addSource == "Teams")
        setMainTab("Members");
      else {
        if (editing)
          if (props.addSource == "ADD PODCAST") {
            // createPost();

            props.setPostData({
              title: "",
              categories: [],
              editing: "none",
              voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
              post_type: "STANDARD",
            });
            setEditing(false);

            if (editorContainer && editorContainer.clear)
              editorContainer.clear();
            setBody({});
          }
        setMainTab(props.addSource);
      }
    else {
      setMainTab("PODCASTS");
    }
  }, [props.addSource]);

  useEffect(() => {
    if (props.postData.voiceSpeech && props.recordMode) {
      setBody({
        time: moment().format("X"),
        blocks: [
          { type: "paragraph", data: { text: props.postData.voiceSpeech } },
        ],
        version: "2.19.1",
      });
    }
  }, [props.postData]);

  const renderPostItem = (item) => (
    <div className="mb-3 py-0 pr-0">
      <PostCard
        onCardClick={() => {
          if (item.post_type !== "STORY" && item.post_type !== "INTERVIEW")
            setBody(JSON.parse(item.body));
          props.setPostData({
            id: item.id,
            title: item.title,
            categories: item.categories,
            editing: "edit",
            image: item.image,
            post_type: item.post_type,
            draft: item.draft,
            voice:
              item.post_type == "INTRO"
                ? item.voice
                : {
                    fullname: item.voice
                      ? JSON.parse(item.voice).fullname
                      : "Joanna",
                    avatar: item.voice
                      ? JSON.parse(item.voice).avatar
                      : "/images/joanna1.png",
                  },
            background:
              item.background && item.background !== "0" ? item.background : "",
            storyLines: item.story_lines
              ? item.story_lines.map((item) => {
                  if (item.description)
                    return {
                      ...item,
                      description: JSON.parse(item.description),
                    };
                  else return item;
                })
              : [],
            members: item.members
              ? item.members.map((item) => {
                  return { ...item.member, ...item, created: true };
                })
              : null,
          });
          setMainTab("EDIT PODCAST");
          props.setAddingSource("EDIT PODCAST");
          props.changeToCategoriesTab("EDIT PODCAST");
          setEditing(item);
          if (editorContainer && editorContainer.current)
            editorContainer.clear();
        }}
        data={{
          ...item,
          category:
            item.categories && item.categories.length > 0
              ? item.categories[0].name
              : "",
        }}
      />
    </div>
  );

  const renderPosts = () => {
    let posts = props.posts;

    if (props.selectedPostCategory.length > 0)
      posts = posts.filter((item) =>
        item.categories.find((cat) =>
          props.selectedPostCategory.find((it) => it.name == cat.name)
        )
      );

    if (groupView) {
      var groups = _.groupBy(posts, function (date) {
        if (moment().diff(moment(date.published_at), "days") == 0)
          return "Today";
        else if (moment().diff(moment(date.published_at), "days") == 1)
          return "Yesterday";
        else return moment(date.published_at).format("MM/DD/YYYY");
      });

      return (
        <div className="mt-5">
          {Object.keys(groups).map((item) => {
            return (
              <div className="mb-4">
                <h4
                  className="greenText mb-0"
                  style={{ fontSize: 17, fontWeight: "bold", color: "#38ef7d" }}
                >
                  {item}
                </h4>
                <div className="row mt-3 mx-0" style={{ paddingBottom: "2em" }}>
                  {groups[item].map((item) => renderPostItem(item))}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return posts.map((item) => renderPostItem(item));
    }
  };
  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <div
          className="d-flex align-items-center w-100"
          style={{ position: "relative" }}
        >
          <h5
            style={{
              cursor: "pointer",
              borderRadius: "10px",
              fontSize: "0.95em",
              lineHeight: "1px",
              padding: "6px 11px",
            }}
            className={`${createStyle.tabBtn} text-center mb-0 `}
            onClick={() => setGroupView(!groupView)}
          >
            <SettingFilled color="white" />
          </h5>
          {tabMains.map((item) => (
            <h5
              className={`${
                mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
              } text-center mb-0 `}
              style={{
                cursor: "pointer",
                textTransform: "uppercase",
                minWidth: "150px",
                padding: "6px 11px",
                borderRadius: "10px",
                fontSize: "0.95em",
              }}
              onClick={async () => {
                if (
                  editorContainer &&
                  editorContainer.current &&
                  item !== "ADD PODCAST" &&
                  props.postData.post_type !== "STORY" &&
                  props.postData.post_type !== "INTERVIEW"
                ) {
                  const tempBody = await editorContainer.save();
                  setBody(tempBody);
                  setEditorContainer(false);
                }
                if (item == "ADD PODCAST") {
                  props.changeToCategoriesTab("EDIT PODCAST");
                  props.setAddingSource("ADD PODCAST");
                }
                setMainTab(item);
              }}
            >
              {item}
            </h5>
          ))}

          <MemberSearch
            setItems={setItems}
            addingContent={addingContent}
            createPost={onStartCreatingPost}
            showPublishBtn={
              mainTab == "ADD PODCAST" || mainTab == "EDIT PODCAST"
            }
            onClickFeed={(feed = false) => {
              setMainTab("PODCASTS");
            }}
            createDraft={() => onStartCreatingPost("draft")}
            onPreview={() => onStartCreatingPost("preview")}
            editing={editing}
          />
        </div>
      </div>
      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {mainTab == "PODCASTS" ? (
          renderPosts()
        ) : mainTab == "Members" || props.interviewAction == "invite" ? (
          <MemberContainer editing={true} searchResults={items} />
        ) : (
          <AddPost
            fetchPosts={props.fetchPosts}
            setRef={(ref) => setEditorContainer(ref)}
            body={body}
          />
        )}
        {props.postData.post_type == "INTERVIEW" ? <InterviewBar /> : ""}
        {props.postData.post_type == "REC0RD" ? <RecordBar /> : ""}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(ProjectDetailTab);
