import React, { useState, useEffect } from "react";
import style from "../style/LatestFeed.module.css";
import PlayCard from "./PlayCard";
import InfiniteScroll from "react-infinite-scroll-component";
import apiRequest from "../api/clientRequest";
import { Button, message } from "antd";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { addItemsToCampaign } from "../redux/actions/project";
import PodcastCard from "./PodcastCard";
import _ from "lodash";
import ShortsCard from "./ShortsCard";
import moment from "moment";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  selectedFeed: state.news.selectedFeed,
  user: state.auth.user,
  feedlyUser: state.news.feedlyUser,
  activeTab: state.main.activeTab,
  todayItems: state.site.todayItems,
  recentItems: state.projects.activeProject
    ? state.projects.activeProject.type == "campaign"
      ? state.projects.activeProject.addedItems
      : state.projects.activeProject.recentItems
    : state.projects.activePreview
    ? state.projects.activePreview.type == "campaign"
      ? state.projects.activePreview.addedItems
      : state.projects.activePreview.recentItems
    : [],
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
});

function OrderFeedContainer(props) {
  const [tab, setTab] = useState("ALL");
  const [feedTracks, setFeedTracks] = useState([]);
  const [page, setPage] = useState(1);

  const tabNames = ["ALL", "News", "Podcast", "Shorts", "Video"];

  useEffect(() => {
    setTab("ALL");
  }, []);

  const renderItems = (data) => {
    let items = data;

    if (tab !== "ALL")
      if (props.selectedFeed && props.selectedFeed.label == "Shorts Categories")
        items = items;
      else items = items.filter((item) => item.type == tab.toLowerCase());
    return items.map((item) => {
      return (
        <div className="mb-3 py-0 pr-0">
          {item.type == "podcast" ? (
            <PodcastCard
              data={item}
              projectView={props.projectView}
              onClick={() => {
                props.onClick({ ...item, subtype: "podcast" });
              }}
              related={items.filter((ite) => ite.id !== item.id).slice(0, 4)}
            />
          ) : item.type == "shortscat" ? (
            <ShortsCard
              data={item}
              projectView={props.projectView}
              onClick={() => {
                props.onClick({
                  ...item,
                  id: item.title,
                  subtype: "shorts",
                });
              }}
              related={items.filter((ite) => ite.id !== item.id).slice(0, 4)}
            />
          ) : (
            <PlayCard
              data={{ ...item }}
              related={items.filter((ite) => ite.id !== item.id).slice(0, 4)}
            />
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    let temp = props.todayTab ? props.todayItems : props.recentItems;
    console.log(temp);
    temp = temp.map((item) => {
      return {
        ...JSON.parse(item.article),
        added: item.added,
      };
    });
    temp = temp.map((item) => {
      return {
        ...item,
        added: moment(item.added).format("MM/DD/YYYY"),
      };
    });

    setFeedTracks(temp);
  }, [props.recentItems, props.todayItems, props.activeTab]);

  useEffect(() => {
    if (
      props.activeTab !== "LATEST FEEDS" ||
      props.activeTab !== "PROJECT ITEMS" ||
      props.activeTab !== "NEWS BOARDS"
    ) {
      let temp = props.todayTab ? props.todayItems : props.recentItems;
      temp = temp.map((item) => {
        return {
          ...JSON.parse(item.article),
          added: item.added,
        };
      });
      temp = temp.filter((item) => item.title);
      temp = temp.map((item) => {
        return {
          ...item,
          added: moment(item.added).format("MM/DD/YYYY"),
        };
      });
      if (props.searchAble)
        temp = temp.filter((item) =>
          item.title.toLowerCase().includes(props.query)
        );

      setFeedTracks(temp);
    }
  }, [props.query]);
  console.log(feedTracks);
  var groups = _.groupBy(feedTracks, function (date) {
    if (moment().diff(moment(date.added), "days") == 0) return "Today";
    else if (moment().diff(moment(date.added), "days") == 1) return "Yesterday";
    else return moment(date.added).format("MM/DD/YYYY");
  });

  const { groupView } = props;

  return (
    <div
      className={`${style.LatestFeed} pb-5 ${props.projectView ? "mt-0" : ""}`}
    >
      {/* {props.projectView ? (
        ""
      ) : (
        <div className="mb-4 d-flex align-items-center">
          <h5 className="mb-0">{props.tab}</h5>
        </div>
      )} */}

      <InfiniteScroll
        dataLength={feedTracks.length}
        // next={fetchMoreData}
        hasMore={true}
        scrollableTarget={props.container}
      >
        {groupView ? (
          Object.keys(groups).map((item) => {
            return (
              <div className="mb-4">
                <h4
                  className="greenText mb-0"
                  style={{ fontSize: 17, fontWeight: "bold", color: "#38ef7d" }}
                >
                  {item}
                </h4>
                <div className="row mt-3 mx-0" style={{ paddingBottom: "2em" }}>
                  {renderItems(groups[item])}
                </div>
              </div>
            );
          })
        ) : (
          <div className="row mt-3 mx-0" style={{ paddingBottom: "2em" }}>
            {renderItems(feedTracks)}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default connect(mapState, dispatchState)(OrderFeedContainer);
