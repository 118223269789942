import React, { useState, useEffect } from "react";
import style from "../../style/Allproject.module.css";
import ProjectCard from "../project/ProjectCard";
import PostDetail from "./PostDetail";
import CreatePost from "./createPost";
import { connect } from "react-redux";
import {
  setActiveProject,
  setActivePreview,
} from "../../redux/actions/project";
import { setAddingSource } from "../../redux/actions/main";
import { useSelector } from "react-redux";
import StrapiRequest from "../../api/strapiRequest";
import InviteCard from "../InviteCard";
import InterviewDetail from "./InterviewDetail";
import { db } from "../../firebase";
var qs = require("qs");
const mapState = (state) => ({
  activeProject: state.projects.activeProject,
});

const mapDispatch = (dispatch) => ({
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  setActivePreview: (data) => dispatch(setActivePreview(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function AllProject(props) {
  const [creatingProject, setCreatingProjects] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(false);
  const [tab, setTab] = useState("All");
  const { user } = useSelector((state) => state.auth);
  const [invitedInterviews, setInvitedInterviews] = useState([]);
  useEffect(() => {
    const getData = async () => {
      await db
        .collection("interview-members")
        .where("user_id", "==", user._id)
        .onSnapshot(async (querySnapshot) => {
          let members = [];

          querySnapshot.forEach((doc) => {
            let itemData = doc.data();
            members.push(itemData);
          });

          let interviews = [];

          for (let i = 0; i < members.length; i++) {
            await db
              .collection("interview")
              .where("id", "==", members[i].interview)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let itemData = doc.data();
                  interviews.push({
                    ...itemData,
                    type: "news",
                    post_type: "INTERVIEW",
                  });
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
          }

          for (let i = 0; i < interviews.length; i++) {
            let interviewMembers = [];
            await db
              .collection("interview-members")
              .where("interview", "==", interviews[i].id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let itemData = doc.data();
                  interviewMembers.push(itemData);
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
            interviews[i]["members"] = interviewMembers;
          }
          setInvitedInterviews(interviews);
        });
    };

    getData();
  }, []);

  useEffect(() => {
    if (props.activeProject) setTab("Post");
    return () => {};
  }, [props.activeProject]);

  return (
    <div>
      <div className={style.ProjectLabel}>
        <span
          onClick={() => {
            props.setActiveProject(null);
            setCreatingProjects(false);
            setTab("All");
            setSelectedInterview(false);
          }}
          className={tab == "All" ? style.active : ""}
        >
          ALL
        </span>
        <span className={tab == "Post" ? style.active : ""}>
          {props.activeProject ? "PODCAST" : "PODCASTS"}
        </span>
      </div>

      {invitedInterviews.length > 0 && !props.activeProject ? (
        <>
          <div style={{ marginTop: "10.5em" }}>
            {!props.activeProject || creatingProject ? (
              <>
                <h4>Interviews</h4>
                <div
                  style={{ flexWrap: "wrap", marginTop: 0 }}
                  className={`${style.ProjectCards} Projects px-0`}
                  id="projectDetail"
                >
                  {invitedInterviews.map((item) => (
                    <InviteCard
                      selectable={true}
                      onSelect={() => setSelectedInterview(item)}
                      data={item}
                      showEdit={false}
                    />
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}

      <div
        style={{
          flexWrap: "wrap",
          paddingTop:
            invitedInterviews.length > 0 && !props.activeProject
              ? "1em"
              : "10.5em",
          marginTop: 0,
          height: props.activeProject ? "100vh" : "auto",
        }}
        className={`${style.ProjectCards} Projects px-0`}
        id="projectDetail"
      >
        {selectedInterview ? "" : <h4 className="w-100">Podcasts</h4>}

        {selectedInterview ? (
          <InterviewDetail interview={selectedInterview} />
        ) : !props.activeProject || creatingProject || selectedInterview ? (
          <CreatePost
            createProject={creatingProject}
            setCreatingProjects={(cond) => {
              setCreatingProjects(cond);
              if (cond) {
                setTab("Post");
              } else {
                setTab("All");
              }
            }}
          />
        ) : (
          ""
        )}

        {selectedInterview ? (
          ""
        ) : props.activeProject && !creatingProject ? (
          <PostDetail
            project={props.activeProject}
            onEdit={() => {
              props.setAddingSource("EDIT PODCAST");
              setCreatingProjects(true);
            }}
          />
        ) : creatingProject ? (
          ""
        ) : (
          props.projects.map((item) => (
            <ProjectCard
              selectable={true}
              onSelect={() => props.setActiveProject(item)}
              onPreview={() => props.setActivePreview(item)}
              data={item}
              showEdit={false}
            />
          ))
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default connect(mapState, mapDispatch)(AllProject);
