import React, { Component } from "react";
import { Link } from "react-router-dom";

class navbar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.state = {
      dropdownOpen: false,
      tab: 0,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleTabChange(event, newValue) {
    this.props.setTab(newValue);
  }
  render() {
    const spotUser = window.localStorage.getItem("spotify_token");

    return (
      <nav className="navbar head">
        <div className="brand sign-brand">
          <span>
            <img
              src="/images/AudioOneLogo@2x.png"
              alt=""
              className="ml-4"
              style={{ width: "150px", filter: "brightness(0)" }}
            />
          </span>
        </div>
        <div className="links mr-4 d-flex">
          {/* <SearchBox /> */}

          <Link className="mx-4" to="/">
            Home
          </Link>
          <Link className="mx-4" to="/signin">
            Sign In
          </Link>
          {/* <Link className="mx-4" to="/signup">
            Sign Up
          </Link> */}
        </div>
      </nav>
    );
  }
}

export default navbar;
