import React from "react";
import style from "../../style/PlayCard.module.css";
import { connect } from "react-redux";
import { removeFromAudience } from "../../redux/actions/project";

const dispatchState = (dispatch) => ({
  removeFromAudience: (data) => dispatch(removeFromAudience(data)),
});

function ContactCard({ data, removeFromAudience }) {
  const {
    firstName,
    lastName,
    email,
    company,
    phone,
    birthday,
    address,
  } = data;

  return (
    <div className={`${style.PlayCard} ${style.contactCard}`}>
      <div className="d-flex align-items-start justify-content-between">
        <h5 className={style.contactName}>
          {firstName} {lastName}
        </h5>
      </div>
      <div>
        <h6>Email Address</h6>
        <p>{email}</p>
      </div>
      <div>
        <h6>Company Name</h6>
        <p>{company}</p>
      </div>

      <div className="d-flex flex-row justify-content-between w-100 mt-3">
        <div>
          <h6>Phone Number</h6>
          <p>{phone}</p>
        </div>

        <div className={style.ActionButton}>
          <img src="/images/pencilIcon.svg" alt="" />
          <img src="/images/addIcon.svg" alt="" />
          <img
            src="/images/delete.svg"
            onClick={() => {
              console.log("clicked");
              removeFromAudience(data);
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default connect(null, dispatchState)(ContactCard);
