import apiRequest from "../../api/clientRequest";
import moment from "moment";
var Types = {
  setMusicList: "setMusicList",
  setOnboarding: "setOnboarding",
  setSocket: "setSocket",
  setTab: "setTab",
  setQueue: "setQueue",
  spotifyFetchList: "spotifyFetchList",
  searchVal: "searchVal",
  searchType: "searchType",
  setTheme: "setTheme",
  setQueueTab: "setQueueTab",
  setSavedItems: "setSavedItems",
  setSubscribedItems: "setSubscribedItems",
  setSearchToggle: "setSearchToggle",
  setInstantSearch: "setInstantSearch",
  setThemeSelector: "setThemeSelector",
  SET_RECENT_PLAYED: "SET_RECENT_PLAYED",
  SET_COMPLETED_PODCAST: "SET_COMPLETED_PODCAST",
  UPDATE_RECENT_PLAYED: "UPDATE_RECENT_PLAYED",
  SET_SHORTS: "SET_SHORTS",
  setSharedItems: "setSharedItems",
  SET_METER_TITLE: "SET_METER_TITLE",
  SET_METER_PROGRESS: "SET_METER_PROGRESS",
  SET_METER_VISIBLE: "SET_METER_VISIBLE",
  SET_RECENT_USERS: "SET_RECENT_USERS",
  SET_AUDIO_HISTORY: "SET_AUDIO_HISTORY",
  SET_COMMENTARY: "SET_COMMENTARY",
  SET_SHARE: "SET_SHARE",
  SET_SHOW_SUBSCRIBED: "SET_SHOW_SUBSCRIBED",
  SET_TODAY_ITEMS: "SET_TODAY_ITEMS",
  SET_PLAYER_PROGRESS: "SET_PLAYER_PROGRESS",
};
// actions
var setOnboarding = (cond) => ({
  type: Types.setOnboarding,
  payload: cond,
});

// actions
var setMusicList = (cond) => ({
  type: Types.setMusicList,
  payload: cond,
});

var setSocket = (cond) => ({
  type: Types.setSocket,
  payload: cond,
});

var setTab = (cond) => ({
  type: Types.setTab,
  payload: cond,
});

var setQueue = (cond) => ({
  type: Types.setQueue,
  payload: cond,
});

var spotifyFetchList = (cond) => ({
  type: Types.spotifyFetchList,
  payload: cond,
});

var setSearchValue = (cond) => ({
  type: Types.searchVal,
  payload: cond,
});

var setSearchType = (cond) => ({
  type: Types.searchType,
  payload: cond,
});

var setTheme = (cond) => ({
  type: Types.setTheme,
  payload: cond,
});

var setQueueTab = (cond) => ({
  type: Types.setQueueTab,
  payload: cond,
});

var setSavedItems = (cond) => ({
  type: Types.setSavedItems,
  payload: cond,
});

var setSubscribedItems = (cond) => ({
  type: Types.setSubscribedItems,
  payload: cond,
});

var setSearchToggle = (cond) => ({
  type: Types.setSearchToggle,
  payload: cond,
});

var setInstantSearch = (cond) => ({
  type: Types.setInstantSearch,
  payload: cond,
});

var setThemeSelector = (cond) => ({
  type: Types.setThemeSelector,
  payload: cond,
});

var setRecentPlayed = (cond) => ({
  type: Types.SET_RECENT_PLAYED,
  payload: cond,
});

var updateRecentPlayed = (cond) => ({
  type: Types.UPDATE_RECENT_PLAYED,
  payload: cond,
});

var setShorts = (cond) => ({
  type: Types.SET_SHORTS,
  payload: cond,
});

var setSharedItems = (cond) => ({
  type: Types.setSharedItems,
  payload: cond,
});

var setMeterProgress = (cond) => ({
  type: Types.SET_METER_PROGRESS,
  payload: cond,
});

var setMeterTitle = (cond) => ({
  type: Types.SET_METER_TITLE,
  payload: cond,
});

var setMeter = (cond) => ({
  type: Types.SET_METER_VISIBLE,
  payload: cond,
});

var setRecentUsers = (cond) => ({
  type: Types.SET_RECENT_USERS,
  payload: cond,
});

var setAudioHistory = (cond) => ({
  type: Types.SET_AUDIO_HISTORY,
  payload: cond,
});

var setCommentary = (cond) => ({
  type: Types.SET_COMMENTARY,
  payload: cond,
});

var setShare = (cond) => ({
  type: Types.SET_SHARE,
  payload: cond,
});

var setShowPodcastSubscribed = (cond) => ({
  type: Types.SET_SHOW_SUBSCRIBED,
  payload: cond,
});

var getTodayItems = () => async (dispatch, getState) => {
  let recentItems = await apiRequest({
    method: "GET",
    url: "/today/recentItems",
  }).then((res) => res.data);
  dispatch({
    type: Types.SET_TODAY_ITEMS,
    payload: recentItems,
  });
};

var addTodayItem = (data) => async (dispatch, getState) => {
  const recentItems = await apiRequest({
    method: "POST",
    url: "/today/recentItems",
    data: {
      item: data,
      date: moment().format("MM/DD/YYYY"),
    },
  }).then((res) => res.data);
  dispatch({
    type: Types.SET_TODAY_ITEMS,
    payload: recentItems,
  });
};

var setPlayerProgress = (cond) => ({
  type: Types.SET_PLAYER_PROGRESS,
  payload: cond,
});

export default {
  setPlayerProgress,
  addTodayItem,
  getTodayItems,
  setShowPodcastSubscribed,
  setShare,
  setCommentary,
  setAudioHistory,
  setMeter,
  setRecentUsers,
  setMeterTitle,
  setMeterProgress,
  setSharedItems,
  setShorts,
  updateRecentPlayed,
  setRecentPlayed,
  setThemeSelector,
  setInstantSearch,
  setSearchToggle,
  setSubscribedItems,
  setSavedItems,
  setQueueTab,
  setTheme,
  setQueue,
  setOnboarding,
  setMusicList,
  setSocket,
  setTab,
  spotifyFetchList,
  setSearchValue,
  setSearchType,
  Types,
};
