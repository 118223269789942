import React from "react";
import style from "../../style/LatestFeed.module.css";
import OpenedCard from "./OpenedCard";
import _ from "lodash";

function AudienceClicked(props) {
  const { feeds, stats } = props.data;
  const renderItems = (data) => {
    let items = _.uniqBy(feeds, "sourceId");
    return items.map((item) => {
      return (
        <div className="mb-3 py-0 pr-0">
          <OpenedCard
            data={item}
            clicked={
              stats
                .filter((ite) => ite.event == "clicked")
                .filter((it) =>
                  it.url.split("---").join("/").includes(item.sourceId)
                ).length
            }
          />
        </div>
      );
    });
  };

  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <h5 className="mb-0" style={{ fontSize: "1.05em" }}>
          AUDIENCE WHO CLICKED
        </h5>
      </div>

      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {props.data.feeds && props.data.stats ? renderItems(props.data) : ""}
      </div>
    </div>
  );
}

export default AudienceClicked;
