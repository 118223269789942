import React, { Component, useState } from "react";
import FrontFooter from "../components/Footer/FrontFooter";
import message from "antd/lib/message";
import apiRequest from "../api/clientRequest";
import logRequest from "../api/logRequest";
import ACTIONS from "../redux/actions/auth";
import { connect } from "react-redux";
import auth0 from "../components/auth/auth0";
import { useAuth } from "../components/auth/auth";
import NewButton from "../components/NewButton";
import { useHistory } from "react-router-dom";

var mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setUser: (item) => dispatch(ACTIONS.setUser(item)),
});

function SignIn(props) {
  const [username, setUsername] = useState("");
  const auth = useAuth();
  const history = useHistory();

  const checkIfUserExists = (val) => {
    return apiRequest({
      method: "POST",
      url: `/auth/email/verify`,
      data: {
        email: val,
      },
    })
      .then((res) => true)
      .catch((err) => {
        return err.data.message;
      });
  };

  const onSubmit = async () => {
    if (!username) {
      message.error("Please enter your email");
    } else {
      const response = await checkIfUserExists(username);
      if (response !== "Email exists") {
        message.info("No account found with this email");
        return;
      }

      auth.signupwithpasswordless(username).then((user) => {
        console.log("auth user", user);
        history.push("/email-sent");
      });
    }
  };

  return (
    <div className="container ">
      <section
        className="session "
        data-action="native"
        style={{ marginTop: "9em" }}
      >
        <h1>Login to Your Account</h1>

        <div className="newFormContainer mb-5">
          <div className="formField" style={{ width: "326px" }}>
            <label htmlFor="">Email Address</label>
            <input
              type="email"
              className="black-outline-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Email"
            />
          </div>
        </div>
        <p style={{ marginBottom: "4em" }}>
          We'll send you an email to confirm it's you. once confirmed, you will
          be able to login
        </p>

        <NewButton onClick={onSubmit} text={"Sign-in"} />
      </section>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
