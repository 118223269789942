import Types from "../constants/campaign";
var defaultState = {
  campaigns: [],
  sendCampaign: null,
  buildCampaign: false,
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.BUILD_CAMPAIGN: {
      let newState = Object.assign({}, state);
      newState.buildCampaign = action.payload;
      return newState;
    }

    case Types.SEND_CAMPAIGN: {
      let newState = Object.assign({}, state);
      newState.sendCampaign = action.payload;
      return newState;
    }

    case Types.SET_CAMPAIGNS: {
      let newState = Object.assign({}, state);
      newState.campaigns = action.payload;
      return newState;
    }

    case Types.ADD_CAMPAIGN: {
      let newState = Object.assign({}, state);
      newState.campaigns = [action.payload, ...newState.projects];
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
