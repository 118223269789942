import React, { useState, useEffect } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { addItemsToCampaign, editProject } from "../../redux/actions/project";
import { setPostData, addInterviewMember } from "../../redux/actions/main";
import MemberCard from "./MemberCard";
import { message, Button } from "antd";
import apiRequest from "../../api/clientRequest";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  teamSearch: state.main.memberSearch,
  individualSearch: state.main.individualSearch,
  user: state.auth.user,
  interviewAction: state.main.interviewAction,
  postData: state.main.postData,
});

const dispatchState = (dispatch) => ({
  editProject: (data) => dispatch(editProject(data)),
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  addInterviewMember: (feed) => dispatch(addInterviewMember(feed)),
});

function MembersContainer(props) {
  const [contacts, setContacts] = useState(
    props.activeProject.members ? props.activeProject.members : []
  );

  useEffect(() => {
    if (!props.editing) setContacts(props.activeProject.members);
    return () => {};
  }, [props.activeProject]);

  useEffect(() => {
    if (props.editing) {
      setContacts(props.searchResults);
    } else setContacts(props.activeProject.members);
    return () => {};
  }, [props.editing]);

  useEffect(() => {
    if (props.editing) {
      setContacts(props.searchResults);
    }
    return () => {};
  }, [props.searchResults]);

  const onInviteMember = (val, invited) => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.interviewAction == "invite") {
      if (!props.postData.title) {
        message.error(`Please enter a title for the interview first`);
        return;
      }
      props.addInterviewMember(val);

      // if (invited) message.success("Member removed successfully.");
      // else message.success("Member Added successfully.");
      let newContacts = [...contacts];
      setContacts(newContacts);

      return;
    } else if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error(
              `Member can not ${invited ? "remove" : "invite"} members`
            );
            return;
          case "Editor":
            message.error(
              `Editor can not ${invited ? "remove" : "invite"} members`
            );
            return;
        }
    }

    if (invited)
      apiRequest({
        url: `/teams/member`,
        method: "DELETE",
        data: {
          teamId: props.activeProject._id,
          type: props.activeProject.type,
          email: val,
        },
      }).then((res) => {
        props.editProject(res.data);
        message.success("Member removed successfully.");
      });
    else
      apiRequest({
        url: "/teams/member",
        method: "POST",
        data: {
          teamId: props.activeProject._id,
          type: props.activeProject.type,
          member: val,
        },
      }).then((res) => {
        props.editProject(res.data);
        message.success("Member Added successfully.");
      });
  };
  const renderItems = (data) =>
    data.map((item) => (
      <div className="mb-3 py-0 pr-0">
        <MemberCard
          data={{ ...item }}
          isInvited={
            props.interviewAction == "invite"
              ? props.postData.members
                ? props.postData.members.find((ite) => ite.email == item.email)
                : false
              : props.activeProject && props.activeProject.members
              ? props.activeProject.members.find(
                  (ite) => ite.email == item.email
                )
              : false
          }
          onClick={() => {
            onInviteMember(
              item,
              props.interviewAction == "invite"
                ? props.postData.members
                  ? props.postData.members.find(
                      (ite) => ite.email == item.email
                    )
                  : false
                : props.activeProject.members
                ? props.activeProject.members.find(
                    (ite) => ite.email == item.email
                  )
                : false
            );
          }}
        />
      </div>
    ));

  return (
    <div className={`${style.LatestFeed} mt-0`}>
      <div
        className={`row mt-3 mx-0 ${style.dropContainer}`}
        style={{ paddingBottom: "10em" }}
      >
        {renderItems(contacts)}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(MembersContainer);
