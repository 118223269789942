import React, { Component } from "react";
import Upload from "antd/lib/upload";
import Icon from "antd/lib/icon";
import message from "antd/lib/message";
import apiRequest from "../../api/clientRequest";
import styles from "../../style/onboarding.module.css";
import NewButton from "../NewButton";

export default class Step2 extends Component {
  state = { pubName: "", tagLine: "", username: "" };

  onInputChange(name, e) {
    this.setState({
      [name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.username) message.error("Please Enter a username");
    else {
      apiRequest({
        url: "/auth/username/verify",
        method: "POST",
        data: { username: this.state.username },
      })
        .then((res) => {
          this.props.submitHandle({
            username: this.state.username,
            pubName: this.state.pubName,
            tagLine: this.state.tagLine,
          });
        })
        .catch((err) => {
          message.error(err.data.message);
        });
    }
  }

  render() {
    const { submitHandle } = this.props;

    return (
      <section className="session " style={{ marginTop: "5em" }}>
        <h1>Create Your Publication</h1>

        <div className="newFormContainer mb-5">
          <div className="formField" style={{ width: "326px" }}>
            <label htmlFor="">Publication Name</label>
            <input
              type="email"
              className="black-outline-input"
              value={this.state.pubName}
              onChange={(e) => this.onInputChange("pubName", e)}
              placeholder="My First Publication"
            />
          </div>
          <div className="formField mt-4" style={{ width: "326px" }}>
            <label htmlFor="">What's it about*</label>
            <input
              type="email"
              className="black-outline-input"
              value={this.state.tagLine}
              onChange={(e) => this.onInputChange("tagLine", e)}
              placeholder="Some Tagline"
            />
          </div>

          <div className="formField mt-4" style={{ flex: "1" }}>
            <label htmlFor="">Your AudioOne FYI URL*</label>
          </div>
          <div className="d-flex">
            <div className="formField " style={{ flex: "1" }}>
              <input
                type="email"
                className="black-outline-input"
                value={this.state.username}
                onChange={(e) => this.onInputChange("username", e)}
                placeholder="username"
              />
            </div>
            <div className="formField ml-2" style={{ flex: "1" }}>
              <input
                type="text"
                className="black-outline-input"
                value={".audioone.fyi"}
                readOnly
              />
            </div>
          </div>
        </div>

        <NewButton text={"Continue"} onClick={() => submitHandle(this.state)} />

        <p style={{ marginTop: "4em" }}>
          Don't worry you can always change the above later.
        </p>
      </section>
    );
  }
}
