import React from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import player from "../../data/player";
const Player = player();

export default function RecentItemCard({ data, onDelete }) {
  const { title, description, type, source, image, link } = data;

  const onPlay = () => {
    if (type == "shorts") Player.playShorts(data);
    else if (type == "podcast")
      if (data.episode) Player.playPodcast({ title, episode: data.episode[0] });
      else Player.playPodcast({ title: source, episode: data });
    else if (type == "post")
      Player.playAudio({ ...data, link }, false, data.speechUrl);
    else Player.playAudio(data);
  };
  return (
    <div className={style.RecentItemCard}>
      <div>
        <div
          className={`${style.backgroundImage} d-flex justify-content-center align-items-center`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <PlayCircleOutlined
            style={{ fontSize: "2em", cursor: "pointer" }}
            onClick={() => onPlay()}
          />
        </div>
        <div className="d-flex align-items-center mt-2 ml-2">
          <img
            src="/images/Feedly_Logo.svg"
            alt=""
            className="mr-2"
            style={{ filter: "invert(1)" }}
          />
          <span style={{ textTransform: "capitalize", fontSize: "0.7em" }}>
            {type}
          </span>
        </div>
      </div>
      <div className={style.RecentItemCardContent}>
        <h6>{title}</h6>
        <p>{source}</p>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`${style.actionBtn} mb-0 ml-auto`}
            style={{ flexWrap: "nowrap" }}
          >
            <img src="/images/chatting.svg" alt="" />
            <img src="/images/shareIcon.svg" alt="" />
            <img src="/images/heartlike.svg" alt="" />
            <img src="/images/delete.svg" alt="" onClick={() => onDelete()} />
          </div>
        </div>
      </div>
    </div>
  );
}
