import Types from "../constants/main";
import apiRequest from "../../api/clientRequest";
import axios from "axios";
import environment from "../../api/environment";
import Actions from "./auth";

const edjsHTML = require("editorjs-html");
const edjsParser = edjsHTML();
export const setRecentItems = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_RECENT,
    payload: data,
  });
};

export const addRecentItem = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.ADD_RECENT,
    payload: data,
  });
};

export const setAddingSource = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.ADD_SOURCE,
    payload: data,
  });
};

export const changeToCategoriesTab = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.CHANGE_TAB,
    payload: data,
  });
};

export const setPostData = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_POST_DATA,
    payload: data,
  });
};

export const setTitleSelectedTag = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_SELECTED_TAG,
    payload: data,
  });
};
export const addInterviewMember = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.ADD_INTERVIEW_MEMBER,
    payload: data,
  });
};

export const setRecordMode = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_RECORD,
    payload: data,
  });
};

export const setPostCategory = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_POST_CATEGORY,
    payload: data,
  });
};

export const setSelectedAudience = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_SELECTED_AUDIENCE,
    payload: data,
  });
};

export const setMeterTitle = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_METER_TITLE,
    payload: data,
  });
};

export const setMeterProgress = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_METER_PROGRESS,
    payload: data,
  });
};

export const setMeter = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_METER,
    payload: data,
  });
};

export const setShareSidebar = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_SHARE_SIDEBAR,
    payload: data,
  });
};

export const setAddItem = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_ADD_ITEM,
    payload: data,
  });
};

export const setAudienceCard = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_AUDIENCE_CARD,
    payload: data,
  });
};

export const setActiveTab = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_ACTIVE_TAB,
    payload: data,
  });
};

export const setInterviewActions = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_INTERVIEW_ACTIONS,
    payload: data,
  });
};

export const setInterviewOptions = (data) => (dispatch, getState) => {
  dispatch({
    type: Types.SET_INTERVIEW_OPTIONS,
    payload: data,
  });
};

export const uploadedRecordedAudio = (file) => async (dispatch, getState) => {
  const curPost = await getState().main.postData;
  const form = new FormData();
  const socket = await getState().site.socket;

  form.append("file", file.content, file.name);
  dispatch(setRecordMode(true));

  const response = await axios
    .post(`${environment.tts_url}/upload-file`, form)
    .catch((err) => {
      alert("unable to upload file");
      return false;
    });
  if (response) {
    let fileUrl = response.data.data;
    socket.emit("processRecordedAudio", {
      fileUrl: fileUrl,
      background: curPost.background,
      removeNoise: curPost.removeNoise,
      enhanceAudio: curPost.audioEnhancement,
      backgroundType: "DELAYED",
    });
  } else if (!response) return;

  // dispatch(
  //   setPostData({
  //     ...curPost,
  //     voiceSpeech: result.transcribe,
  //     originalUrl: result.url,
  //     speechUrl: response.data.data,
  //   })
  // );

  // setTimeout(() => {
  //   dispatch(setRecordMode(false));
  // }, 1000);
  return;
};

export const uploadStandardPost =
  (
    tempBody,
    doTitle,
    voice = false,
    background = false,
    backgroundType = false
  ) =>
  async (dispatch, getState) => {
    const curPost = await getState().main.postData;
    const socket = await getState().site.socket;
    let html = edjsParser.parse(tempBody);
    html = html.join("\n");
    html = html.replace(/<img[^>]*>/g, "");

    const isStory = curPost.post_type == "STORY" ? true : false;

    let image = curPost.image ? curPost.image : false;
    if (curPost.image && curPost.image.file) {
      image = curPost.image.file;
      const response = await axios({
        method: "post",
        url: `${environment.tts_url}/upload-file`,
        data: { base64: image },
      });
      image = response.data.data;
    }

    socket.emit("createPodcast", {
      html,
      background: background
        ? background
        : curPost.background
        ? curPost.background
        : false,
      title: doTitle ? curPost.title : "",
      voice: voice ? voice.fullname : curPost.voice.fullname,
      isStory,
      backgroundType,
      image: image,
    });
    return true;
  };

export const uploadIntroOutro = () => async (dispatch, getState) => {
  const curPost = await getState().main.postData;

  const socket = await getState().site.socket;

  socket.emit("createIntro", {
    variables: curPost.variables,
    recipe: curPost.recipe,
  });
  return true;
};

export const createAdvertisement = (tempBody) => async (dispatch, getState) => {
  const curPost = await getState().main.postData;
  const socket = await getState().site.socket;

  let html = edjsParser.parse(tempBody);
  html = html.join("\n");
  html = html.replace(/<img[^>]*>/g, "");

  socket.emit("createPodcast", {
    html,
    background: curPost.background,
  });
  return true;
};

export const uploadCommentary = (file) => async (dispatch, getState) => {
  const form = new FormData();
  form.append("audio", file.content, file.name);
  form.append("name", file.name);
  form.append("artId", file.id);
  form.append("userId", file.userId);
  form.append("email", file.email);

  const result = await apiRequest({
    url: "/post/commentary",
    method: "POST",
    data: form,
  }).then((res) => {
    return res.data;
  });
  const postData = await getState().main.postData;
  alert("Commentary Added");

  setTimeout(() => {
    dispatch(setRecordMode(false));
  }, 1000);
  console.log(result);
};

export const setProfileSettings = (data) => async (dispatch, getState) => {
  const result = await apiRequest({
    url: "/users/settings",
    method: "POST",
    data: data,
  })
    .then((res) => {
      dispatch(Actions.setUser(res.data));
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  return result;
};

export const searchPodcastEpisodes = async (data) => {
  const result = await apiRequest({
    url: "/news/search/podcast/episode",
    method: "POST",
    data: { query: data },
  }).then((res) => {
    return res.data.data;
  });
  return result;
};

export const searchPodcastShows = async (data) => {
  const result = await apiRequest({
    url: "/news/search/podcast",
    method: "POST",
    data: { query: data },
  }).then((res) => {
    return res.data.data;
  });

  return result;
};

export const searchShortCategories = async () => {
  const result = await apiRequest({
    url: "/news/fetch/audioshorts",
    method: "GET",
  }).then((res) => {
    return res.data.data.map((item) => {
      return {
        title: item,
        type: "shortscat",
      };
    });
  });

  return result;
};
