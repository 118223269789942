import React, { useState, useEffect, useRef } from "react";
import style from "../../style/LatestFeed.module.css";
import PlayCard from "../PlayCard";
import { Button, message } from "antd";
import { connect } from "react-redux";
import {
  addItemsToCampaign,
  setActiveProject,
} from "../../redux/actions/project";
import { setSelectedAudience, setAddingSource } from "../../redux/actions/main";
import PodcastCard from "../PodcastCard";
import _ from "lodash";
import ShortsCard from "../ShortsCard";
import ProjectCard from "../project/ProjectCard";
import createStyle from "../../style/createproject.module.css";
import { ReactSortable } from "react-sortablejs";
import { useHistory } from "react-router-dom";
import MemberContainer from "../Teams/MembersContainer";
import MemberSearch from "../MemberSearch";
import ViewCampaignStats from "./ViewCampaignStats";
import AudienceClicked from "./AudienceClicked";
import AudienceStat from "./AudienceStat";
import EditCampaign from "./EditCampaign";
import axios from "axios";
import environment from "../../api/environment";
import PostCard from "../post/PostCard";
import ContactsContainer from "../Audience/ContactsContainer";
import StrapiRequest from "../../api/strapiRequest";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import moment from "moment";
import { SettingFilled } from "@ant-design/icons";

const mapState = (state) => ({
  projects: state.projects.projects.filter(
    (item) => item.type === "project" || item.type === "brand"
  ),
  posts: state.projects.projects.filter((item) => item.type === "post"),
  audiences: state.projects.projects.filter((item) => item.type === "audience"),
  activeProject: state.projects.activeProject,
  selectedFeed: state.news.selectedFeed,
  addingSource: state.main.addSource,
  user: state.auth.user,
  addSource: state.main.addSource,
  todayItems: state.site.todayItems,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  setSelectedAudience: (data) => dispatch(setSelectedAudience(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function CampaginDetailTabs(props) {
  const [searchVal, setSearchVal] = useState("");
  const [mainTab, setMainTab] = useState("Added Items");
  const [feedTracks, setFeedTracks] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [showProjects, setShowActiveProjects] = useState(false);
  const [items, setItems] = useState([]);
  const [statTab, setStatTab] = useState("clicked");

  const [posts, setPosts] = useState([]);
  const tabMains = ["Added Items"];
  const history = useHistory();
  const emailEditor = useRef();
  const { stats } = props;

  useEffect(() => {
    if (props.activeProject) setFeedTracks(props.activeProject.addedItems);
  }, [props.activeProject]);

  const renderItems = (data) => {
    let items = data.map((item) => {
      return {
        ...JSON.parse(item.article),
        added: item.added,
      };
    });
    items = items.map((item) => {
      return {
        ...item,
        added: moment(item.added).format("MM/DD/YYYY"),
      };
    });
    if (searchVal)
      items = items.filter((item) =>
        item.title.toLowerCase().includes(searchVal)
      );
    return items.map((item) => {
      return (
        <div className="mb-3 py-0 pr-0">
          {item.type == "podcast" ? (
            <PodcastCard
              data={item}
              projectView={props.projectView}
              campaignView={true}
              onClick={() => {
                props.onClick({ ...item, subtype: "podcast" });
              }}
            />
          ) : item.type == "shortscat" ? (
            <ShortsCard
              data={item}
              projectView={props.projectView}
              onClick={() => {
                props.onClick({
                  ...item,
                  id: item.title,
                  subtype: "shorts",
                });
              }}
            />
          ) : (
            <PlayCard data={{ ...item }} campaignView={true} />
          )}
        </div>
      );
    });
  };

  const renderSortable = () => {
    if (
      props.addingSource == "Projects" ||
      props.addingSource == "Brands" ||
      props.addingSource == "Today"
    ) {
      if (props.addingSource == "Today")
        var items = props.todayItems.map((item) => JSON.parse(item.article));
      else
        var items = activeProject.recentItems.map((item) =>
          JSON.parse(item.article)
        );

      return (
        <ReactSortable
          list={items}
          group="campaignFeed"
          setList={(list) => console.log("sorting")}
          className={`row mt-3 mx-0 ${style.dropContainer}`}
          style={{ paddingBottom: "10em" }}
        >
          {items.map((item) => {
            return (
              <div className="mb-3 py-0 pr-0">
                {item.type == "podcast" ? (
                  <PodcastCard
                    data={item}
                    projectView={props.projectView}
                    onClick={() => {
                      props.onClick({ ...item, subtype: "podcast" });
                    }}
                  />
                ) : item.type == "shortscat" ? (
                  <ShortsCard
                    data={item}
                    projectView={props.projectView}
                    onClick={() => {
                      props.onClick({
                        ...item,
                        id: item.title,
                        subtype: "shorts",
                      });
                    }}
                  />
                ) : (
                  <PlayCard data={{ ...item }} />
                )}
              </div>
            );
          })}
        </ReactSortable>
      );
    } else if (props.addingSource == "Posts") {
      return (
        <ReactSortable
          list={posts}
          group="campaignFeed"
          setList={(list) => console.log("sorting")}
          className={`row mt-3 mx-0 ${style.dropContainer}`}
          style={{ paddingBottom: "10em" }}
        >
          {posts.map((item) => {
            return (
              <div className="mb-3 py-0 pr-0">
                <PostCard
                  data={{
                    ...item,
                    category:
                      item.categories.length > 0 ? item.categories[0].name : "",
                  }}
                />
              </div>
            );
          })}
        </ReactSortable>
      );
    } else {
      return <ContactsContainer contacts={activeProject.contacts} />;
    }
  };

  let projects =
    props.addingSource == "Projects" || props.addingSource == "Brands"
      ? props.projects
      : props.addingSource == "AUDIENCES"
      ? props.audiences
      : props.posts;

  if (props.addSource !== "News")
    if (props.addSource == "Members" || props.addSource == "Teams")
      tabMains.push("Members");
    else {
      if (props.addSource == "AUDIENCES") {
        tabMains.push("Build Campaign");
        tabMains.push(props.addSource);
      } else if (props.addSource !== "Added Items")
        tabMains.push(props.addSource);
    }

  // if (activeProject) {
  //   if (props.addingSource == "Projects") tabMains.push("Project Items");
  //   if (props.addingSource == "Brands") tabMains.push("Brand Items");
  //   if (props.addingSource == "Posts") tabMains.push("Post Items");
  //   if (props.addingSource == "AUDIENCES") tabMains.push("Audience Items");
  // }
  useEffect(() => {
    setActiveProject(false);
    console.log(props.addSource);
    if (props.addSource == "Today") {
      setMainTab("Today Items");
      setActiveProject({ title: "today screen" });
      setFeedTracks(props.todayItems);
    } else if (props.addSource !== "News")
      if (props.addSource && props.addSource == "Project") {
        setShowActiveProjects(true);
        setMainTab("Projects");
      } else {
        if (props.addSource == "Members" || props.addSource == "Teams")
          setMainTab("Members");
        else setMainTab(props.addSource);
      }
    else {
      setMainTab("Added Items");
    }
  }, [props.addSource]);

  if (mainTab == "Projects") {
    projects = projects.filter((item) => item.type == "project");
    if (searchVal)
      projects = projects.filter((item) =>
        item.title.toLowerCase().includes(searchVal)
      );
  } else if (mainTab == "Brands") {
    projects = projects.filter((item) => item.type == "brand");
    if (searchVal)
      projects = projects.filter((item) =>
        item.title.toLowerCase().includes(searchVal)
      );
  }

  var cmpWord = "";
  if (activeProject) {
    if (props.addingSource == "Projects") cmpWord = "Project Items";
    if (props.addingSource == "Today") cmpWord = "Today Items";
    if (props.addingSource == "Posts") cmpWord = "Post Items";
    if (props.addingSource == "AUDIENCES") cmpWord = "Audience Items";
  }

  return (
    <div className={style.LatestFeed} style={{ marginBottom: "20em" }}>
      <div className="mb-4 d-flex align-items-center">
        <div className="d-flex align-items-center">
          {tabMains.map((item) => (
            <h5
              className={`${
                mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
              } text-center mb-0 `}
              style={{
                cursor: "pointer",
                textTransform: "uppercase",
                minWidth: "150px",
                padding: "6px 11px",
                borderRadius: "10px",
                fontSize: "0.95em",
              }}
              onClick={() => {
                setMainTab(item);
                setSearchVal("");
              }}
            >
              {item}
            </h5>
          ))}

          <MemberSearch
            setItems={setItems}
            addingContent={false}
            setQuery={setSearchVal}
            addingContent={mainTab == "Added Items" ? "Added Items" : ""}
            selectedAudience={activeProject}
            onClickFeed={() => {
              props.setSelectedAudience(activeProject);

              setActiveProject(null);
              props.setAddingSource("Build Campaign");
            }}
          />
        </div>
      </div>

      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {mainTab == "Added Items" ? (
          renderItems(feedTracks)
        ) : mainTab == "Members" ? (
          <MemberContainer
            setItems={setItems}
            editing={true}
            searchResults={items}
          />
        ) : mainTab == "Campaign Stats" ? (
          <>
            <ViewCampaignStats
              stats={stats.stats}
              statTab={statTab}
              setStatTab={setStatTab}
            />
            {statTab == "clicked" ? (
              <AudienceClicked data={stats} />
            ) : (
              <AudienceStat data={stats} tab={statTab} />
            )}
          </>
        ) : mainTab == "Build Campaign" ? (
          <EditCampaign tab="Edit Email Campaign" btnRef={emailEditor} />
        ) : mainTab == cmpWord ? (
          renderSortable()
        ) : (
          projects.map((item) => (
            <ProjectCard
              campaignSelect={true}
              onSelect={() => {
                props.setActiveProject(item);
                if (props.addingSource == "Projects")
                  history.push("/dashboard/project");
                else if (props.addingSource == "AUDIENCES")
                  history.push("/dashboard/audience");
                else history.push("/dashboard/post");
              }}
              onPreview={async () => {
                console.log(props.addingSource);
                if (props.addingSource == "Projects")
                  setMainTab("Project Items");
                if (props.addingSource == "Brands") setMainTab("Brand Items");
                if (props.addingSource == "AUDIENCES")
                  setMainTab("Audience Items");
                if (props.addingSource == "Posts") {
                  setMainTab("Post Items");
                  let posts = await StrapiRequest({
                    url: `posts?post_id=${item._id}&_sort=_id:DESC`,
                    method: "get",
                  }).then((res) => {
                    let temp = res.data.map((item) => {
                      return {
                        ...item,
                        link: `${environment.api.URL}/post/postBody/${item._id}`,
                        type: "Post",
                        image: item.image ? item.image.url : "",
                      };
                    });
                    setPosts(temp);

                    return temp;
                  });

                  let stories = await StrapiRequest({
                    url: `stories?post_id=${item._id}&_sort=_id:DESC`,
                    method: "get",
                  }).then((res) => {
                    let temp = res.data.map((item) => {
                      return {
                        ...item,
                        link: `${environment.api.URL}/post/postBody/${item._id}`,
                        type: "STORY",
                        image: item.image ? item.image.url : "",
                        post_type: "STORY",
                        story_lines: item.story_lines.map((item) => {
                          return {
                            ...item,
                            image: item.image ? item.image.url : "",
                          };
                        }),
                      };
                    });
                    return temp;
                  });

                  setPosts([...stories, ...posts]);
                }
                setActiveProject(item);
              }}
              data={item}
              showEdit={false}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(CampaginDetailTabs);
