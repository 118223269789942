import React, { useState, useEffect, useRef } from "react";
import createStyle from "../../style/createproject.module.css";
import ProjectCard from "../project/ProjectCard";
import style from "../../style/ProjectDetail.module.css";
import { connect } from "react-redux";
import { updateProject } from "../../redux/actions/project";
import Actions from "../../redux/actions/news";
import _ from "lodash";
import apiRequest from "../../api/clientRequest";
import {
  setAddingSource,
  changeToCategoriesTab,
} from "../../redux/actions/main";
import CampaginDetailTabs from "./CampaginDetailTabs";
var mapStateToProps = (state) => {
  return {
    selectedFeed: state.news.selectedFeed,
    feedlyUser: state.auth.feedlyUser,
    teamSearch: state.main.memberSearch,
    teams: state.projects.projects.filter((item) => item.type == "team"),
    individualSearch: state.main.individualSearch,
    user: state.auth.user,
  };
};

const mapDispatch = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  changeToCategoriesTab: (tab) => dispatch(changeToCategoriesTab(tab)),
});

function ProjectDetail(props) {
  const [feeds, setFeeds] = useState([]);
  const [stats, setStats] = useState([]);

  const { selectedFeed, project } = props;

  const setInitialFeed = async () => {
    setFeeds(
      project.addedItems.map((item) => {
        return JSON.parse(item.article);
      })
    );
  };
  useEffect(() => {
    apiRequest({
      url: "/campaigns/stats",
      method: "POST",
      data: {
        projectId: project._id,
      },
    }).then((res) => {
      setStats(res.data);
    });

    return () => {};
  }, []);

  useEffect(() => {
    setInitialFeed();
  }, [project]);

  return (
    <div>
      <div style={{ position: "relative" }} className="d-flex">
        <ProjectCard
          data={props.project}
          onEdit={props.onEdit}
          onSelect={() => props.setAddingSource("News")}
          onPreview={() => props.setAddingSource("News")}
        />

        <div className="d-flex flex-column">
          <div
            className={createStyle.createContainer}
            style={{ paddingTop: "3em", paddingLeft: "2em" }}
          >
            <div
              style={{ marginTop: "1.7em", width: "160px" }}
              className="d-flex flex-column align-items-center"
            >
              <button
                className={createStyle.addSourcesBtn}
                onClick={() => {
                  // props.setAddingSource("Add Campaign Items");
                  props.setAddingSource("Projects");
                  props.changeToCategoriesTab("Add Campaign Items");
                }}
              >
                <img src="/images/plusIcon.svg" alt="" />
              </button>
              <span className="mt-2">Add Campaign Items</span>
            </div>

            <div
              className={`${createStyle.campaignStatBtn} d-flex flex-column align-items-center`}
              style={{ margin: "0em 2em 0" }}
            >
              <button
                className={createStyle.addSourcesBtn}
                onClick={() => {
                  props.setAddingSource("Build Campaign");
                  props.changeToCategoriesTab("Build Campaign");
                }}
                style={{ border: "none" }}
              >
                <img
                  src="/images/emailLayerIcon.png"
                  className={createStyle.campaignStatImb}
                  alt=""
                />
              </button>
              <span className="mt-2">Build Email Campaign</span>
            </div>

            <div
              className={`${createStyle.campaignStatBtn} d-flex flex-column align-items-center ml-0s`}
              style={{ margin: "0" }}
            >
              <button
                className={createStyle.addSourcesBtn}
                onClick={() => {
                  props.setAddingSource("Campaign Stats");
                }}
                style={{ border: "none" }}
              >
                <img
                  src="/images/statsIcon.svg"
                  className={createStyle.campaignStatImb}
                  alt=""
                />
              </button>
              <span className="mt-2">View Campaign Stats</span>
            </div>

            <div
              className={createStyle.inputContainer}
              style={{ marginLeft: "5em", marginTop: "-3em" }}
            >
              <div className={style.Description}>
                <h6>CAMPAIGN DESCRIPTION</h6>
                <p>{props.project.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CampaginDetailTabs
          news={selectedFeed ? selectedFeed.feed : feeds}
          stats={stats}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(ProjectDetail);
