import React, { useState, useEffect } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import { Input, AutoComplete } from "antd";
import { connect } from "react-redux";
import FeedTag from "../sidebarTabs/components/FeedTag";
import logRequest from "../../api/logRequest";
import { sendCampaign } from "../../redux/actions/campaign";
import { message } from "antd";
import {
  changeToCategoriesTab,
  setAddingSource,
} from "../../redux/actions/main";
import { Checkbox } from "antd";

const mapState = (state) => ({
  audiences: state.projects.projects.filter((item) => item.type == "audience"),
  user: state.auth.user,
  activeProject: state.projects.activeProject
    ? state.projects.activeProject
    : state.projects.activePreview,
  selectedAudience: state.main.selectedAudience,
});

const dispatch = (dispatch) => ({
  sendCampaign: (data) => dispatch(sendCampaign(data)),
  changeToCategoriesTab: (tab) => dispatch(changeToCategoriesTab(tab)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function SendEmail(props) {
  const [selectedTab, setSelectedTab] = useState("");
  const [subject, setSubject] = useState("");
  const [from, setFrom] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [checked, setChecked] = useState(false);
  // const [amelia, setAmeliaChecked] = useState(false);

  // const handleSearch = (value) => {
  //   let items = props.audiences.filter(
  //     (item) => item.type == selectedTab.toLowerCase()
  //   );

  //   items = items.filter((item) =>
  //     item.title.toLowerCase().includes(value.toLowerCase())
  //   );
  //   items = items.map((item) => {
  //     return {
  //       value: item.title,
  //     };
  //   });
  //   setOptions(items);
  // };

  // const onSelect = (value) => {
  //   const foundItem = props.audiences.find((item) => item.title == value);
  //   setSelectedItem(foundItem);
  // };

  useEffect(() => {
    setSelectedItem(props.selectedAudience);
  }, [props.selectedAudience]);

  const tabs = [
    {
      title: "Action",
      subtabs: [
        { title: "Send Campaign", icon: "sendCampaignIcon.svg", id: 1 },
        { title: "Preview Campaign", icon: "previewCampaignIcon.svg", id: 2 },
      ],
    },
  ];

  const onSendCampaign = () => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not send a campaign");
            return;
        }
    }

    if (selectedItem) {
      props.sendCampaign({
        recipients: selectedItem.contacts.map((item) => item.email),
        subject,
        from,
        alternateTemplate: false,
        amelia: true,
      });
      logRequest({ action: "Sent a newsletter" });
    } else {
      message.error("Please select an audience first");
    }
  };

  useEffect(() => {
    props.setAddingSource("Build Campaign");
  }, []);

  return (
    <div className={style.AddItem}>
      <div
        className={`${style.topSection} topSection`}
        style={{ zIndex: "9999999" }}
      >
        {tabs.map((item) => {
          return (
            <div style={{ marginBottom: "50px" }}>
              <div className="d-flex flex-wrap align-items-center mb-5">
                {item.subtabs.map((tab) => (
                  <div
                    className={style.actionBtn}
                    style={{ padding: 0, marginBottom: 0 }}
                  >
                    <button
                      onClick={() => onSendCampaign()}
                      className={`${
                        selectedTab == tab.title ? style.active : ""
                      } d-flex `}
                      style={{
                        marginRight: 0,
                        marginBottom: 0,
                        width: "auto",
                        flexDirection: "revert",
                        height: "28px",
                      }}
                    >
                      <img
                        src={`/images/${tab.icon}`}
                        alt=""
                        className="mr-2"
                      />
                      <span style={{ fontSize: "10px", marginTop: "5px" }}>
                        {tab.title}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
              <div className={`${style.inputContainer} `}>
                <div className="mb-3">
                  <label className="d-block" htmlFor="">
                    Campaign Email Subject
                  </label>
                  <div className={style.inputAppend}>
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={`${style.inputContainer} `}>
                <div className="mb-3">
                  <label className="d-block" htmlFor="">
                    Campaign From
                  </label>
                  <div className={style.inputAppend}>
                    <input
                      type="text"
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                {/* <div className="">
                  <AutoComplete
                    options={options}
                    style={{ width: "100%" }}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                  >
                    <Input
                      size="large"
                      placeholder={`Search For ${selectedTab}s`}
                      style={{
                        border: "1px solid #000000",
                        borderRadius: "10px",
                        width: "100%",
                        backgroundColor: "transparent",
                        color: "#000000 ",
                      }}
                    />
                  </AutoComplete>
                </div> */}
                <div className={`${style.actionBtn} mb-2`}>
                  <button
                    onClick={() => {
                      setSelectedTab("audience");
                      props.setAddingSource("AUDIENCES");
                    }}
                    className={selectedTab == "audience" ? style.active : ""}
                  >
                    <img src="/images/audienceIcon.svg" alt="" />
                    <span>Search for Audiences</span>
                  </button>
                  <button
                    onClick={() => setSelectedTab("email")}
                    className={selectedTab == "email" ? style.active : ""}
                  >
                    <img src="/images/emailIcon.svg" alt="" />
                    <span>Add Email Addresses</span>
                  </button>
                </div>
                {/* <Checkbox onChange={(e) => setChecked(e.target.checked)}>
                  Alternate Template
                </Checkbox>
                <Checkbox onChange={(e) => setAmeliaChecked(e.target.checked)}>
                  Amelia
                </Checkbox> */}
              </div>

              <div
                className="d-flex  flex-wrap px-3"
                style={{
                  overflow: "auto",
                  maxHeight: 300,
                }}
              >
                {selectedItem &&
                  selectedItem.type == "audience" &&
                  selectedItem.contacts.map((item) => (
                    <FeedTag data={{ id: item.id, label: item.email }} />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default connect(mapState, dispatch)(SendEmail);
