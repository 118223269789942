import React, { Component, useState } from "react";
import message from "antd/lib/message";
import apiRequest from "../../api/clientRequest";
import styles from "../../style/onboarding.module.css";
import NewButton from "../NewButton";
import parentCats from "./categories.json";
export default function FirstStep(props) {
  const { submitHandle } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [groupSelected, setGroupSelected] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [categories, setCategories] = useState([]);

  React.useState(() => {
    const fetchData = () => {
      apiRequest({
        url: "/amelia/categories",
        method: "GET",
      })
        .then((res) => {
          setCategories(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, []);

  const renderCards = () => {
    let tempCategories = categories;
    if (groupSelected)
      tempCategories = tempCategories.filter((item) =>
        item.parentCategory.includes(groupSelected)
      );

    if (showSelected)
      tempCategories = tempCategories.filter((item) =>
        selectedCategories.includes(item.title)
      );
    return tempCategories.map((item) => (
      <div
        className={styles.categoryItem}
        style={{
          borderColor: selectedCategories.find((ite) => ite == item.title)
            ? "green"
            : "rgba(64, 64, 64, 0.26)",
        }}
        onClick={() => {
          if (selectedCategories.find((ite) => ite == item.title))
            setSelectedCategories(
              selectedCategories.filter((ite) => ite !== item.title)
            );
          else setSelectedCategories([...selectedCategories, item.title]);
        }}
      >
        <p>{item.title}</p>
        <span
          style={{
            backgroundColor: selectedCategories.find((ite) => ite == item.title)
              ? "green"
              : "#ffffff",
          }}
          className={styles.circleButton}
        ></span>
        <img
          src={`/categories/${item.icon}`}
          style={{ filter: item.icon == "non-profit.svg" ? "invert(1)" : "" }}
          alt=""
        />
      </div>
    ));
  };

  return (
    <section
      className="session"
      style={{ marginTop: "3em", maxWidth: "690px" }}
    >
      <h1 style={{ marginBottom: "2em" }}>What interests you?</h1>

      <div className="d-flex mb-5">
        <div style={{ minWidth: "170px", textAlign: "left" }}>
          <button
            onClick={() => {
              setGroupSelected(false);
            }}
            className={`${styles.roundedBtn} ${
              !groupSelected ? styles.activeBtn : ""
            }`}
          >
            Show All
          </button>
          <button
            onClick={() => {
              setShowSelected(!showSelected);
            }}
            className={`${styles.roundedBtn} ${
              showSelected ? styles.activeBtn : ""
            }`}
          >
            Show Selected
          </button>
        </div>
        {/* <div className="text-left">
          {parentCats.parentGroups.map((item) => (
            <button
              onClick={() => {
                setGroupSelected(item.id);
              }}
              className={`${styles.roundedBtn} ${
                item.id == groupSelected ? styles.activeBtn : ""
              }`}
            >
              {item.name}
            </button>
          ))}
        </div> */}
      </div>
      <div
        className="d-flex flex-wrap mb-5  w-100"
        style={{ width: "100%", height: "470px", overflow: "auto" }}
      >
        {renderCards()}
      </div>
      <NewButton
        text={"Continue"}
        onClick={() => submitHandle({ selectedCategories })}
      />
    </section>
  );
}
