import React from "react";
import styles from "../style/newButton.module.css";

export default function NewButton({ text, onClick, className }) {
  return (
    <button className={`${styles.button} ${className}`} onClick={onClick}>
      {text}
    </button>
  );
}
