import React from "react";
import style from "./feedtag.module.css";
export default function FeedTag(props) {
  const { id, label } = props.data;
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClick(id);
      }}
      className={style.tag}
      style={{
        color: props.isSelected ? `#38ef7d` : "#999",
        borderColor: props.isSelected ? `#38ef7d` : "#999",
      }}
    >
      {label}
    </div>
  );
}
