import Types from "../constants/project";
var defaultState = {
  projects: [],
  activeColor: "",
  activeProject: null,
  activePreview: null,
  createdAudio: [],
};

var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SET_CREATED_AUDIOS: {
      let newState = Object.assign({}, state);
      newState.createdAudio = action.payload;

      return newState;
    }

    case Types.SET_ACTIVE_PROJECT: {
      let newState = Object.assign({}, state);
      newState.activeProject = action.payload;
      newState.activePreview = null;
      if (action.payload !== null) newState.activeColor = action.payload.color;
      else newState.activeColor = "";

      return newState;
    }
    case Types.SET_ACTIVE_PREVIEW: {
      let newState = Object.assign({}, state);
      newState.activePreview = action.payload;
      if (action.payload)
        newState.activeColor = {
          sideTop: action.payload.color.sideTop,
          sideMiddle: action.payload.color.sideMiddle,
          sideBottom: action.payload.color.sideBottom,
          primaryColor: action.payload.color.primaryColor,
        };
      else newState.activeColor = "";
      return newState;
    }

    case Types.UPDATE_PROJECT: {
      let newState = Object.assign({}, state);
      newState.activeProject = action.payload;
      newState.projects = newState.projects.map((item) => {
        if (item._id == newState.activeProject._id)
          return newState.activeProject;
        else return item;
      });

      return newState;
    }

    case Types.SET_ACTIVE_COLOR: {
      let newState = Object.assign({}, state);
      newState.activeColor = action.payload;
      return newState;
    }

    case Types.SET_PROJECTS: {
      let newState = Object.assign({}, state);
      newState.projects = action.payload;
      return newState;
    }

    case Types.EDIT_PROJECT: {
      let newState = Object.assign({}, state);
      newState.projects = newState.projects.map((item) => {
        if (item._id == action.payload._id) return action.payload;
        else return item;
      });
      newState.activeProject = action.payload;
      newState.activeColor = action.payload.color;
      return newState;
    }

    case Types.DELETE_PROJECT: {
      let newState = Object.assign({}, state);
      newState.projects = newState.projects.filter(
        (item) => item._id !== newState.activeProject._id
      );
      newState.activeColor = null;
      newState.activeProject = null;

      return newState;
    }

    case Types.ADD_PROJECTS: {
      let newState = Object.assign({}, state);
      newState.projects = [action.payload, ...newState.projects];
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;
