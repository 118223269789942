import React from "react";
import { connect } from "react-redux";
import style from "../../style/tabs.module.css";
import AllCollection from "../collections/AllCollection";
var mapStateToProps = (state) => {
  return {
    projects: state.projects.projects.filter(
      (item) => item.type == "collection"
    ),
  };
};

function CollectionTab(props) {
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <AllCollection projects={props.projects} type="collection" />
    </div>
  );
}

export default connect(mapStateToProps, null)(CollectionTab);
