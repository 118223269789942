import React, { useState, useEffect } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { addItemsToCampaign, updateProject } from "../../redux/actions/project";
import Actions from "../../redux/actions/news";
import _ from "lodash";
import createStyle from "../../style/createproject.module.css";
import MemberContainer from "../Teams/MembersContainer";
import FeedContainer from "../FeedContainer";
import OrderFeedContainer from "../OrderFeedContainer";
import MemberSearch from "../MemberSearch";
import { setAddingSource, setActiveTab } from "../../redux/actions/main";
import { SettingFilled } from "@ant-design/icons";
import logRequest from "../../api/logRequest";

const mapState = (state) => ({
  projects: state.projects.projects.filter((item) => item.type === "project"),
  activeProject: state.projects.activeProject,
  selectedFeed: state.news.selectedFeed,
  feedlyUser: state.news.feedlyUser,
  user: state.auth.user,
  addSource: state.main.addSource,
  teamSearch: state.main.memberSearch,
  individualSearch: state.main.individualSearch,
  selectedFeed: state.news.selectedFeed,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  updateProject: (data) => dispatch(updateProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  setActiveTab: (data) => dispatch(setActiveTab(data)),
});

function ProjectDetailTab(props) {
  const [mainTab, setMainTab] = useState("PROJECT ITEMS");
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState("");
  const [groupView, setGroupView] = useState(true);

  const tabMains = ["PROJECT ITEMS", "LATEST FEEDS", "NEWS BOARDS"];
  const { selectedFeed } = props;
  const { feeds, addingContent, activeProject } = props;

  if (props.addSource == "Members" || props.addSource == "Teams")
    tabMains.push("Members");

  if (
    (props.addSource == "News Categories" ||
      props.addSource == "Podcast" ||
      props.addSource == "Podcast Categories" ||
      props.addSource == "Podcast Shows" ||
      props.addSource == "Podcast Episodes") &&
    selectedFeed
  )
    tabMains.push(selectedFeed.label);

  if (
    addingContent == "News Categories" ||
    addingContent == "Podcast Categories" ||
    addingContent == "Podcast Shows" ||
    addingContent == "News Boards"
  )
    tabMains.push(`Select ${addingContent}`);

  useEffect(() => {
    props.setActiveTab("PROJECT ITEMS");
  }, []);

  useEffect(() => {
    if (props.addSource !== "News") {
      if (props.addSource && props.addSource == "Members") {
        setMainTab("Members");
      }
    } else {
      setMainTab("PROJECT ITEMS");
    }
  }, [props.addSource]);

  useEffect(() => {
    if (props.selectedFeed && props.selectedFeed.label) {
      setMainTab(props.selectedFeed.label);
    }
  }, [props.selectedFeed]);

  useEffect(() => {
    if (!props.selectedFeed)
      if (addingContent) {
        if (
          addingContent == "News Categories" ||
          addingContent == "Podcast Categories" ||
          addingContent == "Podcast Shows" ||
          addingContent == "News Boards"
        )
          setMainTab(`Select ${addingContent}`);
        else setMainTab(addingContent);
      } else {
        setMainTab("PROJECT ITEMS");
      }
  }, [addingContent]);

  // console.log(feeds);

  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <div className="d-flex align-items-center">
          <h5
            style={{
              cursor: "pointer",
              borderRadius: "10px",
              fontSize: "0.95em",
              lineHeight: "1px",
              padding: "6px 11px",
            }}
            className={`${createStyle.tabBtn} text-center mb-0 `}
            onClick={() => setGroupView(!groupView)}
          >
            <SettingFilled color="white" />
          </h5>
          {tabMains.map((item) => (
            <h5
              className={`${
                mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
              } text-center mb-0 `}
              style={{
                cursor: "pointer",
                textTransform: "uppercase",
                minWidth: "150px",
                padding: "6px 11px",
                borderRadius: "10px",
                fontSize: "0.95em",
                opacity:
                  item == "NEWS BOARDS" && !props.feedlyUser.token ? 0.4 : 1,
              }}
              onClick={() => {
                if (item == "NEWS BOARDS" && !props.feedlyUser.token)
                  console.log("not allowed");
                else {
                  setMainTab(item);
                  props.setActiveTab(item);
                }
              }}
            >
              {item}
            </h5>
          ))}

          <MemberSearch
            setItems={setItems}
            addingContent={
              mainTab == "PROJECT ITEMS"
                ? "Project Items"
                : mainTab == "LATEST FEEDS"
                ? "Latest Feeds"
                : addingContent
            }
            setQuery={setQuery}
            onClickFeed={(feed = false) => {
              const tempFeed = feed ? feed : selectedFeed;
              props.updateProject({ ...tempFeed, type: activeProject.type });
              props.setSelectedFeed(null);
              setMainTab("LATEST FEEDS");
            }}
          />
        </div>
      </div>
      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {mainTab == "Members" ? (
          <MemberContainer editing={true} searchResults={items} />
        ) : mainTab == "PROJECT ITEMS" ? (
          <OrderFeedContainer
            projectView={true}
            container={"projectDetail"}
            tab="project"
            boards={false}
            query={query}
            searchAble={true}
            groupView={groupView}
          />
        ) : mainTab == "LATEST FEEDS" ? (
          <FeedContainer
            news={feeds.filter((item) => item.subtype !== "board")}
            feedlyUser={props.feedlyUser}
            projectView={true}
            container={"projectDetail"}
            tab="project"
            boards={false}
            query={query}
            searchAble={true}
            showDrag={true}
            origFeeds={props.origFeeds.filter(
              (item) => item.subtype !== "board"
            )}
          />
        ) : mainTab == "NEWS BOARDS" ? (
          <FeedContainer
            news={feeds.filter((item) => item.subtype == "board")}
            feedlyUser={props.feedlyUser}
            projectView={true}
            container={"projectDetail"}
            tab="project"
            boards={true}
            query={query}
            searchAble={true}
            showDrag={true}
            origFeeds={props.origFeeds.filter(
              (item) => item.subtype == "board"
            )}
          />
        ) : props.selectedFeed && mainTab == props.selectedFeed.label ? (
          <FeedContainer
            news={selectedFeed.feed}
            feedlyUser={props.feedlyUser}
            projectView={true}
            addingContent={addingContent}
            container={"projectDetail"}
            onClick={(feed = false) => {
              const tempFeed = feed ? feed : selectedFeed;
              logRequest({
                action: `Added an item to project called ${props.activeProject.title}`,
              });
              props.updateProject({
                ...tempFeed,
                type: props.activeProject.type,
              });
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(ProjectDetailTab);
