import React, { useState, useEffect } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { addItemsToCampaign } from "../../redux/actions/project";
import {
  setAddingSource,
  changeToCategoriesTab,
} from "../../redux/actions/main";
import createStyle from "../../style/createproject.module.css";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./constants";
import memberstyle from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import TemplateEditor from "./TemplateEditor";
import StoryAddPost from "./StoryAddPost";
import InterviewAddPost from "./InterviewAddPost";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  postData: state.main.postData,
  recordMode: state.main.recordMode,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
});

function AddPost(props) {
  const [editorTools, setEditorTools] = useState(EDITOR_JS_TOOLS);
  const [placeholder, setPlaceholder] = useState(
    "Start typing here to create your awesome podcast show.  We will transform everything into great sounding audio that you can share with your audience."
  );

  React.useEffect(() => {
    const testPlaceholder =
      props.postData.post_type == "REC0RD"
        ? "Start talking and we will convert everything you say into text.  After you are finished, edit the transcription and add any additional content."
        : "Start typing here to create your awesome podcast show.  We will transform everything into great sounding audio that you can share with your audience.";
    setPlaceholder(testPlaceholder);
  }, [props.postData]);

  return props.postData.post_type == "INTRO" ? (
    <TemplateEditor />
  ) : props.postData.post_type == "INTERVIEW" ? (
    <InterviewAddPost fetchPosts={props.fetchPosts} />
  ) : props.postData.post_type == "STORY" ? (
    <StoryAddPost fetchPosts={props.fetchPosts} />
  ) : (
    <div className={`${style.LatestFeed} mt-0`}>
      <div
        className={`row mt-3 mx-0 ${style.dropContainer} ${style.statusBar} flex-column`}
      >
        <div className="d-flex px-4">
          <div
            className={`${memberstyle.userItem} mr-4 d-flex mt-3 align-items-center`}
            onClick={() => {
              if (props.postData.post_type == "REC0RD") console.log("record");
              else props.changeToCategoriesTab("VoiceTab");
            }}
            style={{ opacity: props.postData.post_type == "REC0RD" ? 0.5 : 1 }}
          >
            <img
              src={props.postData.voice.avatar}
              className={style.voiceAvatar}
              alt=""
            />
            <div className={memberstyle.userDetail}>
              <div>
                <p style={{ color: "#38ef7d" }}>VOICE</p>
                <p>{props.postData.voice.fullname}</p>
                <p style={{ opacity: "0.5" }}>Narration, Promo</p>
              </div>
              <div className="ml-4">
                <h5 className={`${style.statusActionBtn} text-center mb-0 `}>
                  Change
                </h5>
              </div>
            </div>
          </div>
          <div
            className={`${memberstyle.userItem} mr-4 d-flex mt-3 align-items-center`}
            onClick={() => props.changeToCategoriesTab("BackgroundMusicTab")}
            style={{ opacity: props.postData.background ? 1 : "0.5" }}
          >
            <img
              src="/images/musicIcon.svg"
              className={style.voiceAvatar}
              alt=""
              style={{ borderRadius: 0 }}
            />
            <div className={memberstyle.userDetail}>
              <div>
                <p style={{ color: "#38ef7d" }}>BACKGROUND MUSIC</p>
                <p style={{ height: "35px" }}>{props.postData.background}</p>
              </div>
              <div className="ml-4">
                <h5 className={`${style.statusActionBtn} text-center mb-0 `}>
                  Change
                </h5>
              </div>
            </div>
          </div>
          {/* <div
            className={`${memberstyle.userItem} d-flex mt-3 align-items-center`}
            onClick={() => {
              // props.setPostData({ ...props.postData, voice: item.fullname });
            }}
            style={{ opacity: "0.5", cursor: "disabled" }}
          >
            <img
              src="/images/calenderIcon.svg"
              className={style.voiceAvatar}
              style={{ borderRadius: 0 }}
              alt=""
            />
            <div className={memberstyle.userDetail}>
              <div>
                <p style={{ color: "#38ef7d" }}>SCHEDULE</p>
                <p>Published</p>
                <p style={{ opacity: "0.5" }}>July 29, 2020</p>
              </div>
              <div className="ml-4">
                <h5 className={`${style.statusActionBtn} text-center mb-0 `}>
                  Change
                </h5>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className={`row mt-3 mx-0 ${style.dropContainer} flex-column`}
        style={{ paddingBottom: "10em" }}
      >
        <div className="pr-5 pb-5 w-100">
          <div className={style.editorJs}>
            {props.recordMode ? (
              ""
            ) : (
              <EditorJs
                tools={EDITOR_JS_TOOLS}
                instanceRef={(instance) => props.setRef(instance)}
                // onChange={(api, newData) => props.onChangeBody(newData)}
                data={props.body}
                // enableReInitialize={props.recordMode}
                placeholder={placeholder}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(AddPost);
