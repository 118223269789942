import React, { useState, useEffect } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { updateProject, editProject } from "../../redux/actions/project";
import _ from "lodash";
import createStyle from "../../style/createproject.module.css";
import MemberContainer from "../Teams/MembersContainer";
import MemberSearch from "../MemberSearch";
import FileDropZone from "../FileDropZone";
import ContactsContainer from "./ContactsContainer";
import apiRequest from "../../api/clientRequest";
import { setAddingSource } from "../../redux/actions/main";
import { message } from "antd";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  user: state.auth.user,
  addSource: state.main.addSource,
});

const dispatchState = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  editProject: (data) => dispatch(editProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function ProjectDetailTab(props) {
  const [mainTab, setMainTab] = useState("CONTACTS");
  const [items, setItems] = useState([]);
  const [searchVal, setSearchVal] = useState(null);

  const tabMains = ["CONTACTS"];
  const { addingContent } = props;

  if (props.addSource == "Members" || props.addSource == "Teams")
    tabMains.push("Members");

  if (addingContent == "Add Contacts") tabMains.push(`Add Contacts`);

  useEffect(() => {
    // props.setAddingSource("Contacts");
    return () => {};
  }, []);

  useEffect(() => {
    if (props.addSource !== "News") {
      if (props.addSource && props.addSource == "Members") {
        props.setAddingContent("");
        setMainTab("Members");
      }
    } else {
      setMainTab("CONTACTS");
    }
  }, [props.addSource]);

  useEffect(() => {
    if (addingContent) {
      props.setAddingSource("Add Contacts");
      setMainTab(addingContent);
    } else {
      if (!props.addSource) setMainTab("CONTACTS");
    }
  }, [addingContent]);

  return (
    <div className={style.LatestFeed}>
      <div className="mb-4 d-flex align-items-center">
        <div className="d-flex align-items-center">
          {tabMains.map((item) => (
            <h5
              className={`${
                mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
              } text-center mb-0 `}
              style={{
                cursor: "pointer",
                textTransform: "uppercase",
                minWidth: "150px",
                padding: "6px 11px",
                borderRadius: "10px",
                fontSize: "0.95em",
              }}
              onClick={() => setMainTab(item)}
            >
              {item}
            </h5>
          ))}

          <MemberSearch
            setItems={setItems}
            addingContent={addingContent}
            setQuery={setSearchVal}
            onClickFeed={(feed = false) => {
              //   const tempFeed = feed ? feed : selectedFeed;
              //   props.updateProject({ ...tempFeed, type: activeProject.type });
              setMainTab("CONTACTS");
            }}
          />
        </div>
      </div>
      <div className="row mt-3 mx-0" style={{ paddingBottom: "10em" }}>
        {mainTab == "Members" ? (
          <MemberContainer editing={true} searchResults={items} />
        ) : mainTab == "Add Contacts" ? (
          <FileDropZone onSelectedFile={(files) => {}} />
        ) : (
          <ContactsContainer query={searchVal} />
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(ProjectDetailTab);
