import backendRequest from "../../api/clientRequest";

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user

// Create a new user
export function createUser(uid, data) {
  console.log({ uid, ...data });
  return backendRequest({
    url: "/auth/create-user",
    method: "POST",
    data: { uid, sub: uid, ...data },
  }).then((res) => res.data.data);
}

//Get User
export async function getUser(uid) {
  const response = await backendRequest({
    url: `/users/me`,
    method: "get",
  });
  return response;
}
