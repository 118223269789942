import React, { useState, useEffect, createRef } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { addItemsToCampaign } from "../../redux/actions/project";
import {
  setAddingSource,
  setPostData,
  changeToCategoriesTab,
  createAdvertisement,
  uploadStandardPost,
  setMeter,
  setMeterProgress,
  setMeterTitle,
  setShareSidebar,
} from "../../redux/actions/main";
import createStyle from "../../style/createproject.module.css";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./constants";
import memberstyle from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import useDynamicRefs from "use-dynamic-refs";
import StrapiRequest from "../../api/strapiRequest";
import apiRequest from "../../api/clientRequest";
import logRequest from "../../api/logRequest";
import player from "../../data/player";
import environment from "../../api/environment";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { message } from "antd";
import FeedTag from "../sidebarTabs/components/FeedTag";
import detailStyle from "../../style/ProjectDetail.module.css";
import $ from "jquery";
import StoryTemplate from "./StoryTemplate";

const Player = player();
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  postData: state.main.postData,
  recordMode: state.main.recordMode,
  socket: state.site.socket,
  user: state.auth.user,
  shareSidebar: state.main.shareSidebar,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setPostData: (feed) => dispatch(setPostData(feed)),
  createAdvertisement: (feed) => dispatch(createAdvertisement(feed)),
  uploadStandardPost: (feed, doTitle, voice, background, backgroundType) =>
    dispatch(
      uploadStandardPost(feed, doTitle, voice, background, backgroundType)
    ),
  setMeter: (feed) => dispatch(setMeter(feed)),
  setMeterProgress: (feed) => dispatch(setMeterProgress(feed)),
  setMeterTitle: (feed) => dispatch(setMeterTitle(feed)),
  setShareSidebar: (feed) => dispatch(setShareSidebar(feed)),
});

function AddPost(props) {
  const [stories, setStories] = useState([]);
  const [tempPostData, setTempPostdata] = useState({});
  const propsRef = React.useRef(props.postData);
  const editorBodyRef = React.useRef("");
  const selectedItemRef = React.useRef("");
  const storyCreated = React.useRef("");
  const story = React.useRef("");

  const postTypes = [
    { id: 1, name: "STANDARD" },
    { id: 3, name: "REC0RD" },
  ];

  const specialPostTypes = [
    { id: 1, name: "INTRO" },
    { id: 2, name: "OUTRO" },
    { id: 3, name: "AD" },
    { id: 5, name: "EVENT" },
  ];

  const musicTypes = [
    { id: 1, name: "IMMEDIATELY" },
    { id: 2, name: "DELAYED" },
  ];
  const [selectedPostType, setSelectedPostType] = useState(postTypes[0].name);
  const [selectedMusicType, setSelectedMusicType] = useState(
    musicTypes[0].name
  );

  const [selectedStoryline, setSelectedStoryLine] = useState("none");
  const [placeholder, setPlaceholder] = useState(
    "Start typing here to create your awesome podcast show.  We will transform everything into great sounding audio that you can share with your audience."
  );

  const { socket } = props;

  useEffect(() => {
    let allItems = [...stories];

    if (selectedStoryline >= 0 && selectedStoryline !== false) {
      if (
        (props.postData.voice ||
          props.postData.image ||
          props.postData.background ||
          props.postData.backgroundOff ||
          props.postData.storyTitle ||
          props.postData.template) &&
        !props.postData.clickedStory
      ) {
        let tempItem = {};
        if (props.postData.image) tempItem["image"] = props.postData.image;
        if (props.postData.voice) tempItem["voice"] = props.postData.voice;
        if (props.postData.storyTitle)
          tempItem["storyTitle"] = props.postData.storyTitle;
        if (props.postData.backgroundOff) tempItem["background"] = "";
        if (props.postData.sub_type)
          tempItem["sub_type"] = props.postData.sub_type;
        if (props.postData.speechUrl)
          tempItem["speechUrl"] = props.postData.speechUrl;
        if (props.postData.template)
          tempItem["template"] = props.postData.template;

        if (props.postData.voiceSpeech) {
          tempItem["voiceSpeech"] = props.postData.voiceSpeech;
          tempItem["description"] = props.postData.voiceSpeech;
        }

        if (props.postData.background)
          tempItem["background"] = props.postData.background;

        allItems[selectedStoryline] = {
          ...allItems[selectedStoryline],
          ...tempItem,
        };

        setStories(allItems);
      }
    } else {
      if (props.postData.image) {
        if (
          propsRef.current.storyImage &&
          propsRef.current.storyImage.file !== props.postData.image.file
        )
          props.setPostData({
            ...props.postData,
            storyImage: props.postData.image,
            image: "",
          });
        else
          props.setPostData({
            ...props.postData,
            storyImage: props.postData.image,
            image: "",
          });
      }
    }
  }, [props.postData]);

  React.useEffect(() => {
    socket.on("completedStoryTTS", (data) => {
      console.log(data, "completed tts");
      if (data.progress == 100) {
        createStoryLine({ speechUrl: data.speechUrl, duration: data.duration });
      } else {
        props.setMeterTitle(data.progressTitle);
        props.setMeterProgress(data.progress);
      }
    });
    if (props.postData.id) storyCreated.current = props.postData;

    if (props.postData.storyLines) {
      let allItems = props.postData.storyLines.map((item) => {
        return {
          id: item.id,
          description: item.description,
          voice: {
            fullname: item.voice ? JSON.parse(item.voice).fullname : "Joanna",
            avatar: item.voice
              ? JSON.parse(item.voice).avatar
              : "/images/joanna1.png",
          },
          background: item.background,
          created: true,
          speechUrl: item.speechUrl,
          image: item.image,
          backgroundType: item.backgroundType,
          template: item.template,
        };
      });
      console.log(allItems);
      setStories(allItems);
    } else {
      setStories([
        {
          id: 0,
          voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
          body: null,
        },
      ]);
    }
    $("body").on("focusin", ".editorContainer > div", function (e) {
      // console.log(e, "editor clicked");
      const parent = $(e.target).parents(".editorContainer");
      const storyIndex = $(parent[0]).attr("data-index");
      setSelectedStoryLine(storyIndex);
      setTempPostdata((val) => {
        props.setPostData({
          ...val,
          selectedStory: parseInt(storyIndex) + 1,
          clickedStory: true,
        });
        return val;
      });
    });

    return () => {
      socket.off("completedStoryTTS");
    };
  }, []);

  React.useEffect(() => {
    console.log("inside here", props.postData);
    propsRef.current = props.postData;
    setTempPostdata(props.postData);
  }, [props.postData]);

  const onStartCreatingPost = async (SelectedItem) => {
    const { body, voice, background, backgroundType, sub_type, speechUrl } =
      SelectedItem;

    if (!props.postData.title) {
      message.error("Please provide a title for the story");
      return;
    }

    if (!storyCreated.current) await createStory();
    const tempBody = await body.save();
    editorBodyRef.current = tempBody;

    props.setMeter(true);
    props.setMeterTitle("Processing");
    props.setMeterProgress(25);

    console.log(sub_type);
    if (sub_type == "REC0RD") createStoryLine({ speechUrl, duration: 0 });
    else
      props.uploadStandardPost(
        tempBody,
        props.postData.title,
        voice,
        background,
        backgroundType
      );
  };

  const createStory = async () => {
    apiRequest({
      url: "/campaigns/clearcache",
      method: "GET",
    });

    props.setMeter(true);
    props.setMeterTitle("Creating Story");
    props.setMeterProgress(25);
    let postData = props.postData;

    logRequest({ action: `Created story with name ${postData.title}` });
    await StrapiRequest({
      url: `stories`,
      method: "POST",
      data: {
        title: postData.title,
        published: new Date(),
        categories: postData.categories
          ? postData.categories.map((item) => item.id)
          : [],
        post_id: props.activeProject._id,
        source: props.user.fullname,
        user_id: props.user.strapiUser.id,
        draft: true,
        background: postData.storyBackground,
      },
    })
      .then(async (res) => {
        if (postData.storyImage && postData.storyImage.file) {
          props.setMeterTitle("Uploading Thumbnail");
          props.setMeterProgress(90);
          var file = dataURLtoFile(
            postData.storyImage.file,
            postData.storyImage.name
          );
          const data = new FormData();
          data.append("files", file);
          data.append("refId", res.data._id);
          data.append("ref", "story");
          data.append("field", "image");
          await StrapiRequest({
            url: "upload",
            data,
            method: "post",
          });
        }
        props.setMeterTitle("Story Saved Successfully");
        props.setMeterProgress(100);
        storyCreated.current = res.data;

        props.setPostData({
          ...props.postData,
          created: true,
          draft: true,
          id: res.data._id,
        });
      })
      .catch((err) => {
        console.log(err);
        props.setMeter(false);
      });
  };

  const fetchAllChapterDuration = async () => {
    if (propsRef.current.storyBackground && storyCreated.current) {
      props.setMeter(true);
      props.setMeterTitle("Creating story background");
      props.setMeterProgress(50);
      try {
        const chapters = await StrapiRequest({
          url: `storylines?story=${storyCreated.current._id}`,
          method: "GET",
        }).then((res) => res.data);
        var totalDuration = 0;

        chapters.map((item) => {
          totalDuration += parseInt(item.duration);
        });
        apiRequest({
          url: `${environment.tts_url}/generate-background-stories`,
          method: "POST",
          data: {
            background: propsRef.current.storyBackground,
            duration: totalDuration / 1000,
          },
        }).then(async (res) => {
          await StrapiRequest({
            url: `stories/${storyCreated.current.id}`,
            method: "PUT",
            data: {
              background_url: res.data.data,
            },
          });

          props.setMeterTitle("Created story background");
          props.setMeterProgress(100);

          setTimeout(() => {
            props.setMeter(false);
          }, 1000);
        });
      } catch (e) {
        props.setMeterTitle("");
        props.setMeterProgress(0);
        props.setMeter(false);
      }
    }
  };

  const createStoryLine = async ({ duration, speechUrl }) => {
    apiRequest({
      url: "/campaigns/clearcache",
      method: "GET",
    });

    let postData = selectedItemRef.current;

    if (postData.template && postData.template.backgroundImage)
      delete postData.template["backgroundImage"];

    logRequest({ action: `Edited storyline with name ${postData.title}` });

    if (selectedItemRef.current.created) {
      props.setMeter(true);
      props.setMeterTitle("Editing StoryLine");
      props.setMeterProgress(25);

      await StrapiRequest({
        url: `storylines/${selectedItemRef.current.id}`,
        method: "PUT",
        data: {
          title: postData.title,
          description: JSON.stringify(editorBodyRef.current),
          published: new Date(),
          categories: [],
          source: props.user.fullname,
          user_id: props.user.strapiUser.id,
          voice: JSON.stringify(postData.voice),
          post_type: "StoryLine",
          speechUrl: speechUrl,
          originalUrl: "   ",
          draft: false,
          background: postData.background,
          story: storyCreated.current.id,
          duration: `${duration}`,
          subType: postData.sub_type ? postData.sub_type : "",
          storyTitle: postData.storyTitle,
          order: `${postData.order}`,
          backgroundType: postData.backgroundType,
          template: postData.template
            ? {
                ...postData.template,
                html: window.editor.getHtml(),
                css: window.editor.getCss(),
              }
            : "",
        },
      })
        .then(async (res) => {
          props.setMeterTitle("Storyline  Saved Successfully");
          props.setMeterProgress(80);
          if (postData.image && postData.image.file) {
            props.setMeterTitle("Uploading Thumbnail");
            props.setMeterProgress(90);
            var file = dataURLtoFile(postData.image.file, postData.image.name);
            const data = new FormData();
            data.append("files", file);
            data.append("refId", res.data._id);
            data.append("ref", "storyline");
            data.append("field", "image");
            await StrapiRequest({
              url: "upload",
              data,
              method: "post",
            });
          }

          setStories((stories) => {
            let foundInd = stories.findIndex((item) => item.id == postData.id);
            let tempStories = [...stories];
            tempStories[foundInd]["speechUrl"] = speechUrl;
            tempStories[foundInd]["created"] = true;
            return tempStories;
          });
        })
        .catch((err) => {
          console.log(err);
          logRequest({
            action: `Failed while editing storyline with name ${postData.title}`,
          });

          props.setMeter(false);
        });
    } else {
      props.setMeter(true);
      props.setMeterTitle("Creating StoryLine");
      props.setMeterProgress(25);

      logRequest({ action: `Created storyline with name ${postData.title}` });

      await StrapiRequest({
        url: `storylines`,
        method: "POST",
        data: {
          title: postData.title,
          description: JSON.stringify(editorBodyRef.current),
          published: new Date(),
          categories: [],
          source: props.user.fullname,
          user_id: props.user.strapiUser.id,
          voice: JSON.stringify(postData.voice),
          post_type: "StoryLine",
          speechUrl: speechUrl,
          originalUrl: "   ",
          draft: false,
          background: postData.background,
          story: storyCreated.current.id,
          duration: `${duration}`,
          subType: postData.sub_type ? postData.sub_type : "",
          storyTitle: postData.storyTitle,
          order: `${postData.order}`,
          backgroundType: postData.backgroundType,
          template: postData.template
            ? {
                ...postData.template,
                html: window.editor.getHtml(),
                css: window.editor.getCss(),
              }
            : "",
        },
      })
        .then(async (res) => {
          props.setMeterTitle("Storyline  Saved Successfully");
          props.setMeterProgress(80);
          if (postData.image && postData.image.file) {
            props.setMeterTitle("Uploading Thumbnail");
            props.setMeterProgress(90);
            var file = dataURLtoFile(postData.image.file, postData.image.name);
            const data = new FormData();
            data.append("files", file);
            data.append("refId", res.data._id);
            data.append("ref", "storyline");
            data.append("field", "image");
            await StrapiRequest({
              url: "upload",
              data,
              method: "post",
            });
          }

          setStories((stories) => {
            let foundInd = stories.findIndex((item) => item.id == postData.id);
            let tempStories = [...stories];
            tempStories[foundInd]["speechUrl"] = speechUrl;
            tempStories[foundInd]["created"] = true;
            tempStories[foundInd]["id"] = res.data.id;
            return tempStories;
          });
        })
        .catch((err) => {
          props.setMeter(false);
          logRequest({
            action: `Failed while created storyline with name ${postData.title}`,
          });
        });
    }
    props.setMeter(false);
    props.setMeterTitle("");
    props.setMeterProgress(0);
    props.fetchPosts();
    fetchAllChapterDuration();
  };

  const onDeleteStoryLine = (id) => {
    setStories(stories.filter((item) => item.id !== id));
    axios({
      url: `${environment.strapi_url}storylines/${id}`,
      method: "delete",
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
      });
  };

  const setBodyRef = (index, instance) => {
    let allItems = [...stories];
    allItems[index]["body"] = instance;
    setStories(allItems);
  };

  const onSetBackgroundType = (index, type) => {
    let allItems = [...stories];
    allItems[index]["backgroundType"] = type;
    setStories(allItems);
  };

  const onSave = async (index) => {
    let allItems = [...stories];
    let SelectedItem = allItems[index];
    selectedItemRef.current = { ...SelectedItem, order: index + 1 };
    onStartCreatingPost(SelectedItem, index + 1);
  };
  return (
    <div
      className={`${style.LatestFeed} mt-0`}
      style={{
        marginBottom: "20em",
        width: "970px",
      }}
    >
      {selectedStoryline >= 0 && selectedStoryline !== false ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            console.log("overlay click");
            setSelectedStoryLine(false);

            props.setPostData({
              ...props.postData,
              selectedStory: null,
              clickedStory: true,
              image: "",
              background: "",
              voice: "",
            });

            props.setShareSidebar({ visible: false });
          }}
          className={style.storyOverlay}
        ></div>
      ) : (
        ""
      )}

      <h5
        className={`${createStyle.tabBtn} text-center mb-0 `}
        style={{
          cursor: "pointer",
          position: "fixed",
          left: "1200px",
          top: "53%",
          border: "none",
          zIndex: "999999",
        }}
        onClick={() => {
          setStories([
            ...stories,
            {
              id: stories.length,
              voice: { fullname: "Joanna", avatar: "/images/joanna1.png" },
            },
          ]);
          setSelectedStoryLine(false);
          props.setPostData({
            ...props.postData,
            background: "",
            voice: "",
            image: "",
            sub_type: "STANDARD",
            selectedStory: null,
            clickedStory: false,
          });
        }}
      >
        <img src="/images/plusButton.svg" alt="" />
      </h5>

      {stories.map((item, index) => (
        <div
          style={{
            position: "relative",
            zIndex: selectedStoryline == index ? 99999 : 1,
          }}
          onClick={() => {
            setSelectedStoryLine(index);
            props.setPostData({
              ...props.postData,
              selectedStory: index + 1,
              clickedStory: true,
              image: item.image ? item.image : false,
            });
          }}
        >
          <div
            className={`row mt-5 mx-0 ${style.dropContainer} ${style.statusBar} `}
            style={{
              borderRadius: 0,
              // height: "75px",
              width: "970px",
              position: "relative",
            }}
          >
            <div className="d-flex pl-4 w-100 mb-4">
              <div
                className={`${memberstyle.userItem} ${memberstyle.smallUserItem} mr-4 d-flex mt-2 align-items-center`}
                style={{ opacity: item.sub_type == "REC0RD" ? 0.5 : 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (item.sub_type !== "REC0RD") {
                    props.changeToCategoriesTab("VoiceTab");
                    setSelectedStoryLine(index);
                    props.setPostData({
                      ...props.postData,
                      ...item,
                      clickedStory: false,
                      selectedStory: index + 1,
                    });
                  }
                }}
              >
                <img
                  src={item.voice.avatar}
                  className={style.voiceAvatar}
                  alt=""
                  style={{ width: "35px", height: "35px" }}
                />
                <div className={memberstyle.userDetail}>
                  <div>
                    <p style={{ color: "#38ef7d" }}>VOICE</p>
                    <p>{item.voice.fullname}</p>
                    <p style={{ opacity: "0.5" }}>Narration, Promo</p>
                  </div>
                  <div className="ml-4">
                    <h5
                      className={`${style.statusActionBtn} text-center mb-0 `}
                    >
                      Change
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className={`${memberstyle.userItem} ${memberstyle.smallUserItem} d-flex mt-2 align-items-center`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  props.changeToCategoriesTab("ImageTab");
                  setSelectedStoryLine(index);
                  props.setPostData({
                    ...props.postData,
                    ...item,
                    image: item.image ? item.image : false,
                    clickedStory: false,
                    selectedStory: index + 1,
                  });
                }}
              >
                <img
                  src={
                    item.image
                      ? item.image.file
                        ? item.image.file
                        : item.image
                      : "https://storage.googleapis.com/audio_one_background_music/city.jpg"
                  }
                  className={style.voiceAvatar}
                  style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                  alt=""
                />
                <div className={memberstyle.userDetail}>
                  <div>
                    <p style={{ color: "#38ef7d" }}>COVER IMAGE</p>
                    <p style={{ height: "35px", paddingTop: "5px" }}>Image</p>
                  </div>
                  <div className="ml-4">
                    <h5
                      className={`${style.statusActionBtn} text-center mb-0 `}
                    >
                      Change
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className={`${memberstyle.userItem} ${memberstyle.smallUserItem} ml-4 d-flex mt-2 align-items-center`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  props.changeToCategoriesTab("BackgroundMusicTab");
                  setSelectedStoryLine(index);

                  props.setPostData({
                    ...props.postData,
                    ...item,
                    clickedStory: false,
                    selectedStory: index + 1,
                  });
                }}
              >
                <img
                  src="/images/musicIcon.svg"
                  className={style.voiceAvatar}
                  alt=""
                  style={{
                    borderRadius: 0,
                    filter: "brightness(6.5)",
                    width: "35px",
                    height: "35px",
                  }}
                />
                <div className={memberstyle.userDetail}>
                  <div>
                    <p style={{ color: "#38ef7d" }}>BACKGROUND MUSIC</p>
                    <p style={{ height: "35px", paddingTop: "5px" }}>
                      {props.postData.storyBackground ? "" : item.background}
                    </p>
                  </div>
                  <div className="ml-4">
                    <h5
                      className={`${style.statusActionBtn} text-center mb-0 `}
                    >
                      Change
                    </h5>
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-center justify-content-end pr-3 mt-2"
                style={{ flex: 1 }}
              >

                {item.speechUrl ? (
                  <img
                    onClick={() => {
                      Player.playExtraAud({
                        title: `${props.postData.title} Storyline ${index + 1}`,
                        link: item.speechUrl,
                        source: "",
                        subtype: "extra",
                        sid: item.id,
                        preview: true,
                        recent: true,
                        image:
                          "https://storage.googleapis.com/audio_one_background_music/music.png",
                      });
                    }}
                    src={"/images/playIcon.svg"}
                    className="mb-2"
                    style={{
                      filter: "invert(1)",
                      cursor: "pointer",
                      marginRight: "2.3em",
                      width: "35px",
                      marginTop: "6px",
                    }}
                  />
                ) : (
                  ""
                )}
                {item.created ? (
                  <h5
                    className={`${style.statusActionBtn} ${style.redButton} text-center mb-0 `}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDeleteStoryLine(item.id);
                    }}
                  >
                    Remove
                  </h5>
                ) : (
                  ""
                )}
                <h5
                  className={`${style.statusActionBtn} ${style.greenButton} text-center mb-0 ml-3`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSave(index);
                  }}
                >
                  Save
                </h5>
              </div>
            </div>
            <div
              className={`d-flex pl-4 mt-0  w-100 ${memberstyle.userDetail} ${detailStyle.Description}`}
              style={{ justifyContent: "flex-start" }}
            >
              <div style={{ width: "217px" }}>
                <h6
                  style={{
                    textTransform: "uppercase",
                  }}
                  className="mb-2"
                >
                  PODCAST TYPE
                </h6>
                <div className="d-flex">
                  {postTypes.map((item) => (
                    <FeedTag
                      onClick={(id) => {
                        setSelectedPostType(item.name);
                        setSelectedStoryLine(index);
                        props.setPostData({
                          ...props.postData,
                          sub_type: item.name,
                          clickedStory: false,
                        });
                      }}
                      isSelected={selectedPostType == item.name}
                      data={{ id: item.id, label: item.name }}
                    />
                  ))}
                </div>
              </div>
              <div className="ml-4">
                <h6 style={{ textTransform: "uppercase" }} className="mb-2">
                  PODCAST SPECIAL
                </h6>

                <div className="d-flex">
                  {specialPostTypes.map((item) => (
                    <FeedTag
                      onClick={(id) => {
                        setSelectedPostType(item.name);
                        setSelectedStoryLine(index);
                      }}
                      isSelected={selectedPostType == item.name}
                      data={{ id: item.id, label: item.name }}
                    />
                  ))}
                </div>
              </div>
              <div className="ml-4">
                <h6 style={{ textTransform: "uppercase" }} className="mb-2">
                  PODCAST MUSIC
                </h6>

                <div className="d-flex">
                  {musicTypes.map((music) => (
                    <FeedTag
                      onClick={(id) => {
                        onSetBackgroundType(index, music.name);
                      }}
                      isSelected={item.backgroundType == music.name}
                      data={{ id: music.id, label: music.name }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`row  mx-0 ${style.dropContainer} flex-column`}
            style={{
              minHeight: "200px",
              paddingBottom: index == stories.length - 1 ? "20em" : "",
            }}
          >
            <div className="pr-5  w-100">
              <div
                className={`${style.editorJs} editorContainer`}
                style={{
                  borderRadius: 0,
                  width: "970px",
                }}
                data-index={index}
              >
                <EditorJs
                  tools={EDITOR_JS_TOOLS}
                  instanceRef={(instance) => {
                    setBodyRef(index, instance);
                  }}
                  className={`storyEditor`}
                  // enableReInitialize={item.sub_type == "REC0RD" ? true : false}
                  data={item.description}
                  minHeight={0}
                  placeholder={placeholder}
                  style={{ height: "100%" }}
                />
              </div>
            </div>
            {props.shareSidebar.visible &&
            props.shareSidebar.type == "storyTemplate" &&
            selectedStoryline == index ? (
              <div className="  w-100">
                <StoryTemplate data={stories[selectedStoryline]} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default connect(mapState, dispatchState)(AddPost);
