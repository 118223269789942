import React from "react";
import { Modal, Button, message } from "antd";
import styles from "../style/sharemodal.module.css";
import _ from "lodash";
import { connect } from "react-redux";

function LinkModal(props) {
  const copyText = (text) => {
    const self = this;
    navigator.clipboard.writeText(text).then(
      function () {
        message.success("Link Copied To Clipboard", 7);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <Modal
      visible={props.visible}
      title="Share this Article"
      footer={[]}
      className={`${styles.shareEmail} shareEmail share-modal shareLinkModal `}
      onCancel={() => {
        props.setVisibility(false);
      }}
    >
      <div className="p-3">
        <div className="mb-4 d-flex">
          <img
            className={styles.shareArticleImage}
            src={props.loadedArticle ? props.loadedArticle.image : ""}
          />
          <h4 className={styles.shareArticleTitle}>
            {props.loadedArticle ? props.loadedArticle.title : ""}
          </h4>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <a href={props.loadedLink} target="_blank" className="mr-3">
            {props.loadedLink}
          </a>
          <Button
            className="btn btn-black"
            style={{ border: "none" }}
            onClick={() => copyText(props.loadedLink)}
          >
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
}

var mapState = (state) => {
  return {
    user: state.auth.user,
    feedlyUser: state.news.feedlyUser,
  };
};

export default connect(mapState, null)(LinkModal);
