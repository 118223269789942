import React, { useState } from "react";
import style from "../style/Allproject.module.css";
import ProjectCard from "./project/ProjectCard";

// import ProjectDetail from "./ProjectDetail";

export default function TopItemsContainer({
  pageName,
  setProjectVisible,
  projectVisible,
  tab,
  data,
  onSelect,
  onPreview,
  todayTab,
  onAdd,
}) {
  const horizontalScroll = (event) => {
    const delta = Math.max(
      -1,
      Math.min(1, event.nativeEvent.wheelDelta || -event.nativeEvent.detail)
    );
    event.currentTarget.scrollLeft -= delta * 10;
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div>
      {todayTab ? (
        <div className={style.ProjectLabel}>
          <span className={projectVisible ? "" : style.active}>{tab}</span>
          <span className={projectVisible ? style.active : ""}>{pageName}</span>
        </div>
      ) : (
        ""
      )}

      <h4
        style={{
          marginTop: todayTab ? "8em" : "0em",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Recently Added
      </h4>
      <div
        className={`${style.ProjectCards} mt-0 mb-0`}
        style={{ width: "85%", height: "auto" }}
        onWheel={horizontalScroll}
      >
        {data.map((item) => (
          <ProjectCard
            selectable={true}
            onSelect={() => onSelect(item)}
            showEdit={false}
            onPreview={() => onPreview(item)}
            data={item}
            todayScreen={true}
            onAdd={onAdd}
          />
        ))}
      </div>
      {/* )} */}
    </div>
  );
}
