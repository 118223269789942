import React, { useState, useEffect } from "react";
import style from "../../style/sidebarTabsContent.module.css";
import FeedTag from "./components/FeedTag";
import { connect } from "react-redux";
import {
  setAddingSource,
  setPostData,
  changeToCategoriesTab,
} from "../../redux/actions/main";
import { editProject } from "../../redux/actions/project";
import { message } from "antd";
import apiRequest from "../../api/clientRequest";
import FileDropZone from "../FileDropZone";
import { setAudienceCard } from "../../redux/actions/main";
const mapState = (state) => ({
  feedlyUser: state.news.feedlyUser,
  activeProject: state.projects.activeProject,
});

const mapDispatch = (dispatch) => ({
  editProject: (data) => dispatch(editProject(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  setAudienceCard: (data) => dispatch(setAudienceCard(data)),
});

function AudienceContacts(props) {
  const [tabType, setTabType] = useState("image");

  const addContacts = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("contacts", file);

      apiRequest({
        method: "POST",
        url: `/audience/contacts/${props.activeProject._id}`,
        headers: {
          "Content-Type": `multipart/form-data;`,
        },
        data: formData,
      }).then((res) => {
        props.editProject(res.data);
        message.success("Audience Contacts Added Successfully.");
      });
    } else {
      message.error("Please select a csv file first.");
    }
  };
  return (
    <div className={style.AddItem}>
      <div className={`${style.topSection} topSection`} style={{ top: "0" }}>
        <div className={style.actionBtn}>
          <button
            className={style.active}
            onClick={() => {
              setTabType("image");
            }}
            style={{
              borderColor: tabType == "image" ? "#38ef7d" : "transparent",
            }}
          >
            <img
              src="/images/csvFileIcon.svg"
              style={{ filter: "brightness(1)" }}
              alt=""
            />
            <span>Import Contacts</span>
          </button>
          <button
            className={style.active}
            onClick={() => {
              setTabType("categories");
              props.setAudienceCard({ visible: true });
            }}
            style={{
              borderColor: tabType == "categories" ? "#38ef7d" : "transparent",
            }}
          >
            <img
              src="/images/addContactIcon.svg"
              alt=""
              style={{ filter: "brightness(1)" }}
            />
            <span>Add Contacts</span>
          </button>{" "}
        </div>
      </div>

      <div
        className=""
        style={{
          overflow: "auto",
          paddingBottom: "6em",
          paddingTop: "9em",
          paddingLeft: "1em",
          paddingRight: "1em",
        }}
      >
        {tabType == "image" ? (
          <div style={{ paddingTop: "4em" }}>
            <FileDropZone
              onSelectedFile={(files) => {
                addContacts(files[0]);
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(AudienceContacts);
