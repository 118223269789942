import React, { useState, useEffect } from "react";
import createStyle from "../../style/createproject.module.css";
import ProjectCard from "./ProjectCard";
import style from "../../style/ProjectDetail.module.css";
import SourcesContainer from "./SourcesContainer";
import { connect } from "react-redux";
import { updateProject } from "../../redux/actions/project";
import Actions from "../../redux/actions/news";
import apiRequest from "../../api/clientRequest";
import {
  changeToCategoriesTab,
  searchShortCategories,
  setAddingSource,
} from "../../redux/actions/main";
import _ from "lodash";
import ProjectDetailTab from "./ProjectDetailTab";

var mapStateToProps = (state) => {
  return {
    todayNews: state.news.todayNews,
    selectedFeed: state.news.selectedFeed,
    feedlyUser: state.news.feedlyUser,
    teamSearch: state.main.memberSearch,
    individualSearch: state.main.individualSearch,
    teams: state.projects.projects.filter((item) => item.type == "team"),
    user: state.auth.user,
  };
};

const mapDispatch = (dispatch) => ({
  updateProject: (data) => dispatch(updateProject(data)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function ProjectDetail(props) {
  const [addSources, setAddSources] = useState(false);
  const [sources, setSources] = useState([]);
  const [feeds, setFeeds] = useState([]);
  const [projectFeeds, setProjectFeeds] = useState([]);
  const [originalFeeds, setOriginalFeeds] = useState([]);
  const [activeSource, setActiveSource] = useState("");

  const { project, feedlyUser, selectedFeed } = props;

  const setInitialFeed = async () => {
    let items = [];

    for (let i = 0; i < project.feeds.length; i++) {
      await apiRequest({
        url: `/feedly/articles/${project.feeds[i].label}`,
        method: "POST",
        data: {
          feedlyUser: feedlyUser,
          projectId: project._id,
          feedtype: project.feeds[i].feedtype,
          feed: project.feeds[i],
        },
      })
        .then((res) => {
          let tempFeeds = [...feeds, ...res.data.articles];
          items.push(...tempFeeds);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (project.podcastfeed)
      await apiRequest({
        url: `/podcast/fetch`,
        method: "POST",
        data: {
          ids: project.podcastfeed.map(
            (item) => JSON.parse(item.podcast).feed.id
          ),
          projectId: project._id,
        },
      })
        .then((res) => {
          console.log(res.data);
          items.push(...res.data);
        })
        .catch((err) => {
          console.log(err);
        });

    // if (project.shortsfeed)
    //   await apiRequest({
    //     url: `/projects/shorts`,
    //     method: "POST",
    //     data: {
    //       categories: project.shortsfeed.map(
    //         (item) => JSON.parse(item.short).title
    //       ),
    //       projectId: project._id,
    //     },
    //   })
    //     .then((res) => {
    //       items.push(...res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });

    items = items.sort((a, b) => {
      return b.published - a.published;
    });

    setOriginalFeeds(items);
    items = items.slice(0, 20);
    setFeeds(items);
  };

  useEffect(() => {
    let projLenght = [...project.feeds, ...project.podcastfeed];
    if (project.shortsfeed) projLenght.push(...project.shortsfeed);

    setSources(projLenght);
    if (projLenght.length !== sources.length) setInitialFeed();
  }, [project]);

  useEffect(() => {
    if (selectedFeed) setActiveSource("");
  }, [selectedFeed]);

  useEffect(() => {
    props.changeToCategoriesTab("Projects");
    props.setAddingSource("Projects");

    return () => {
      props.changeToCategoriesTab("");
    };
  }, []);

  const fetchShorts = async () => {
    props.setSelectedFeed({
      label: "Shorts Categories",
      feed: [],
      id: 1,
      type: "podcast",
    });
    let results = await searchShortCategories();
    props.setSelectedFeed(null);
    props.setSelectedFeed({
      label: "Shorts Categories",
      feed: results,
      id: 2,
      type: "shorts",
    });
  };

  return (
    <div>
      <div style={{ position: "relative" }} className="d-flex">
        <ProjectCard
          data={props.project}
          onEdit={props.onEdit}
          onSelect={() => {
            props.setAddingSource("News");
            setActiveSource("");
            setAddSources(false);
          }}
          onPreview={() => {
            props.setAddingSource("News");
            setActiveSource("");
            setAddSources(false);
          }}
        />
        <div
          className={createStyle.createContainer}
          style={{ paddingTop: "6em" }}
        >
          <div style={{ marginTop: activeSource ? "-4.5em" : "-1em" }}>
            <button
              className={createStyle.addSourcesBtn}
              onClick={() => {
                setAddSources(true);
                props.setAddingSource("News Categories");
                props.changeToCategoriesTab("Categories");
              }}
            >
              <img src="/images/plusIcon.svg" alt="" />
            </button>
            <span>Add Sources</span>
          </div>

          <div className={createStyle.inputContainer}>
            <div className={style.Description}>
              {addSources ? (
                <SourcesContainer
                  setActiveSource={(val) => {
                    console.log(val);
                    if (val == "Shorts Categories") fetchShorts();
                    props.changeToCategoriesTab(val);
                    props.setAddingSource(val);
                    props.setSelectedFeed(null);
                    setActiveSource(val);
                  }}
                />
              ) : (
                <>
                  <h6 style={{ textTransform: "uppercase" }}>
                    {props.project.type} DESCRIPTION
                  </h6>
                  <p>{props.project.description}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProjectDetailTab
        feeds={feeds}
        origFeeds={originalFeeds}
        addingContent={activeSource}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(ProjectDetail);
