import React, { useState } from "react";
import { connect } from "react-redux";
import FeedContainer from "../FeedContainer";
import PlayCard from "../PlayCard";
import TopItemsContainer from "../TopItemsContainer";
import style from "../../style/tabs.module.css";
import createStyle from "../../style/createproject.module.css";
import moment from "moment";
import { SettingFilled } from "@ant-design/icons";
import OrderFeedContainer from "../OrderFeedContainer";
import {
  setActiveProject,
  setActivePreview,
} from "../../redux/actions/project";
import _ from "lodash";
import {
  setAddingSource,
  setActiveTab,
  changeToCategoriesTab,
} from "../../redux/actions/main";
import Actions from "../../redux/actions/news";

var mapStateToProps = (state) => {
  let allRecents = [...state.projects.projects];

  allRecents = allRecents.sort((a, b) => {
    return moment(b.createdAt).format("X") - moment(a.createdAt).format("X");
  });

  allRecents = [...state.main.recents, ...allRecents].slice(0, 10);
  allRecents = _.uniqBy(allRecents, "_id");

  return {
    todayNews: [...state.news.todayNews, ...state.news.podcasts],
    selectedFeed: state.news.selectedFeed,
    feedlyUser: state.news.feedlyUser,
    recents: allRecents,
  };
};
const mapDispatch = (dispatch) => ({
  setActiveProject: (data) => dispatch(setActiveProject(data)),
  setActivePreview: (data) => dispatch(setActivePreview(data)),
  setAddingSource: (feed) => dispatch(setAddingSource(feed)),
  setActiveTab: (data) => dispatch(setActiveTab(data)),
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
  setSelectedFeed: (feed) => dispatch(Actions.setSelectedFeed(feed)),
});
function TodayNews(props) {
  const { selectedFeed, todayNews } = props;
  const [mainTab, setMainTab] = useState("LATEST FEEDS");
  const [groupView, setGroupView] = useState(true);

  const tabMains = ["TODAY ITEMS", "LATEST FEEDS"];

  const onClickCard = (data) => {
    props.setAddingSource("News");

    props.setActiveProject(data);
    if (data.type == "brand") props.history.push("/dashboard/brand");
    else if (data.type == "project") props.history.push("/dashboard/project");
    else if (data.type == "campaign") props.history.push("/dashboard/campaign");
    else if (data.type == "template") props.history.push("/dashboard/template");
    else if (data.type == "team") props.history.push("/dashboard/teams");
    else if (data.type == "post") props.history.push("/dashboard/podcast");
    else if (data.type == "collection")
      props.history.push("/dashboard/collection");
  };
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <TopItemsContainer
        tab={"Today"}
        data={props.recents}
        todayTab={true}
        onSelect={onClickCard}
        onPreview={(item) => props.setActivePreview(item)}
      />

      <div className="d-flex align-items-center mt-3">
        <h5
          style={{
            cursor: "pointer",
            borderRadius: "10px",
            fontSize: "0.95em",
            lineHeight: "1px",
            padding: "6px 11px",
          }}
          className={`${createStyle.tabBtn} text-center mb-0 `}
          onClick={() => setGroupView(!groupView)}
        >
          <SettingFilled color="white" />
        </h5>
        {tabMains.map((item) => (
          <h5
            className={`${
              mainTab == item ? createStyle.activeTabBtn : createStyle.tabBtn
            } text-center mb-0 `}
            style={{
              cursor: "pointer",
              textTransform: "uppercase",
              minWidth: "150px",
              padding: "6px 11px",
              borderRadius: "10px",
              fontSize: "0.95em",
            }}
            onClick={() => {
              setMainTab(item);
              props.setActiveTab(item);
              props.setAddingSource("News");
              props.changeToCategoriesTab("News");
              props.setSelectedFeed(null);
            }}
          >
            {item}
          </h5>
        ))}
      </div>

      {mainTab == "TODAY ITEMS" ? (
        <OrderFeedContainer
          container={"tabsContainer"}
          boards={false}
          groupView={groupView}
          todayTab={true}
        />
      ) : (
        <FeedContainer
          news={selectedFeed ? selectedFeed.feed : todayNews}
          tab={selectedFeed ? selectedFeed.label : "Now"}
          container={"tabsContainer"}
          feedlyUser={props.feedlyUser}
          todayTab={true}
        />
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatch)(TodayNews);
