var Types = {
  setUser: "setUser",
  linkAccount: "linkAccount",
  RESET_AUTH: "RESET_AUTH",
  AUTHENTICATE_USER: "AUTHENTICATE_USER",
};
// actions
var setUser = (cond) => ({
  type: Types.setUser,
  payload: cond,
});

var setLinkAccountCreds = (cond) => ({
  type: Types.linkAccount,
  payload: cond,
});

var resetAuth = (cond) => ({
  type: Types.RESET_AUTH,
  payload: cond,
});

var setAuthenticated = (cond) => ({
  type: Types.AUTHENTICATE_USER,
  payload: cond,
});

export default {
  setUser,
  setLinkAccountCreds,
  Types,
  setAuthenticated,
  resetAuth,
};
