import React, { useState, useEffect } from "react";
import style from "../../style/Allproject.module.css";
import { connect } from "react-redux";
import CreateSettings from "./CreateSettings";

function AllSetting(props) {
  return (
    <div>
      <div className={style.ProjectLabel}>
        <span onClick={() => {}}>ALL</span>
        <span>USER SETTINGS</span>
        <span>PLATFORM SETTINGS</span>
      </div>
      <div
        style={{ flexWrap: "wrap", paddingTop: "10.5em", marginTop: 0 }}
        className={`${style.ProjectCards} Projects`}
        id="projectDetail"
      >
        <CreateSettings />
      </div>
    </div>
  );
}

export default AllSetting;
