import React, { useState, useEffect } from "react";
import style from "../style/RightSidebar.module.css";
import { Tabs } from "antd";
import DetailTab from "./sidebarTabs/Details";
import RecentItem from "./sidebarTabs/RecentItem";
import AddItem from "./sidebarTabs/AddItem";
import FeedTab from "./sidebarTabs/FeedTab";
import ToolsTab from "./sidebarTabs/ToolsTab";
import AddTeam from "./sidebarTabs/AddTeam";
import ShareTab from "./sidebarTabs/ShareTab";
import CampaignAddItem from "./sidebarTabs/CollectionAddItem";
import { connect } from "react-redux";
import SendEmail from "./sidebarTabs/SendEmail";
import { changeToCategoriesTab } from "../redux/actions/main";
import { useLocation } from "react-router-dom";
import PostCategoriesTab from "./sidebarTabs/PostCategoriesTab";
import AddPostDetail from "./sidebarTabs/AddPostDetail";
import AdvancePost from "./sidebarTabs/AdvancePost";
import ShareLinkSidebar from "./sidebarTabs/ShareLinkTab";
import AudienceContacts from "./sidebarTabs/AudienceContacts";
import StoryTemplates from "./sidebarTabs/StoryTemplates";
const mapState = (state) => ({
  activeColor: state.projects.activeColor,
  activeProject: state.projects.activeProject,
  activePreview: state.projects.activePreview,
  addingSource: state.main.addSource,
  tabChange: state.main.tabChange,
  shareSidebar: state.main.shareSidebar,
});

const mapDispatch = (dispatch) => ({
  changeToCategoriesTab: (feed) => dispatch(changeToCategoriesTab(feed)),
});

function RightSidebar({
  activeColor,
  activeProject,
  activePreview,
  addingSource,
  tabChange,
  changeToCategoriesTab,
  shareSidebar,
}) {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("Recent Items");
  const [curProject, setCurProject] = useState(null);
  const [expanded, setExpanded] = useState(false);

  let location = useLocation();

  let todayTabs = [
    {
      title:
        addingSource == "News Categories" || addingSource == "News"
          ? "News Categories"
          : addingSource == "News Boards"
          ? "News Boards"
          : addingSource == "Shorts"
          ? "Shorts Categories"
          : "Podcast Categories",
      component: <FeedTab />,
    },
  ];

  let tabs = [
    {
      title:
        addingSource == "News Categories" || addingSource == "News"
          ? "News Categories"
          : addingSource == "News Boards"
          ? "News Boards"
          : addingSource == "Shorts"
          ? "Shorts Categories"
          : "Podcast Categories",
      component: <FeedTab />,
    },
    {
      title: "Recent Items",
      component: <RecentItem showTab={true} showDrag={true} />,
    },
    {
      title: "Collaborate",
      component: <ShareTab />,
    },
  ];

  if (
    (activeProject && activeProject.type == "campaign") ||
    (activePreview && activePreview.type == "campaign")
  )
    tabs = [
      { title: "Add Items", component: <AddItem /> },
      { title: "Tools", component: <ToolsTab /> },
      {
        title: "Collaborate",
        component: <ShareTab />,
      },
      { title: "Send Email", component: <SendEmail /> },
      // { title: "Recent Items", component: <RecentItem showTab={true} /> },
      { title: "Team", component: <AddTeam /> },
    ];

  if (
    (activeProject && activeProject.type == "team") ||
    (activePreview && activePreview.type == "team")
  )
    tabs = [{ title: "Team", component: <AddTeam /> }];

  if (
    (activeProject && activeProject.type == "collection") ||
    (activePreview && activePreview.type == "collection")
  )
    tabs = [
      { title: "Add Items", component: <CampaignAddItem /> },
      {
        title: "Collaborate",
        component: <ShareTab />,
      },
    ];

  if (activeProject && activeProject.type == "post")
    tabs = [
      { title: "EDIT PODCAST", component: <AddPostDetail /> },
      // { title: "Post Categories", component: <PostCategoriesTab /> },
      // { title: "Advance Post", component: <AdvancePost /> },
      {
        title: "Collaborate",
        component: <ShareTab />,
      },
    ];
  if (activeProject && activeProject.type == "audience")
    tabs = [
      { title: "ADD CONTACTS", component: <AudienceContacts /> },
      // { title: "Post Categories", component: <PostCategoriesTab /> },
      // { title: "Advance Post", component: <AdvancePost /> },
      {
        title: "Collaborate",
        component: <ShareTab />,
      },
    ];

  if (activeProject && activeProject.type == "template")
    tabs = [{ title: "Collaborate", component: <ShareTab /> }];

  if (shareSidebar.visible)
    if (shareSidebar.type == "post") tabs = [];
    else if (shareSidebar.type == "storyTemplate")
      tabs = [{ title: "Share Link", component: <StoryTemplates /> }];
    else tabs = [{ title: "Share Link", component: <ShareLinkSidebar /> }];

  useEffect(() => {
    if (!activeProject) {
      setCurProject(null);
      setActiveTab(tabs[0].title);
    } else {
      if (
        activeProject.type !== "project" &&
        activeProject.type !== "brand" &&
        tabs.length > 0
      )
        setActiveTab(tabs[0].title);

      setCurProject(activeProject);
    }
  }, [activeProject]);

  useEffect(() => {
    if (tabs.length > 0) {
      if (tabChange && tabChange == "Build Campaign")
        if (shareSidebar.visible) setActiveTab(tabs[0].title);
        else setActiveTab(tabs[3].title);
      else if (tabChange && tabChange == "Add Campaign Items")
        if (shareSidebar.visible) setActiveTab(tabs[0].title);
        else setActiveTab(tabs[0].title);
      else if (tabChange && tabChange == "EDIT PODCAST")
        if (shareSidebar.visible) setActiveTab(tabs[0].title);
        else setActiveTab(tabs[0].title);
      else if (
        (tabChange && tabChange == "Projects") ||
        tabChange == "Recent Items"
      ) {
        if (shareSidebar.visible) setActiveTab(tabs[0].title);
        else setActiveTab(tabs[1].title);
      } else {
        setActiveTab(tabs[0].title);
      }
    }
    return () => {};
  }, [tabChange, shareSidebar]);

  const visibleTabs = [
    "ADD PODCAST",
    "EDIT PODCAST",
    "Podcast Categories",
    "Podcast Shows",
    "Podcast Episodes",
    "Spotify Podcast Shows",
    "Shorts Categories",
    "News Categories",
    "News Boards",
    "Video",
    "Shorts",
    "Shorts Categories",
    "Build Campaign",
    "Add Campaign Items",
    "Add Contacts",
    "Members",
    "Collaborate",
    "Teams",
    "Campaign",
    "Projects",
    "Posts",
    "Collections",
    "campaginDetail",
    "PODCASTS",
    "addAudience",
    "Today",
  ];

  return (
    <>
      {shareSidebar.visible ? (
        shareSidebar.type == "post" ? (
          ""
        ) : (
          <div
            className={`${style.RightSidebar} ${
              expanded ? style.RightSidebarExpanded : ""
            }`}
            style={{
              backgroundColor: `#141414`,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <img
              className={style.expandIcon}
              className={`${style.expandIcon} ${
                expanded ? style.expandedIcon : ""
              }`}
              src="/images/expandIcon.svg"
              alt=""
            />
            <div className={style.tabBody}>
              {tabs.find((item) => item.title == activeTab)
                ? tabs.find((item) => item.title == activeTab).component
                : ""}
              <div
                className={style.topGradient}
                key={10}
                style={{
                  backgroundImage: `linear-gradient(rgba(20, 20, 20, 0) 0%, rgb(0 0 0 / 90%) 15%, rgb(0 0 0) 100%)`,
                  height: "260px",
                }}
              ></div>
              <div
                className={style.footer}
                key={11}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%)`,
                }}
              ></div>
            </div>
          </div>
        )
      ) : (activeProject || activePreview) &&
        visibleTabs.includes(addingSource) ? (
        <>
          <div
            className={`${style.tabContainer} ${
              expanded ? style.tabContainerExpanded : ""
            }`}
          >
            {tabs.map((item) => (
              <div
                onClick={() => setActiveTab(item.title)}
                className={activeTab == item.title ? style.active : ""}
              >
                {item.title}
              </div>
            ))}
          </div>
          <div
            className={`${style.RightSidebar} ${
              expanded ? style.RightSidebarExpanded : ""
            }`}
            style={{
              backgroundColor: `#141414`,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <img
              className={style.expandIcon}
              className={`${style.expandIcon} ${
                expanded ? style.expandedIcon : ""
              }`}
              src="/images/expandIcon.svg"
              alt=""
            />
            <div className={style.tabBody}>
              {tabs.find((item) => item.title == activeTab)
                ? tabs.find((item) => item.title == activeTab).component
                : ""}
              <div
                className={style.topGradient}
                key={10}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.9) 15%, #141414 100%)`,
                  height: activeTab == "Recent Items" ? "120px" : "260px",
                }}
              ></div>
              <div
                className={style.footer}
                key={11}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%)`,
                }}
              ></div>
            </div>
          </div>
        </>
      ) : location.pathname == "/dashboard" ? (
        <>
          <div
            className={`${style.tabContainer} ${
              expanded ? style.tabContainerExpanded : ""
            }`}
          >
            {todayTabs.map((item) => (
              <div
                onClick={() => setActiveTab(item.title)}
                className={activeTab == item.title ? style.active : ""}
              >
                {item.title}
              </div>
            ))}
          </div>
          <div
            className={`${style.RightSidebar} ${
              expanded ? style.RightSidebarExpanded : ""
            }`}
            style={{
              backgroundColor: `rgb(20, 20, 20)`,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <img
              className={style.expandIcon}
              className={`${style.expandIcon} ${
                expanded ? style.expandedIcon : ""
              }`}
              src="/images/expandIcon.svg"
              alt=""
            />
            <div className={style.tabBody}>
              {todayTabs.find((item) => item.title == activeTab)
                ? todayTabs.find((item) => item.title == activeTab).component
                : ""}
              <div
                className={style.topGradient}
                key={10}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.9) 15%, #141414 100%)`,
                  height: "260px",
                }}
              ></div>
              <div
                className={style.footer}
                key={11}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 100%)`,
                }}
              ></div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default connect(mapState, mapDispatch)(RightSidebar);
