import React, { Component } from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import { withRouter } from "react-router-dom";
import message from "antd/lib/message";
import { connect } from "react-redux";
import ACTIONS from "../../redux/actions/site";
import AUTHACTIONS from "../../redux/actions/auth";
import apiRequest from "../../api/clientRequest";
import styles from "../../style/onboarding.module.css";
var mapStateToProps = (state) => {
  return {
    onboarding: state.site.onboarding,
    user: state.auth.user,
  };
};

var mapDispatchToProps = (dispatch) => ({
  setOnboarding: (item) => dispatch(ACTIONS.setOnboarding(item)),
  setUser: (item) => dispatch(AUTHACTIONS.setUser(item)),
  setThemeSelector: (item) => dispatch(ACTIONS.setThemeSelector(item)),
});

const steps = [<FirstStep />, <SecondStep />, <ThirdStep />, <FourthStep />];
class OnBoardingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formCompleted: false,
      current: 0,
      firstStep: {
        selectedCategories: [],
      },
      secondStep: {
        pubName: "",
        tagLine: "",
        username: "",
      },
      thirdStep: {
        name: "",
        bio: "",
        avatar: "",
      },
    };
  }

  componentWillMount() {
    // if (!this.props.onboarding) this.props.history.push("/");
  }

  next(e) {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    this.handleSubmitMyForm();
  }

  prev(e) {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (this.state.current != 0) {
      const current = this.state.current - 1;
      this.setState({
        current,
      });
    }
  }

  async formSubmit(val) {
    const self = this;
    if (val) {
      if (this.state.current == 0) {
        this.setState((prevState) => ({
          firstStep: val,
        }));
      }
      if (this.state.current == 1) {
        this.setState((prevState) => ({
          secondStep: val,
        }));
      }
      if (this.state.current == 2) {
        this.setState(
          (prevState) => ({
            thirdStep: val,
          }),
          () => {
            self.submitForm();
          }
        );
      } else {
        var current = this.state.current + 1;

        this.setState({
          current,
        });
      }
    }
  }

  submitForm() {
    const config = {
      headers: {
        "Content-Type": `multipart/form-data;`,
      },
    };
    console.log(this.state);

    const formData = new FormData();
    formData.append("username", this.state.secondStep.username);
    formData.append("pubName", this.state.secondStep.pubName);
    formData.append("tagLine", this.state.secondStep.tagLine);
    formData.append("name", this.state.thirdStep.name);
    formData.append("bio", this.state.thirdStep.bio);
    formData.append("fullname", this.state.thirdStep.name);

    formData.append("avatar", this.state.thirdStep.avatar);
    formData.append("user_id", this.props.user._id);
    formData.append(
      "newstags",
      this.state.firstStep.selectedCategories.join(",")
    );

    if (this.props.match.params.source) {
      formData.append("user_type", "social");
    } else {
      formData.append("user_type", "normal");
    }

    apiRequest({
      url: "/auth/onboarding",
      method: "POST",
      data: formData,
      config,
    })
      .then((res) => {
        this.props.setUser({ ...this.props.user, ...res.data.user });
        this.props.setOnboarding(null);
        this.props.history.push("/dashboard");
        this.props.setThemeSelector(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderStep() {
    if (this.state.current == 0) {
      return (
        <FirstStep
          key="firststep"
          data={this.state.firstStep}
          bindSubmitForm={this.bindSubmitForm}
          submitHandle={this.formSubmit.bind(this)}
          source={this.props.match.params.source}
        />
      );
    } else if (this.state.current == 1) {
      return (
        <SecondStep
          firstname={this.state.firstStep.firstname}
          key="secondStep"
          data={this.state.secondStep}
          submitHandle={this.formSubmit.bind(this)}
        />
      );
    } else if (this.state.current == 2) {
      return (
        <ThirdStep
          key="thirdStep"
          code={this.state.secondStep.code}
          data={this.state.thirdStep}
          submitHandle={this.formSubmit.bind(this)}
        />
      );
    } else if (this.state.current == 3) {
      return (
        <FourthStep
          key="fourthStep"
          code={this.state.fourthStep.code}
          data={this.state.fourthStep}
          submitHandle={this.formSubmit.bind(this)}
        />
      );
    }
  }

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm.click();
    }
  };

  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  backStep() {
    this.setState({
      formCompleted: false,
    });
  }

  render() {
    const { current } = this.state;

    return (
      <div className="container ">
        {this.renderStep()}
        {/* <div className={styles.formSteps}>
          {" "}
          <div className="step-button">
            {this.state.current > 0 && (
              <button
                onClick={(e) => this.prev(e)}
                className={`${styles.stepButton}   `}
              >
                BACK
              </button>
            )}
            /
            {this.state.current == 3 ? (
              <button
                onClick={(e) => this.next(e)}
                className={`${styles.stepButton}   `}
              >
                SUBMIT
              </button>
            ) : (
              <button
                onClick={(e) => {
                  this.next(e);
                }}
                className={`  ${styles.stepButton}`}
              >
                NEXT
              </button>
            )}
          </div>
        </div>{" "} */}
      </div>
    );
  }
}

const withRoute = withRouter(OnBoardingForm);

export default connect(mapStateToProps, mapDispatchToProps)(withRoute);
