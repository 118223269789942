import React from "react";
import styles from "../../style/interviewBar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setInterviewActions } from "../../redux/actions/main";
import ImageInitial from "../ImageInitial";
import { setInterviewOptions } from "../../redux/actions/main";
import {
  setMeter,
  setMeterProgress,
  setMeterTitle,
  setPostData,
} from "../../redux/actions/main";
import { useRef } from "react";
import { setRecordMode, uploadedRecordedAudio } from "../../redux/actions/main";
import { v4 as uuidv4 } from "uuid";
import { Spin, Switch } from "antd";
import style from "../../style/sidebarTabsContent.module.css";
import player from "../../data/player";
import $ from "jquery";

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export default function RecordBar() {
  const dispatch = useDispatch();
  const Player = player();
  const [recording, setRecording] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [timer, setTimer] = React.useState(false);
  const [recordedData, setRecordedData] = React.useState(null);
  const [recorder, setRecorder] = React.useState(null);
  const [stream, setStream] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  const [noiseCancellation, setNoiseCancellation] = React.useState(false);
  const [audioEnhancement, setAudioEnhancement] = React.useState(false);
  const [recordedFile, setRecordedFile] = React.useState(false);
  const { socket } = useSelector((state) => state.site);
  const { postData } = useSelector((state) => state.main);

  const postDataRef = useRef(null);

  React.useEffect(() => {
    postDataRef.current = postData;
    setNoiseCancellation(postData.removeNoise);
  }, [postData]);

  React.useEffect(() => {
    socket.on("completedProcessRecordedAudio", (data) => {
      console.log(data, "completed tts");
      if (data.progress == 100) {
        setProcessing(false);
        startRecordPlay(data.results.url);

        dispatch(
          setPostData({
            ...postDataRef.current,
            voiceSpeech: data.results.transcript,
            originalUrl: data.results.originalUrl,
            speechUrl: data.results.url,
            noiseUrl: postDataRef.current.removeNoise
              ? data.results.url
              : postDataRef.current.noiseUrl,
            audEnhancedUrl: postDataRef.current.audioEnhancement
              ? data.results.url
              : postDataRef.current.audEnhancedUrl,
            clickedStory: false,
          })
        );

        dispatch(setMeter(false));
        dispatch(setMeterProgress(0));
        dispatch(setMeterTitle(""));

        dispatch(setRecordMode(false));
      } else {
        dispatch(setMeterTitle(data.progressTitle));
        dispatch(setMeterProgress(data.progress));
      }
    });

    $(".bottomQueue").css("bottom", "120px");
    $(".progressbarContainer").css("bottom", "122px");
    return () => {
      socket.off("completedProcessRecordedAudio");
      $(".bottomQueue").css("bottom", "2px");
      $(".progressbarContainer").css("bottom", "8px");
    };
  }, []);

  const startRecorder = () => {
    setRecording(true);
    dispatch(setRecordMode(true));
    // startRecording();

    var AudioContext = window.AudioContext || window.webkitAudioContext;
    var audioContext = new AudioContext();

    var constraints = {
      audio: { echoCancellation: false },
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (str) {
        var rec;
        var input;
        var gumStream;

        gumStream = str;
        input = audioContext.createMediaStreamSource(str);

        setStream(str);

        rec = new window.WebAudioRecorder(input, {
          workerDir: "/js/", // must end with slash
          encoding: "wav",
          numChannels: 1, //2 is the default, mp3 encoding supports only 2
          onEncoderLoading: function (rec, encoding) {
            // show "loading encoder..." display
            console.log("Loading " + encoding + " encoder...");
          },
          onEncoderLoaded: function (rec, encoding) {
            // hide "loading encoder..." display
            console.log(encoding + " encoder loaded");
            const tim = setInterval(() => {
              setTime((time) => time + 1);
            }, 1000);
            setTimer(tim);
          },
        });

        setRecorder(rec);

        rec.onComplete = function (rec, blob) {
          const fileName = `${uuidv4()}recording.wav`;

          const reader = new FileReader();
          reader.onload = async function (e) {
            const srcUrl = e.target.result;
            setRecording(false);
            setRecordedData(srcUrl);
            setProcessing(true);
            dispatch(setMeter(true));
            dispatch(setMeterProgress(20));
            dispatch(setMeterTitle("Uploading Audio"));

            dispatch(
              uploadedRecordedAudio({
                content: blob,
                name: fileName,
              })
            );

            setRecordedFile({
              content: blob,
              name: fileName,
            });

            // startRecordPlay(response);
          };
          reader.readAsDataURL(blob);
        };

        rec.setOptions({
          timeLimit: 120,
          encodeAfterRecord: true,
          bufferSize: 16384,
        });

        //start the recording process
        rec.startRecording();
      })
      .catch(function (err) {});
  };

  const startRecordPlay = (srcUrl) => {
    Player.playExtraAud({
      title: `Test Recording `,
      link: srcUrl ? srcUrl : recordedData,
      source: "",
      sid: uuidv4(),
      image:
        "https://storage.googleapis.com/audio_one_background_music/music.png",
    });
  };

  const stopRecorder = async () => {
    setRecording(false);
    clearInterval(timer);
    setTime(0);
    stream.getAudioTracks()[0].stop();

    recorder.finishRecording();
  };

  const onPlayPlayer = () => {
    if (recordedData) startRecordPlay();
  };

  // const onRemove = () => {
  //   if (recordedData) {
  //     setRecordedData(null);
  //     setRecordedFile(null);
  //   }
  // };
  console.log(postDataRef.current);
  return (
    <div className={styles.barContainer}>
      <div className="d-flex ">
        {/* <div
          className="ml-3 d-flex align-items-center"
          style={{ marginTop: "-20px", cursor: "pointer" }}
        >
          <img src="/images/dummyImage.png" alt="" />
          <div className="ml-2" style={{ width: "200px" }}>
            <p
              style={{
                color: "#38ef7d",
                fontSize: "10px",
                marginBottom: 0,
              }}
            >
              NFL 2022 Draft Prospects
            </p>
            <p
              style={{
                color: "#fff",
                fontSize: "10px",
                marginTop: "3px",
              }}
            >
              Macquarie Sports Radio
            </p>
          </div>
        </div> */}
        {/* <div
          className={"d-flex flex-column align-items-center ml-5 pb-2"}
          style={{ cursor: "pointer" }}
          onClick={onRemove}
        >
          <img
            src="/images/micIcon.svg"
            alt=""
            style={{ filter: "none", width: "35px" }}
            className="mb-2"
          />
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "50px",
              marginTop: "-4px",
            }}
          >
            Remove Recording
          </p>
        </div> */}
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ margin: "0 80px", cursor: "pointer" }}
        >
          <img
            src="/images/playIcon.svg"
            className="mx-5"
            style={{ width: "40px", height: "40px", filter: "invert(1)" }}
            onClick={onPlayPlayer}
          />

          <p
            className="text-center mt-2"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Play / Pause
          </p>
        </div>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ cursor: "pointer" }}
        >
          <div style={{ height: "48px", lineHeight: "44px" }}>
            {fancyTimeFormat(time)}
          </div>
          <p
            className="text-center"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Duration
          </p>
        </div>
      </div>
      <div className="d-flex" style={{ magrinTop: "-12px" }}>
        <div
          className={"d-flex flex-column align-items-center"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (recording) {
              stopRecorder();
            } else {
              startRecorder();
            }
          }}
        >
          {processing ? (
            <Spin size="large" />
          ) : recording ? (
            <img
              src="/images/stopRecordingButton.svg"
              alt=""
              style={{ width: "45px" }}
            />
          ) : (
            <img
              src="/images/recordButton.svg"
              alt=""
              style={{ filter: "invert(1)", width: "45px" }}
            />
          )}
          <p
            className="text-center mt-2"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "100px",
            }}
          >
            Record Interview
          </p>
        </div>
      </div>
      <div className="d-flex ">
        <div
          className={"d-flex flex-column align-items-center ml-5 pb-2"}
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              noiseCancellation
                ? "/images/noiseCancellationActive.svg"
                : "/images/noiseCancellation.svg"
            }
            className="mx-5"
            style={{ width: "40px", height: "40px", filter: "invert(1)" }}
            onClick={() => {
              dispatch(
                setPostData({
                  ...postDataRef.current,
                  removeNoise: !noiseCancellation,
                  audioEnhancement: false,
                })
              );
              setAudioEnhancement(false);
              setNoiseCancellation(!noiseCancellation);

              if (!noiseCancellation && recordedFile) {
                setProcessing(true);
                dispatch(setMeter(true));
                dispatch(setMeterProgress(20));
                dispatch(setMeterTitle("Processing Now"));
                dispatch(uploadedRecordedAudio(recordedFile));
              }
            }}
          />

          <p
            className="text-center mt-2"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "50px",
            }}
          >
            Noise Cancellation
          </p>
        </div>

        <div
          className={"d-flex flex-column align-items-center"}
          style={{ margin: "0 80px", cursor: "pointer" }}
        >
          <img
            src={
              audioEnhancement
                ? "/images/audioEnhancementActive.svg"
                : "/images/audioEnhancement.svg"
            }
            className="mx-5"
            style={{ width: "40px", height: "40px", filter: "invert(1)" }}
            onClick={() => {
              dispatch(
                setPostData({
                  ...postDataRef.current,
                  audioEnhancement: !audioEnhancement,
                  removeNoise: !audioEnhancement ? true : false,
                })
              );
              setNoiseCancellation(!audioEnhancement ? true : false);
              setAudioEnhancement(!audioEnhancement);

              if (!audioEnhancement) {
                if (recordedFile) {
                  setProcessing(true);
                  dispatch(setMeter(true));
                  dispatch(setMeterProgress(20));
                  dispatch(setMeterTitle("Processing Now"));
                  dispatch(uploadedRecordedAudio(recordedFile));
                }
              }
            }}
          />

          <p
            className="text-center mt-2"
            style={{
              color: "#38ef7d",
              fontSize: "10px",
              width: "70px",
            }}
          >
            Audio Enhancement
          </p>
        </div>

        {/* <div
          className={"d-flex align-items-center mx-5"}
          style={{ marginTop: "-22px" }}
        >
          {postData.members
            ? postData.members.map((item) => (
                <div className="mx-1">
                  <ImageInitial fullname={item.fullname} image={item.avatar} />
                </div>
              ))
            : ""}

          <img
            src="/images/moreIcon.svg"
            alt=""
            style={{
              borderRadius: "50%",
              filter: "none",
              width: "40px",
              border: "2px solid transparent",
              cursor: "pointer",
            }}
            className=" mx-1"
          />
        </div> */}
      </div>{" "}
    </div>
  );
}
