import React, { useState } from "react";
import styles from "../style/newQueueCard.module.css";
import player from "../data/player";

export default function NewQueueCard(props) {
  const { title, image, source, type } = props.data;
  const container = React.useRef(null);
  const Player = player();

  React.useEffect(() => {
    if (props.currentPlayed && !props.expanded)
      if (props.currentPlayed.id == props.data.id)
        if (container && container.current) {
          setTimeout(() => {
            if (container && container.current) {
              container.current.scrollIntoView({
                behavior: "smooth",
                inline: "start",
              });
            }
          }, 1200);
        }
  }, [props.currentPlayed]);

  React.useEffect(() => {
    if (props.currentPlayed && !props.expanded)
      if (props.currentPlayed.id == props.data.id)
        if (container && container.current) {
          setTimeout(() => {
            if (container && container.current) {
              container.current.scrollIntoView({
                behavior: "smooth",
                inline: "start",
              });
            }
          }, 1200);
        }
  });

  React.useEffect(() => {
    if (props.currentPlayed && !props.expanded)
      if (props.currentPlayed.id == props.data.id)
        if (container && container.current) {
          setTimeout(() => {
            if (container && container.current) {
              container.current.scrollIntoView({
                behavior: "smooth",
                inline: "start",
              });
            }
          }, 1200);
        }
  }, [props.expanded]);

  let itemIndex = props.queue.items.findIndex(
    (item) => item.id == props.data.id
  );
  const { expanded } = props;

  return (
    <div
      onClick={() => {
        if (!props.expanded)
          setTimeout(() => {
            container.current.scrollIntoView({
              behavior: "smooth",
              inline: "start",
            });
          }, 800);

        if (window.innerWidth < 768) props.onExpand(!props.expanded);
      }}
      className={` ${
        props.queue.currentPlayed["id"] &&
        props.queue.currentPlayed.id == props.data.id
          ? styles.activeItem
          : ""
      } ${expanded ? styles.expanded : ""} ${styles.container}`}
      style={{
        background:
          props.queue.currentPlayed["id"] &&
          props.queue.currentPlayed.id == props.data.id &&
          !expanded
            ? "#4d4e4e"
            : image
            ? `url(${image})`
            : "#4d4e4e",
      }}
      ref={container}
    >
      {props.queue.currentPlayed.id !== props.data.id ||
      (props.queue.currentPlayed.id == props.data.id && expanded) ? (
        <div className={styles.overlay}></div>
      ) : (
        ""
      )}

      <div className="d-flex ">
        {props.queue.currentPlayed["id"] &&
        props.queue.currentPlayed.id == props.data.id &&
        !expanded ? (
          ""
        ) : (
          <>
            <div className={styles.responsiveTypeIcon}>
              {type == "podcast" ? (
                <img
                  src="/images/Radio.png"
                  style={{ filter: "brightness(2)", width: "25px" }}
                />
              ) : type == "spotify" ? (
                <img src="/images/spotWhite.png" />
              ) : (
                <img src="/images/feedlyWhite.png" />
              )}{" "}
              {type == "post" ? "Podcast" : type == "normal" ? "News" : type}
            </div>

            <img
              src="/images/nextButtonIcon.svg"
              alt=""
              className={styles.responsiveIcon}
              onClick={(e) => {
                props.onPlay(true);
              }}
            />
          </>
        )}
        <div className={styles.imgContainer}>
          {props.queue.currentPlayed["id"] &&
          props.queue.currentPlayed.id == props.data.id ? (
            <>
              <img src={image} alt="" />
              <div className={styles.thumbGradient}></div>
            </>
          ) : (
            ""
          )}

          <div className={styles.actionIcons}>
            {props.queue.currentPlayed["id"] &&
            props.queue.currentPlayed.id == props.data.id ? (
              props.queue.currentPlayed.playing ? (
                <img
                  src="/images/circlePause.svg"
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    Player.playQueue(props.data);
                  }}
                />
              ) : (
                <img
                  src={"/images/playIcon.svg"}
                  alt=""
                  style={{ width: "40px", opacity: "50%", filter: "invert(1)" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    Player.playQueue(props.data);
                  }}
                />
              )
            ) : itemIndex < props.currentIndex ? (
              <img
                src={"/images/nextButtonIcon.svg"}
                alt=""
                style={{ width: "48px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onPlay(true);
                }}
              />
            ) : (
              <img
                src="/images/nextButtonIcon.svg"
                alt=""
                style={{ width: "48px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onPlay(true);

                  // Player.playQueue(props.data);
                  // else Player.playAudio(props.data);
                }}
              />
            )}
          </div>
        </div>

        <div>
          <p className={styles.title}>{title.split(":")[0]}</p>
          <p className={styles.source}>{title.split(":")[1]}</p>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between h-100">
        {props.queue.currentPlayed["id"] &&
        props.queue.currentPlayed.id == props.data.id ? (
          <div className="d-flex justify-content-center">
            <img
              src="/images/forwardIcon.png"
              alt=""
              className={`${styles.forward} mr-3`}
              style={{ transform: "rotate(180deg)" }}
              onClick={(e) => {
                e.stopPropagation();
                props.playPrevious();
              }}
            />
            <img
              src="/images/forwardIcon.png"
              alt=""
              className={styles.forward}
              onClick={(e) => {
                e.stopPropagation();
                props.playForward();
              }}
            />
          </div>
        ) : (
          <div></div>
        )}

        <div
          className="d-flex align-items-center"
          style={{
            marginLeft:
              props.queue.currentPlayed["id"] &&
              props.queue.currentPlayed.id == props.data.id
                ? "0"
                : "-60px",
          }}
        >
          {props.type == "podcast" ? (
            <img
              src="/images/Radio.png"
              style={{ width: "15px", height: "15px", marginRight: "10px" }}
            />
          ) : props.type == "spotify" ? (
            <img
              src="/images/spotWhite.png"
              style={{ width: "15px", height: "15px", marginRight: "10px" }}
            />
          ) : (
            <img
              src="/images/feedlyWhite.png"
              style={{ width: "15px", height: "15px", marginRight: "10px" }}
            />
          )}

          <span
            style={{
              textTransform: "capitalize",
              color: "white",
              fontSize: "0.7em",
            }}
          >
            {props.type == "normal" ? "News" : props.type}
          </span>
        </div>
      </div>
    </div>
  );
}
