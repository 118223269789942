import React from "react";
import { connect } from "react-redux";
import AllPost from "../post/AllPost";
import style from "../../style/tabs.module.css";
var mapStateToProps = (state) => {
  return {
    todayNews: state.news.todayNews,
    selectedFeed: state.news.selectedFeed,
    feedlyUser: state.news.feedlyUser,
    projects: state.projects.projects.filter((item) => item.type === "post"),
  };
};

function PostTab(props) {
  return (
    <div id="tabsContainer" className={style.tabsContainer}>
      <AllPost projects={props.projects} />
    </div>
  );
}

export default connect(mapStateToProps, null)(PostTab);
