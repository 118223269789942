import React, { useState, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk";
import firebase from "../../firebase";
import { useSelector } from "react-redux";
import { createLogger } from "redux-logger";
import axios from "axios";
import { setInterviewOptions } from "../../redux/actions/main";
import { useDispatch } from "react-redux";
import {
  recordAcquire,
  recordQuery,
  recordStart,
  recordStop,
} from "../../api/agoraHelper";
import $ from "jquery";

export default function LiveStreaming(props) {
  const [agoraClient, setAgoraClient] = useState(null);
  const [localStreams, setLocalStreams] = useState([]);
  const [localStream, setLocalStream] = useState("");
  const [recording, setRecording] = useState(false);
  const [sid, setsid] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);

  const [currentUserId, setCurrentUserId] = useState(
    Math.floor(Math.random() * 99999999)
  );
  const [token, setToken] = useState(false);

  const dispatch = useDispatch();
  const interviewOptionRef = useRef(null);

  const { interviewOptions } = useSelector((state) => state.main);
  const { user } = useSelector((state) => state.auth);
  let streamOptions = {
    audio: true,
    video: false,
    streamID: null,
    screen: false,
  };

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    interviewOptionRef.current = interviewOptions;
    if (interviewOptions.globalRecording == "stop" && recording)
      handleRecordStop();
    else if (interviewOptions.globalRecording == "start" && !recording)
      handleRecordStart(currentUserId, props.room.title, token);
  }, [interviewOptions]);

  const loadClient = async () => {
    let tempClient = AgoraRTC.createClient({
      mode: "live",
      codec: "vp8",
    });

    const tempToken = await axios
      .get(
        `https://devtests.audioone.cloud/access_token?channel=${props.room.title}&uid=${currentUserId}`
      )
      .then((res) => res.data.token)
      .catch((err) => {
        console.log(err);
        return false;
      });

    setToken(tempToken);

    try {
      tempClient.init(process.env.REACT_APP_AGORA_APP_ID);

      setAgoraClient(tempClient);
      subscribeToStreamStart(tempClient);
      subscribeToStreamStop(tempClient);
      streamOptions.streamID = props.roomId;

      tempClient.on("client-role-changed", (evt, role) => {
        console.log("User role changed", evt);
      });
      setUserOnline(user, props.roomId);

      tempClient.join(
        tempToken,
        props.room.title,
        currentUserId,
        (uid) => {
          setLocalStreams([...localStreams, uid]);
          console.log("User " + uid + " join channel successfully");

          // handleRecordStart(uid, props.room.title, tempToken);
          dispatch(setInterviewOptions({ userId: currentUserId }));

          tempClient.on("user-joined", handleUserJoined);
          tempClient.on("user-left", handleUserLeft);

          tempClient.enableAudioVolumeIndicator();
          tempClient.on("volume-indicator", (volumes) => {
            console.log(volumes);

            volumes.attr.forEach((volume) => {
              console.log(`UID ${volume.uid} Level ${volume.level}`);
              if (currentUserId == volume.uid && volume.level > 5) {
                $("#audioPlayer0").css({
                  "box-shadow": "0 2px 4px 0 #0C9DFD, 0 2px 5px 0 #0C9DFD",
                });
              } else if (currentUserId == volume.uid && volume.level < 5) {
                $("#audioPlayer0").css({
                  "box-shadow": "none",
                });
              }

              if (currentUserId != volume.uid && volume.level > 5) {
                $("#audioPlayer1").css({
                  "box-shadow": "0 2px 4px 0 #0C9DFD, 0 2px 5px 0 #0C9DFD",
                });
              } else if (currentUserId != volume.uid && volume.level < 5) {
                $("#audioPlayer1").css({
                  "box-shadow": "none",
                });
              }
            });
          });
          // Create a local stream
          tempClient.setClientRole("host");
          createLocalStream(tempClient);
        },
        handleError
      );
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  React.useEffect(() => {
    loadClient();

    return () => {
      handleRecordStop();
      leaveRoom();
    };
  }, []);

  // Handle user join
  const handleUserJoined = (user) => {
    const id = user.uid;
    setRemoteUsers([id, ...remoteUsers]);
  };

  // Handle user leave
  const handleUserLeft = (user) => {
    const id = user.uid;
    setRemoteUsers(remoteUsers.filter((item) => item !== id));
  };

  const handleRecordStart = async (uid, roomname, channelToken) => {
    recordAcquire(uid, roomname).then((val) => {
      recordStart(uid, val.data.resourceId, roomname, channelToken).then(
        (val2) => {
          setsid(val2);
          setRecording(true);
        }
      );
    });
  };
  const handleRecordStop = async () => {
    try {
      recordQuery(sid)
        .then(async (d1) => {
          console.warn(d1);
          let data = await recordStop(sid, currentUserId, props.room.title);
          console.log(data);
          setRecording(false);
          return data;
        })
        .catch((error) => {
          console.error(error);
          setRecording(false);
        });
    } catch (error) {
      console.error(error);
      setRecording(false);
    }
  };

  const setUserOnline = (newUser, roomId) => {
    try {
      const reference = firebase
        .database()
        .ref(`/online/${roomId}/${newUser._id}`);
      const online = {
        displayName: newUser.email,
        date: new Date().getTime(),
        key: newUser._id,
      };
      reference.set(online).then(() => {
        console.log("Online status set as true");
      });

      reference
        .onDisconnect()
        .remove()
        .then(() => console.log("On disconnect configured"));
    } catch (e) {
      console.log(e);
    }
  };
  // change

  const setPresenceOffline = (roomId, newUser) => {
    firebase.database().ref(`/online/${roomId}/${newUser._id}`).remove();
  };

  const createLocalStream = (tempClient) => {
    const locStream = AgoraRTC.createStream(streamOptions);
    setLocalStream(locStream);
    locStream.init(() => {
      tempClient.publish(locStream, handleError);
    }, handleError);
  };

  const handleError = (err) => {
    console.log(err, "error is");
    console.error(err);
  };

  const subscribeToStreamStart = (tempClient) => {
    tempClient.on("stream-added", (evt) => {
      if (!localStreams.includes(evt.stream.getId())) {
        tempClient.subscribe(evt.stream, null, handleError);
      }
    });
    // Play the remote stream when it is subsribed
    tempClient.on("stream-subscribed", (evt) => {
      const stream = evt.stream;
      const streamId = String(stream.getId());
      addStream(streamId);
      stream.play(streamId);
    });
  };

  const subscribeToStreamStop = (tempClient) => {
    // Remove the corresponding view when a remote user unpublishes.
    tempClient.on("stream-removed", (evt) => {
      const stream = evt.stream;
      const streamId = String(stream.getId());
      stream.close();
      removeStream(streamId);
    });
    // Remove the corresponding view when a remote user leaves the channel.
    tempClient.on("peer-leave", (evt) => {
      const stream = evt.stream;
      const streamId = String(stream.getId());
      stream.close();
      removeStream(streamId);
    });
  };

  const leaveRoom = () => {
    if (localStream) {
      setPresenceOffline(user._id, props.roomId);
      localStream.stop();
      localStream.close();
    }
  };

  const addStream = (elementId) => {
    console.log(elementId);
    // Creates a new div for every stream
    const streamDiv = document.createElement("div");
    streamDiv.id = elementId;
    const container = document.querySelector("#roomContainer");
    console.log(streamDiv, "streamdiv is");
    console.log(container);
    container.appendChild(streamDiv);
  };
  const removeStream = (elementId) => {
    const remoteDiv = document.getElementById(elementId);
    if (remoteDiv) {
      remoteDiv.remove();
    }
  };

  return <div id="roomContainer"></div>;
}
