import React, { useState, useEffect } from "react";
import style from "../../style/LatestFeed.module.css";
import { connect } from "react-redux";
import { addItemsToCampaign } from "../../redux/actions/project";
import { setAddingSource } from "../../redux/actions/main";
import ContactCard from "./ContactCard";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
});

function ContactsContainer(props) {
  const [contacts, setContacts] = useState(
    props.contacts ? props.contacts : props.activeProject.contacts
  );

  const renderItems = (data) =>
    data.map((item) => (
      <div className="mb-3 py-0 pr-0">
        <ContactCard data={{ ...item }} />
      </div>
    ));

  useEffect(() => {
    if (props.query) {
      setContacts(
        contacts.filter((item) =>
          `${item.firstName} ${item.lastName}`.includes(props.query)
        )
      );
    } else {
      if (props.contacts) setContacts(props.contacts);
      else setContacts(props.activeProject.contacts);
    }
  }, [props.query]);

  useEffect(() => {
    if (props.activeProject.contacts) {
      setContacts(props.activeProject.contacts);
    }
  }, [props.activeProject.contacts]);

  useEffect(() => {
    if (!props.contacts) props.setAddingSource("Contacts");
    return () => {};
  }, []);

  return (
    <div className={`${style.LatestFeed} mt-0`}>
      <div
        className={`row mt-3 mx-0 ${style.dropContainer}`}
        style={{ paddingBottom: "10em" }}
      >
        {renderItems(contacts)}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(ContactsContainer);
