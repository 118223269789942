import React, { useEffect, useState } from "react";
import style from "../../style/sidebarTabsContent.module.css";

import RecentItemCard from "./RecentItemCard";
import { connect } from "react-redux";
import {
  removeItemFromProject,
  addItemsToCampaign,
  addItemToProject,
} from "../../redux/actions/project";
import { setAddingSource } from "../../redux/actions/main";
import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
import { message } from "antd";
import createStyle from "../../style/createproject.module.css";

const mapState = (state) => ({
  activeProject: state.projects.activeProject
    ? state.projects.activeProject
    : state.projects.activePreview
    ? state.projects.activePreview
    : false,
  user: state.auth.user,

  recentItems: state.projects.activeProject
    ? state.projects.activeProject.type == "campaign"
      ? state.projects.activeProject.addedItems
      : state.projects.activeProject.recentItems
    : state.projects.activePreview
    ? state.projects.activePreview.type == "campaign"
      ? state.projects.activePreview.addedItems
      : state.projects.activePreview.recentItems
    : [],
});

const dispatchState = (dispatch) => ({
  removeItemFromProject: (item) => dispatch(removeItemFromProject(item)),
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  setAddingSource: (data) => dispatch(setAddingSource(data)),
  addItemToProject: (data) => dispatch(addItemToProject(data)),
});

function RecentItem({
  recentItems,
  removeItemFromProject,
  addItemsToCampaign,
  data,
  topPosition,
  showDrag,
  addView,
  activeProject,
  user,
  showTab,
  addItemToProject,
}) {
  const [items, setItems] = useState(
    data && data.length > 0 ? data : addView ? [] : recentItems
  );

  const [filter, setFilter] = useState("All");

  const tabs = ["Added Items"];

  useEffect(() => {
    let tempData = data && data.length > 0 ? data : addView ? [] : recentItems;
    tempData = tempData.map((item) => {
      if (item.article) return { ...JSON.parse(item.article), ...item };
      return item;
    });
    setItems(tempData);
  }, [data, recentItems]);

  let tempItems = items;
  if (filter !== "All")
    tempItems = tempItems.filter(
      (item) => JSON.parse(item.article).type == filter
    );
  return (
    <div style={{ paddingTop: "4em" }} className={style.RecentItem}>
      <div
        className={`${style.topSection} `}
        style={{ paddingTop: addView ? "8em" : "0.5em" }}
      >
        <div className={style.RecentItemTags}>
          <a
            href="#"
            className={filter == "All" ? style.active : ""}
            onClick={() => setFilter("All")}
          >
            All
          </a>
          <a
            href="#"
            className={filter == "news" ? style.active : ""}
            onClick={() => setFilter("news")}
          >
            News
          </a>
          <a
            href="#"
            className={filter == "shorts" ? style.active : ""}
            onClick={() => setFilter("shorts")}
          >
            Shorts
          </a>
          <a
            href="#"
            className={filter == "podcast" ? style.active : ""}
            onClick={() => setFilter("podcast")}
          >
            Podcast
          </a>
          <a
            href="#"
            className={filter == "post" ? style.active : ""}
            onClick={() => setFilter("post")}
          >
            Post
          </a>
        </div>
      </div>
      <div
        className={style.RecentItemCards}
        style={{
          padding: "0em",
          paddingTop: "2.5em",
          paddingBottom: "6em",
        }}
      >
        <h4
          className="mb-0"
          style={{
            fontSize: 17,
            fontWeight: "bold",
            position: "absolute",
            zIndex: 99999,
            top: addView ? "200px" : "70px",
          }}
        >
          {addView ? "Recently Added Items" : "Recent Items"}
        </h4>
        {showDrag ? (
          <ReactSortable
            list={tempItems}
            setList={(newState) => {
              const { members } = activeProject;
              // if (activeProject.invited) {
              //   const foundMember = members.find(
              //     (item) => item.user_id == user._id
              //   );
              //   if (foundMember)
              //     switch (foundMember.role) {
              //       case "Member":
              //         message.error("Member can't edit a campaign");
              //         return;
              //     }
              // }

              let newArts = newState.map((item) => {
                if (item.article) return JSON.parse(item.article);
                return item;
              });
              console.log(newArts);
              console.log(tempItems);
              if (newArts.length !== tempItems.length) {
                console.log("inside here");
                if (activeProject.type == "campaign")
                  addItemsToCampaign(newArts);
                else {
                  let newIds = newArts.map((item) => {
                    return item.id;
                  });
                  let existingIds = tempItems.map((item) => {
                    return item.id;
                  });

                  console.log(newIds);
                  console.log(existingIds);
                  const foundIndex = newIds.findIndex(
                    (item) => !existingIds.includes(item)
                  );
                  console.log(foundIndex);
                  console.log(newArts[foundIndex]);

                  addItemToProject(newArts[foundIndex]);
                }
                // setItems(newArts);
              }
            }}
            group="campaignFeed"
            style={{ minHeight: "300px" }}
          >
            {tempItems.map((item) => (
              <RecentItemCard
                key={item._id ? item._id : item.id}
                data={item}
                onDelete={() => removeItemFromProject(item._id)}
              />
            ))}
          </ReactSortable>
        ) : (
          tempItems &&
          tempItems.map((item) => (
            <RecentItemCard
              data={item}
              onDelete={() => removeItemFromProject(item._id)}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default connect(mapState, dispatchState)(RecentItem);
