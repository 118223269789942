import React from "react";
import styles from "../../style/shareLinkTab.module.css";
import sidebarstyle from "../../style/sidebarTabsContent.module.css";
import voices from "./voices.json";
import style from "../../style/sidebarTabsContent.module.css";
import ImageInitial from "../ImageInitial";
import { useDispatch } from "react-redux";
import { setShareSidebar } from "../../redux/actions/main";
import { Switch } from "antd";
import { connect } from "react-redux";
import player from "../../data/player";
import backgroundMusicAudio from "../sidebarTabs/backgroundLoopSounds.json";
const mapState = (state) => ({
  postData: state.main.postData,
  queue: state.site.queue,
});
const Player = player();

function ShareLinkTab(props) {
  const [selectedType, setSelectedType] = React.useState("textVoice");
  const [selectedVoice, setSelectedVoice] = React.useState("");
  const [backgroundMusic, setBackgroundMusic] = React.useState(false);
  const { currentPlayed } = props.queue;

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <div className={"d-flex justify-content-between"}>
        <span className={styles.heading}>Share Options</span>
        <img
          src="/images/images/closeCircleOutlined.svg"
          className={styles.closeBtn}
          onClick={() => dispatch(setShareSidebar({ visible: false }))}
        />
      </div>

      <div
        className={` ${sidebarstyle.popupCard} d-flex justify-content-center`}
      >
        <div className={`${sidebarstyle.actionBtn} px-0 w-100`}>
          <button
            className={sidebarstyle.active}
            onClick={() => {
              setSelectedType("textVoice");
            }}
            style={{
              borderColor:
                selectedType == "textVoice" ? "#38ef7d" : "transparent",
            }}
          >
            <img src="/images/speaking.svg" alt="" />
            <span>Text to Voice</span>
          </button>
          <button
            className={sidebarstyle.active}
            onClick={() => {
              setSelectedType("backgroundMusic");
            }}
            style={{
              borderColor:
                selectedType == "backgroundMusic" ? "#38ef7d" : "transparent",
            }}
          >
            <img
              src="/images/backgroundMusic.svg"
              style={{
                width: "29px",
                height: "22px",
                filter: "invert(1)",
                marginBottom: "6px",
              }}
              alt=""
            />
            <span>Background Music</span>
          </button>
        </div>
      </div>

      <div
        style={{
          overflow: "auto",
          height: "530px",
          paddingBottom: "5em",
          paddingTop: "3em",
        }}
      >
        {selectedType == "textVoice" ? (
          <>
            <h4
              className="mb-0"
              style={{
                fontSize: 17,
                fontWeight: "bold",
                marginTop: "5em",
                position: "absolute",
                top: "100px",
                zIndex: 999999,
              }}
            >
              VOICES
            </h4>
            {voices.map((item) => (
              <div
                className={`${style.userItem} d-flex mt-3 align-items-center`}
                onClick={() => {
                  setSelectedVoice(item);
                  dispatch(
                    setShareSidebar({
                      visible: true,
                      voice: item,
                      background: backgroundMusic,
                    })
                  );
                }}
              >
                <ImageInitial fullname={item.fullname} image={item.avatar} />

                <div className={style.userDetail}>
                  <div
                    style={{
                      color:
                        selectedVoice.fullname == item.fullname
                          ? "#38ef7d"
                          : "white",
                    }}
                  >
                    <p>{item.fullname}</p>

                    <p>
                      {" "}
                      <span style={{ color: "white" }}>
                        {" "}
                        {item.premium ? "Premium" : ""}
                      </span>{" "}
                      Narration
                    </p>
                  </div>
                  <div>
                    <img src="/images/playIcon.svg" />
                  </div>
                </div>
              </div>
            ))}{" "}
          </>
        ) : (
          <>
            <h4
              className="mb-0"
              style={{
                fontSize: 17,
                fontWeight: "bold",
                marginTop: "5em",
                position: "absolute",
                top: "100px",
                zIndex: 999999,
              }}
            >
              BACKGROUND MUSIC
            </h4>
            {backgroundMusicAudio.map((item, index) => (
              <div
                className={`${style.reverbContainer} d-flex align-items-center p-2`}
              >
                <p>{index + 1}</p>
                <div
                  className=" ml-2 d-flex justify-content-between align-items-center"
                  style={{ flex: 1 }}
                >
                  <div>
                    <span className="d-block">Sound {item.name}</span>
                    <img
                      src="/images/backgroundMusic.svg"
                      alt=""
                      style={{ width: "15px", filter: "invert(1)" }}
                    />{" "}
                  </div>
                  <div className="text-center">
                    {" "}
                    <div>
                      {currentPlayed.id == item.id && currentPlayed.playing ? (
                        <img
                          src={"/images/images/pause.png"}
                          className="mb-2"
                          style={{ width: "25px", cursor: "pointer" }}
                          onClick={() => {
                            Player.playQueue(item);
                          }}
                        />
                      ) : (
                        <img
                          src={"/images/playIcon.svg"}
                          className="mb-2"
                          style={{ filter: "invert(1)", cursor: "pointer" }}
                          onClick={() => {
                            if (
                              currentPlayed.id == item.id &&
                              !currentPlayed.playing
                            )
                              Player.playQueue({
                                ...item,
                                sid: index,
                                url: item.loops[
                                  Math.floor(Math.random() * item.loops.length)
                                ],
                              });
                            else
                              Player.playExtraAud({
                                title: item.name,
                                link: item.loops[
                                  Math.floor(Math.random() * item.loops.length)
                                ],
                                source: "",
                                subtype: "extra",
                                sid: index,
                                image:
                                  "https://storage.googleapis.com/audio_one_background_music/music.png",
                              });
                          }}
                        />
                      )}
                    </div>
                    <Switch
                      className={`${style.switch} ${
                        backgroundMusic == `${item.name}`
                          ? style.activeSwitch
                          : ""
                      }`}
                      checkedChildren={<span>On</span>}
                      unCheckedChildren={<span>Off</span>}
                      checked={backgroundMusic == `${item.name}`}
                      onChange={() => {
                        if (backgroundMusic == `${item.name}`) {
                          // if (props.postData.post_type !== "STORY")
                          setBackgroundMusic(false);
                          dispatch(
                            setShareSidebar({
                              visible: true,
                              background: false,
                              voice: selectedVoice,
                            })
                          );
                        } else {
                          dispatch(
                            setShareSidebar({
                              visible: true,
                              voice: selectedVoice,
                              background: item.name,
                            })
                          );
                          setBackgroundMusic(`${item.name}`);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
export default connect(mapState, null)(ShareLinkTab);
