import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import environment from "../../api/environment";
const ChangeFontSize = require("@quanzo/change-font-size");

export const EDITOR_JS_TOOLS = {
  // embed: Embed,
  // table: Table,
  // marker: Marker,
  // list: List,
  // warning: Warning,
  // code: Code,
  // linkTool: LinkTool,
  // image: {
  //   class: Image,
  //   config: {
  //     endpoints: {
  //       byFile: `${environment.api.URL}/post/uploadImage`, // Your backend file uploader endpoint
  //       byUrl: `${environment.api.URL}/post/fetchUrl`, // Your endpoint that provides uploading by Url
  //     },
  //   },
  // },
  // raw: Raw,
  // header: Header,
  // quote: Quote,
  // checklist: CheckList,
  // delimiter: Delimiter,
  // inlineCode: InlineCode,
  // plus10percent: {
  //   class: ChangeFontSize,
  // },
  // plus20percent: {
  //   class: ChangeFontSize,
  //   config: {
  //     cssClass: "plus20pc",
  //     buttonText: "20%",
  //   },
  // },
  // simpleImage: SimpleImage,
};
