import React, { useState, useEffect, useRef } from "react";
import style from "../../style/editCampaign.module.css";
import { Button, message } from "antd";
import { connect } from "react-redux";
import { addItemsToCampaign, editProject } from "../../redux/actions/project";
import apiRequest from "../../api/clientRequest";
import axios from "axios";
import TemplatePicker from "../campaign/TemplatePicker";
import { encode, decode } from "js-base64";

const mapState = (state) => ({
  activeProject: state.projects.activeProject,
  activeColor: state.projects.activeColor,
  user: state.auth.user,
});

const dispatchState = (dispatch) => ({
  addItemsToCampaign: (data) => dispatch(addItemsToCampaign(data)),
  editProject: (data) => dispatch(editProject(data)),
});

function EditCampaign(props) {
  const [emailEdittor, setEditor] = useState(null);
  const [templatePicker, setTemplatePicker] = useState("");

  useEffect(() => {
    if (document.getElementById("editor-container"))
      document.getElementById("editor-container").remove();

    var para = document.createElement("div"); // Create a <p> element
    para.style.height = "580px";
    para.setAttribute("id", "editor-container");

    document.getElementById("editorOutside").appendChild(para);

    const editor = window.unlayer.createEditor({
      id: "editor-container",
      projectId: 9154,
      displayMode: "email",
      customCSS: [
        "https://use.fontawesome.com/releases/v5.1.1/css/all.css",
        `
      .blockbuilder-preview {
        background-color: ${props.activeColor.secondaryColor};
        background-image: none;
      }

      .blockbuilder-preferences{
        border-left:none !important
      }
        .blockbuilder-preferences   div{
          background-color: ${props.activeColor.secondaryColor} !important;
          background-image: none !important;
        }
    
        .blockbuilder-preferences .nav-tabs .nav-item .nav-link.active{
          background-color: ${props.activeColor.primaryColor} !important;
          background-image: none !important;
        }

        .blockbuilder-preferences .nav-tabs .nav-item .nav-link:hover{
          background-color: ${props.activeColor.primaryColor} !important;
          background-image: none !important;
        }

        .blockbuilder-preview.design-web.editor-desktop{
          overflow-x:hidden
        }

        .blockbuilder-preview.design-web.editor-desktop::-webkit-scrollbar-track,.col-sm-12::-webkit-scrollbar-track,.icsSuz .tab-content::-webkit-scrollbar-track,.blockbuilder-options-content::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: ${props.activeColor.secondaryColor};
        }
      
        .blockbuilder-preview.design-web.editor-desktop::-webkit-scrollbar,.col-sm-12::-webkit-scrollbar,.icsSuz .tab-content::-webkit-scrollbar ,.blockbuilder-options-content::-webkit-scrollbar{
            width: 6px;
            background-color: transparent;
        }
        
        .blockbuilder-preview.design-web.editor-desktop::-webkit-scrollbar-thumb,.col-sm-12::-webkit-scrollbar-thumb,.icsSuz .tab-content::-webkit-scrollbar-thumb ,.blockbuilder-options-content::-webkit-scrollbar-thumb{
            background-color: white;
            border-radius:15px;
        }

        .icsSuz .blockbuilder-content-tool, .icsSuz .blockbuilder-classic-tool{
          border:1px solid black !important; 
        }
        .jYMMZB .blockbuilder-column::after{
          outline:black solid 1px
        }

        .fXGmdA{
          color:black !important
        }

        .bootstrap input{
          background:transparent !important;
          border:1px solid black !important;
        }

        .eNpfYQ{
          background-color:transparent;
          color:black
        }
        .bZHhHK > .card .card-header{
          background:transparent;
          border:1px solid black !important;
          color:black !important;
        }

        .blockbuilder-options-content{
          padding:0 8px
        }

        .gLAFCy .blockbuilder-widget-label label.blockbuilder-label-primary{
          color:black
        }

        .gLAFCy{
          border-bottom:1px solid ${props.activeColor.primaryColor} !important;
        }

        .kVnMcU .form-control,.kVnMcU .input-group-text{
          background-color:${props.activeColor.primaryColor} !important;
          border:1px solid black !important;
          color:black !important
        }
        .gLAFCy .blockbuilder-widget-hint{
          color:black !important
        }
        .cFvDFf .blockbuilder-counter-control-value{
          background-color:${props.activeColor.primaryColor} !important;
          border-color:black !important
        }

        .haLTEN .nav-tabs{
          background-color:transparent !important;
          border-color:${props.activeColor.primaryColor} !important
        }

        .haLTEN .nav-tabs .nav-item .nav-link.active,.haLTEN .blockbuilder-options-header,.haLTEN .blockbuilder-options-header .icon,.cylmga{
          border-color:${props.activeColor.primaryColor} !important
        }
        

        .gLAFCy .blockbuilder-widget-label label,.bZHhHK > .card .card-header .group-toggle{
          color:black !important
        }

        .hCRjOw .blockbuilder-dropzone,.kdRQfw{
          border-color:black !important;
          color:black !important
        }

        .cFvDFf{
          border-color:black !important
        }

        .icsSuz .nav-tabs .nav-item .nav-link .nav-icon,.icsSuz .nav-tabs .nav-item .nav-link .nav-name{
          color:#1c1c1c !important;
          opacity:1 !important
        }
        .eTOHUT{
          color:#1c1c1c
        }
      `,
      ],
    });

    editor.addEventListener("design:updated", async function (updates) {
      // Design is updated by the user

      const { members } = props.activeProject;
      const { user } = props;
      if (props.activeProject.invited) {
        const foundMember = members.find((item) => item.user_id == user._id);
        if (foundMember)
          switch (foundMember.role) {
            case "Member":
              return;
          }
      }

      editor.saveDesign(async function (design) {
        apiRequest({
          url: "/campaigns/emails",
          method: "POST",
          data: {
            projectId: props.activeProject._id,
            item: JSON.stringify(design),
            type: "template",
          },
        }).then((res) => {
          // message.success("Template saved");
        });
      });
    });

    // editor.addEventListener("onLoad", function (updates) {
    //   // Design is updated by the user
    //   console.log("loaded");
    // });

    if (
      props.activeProject.design &&
      Object.keys(props.activeProject.design).length !== 0
    )
      editor.loadDesign(JSON.parse(props.activeProject.design));
    setEditor(editor);
  }, [props.activeProject]);

  const saveCampaign = () => {
    const { members } = props.activeProject;
    const { user } = props;
    if (props.activeProject.invited) {
      const foundMember = members.find((item) => item.user_id == user._id);
      if (foundMember)
        switch (foundMember.role) {
          case "Member":
            message.error("Member can not edit a campaign");
            return;
        }
    }

    emailEdittor.saveDesign(async function (design) {
      const image = await axios({
        url: "https://api.unlayer.com/v2/export/image",
        method: "POST",
        data: {
          displayMode: "email",
          design,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic SnNUTE0zMDhENWkwRnlleDhWUnEzZjhsOGx5THExeFBSQVhRcVVGZUcycllmR1FxdjhiZFQ3dDJYOWZlcWFjZzpDb3dvcmtpbmcyMDE5JCRfXw==`,
        },
      })
        .then((response) => {
          return response.data.data.url;
        })
        .catch((err) => {
          console.log(err);
        });

      apiRequest({
        url: "/campaigns/emails",
        method: "POST",
        data: {
          projectId: props.activeProject._id,
          item: JSON.stringify(design),
          type: "template",
          image,
        },
      }).then((res) => {
        message.success("email saved");
        props.editProject({ ...res.data, feeds: [], type: "template" });
      });
    });
  };

  return (
    <div className={`${style.editCampaign} mt-0`}>
      <div className="mb-4 d-flex align-items-center">
        <Button
          style={{
            borderRadius: "15px",
            backgroundColor: templatePicker
              ? "rgba(255, 255, 255, 0.43)"
              : "transparent",
            borderColor: "white",
            color: "white",
          }}
          className="mr-4"
          onClick={() => setTemplatePicker(!templatePicker)}
        >
          {templatePicker ? "Close Template Browser" : "View Template Browser"}
        </Button>
        <Button
          style={{
            borderRadius: "15px",
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
          }}
          className="ml-4"
          ref={props.btnRef}
          onClick={() => saveCampaign()}
        >
          Save Template
        </Button>
      </div>
      {templatePicker ? (
        <TemplatePicker
          onClick={(item) => emailEdittor.loadDesign(item.design)}
          color={props.activeColor.primaryColor}
        />
      ) : (
        ""
      )}

      <div
        className="row mt-3 mx-0"
        id="editorOutside"
        style={{ paddingBottom: "10em", width: "90%" }}
      ></div>
    </div>
  );
}

export default connect(mapState, dispatchState)(EditCampaign);
